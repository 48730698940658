<mat-form-field class="form-field-container" appearance="outline" color="accent">
  <mat-label>Query Term</mat-label>
  <mat-select
    [formControl]="dropdownControl"
    (selectionChange)="onDropdownChanged()"
    [value]="currentOption?.fullName"
    class="fit-content-field"
  >
    <mat-option *ngFor="let option of availableSearchTerms" [value]="option.fullName">
      {{ option.fullName }}
    </mat-option>
  </mat-select>
</mat-form-field>
