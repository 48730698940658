import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { LogicalOperator, PopulatedSearchOption, SearchableOption } from "@tdms/common";

/**
 * Component that represents the logical operator and grouping indentation as displayed by the advanced query builder.
 * It essentially acts as a container around query-segment that adds the logical operator selector and any indentation as required.
 */
@Component({
  selector: "data-store-query-grouping",
  templateUrl: "./query-grouping.component.html",
  styleUrls: ["./query-grouping.component.scss"],
})
export class QueryGroupingComponent implements OnInit, OnChanges {
  /**
   * The search option represented in this grouping.
   */
  @Input() searchOption!: PopulatedSearchOption;

  /**
   * The available search options/terms to choose from.
   */
  @Input() availableOptions?: SearchableOption[];

  /**
   * If we are currently executing a search query.
   */
  @Input() isSearching!: boolean;

  /**
   * Called when this grouping is deleted.
   */
  @Input() optionDeleted!: (option: PopulatedSearchOption) => void;

  /**
   * Called when this grouping's values are updated (term, value, comparison, logical operator, etc).
   * Note that the logical operator indicated by the search option is actually tied to the *previous* search option,
   * meaning that this callback will be called with the previous option in that instance.
   */
  @Input() optionUpdated!: (option: PopulatedSearchOption) => void;

  /**
   * The search option that directly precedes this one.
   * It's logical operator will be displayed in our dropdown.
   */
  @Input() previousSearchOption?: PopulatedSearchOption;

  /**
   * The previous logical operator, pulled from the provided previous search option.
   */
  previousLogicalOperator?: LogicalOperator;

  constructor() {
    this.onLogicalOperatorChanged = this.onLogicalOperatorChanged.bind(this);
  }

  ngOnInit(): void {
    if (this.previousSearchOption != null) {
      this.previousLogicalOperator = this.previousSearchOption.nextLogicalOperator;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.previousSearchOption != null)
      this.previousLogicalOperator = this.previousSearchOption?.nextLogicalOperator;
  }

  /**
   * Since the logical operators now show up on the row for the last term in the "grouping",
   * this assigns the operator to the previous operator passed in.
   * If the previous option is not passed in, this is a no-op.
   * @param operator The operator that was selected.
   */
  onLogicalOperatorChanged(operator: LogicalOperator) {
    if (this.previousSearchOption == null) return;
    this.previousSearchOption.nextLogicalOperator = operator;
    this.previousLogicalOperator = operator;
    this.optionUpdated(this.previousSearchOption);
  }
}
