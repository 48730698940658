import { Bookmark, ChartConfiguration } from "@tdms/common";
import { Observable, of } from "rxjs";
import { MetricCardDataStore } from "../../metric-card/models/metric.configuration";

export class MetricGridDataStore {
  cards: Observable<MetricCardDataStore>[];
  timelineCard?: Observable<MetricCardDataStore>;
  displayTimeline: Observable<boolean>;
  startDate: Observable<Date>;
  endDate: Observable<Date>;
  bookmarks: Observable<Bookmark[]>;

  constructor(
    cards: Observable<MetricCardDataStore<any>>[],
    timelineCard: Observable<MetricCardDataStore<any>> | undefined,
    displayTimeline: Observable<boolean> = of(true),
    startDate: Observable<Date>,
    endDate: Observable<Date>,
    bookmarks: Observable<Bookmark[]>
  ) {
    this.cards = cards;
    this.timelineCard = timelineCard;
    this.displayTimeline = displayTimeline;

    this.startDate = startDate;
    this.endDate = endDate;

    this.bookmarks = bookmarks;
  }

  static empty(timelineConfiguration: ChartConfiguration): MetricGridDataStore {
    return new MetricGridDataStore(
      [],
      of(MetricCardDataStore.empty(timelineConfiguration)),
      of(false),
      of(new Date()),
      of(new Date()),
      of([])
    );
  }
}
