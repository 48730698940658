<shared-div-progress-component
  [ready]="!isSearching"
  [notReadyMessage]="
    'Searching in progress, please wait... You will be automatically redirected when the search completes.'
  "
>
  <div class="home-page-container">
    <query-bar [onSearchStarted]="onSearchStarted" [isSearching]="isSearching"></query-bar>
    <!-- Overarching info -->
    <mat-card class="total-info">
      <mat-card-content class="content">
        <span><span class="title">Total Used Space:</span>{{ numberWithCommas(totalSpaceUsed) }} mb</span>
        <span><span class="title">Total Files:</span>{{ numberWithCommas(totalFiles, 0) }}</span>
      </mat-card-content>
    </mat-card>
    <!-- Actual metric pie charts for total data -->
    <div class="metric-display">
      <metric-grid
        *ngIf="currentMetricDataStore"
        class="pie-chart-grid"
        [dataStore]="currentMetricDataStore"
      ></metric-grid>
    </div>
  </div>
</shared-div-progress-component>
