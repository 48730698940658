import { Component, Input } from "@angular/core";
import { PluginSettingsService } from "@tdms/frontend/modules/settings/services/settings.service";
import { SettingCollection } from "@tdms/frontend/modules/settings/store/collection/collection.model";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

/**
 * The Setting Panel component creates an accordion containing Setting Fields based on a provide collection of Settings.
 */
@Component({
  selector: "setting-panel",
  templateUrl: "./setting.panel.component.html",
  styleUrls: ["./setting.panel.component.scss"],
})
export class SettingPanelComponent extends SubscribingComponent {
  @Input() collection!: SettingCollection;

  constructor(public settingService: PluginSettingsService) {
    super();
  }

  get helpfulTitle() {
    return this.collection.helpfulTitle;
  }

  get helpfulDescription() {
    return this.collection.helpfulDescription;
  }
}
