import { Route } from "@angular/router";
import { cloneDeep } from "lodash-es";
import { TDMS_ROUTES } from "./config";
import { Route_URLs } from "./url";

/** A class that provides helper functions to Router Routes */
export class RouteHelper {
  /** Returns a flattened version of the section routes */
  static getFlatRoutes() {
    return TDMS_ROUTES.flatMap((x) => {
      // Group
      if ("configs" in x) return x.configs.map((z) => z);
      // Standard
      else return x;
    }).filter((z) => z.path != null);
  }

  /** Returns the routes in a format the routing modules will understand */
  static getRoutingModuleRoutes() {
    return [
      // Populate defined routes
      ...this.getFlatRoutes(),
      // Base redirect for login
      {
        path: "**",
        redirectTo: Route_URLs.login,
      },
    ] as Route[];
  }

  /** Returns routing content relevant to the sidenav for rendering */
  static getSidenavGroups() {
    // Map out routes that don't support sidenav options.
    const mappedRoutes = TDMS_ROUTES.map((x) => {
      // Group handling
      if ("configs" in x) {
        x = cloneDeep(x);
        x.configs = x.configs.filter((x) => x.sidenavOptions != null);
      }
      return x;
    });
    return mappedRoutes.filter((x) => x.sidenavOptions != null);
  }

  /** Given a route path, finds the config associated to it */
  static findConfigByRoute(path: Route_URLs | string | undefined) {
    return this.getFlatRoutes().find((x) => x.path === path);
  }
}
