import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { AngularMaterialModule } from "@tdms/frontend/modules/material/material.module";
import { MetricsModule } from "@tdms/frontend/modules/metrics/metrics.module";
import { SharedModule } from "@tdms/frontend/modules/shared/shared.module";
import { SessionSummaryComponent } from "./components/dashboard/dashboard.component";
import { AdvancedComponent } from "./components/tabs/advanced/advanced.component";
import { SessionSummaryConstructTableComponent } from "./components/tabs/advanced/construct-table/construct-table.component";
import { GaugeLegendComponent } from "./components/tabs/advanced/gauge-legend/gauge-lengend.component";
import { SessionSummaryHelpDialogComponent } from "./components/tabs/advanced/help/help-dialog.component";
import { OverviewComponent } from "./components/tabs/overview/overview.component";
import SessionSummaryService from "./services/session.summary.service";
import { sessionSummaryReducer } from "./store/summary.reducer";

/** Components provided by this module */
const COMPONENTS = [
  OverviewComponent,
  SessionSummaryComponent,
  AdvancedComponent,
  SessionSummaryHelpDialogComponent,
  SessionSummaryConstructTableComponent,
  GaugeLegendComponent,
];

/** Angular module for session summary capabilities */
@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    SharedModule,
    AngularMaterialModule,
    StoreModule.forFeature("sessionSummary", sessionSummaryReducer),
    MetricsModule,
  ],
  providers: [SessionSummaryService],
  exports: COMPONENTS,
})
export class SessionSummaryModule {}
