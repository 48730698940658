import { Type } from "class-transformer";
import { TDMSBase } from "../base";
import { TimeSeriesChartBase } from "./base";
import { Bookmark } from "./bookmark";

/**
 * The data format for time series bar charts
 */
export class TimeSeriesBarChartValues extends TDMSBase {
  /**
   * The start time of this time series
   */
  @Type(() => Date)
  startTime!: Date;

  /**
   * The end time of this time series
   */
  @Type(() => Date)
  endTime!: Date;

  /**
   * Returns if `this` start and end time is within the min and max range given
   */
  isWithinRange(minRange: Date | number, maxRange: Date | number) {
    const minDomain = typeof minRange === "number" ? minRange : minRange.getTime();
    const maxDomain = typeof maxRange === "number" ? maxRange : maxRange.getTime();
    const minValue = this.startTime.getTime();
    const maxValue = this.endTime.getTime();
    return (minValue >= minDomain && minValue <= maxDomain) || (minDomain >= minValue && minDomain <= maxValue);
  }

  constructor(startTime: Date, endTime: Date) {
    super();
    this.startTime = startTime;
    this.endTime = endTime;
  }
}

/**
 * This class is the format we expect while displaying a horizontal bar chart considering time series data
 */
export class TimeSeriesBarChartData extends TimeSeriesChartBase<TimeSeriesBarChartValues> {
  @Type(() => TimeSeriesBarChartValues)
  series: TimeSeriesBarChartValues[];

  static override toCSV(data: TimeSeriesChartBase<TimeSeriesBarChartValues>[]) {
    // We use a custom chart in the frontend to display this time series bar chart and since excel can't display one of those, all we can do is output the data.
    let csvString = data.flatMap((x) => [`${x.name}-start`, `${x.name}-end`]).join(",") + "\n";
    // Append the new lines for the CSV
    for (let i = 0; i < Math.max(...data.map((z) => z.series.length)); i++) {
      // Grab all data sets in a row
      csvString +=
        data
          .filter((z) => z.series[i] != null)
          .flatMap((x) => [x.series[i].startTime.toISOString(), x.series[i].endTime.toISOString()])
          .join(",") + "\n";
    }
    return csvString;
  }

  constructor(name: string, series: TimeSeriesBarChartValues[], seriesSpecificBookmarks: Bookmark[] = []) {
    super(name, seriesSpecificBookmarks);
    this.name = name;
    this.series = series;
  }
}
