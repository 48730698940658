import { Component } from "@angular/core";
import { TimeSeriesBarChartData } from "@tdms/common";
import { ChartDataset, ChartType } from "chart.js";
import { merge } from "lodash-es";
import { BookmarkChartBaseComponent } from "../shared/bookmark-base/bookmark.base.component";
import { ExtendedChartOptions } from "../shared/plugins/plugin.typing";

/**
 * This chart is a generic bar chart component that stores bars horizontally across a time range. This can be thought
 *  of as a gantt chart but it can have multiple bars per row.
 */
@Component({
  selector: "charts-time-series-bar[data][colorLookup][configuration][totalChartTimeFrame]",
  templateUrl: "../shared/base/base.component.html",
  styleUrls: ["../shared/base/base.component.scss"],
})
export class TimeSeriesBarChartComponent<
  ChartDataType extends TimeSeriesBarChartData
> extends BookmarkChartBaseComponent<ChartDataType, "bar"> {
  override chartType: ChartType = "bar";

  override getChartData(dataSet: ChartDataType[]): ChartDataset<"bar", any>[] {
    const data: ChartDataset<"bar", any>[] = [];
    // Filter down data to only display enabled data
    const dataToProcess = this.filterDataset(dataSet);
    const colorSet = dataToProcess.map((x) => this.getColor(x.name));
    for (let i = 0; i < Math.max(...dataSet.map((x) => x.series.length)); i++) {
      data.push({
        // Flatten data together
        data: dataToProcess.map((x) => [
          x.series[i]?.startTime.toISOString(),
          x.series[i]?.endTime.toISOString(),
          x.name, // Label
          this.getColor(x.name), // Color
          dataToProcess.findIndex((z) => x.name === z.name), // Order
        ]),
        backgroundColor: colorSet,
      });
    }
    return data;
  }

  /**
   * Returns the minimum data value possible for our current data set
   */
  get minDataVal() {
    return this.data != null && this.data[0] != null
      ? Math.min(...this.data.map((x) => x.series[0].startTime.getTime()))
      : 0;
  }

  override chartOptionOverrides(coreOptions: ExtendedChartOptions<"bar">): ExtendedChartOptions<"bar"> {
    const superOptions = super.chartOptionOverrides(coreOptions);
    const xFormatter = this.xFormatter;
    const newOptions = {
      plugins: {},
      indexAxis: "y",
      scales: {
        x: {
          // Necessary so the chart actually knows where to start. We also need an to handle xDomainRange if given since we are overriding it
          min: this.xDomainRange ? this.xDomainRange[0].toISOString() : this.totalChartTimeFrame[0].toISOString(),
          max: this.xDomainRange ? this.xDomainRange[1].toISOString() : this.totalChartTimeFrame[1].toISOString(),
          type: "time",
          time: {},
          ticks: {
            callback: function (_, tickIndex, ticks) {
              const tickVal = new Date(ticks[tickIndex].value);
              return xFormatter(tickVal);
            },
          },
        },
        y: {
          stacked: true,
        },
      },
    } as ExtendedChartOptions<"bar">;
    return merge(superOptions, newOptions);
  }
}
