import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import {
  CustomTypes,
  DataStoreFile,
  DataStoreTopics,
  RoleMappingRequest,
  Session,
  SessionCreateMessage,
  TDMSWebSocketMessage,
  Tag,
} from "@tdms/common";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { selectFilesForSession } from "@tdms/frontend/modules/data-store/models/store/data.store.selector";
import { SessionService } from "@tdms/frontend/modules/session/services/session.service";
import { SessionActions } from "@tdms/frontend/modules/session/store/session.action";
import { selectSessionFromId } from "@tdms/frontend/modules/session/store/session.selector";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { DateTimePickerComponent } from "@tdms/frontend/modules/shared/components";
import { Configuration } from "@tdms/frontend/modules/shared/models/config";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { TagState } from "@tdms/frontend/modules/tag/store/tag.state";
import { isEqual } from "lodash-es";

/** Interface for what data is available to the dialog creation */
export interface SessionInfoDialogInput {
  /** The session we wish to either edit, or if one is not given, we are creating one */
  session: Session | undefined;
  /** If we should be able to edit the session time frames (start/end dates). Default is true. */
  timeEditingEnabled: boolean;
  /** If we should be able to edit the session role mappings and roles */
  roleMappingEditingEnabled: boolean;
}

export type RoleMappingOptions = Partial<CustomTypes.OmitKey<RoleMappingRequest, "mappings">>;

/** Specifies how the data should be displayed for the session info dialog */
type SessionInfoDialogMode = "edit" | "create";

/** A component that can be used in a dialog to provide options for editing or creating sessions with certain information */
@Component({
  selector: "session-info",
  templateUrl: "./info.component.html",
  styleUrls: ["./info.component.scss"],
})
export class SessionInfoComponent extends SubscribingComponent {
  /** Boolean to track if we're currently waiting on backend response from session create/edit */
  sessionProcessing = false;

  /** The session we are currently editing or creating */
  session: Session;

  /** Our current mode for handling the given session of {@link session} */
  mode: SessionInfoDialogMode = "create";

  /** If we were given a session */
  private originalSession: Session | undefined;

  /** Tracks the file that created this session */
  creationSessionFile: DataStoreFile | undefined;

  /** Form for the overarching form group */
  form!: FormGroup;

  /** Toggle to use to disable role editing button */
  canEditRoleMappings: boolean;

  constructor(
    private store: Store<TagState>,
    private dialogRef: MatDialogRef<any>,
    private wsService: WebSocketService,
    @Inject(MAT_DIALOG_DATA) private properties: SessionInfoDialogInput | undefined,
    public sessionService: SessionService,
    private snackBar: MatSnackBar,
    private configService: ConfigService
  ) {
    super();
    const canEditTimes = this.properties?.timeEditingEnabled ?? true;
    this.canEditRoleMappings = this.properties?.roleMappingEditingEnabled!;
    // Handle the session creation/assignment
    if (properties?.session == null) {
      const startDate = new Date();
      startDate.setUTCHours(0, 0, 0, 0);
      const endDate = new Date();
      endDate.setUTCHours(1, 0, 0, 0);
      this.session = new Session(undefined, [], "New Session", startDate, endDate);
    } else {
      this.mode = "edit";
      this.session = properties.session;
      this.originalSession = properties.session.clone();
    }
    // Create the actual form group
    this.form = new FormGroup({
      name: new FormControl({ value: this.session.name, disabled: !this.canEdit }, [Validators.required]),
      startDate: new FormControl({ value: this.session.startDate, disabled: !this.canEdit || !canEditTimes }, [
        Validators.required,
        DateTimePickerComponent.dateDifferenceValidator,
        DateTimePickerComponent.dateLessThanValidator,
      ]),
      endDate: new FormControl({ value: this.session.endDate, disabled: !this.canEdit || !canEditTimes }, [
        Validators.required,
        DateTimePickerComponent.dateDifferenceValidator,
        DateTimePickerComponent.dateLessThanValidator,
      ]),
      tags: new FormControl(this.session.tags || [], []),
    });
    // Track changes in the session we are using so we can update information as need be
    this.addSubscription(
      this.store.select(selectSessionFromId(this.session.id)).subscribe((x) => {
        // Make sure we only are tracking actual changes
        const tagsHaveChanged = !isEqual(this.form.controls["tags"].value, x?.tags);
        if (x && tagsHaveChanged) {
          // Handle tag updates that will happen if we edit the tag from within this menu. Use the base tags from the update and include any other ones we've updated.
          const tags = Tag.fromPlainArray(x.tags);
          for (let tag of this.form.controls["tags"].value) if (!tags.find((z) => z.id === tag.id)) tags.push(tag);
          this.session.tags = tags;
          this.form.controls["tags"].setValue(tags);
        }
      })
    );
    // Track if we have a file that created this session or not
    this.addSubscription(
      this.store
        .select(selectFilesForSession)
        .subscribe((files) => (this.creationSessionFile = files.find((x) => x.createdSession)))
    );
  }

  /** Returns if the values have actually changed. If we are not in edit mode, will always resolve true. */
  get editValuesHaveChanged() {
    return this.mode !== "edit" || !this.sessionFromForm.equals(this.originalSession);
  }

  /** Gets the session considering form inputs */
  get sessionFromForm() {
    const session = this.session.clone();
    session.name = this.form.controls["name"].value;
    session.startDate = this.form.controls["startDate"].value;
    session.endDate = this.form.controls["endDate"].value;
    session.tags = this.form.controls["tags"].value;
    return session;
  }

  async submit() {
    if (!this.editValuesHaveChanged && this.form.valid) return; // Validate
    this.sessionProcessing = true;
    // Ask the service to send the request
    const session = this.sessionFromForm;
    const result =
      this.mode === "create" ? await this.sessionService.create(session) : await this.sessionService.edit(session);
    this.sessionProcessing = false;
    // Handle response
    if (!result.payload.successful)
      this.snackBar.open(result.payload.failureMessage!, "close", Configuration.ErrorSnackbarConfig);
    else {
      this.snackBar.open(
        `${session.name} ${this.mode === "create" ? "created" : "edited"} successfully`,
        "close",
        Configuration.SnackbarConfig
      );
      this.dialogRef.close();
    }
  }

  /** Returns if we can edit sessions */
  get canEdit() {
    return this.configService.configData?.session.allowEditing ?? false;
  }

  /**
   * Sets the given session as the current session
   */
  async selectSession(session = this.session) {
    this.store.dispatch(SessionActions.select({ session: session }));
    // Redirect is handled in session service
    this.dialogRef.close();
  }

  /**
   * Opens role mapping dialog to allow user to edit Roles after inital session upload.
   */
  async openRoleMapping(session: Session) {
    if (this.properties?.roleMappingEditingEnabled && this.canEdit) {
      this.sessionProcessing = true;
      const response = await this.wsService.sendAndReceive<SessionCreateMessage>(
        new TDMSWebSocketMessage(DataStoreTopics.requestRoleMapping, session.id, Session.fromPlain(this.session))
      );

      if (!response.payload.successful) {
        this.snackBar.open(response.payload.failureMessage!, "close", Configuration.ErrorSnackbarConfig);
        this.sessionProcessing = false;
        return;
      }
      if (response.payload.cancellingDialog) {
        this.snackBar.open(`No roles were edited.`, "close", Configuration.SnackbarConfig);
        this.sessionProcessing = false;
        return;
      }
      this.snackBar.open(
        `${response.payload.session.name} roles edited successfully`,
        "close",
        Configuration.SnackbarConfig
      );
      this.dialogRef.close();
      this.sessionProcessing = false;
    }
  }
}
