import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PopulatedSearchOption } from "@tdms/common";

export interface QueryBuilderDialogProperties {
  formErrors: PopulatedSearchOption[];
  isSearching: boolean;
  onSearchStarted: (options: PopulatedSearchOption[]) => void;
}

@Component({
  selector: "data-store-query-builder-dialog",
  templateUrl: "./query-builder-dialog.component.html",
  styleUrls: ["./query-builder-dialog.component.scss"],
})
export class QueryBuilderDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public properties: QueryBuilderDialogProperties) {}

  ngOnInit(): void {}
}
