import { Component, Input, OnInit } from "@angular/core";

/**
 * A generic component to allow easy wrapping of any div element that won't be rendered
 *  until the value of `ready` is true. Will display a progress indicator as well as a darken
 *  the component so it isn't selectable.
 */
@Component({
  selector: "shared-div-progress-component",
  templateUrl: "./div-progress-component.component.html",
  styleUrls: ["./div-progress-component.component.scss"],
})
export class DivProgressComponent implements OnInit {
  /**
   * This determines if the progress spinner should be over layed or not.
   */
  @Input() ready: boolean | null = false;

  /**
   * The message you want displayed under the loading spinner while it is not ready.
   * @default undefined No message will be displayed.
   */
  @Input() notReadyMessage: string | undefined = undefined;

  /**
   * If the spinner should be shown.
   */
  @Input() showSpinner = true;

  /**
   * Returns the spinners expected diameter considering parent size
   */
  @Input() spinnerDiameter = 80;

  /**
   * The progress to display on the spinner. If undefined is given, an indeterminate spinner will be displayed.
   * @default undefined
   */
  @Input() progress: number | undefined = undefined;

  constructor() {}

  ngOnInit(): void {}
}
