import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { BookmarkService } from "@tdms/frontend/modules/bookmark/services/bookmark.service";
import { AngularMaterialModule } from "@tdms/frontend/modules/material/material.module";
import { SharedModule } from "@tdms/frontend/modules/shared/shared.module";
import { BookmarkDialogComponent } from "./components/dialog/bookmark-dialog.component";
import { bookmarkTypeReducer } from "./store/bookmark.type.reducer";

const COMPONENTS = [BookmarkDialogComponent];

@NgModule({
  declarations: COMPONENTS,
  exports: COMPONENTS,
  imports: [
    CommonModule,
    SharedModule,
    AngularMaterialModule,
    StoreModule.forFeature("bookmarkType", bookmarkTypeReducer),
  ],
  providers: [BookmarkService],
})
export class BookmarkModule {}
