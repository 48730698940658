import { Component, Input } from "@angular/core";

@Component({
  selector: "menu-button[show][onClick]",
  templateUrl: "./menu-button.component.html",
  styleUrls: ["./menu-button.component.scss"],
})
export class MenuButtonComponent {
  @Input() show!: boolean;
  @Input() onClick!: () => void;
}
