import { DialogRef } from "@angular/cdk/dialog";
import { Component, Input } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TrackedFile } from "@tdms/frontend/modules/data-store/components/uploader/file-tree/models/tracked.file";

import { UploadService } from "@tdms/frontend/modules/data-store/services/upload.service";
import { Configuration } from "@tdms/frontend/modules/shared/models/config";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

/**
 * A component that handles upload capabilities for the data store
 */
@Component({
  selector: "aggregate-uploader",
  templateUrl: "./aggregate-uploader.component.html",
  styleUrls: ["./aggregate-uploader.component.scss"],
})
export class AggregateUploaderComponent extends SubscribingComponent {
  @Input() isAggregateUploader = true;

  // Track file currently uploaded
  currentFile: TrackedFile | undefined;

  /**
   * Tracks if we are uploading content or not
   */
  isUploading = false;

  /**
   * Upload progress to display on the spinner
   */
  uploadingProgress: number | undefined = 0;

  /**
   * The text to display when some data is being uploaded.
   */
  uploadingText: string = "";

  constructor(private uploadService: UploadService, private snackBar: MatSnackBar, private dialogRef: DialogRef) {
    super();
  }

  ngOnInit(): void {}

  async submitCallback() {
    if (!this.currentFile) {
      this.snackBar.open(`No File Selected`, "Close", Configuration.ErrorSnackbarConfig);
      return;
    }

    try {
      this.isUploading = true;

      // Await the upload of each file before continuing to the next, and update progress
      await this.uploadService.uploadToDataStore(
        this.currentFile!,
        undefined,
        undefined,
        (_, totalProgress) => {
          this.uploadingProgress = totalProgress;
          if (totalProgress === 100) {
            this.uploadingText = "Processing file...";
            this.uploadingProgress = undefined;
          }
        },
        true
      );

      this.dialogRef.close();
    } catch (e) {
      this.snackBar.open(`Error during file upload: ${e}`, "Close", Configuration.ErrorSnackbarConfig);
    } finally {
      this.isUploading = false;
      this.uploadingText = "";
    }
  }

  /**
   * Updates the extra files in the tree
   */
  handleFileUpdate(updatedFile: TrackedFile) {
    // Clone deep to forcibly change the reference
    this.currentFile = updatedFile;
  }

  get submitButtonAvailable() {
    if (this.currentFile) return true;
    else return false;
  }

  get validationMessage() {
    if (this.currentFile) return "";
    else return "Please select a file";
  }
}
