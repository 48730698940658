import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { UserService } from "@tdms/frontend/modules/user/services/user.service";
import { first } from "rxjs";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  /**
   * The form for our validation groups
   */
  readonly loginForm = new FormGroup({
    username: new FormControl("", [Validators.required]),
    password: new FormControl("", [Validators.required]),
  });

  constructor(private configService: ConfigService, private userService: UserService) {
    this.configService.configDataWatcher.pipe(first()).subscribe(() => this.checkDemoData());
    this.checkDemoData();
  }

  /**
   * Checks to see if we need to set defaults for demo data
   */
  checkDemoData() {
    // Set some demo mode data if it's enabled
    if (this.configService.configData?.demoMode.enabled) {
      this.loginForm.controls["username"].setValue(this.configService.configData.demoMode.defaultUsername);
      this.loginForm.controls["password"].setValue(this.configService.configData.demoMode.defaultPassword);
    }
  }

  /**
   * Return a list of notifications to display above the login
   */
  get notifications() {
    const array: { type: "error" | "success" | "accent"; message: string }[] = [];
    // Check for demo mode to auto fill the login fields
    if (this.configService.configData?.demoMode.enabled) array.push({ type: "accent", message: `Demo mode enabled` });
    // Check if we need to display a notification about a previous failure
    if (this.userService.previousLoginResponse != null)
      array.push({
        type: this.userService.previousLoginResponse.success ? "success" : "error",
        message: this.userService.previousLoginResponse.message,
      });
    return array;
  }

  /**
   * Handles the login request from the form
   */
  submitLogin(_event: SubmitEvent) {
    if (this.loginForm.invalid) return;
    // Valid, send request
    this.userService.login(this.loginForm.controls["username"].value!, this.loginForm.controls["password"].value!);
  }

  ngOnInit(): void {}

  get frontendVersion() {
    // Get the shorter version
    return $VERSIONING.SHORTVERSION;
  }

  /**
   * Returns if the login button should be able to be clicked
   */
  get loginIsDisabled() {
    return this.userService.isAwaitingLoginStatus || this.notifications.find((z) => z.type === "success");
  }
}
