import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ComparisonOption, PopulatedSearchOption } from "@tdms/common";
import { SearchService } from "@tdms/frontend/modules/data-store/services/search.service";

/**
 * This component represents a selector for a comparison option for a given Populated Search Option.
 * It should be included on any searchable term editor in the query bar.
 */
@Component({
  selector: "data-store-comparison-selector",
  templateUrl: "./comparison-selector.component.html",
  styleUrls: ["./comparison-selector.component.scss"],
})
export class ComparisonSelectorComponent implements OnInit, OnChanges {
  @Input() option!: PopulatedSearchOption;

  @Input() isSearching!: boolean;

  /**
   * This is called whenever the comparison type changes.
   * It should update the populated search option and thus update this component as necessary.
   */
  @Input() onComparisonChanged!: (comparison: ComparisonOption) => void;

  @Input() tooltip: string = "";

  /**
   * This is populated with the list of supported comparison options available for the given search option type.
   */
  supportedComparisonOptions: readonly ComparisonOption[] = [];

  /**
   * This is the angular dropdown control used in the component to select the requisite option.
   */
  dropdownControl = new FormControl<ComparisonOption>("=");

  constructor(private searchService: SearchService) {
    this.onDropdownChanged = this.onDropdownChanged.bind(this);
    this.getComparisonDescription = this.getComparisonDescription.bind(this);
  }

  ngOnInit(): void {
    // Set dropdown control to the current option
    this.dropdownControl.setValue(this.option.comparisonOption);
    this.updateSupportedOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    /// If our term changes, we need to re-check the supported options.
    if (changes.option != null || changes.tooltip != null) this.updateSupportedOptions();
  }

  /**
   * Grab the supported options for our term and adjust our control's status accordingly.
   * @returns True for restricted, false for unrestricted.
   */
  updateSupportedOptions() {
    this.supportedComparisonOptions = this.searchService.getSupportedComparisonOptions(this.option);
    if (this.supportedComparisonOptions.length <= 1) {
      // If we only have one option, make sure that's what we have selected
      this.option.comparisonOption = this.supportedComparisonOptions[0];
      this.dropdownControl.disable();
      return true;
    } else {
      this.dropdownControl.enable();
      return false;
    }
  }

  onDropdownChanged() {
    if (this.dropdownControl.value == null) return;
    this.onComparisonChanged(this.dropdownControl.value);
  }

  getComparisonDescription(option: ComparisonOption): string {
    if (this.option.dataType == "date") {
      switch (option) {
        case "<":
          return "Is before";
        case "<=":
          return "Is on or before";
        case "=":
          return "Is on";
        case ">":
          return "Is after";
        case ">=":
          return "Is on or after";
      }
    }

    switch (option) {
      case "<":
        return "Is less than";
      case "<=":
        return "Is less than or equal to";
      case "=":
        return "Is equal to";
      case ">":
        return "Is greater than";
      case ">=":
        return "Is greater than or equal to";
    }
  }
}
