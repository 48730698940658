import { addSeconds, format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { TDMSBase } from "../base";
import { CustomTypes } from "../custom.types";
import { Utility } from "../utility";

/** Time format for use in the charts to include only HH:mm:ss (hours, minutes, seconds) */
export const TIME_FORMAT = "HH:mm:ss";
export const SHORT_TIME_FORMAT = "mm:ss";

/**
 * This class provides configuration capabilities to charts displaying on the comparison interface
 */
export class ChartComparisonOptions extends TDMSBase {
  /**
   * If this chart should display on the comparison interface
   */
  enabled = true;

  /**
   * If bookmarks can be added to this metric during comparison
   * @default false
   */
  showBookmarkAdd = false;

  /** Provides the ability to override xAxis format from the standard defined in the base chart */
  xAxisFormatOverride: CustomTypes.PropertyNames<typeof ComparisonXAxisFormatters, Function> | undefined;

  constructor(options: Partial<ChartComparisonOptions>) {
    super();
    Object.assign(this, options);
  }
}

/**
 * This class maintains the chart configuration the frontend expects to try and load metric charts.
 *
 * **Note:** Not every configuration value in this class will be utilized. That depends on the `type` property.
 */
export class ChartConfiguration extends TDMSBase {
  /**
   * The type of chart that we intend to display
   */
  type: "line" | "vertical-bar" | "time-series-horizontal-bar" | "pie" | "gauge" | "waveform" = "line";

  /**
   * The name of the metric this configuration ties to.
   * Important for the frontend to be able to correlate the chart to any user settings tied to the metric.
   */
  metricName: string = "unknown";

  /**
   * Show lines on line graph
   * @default true
   */

  showLines: boolean = true;
  /**
   * The title of the chart to display
   */
  title: string = "Chart Title";
  showTitle = true;
  titleStart: "sessionTimeStart" | string | undefined;
  titleEnd: "sessionTimelineEnd" | string | undefined;

  /// X Axis label handling
  xAxisLabel: string | undefined;
  showXAxis: boolean = true;
  xAxisFormat: CustomTypes.PropertyNames<typeof ChartXAxisFormatters, Function> = "default";
  showXAxisLabel: boolean = true;
  trimXAxisTicks: boolean = false;

  /// Y Axis handling
  yAxisLabel: string | undefined;
  showYAxisLabel: boolean = true;
  showYAxis: boolean = true;
  yScaleMax: number | undefined;
  yScaleMin: number | undefined;
  yAxisFormat: CustomTypes.PropertyNames<typeof ChartYAxisFormatters, Function> = "default";
  trimYAxisTicks: boolean = false;

  /// Styling settings
  showAnimations: boolean = false;
  sizing: "full" | "half" = "full";
  position!: number;
  gradient: boolean = false;

  /// Help information
  showHelp: boolean = false;
  helpBody: string = "Help body";

  /// Bookmark information
  showBookmark: boolean = false;

  /// Endpoints to request data
  getBySessionIdQueue!: string;
  /**
   * If data should be requested on domain change to get the current domain set of data
   */
  shouldRequestDataOnDomainChange: boolean = false;

  /// Legend info
  displayLegend: boolean = true;

  /**
   * Defines if this chart should be used for the overarching session timeline. This can only be used
   *  via line charts and only one chart may say this.
   */
  shouldBeUsedForTimeline = false;

  /**
   * Comparison options for this chart configuration
   */
  comparison: ChartComparisonOptions | undefined = undefined;

  /**
   * If the frontend should allow this chart data to be exported to a CSV file
   */
  exportAllowed: boolean = true;

  /**
   * If set to true, the user will be able to select a range on this chart to determine peak values and generate
   *  a nice little table to display this information. This only works for line charts
   * @default false
   */
  supportsSelectionZone: boolean = false;

  /**
   * When using the `supportsSelectionZone` if this is also enabled, entropy math will be calculated to display additional
   *  metric information in that specific charts legend.
   * @default false
   */
  shouldCalculateEntropyMath: boolean = false;

  /** Footer text to be displayed under the chart if need be */
  footer?: string;

  /**
   * Returns if this chart configuration is a time series data set
   */
  get isTimeSeriesChart() {
    return this.type === "line" || this.type === "time-series-horizontal-bar";
  }

  constructor(
    options: Partial<ChartConfiguration> & { getBySessionIdQueue: string; position: number } // These are required fields
  ) {
    super();
    Object.assign(this, options);
  }
}

/**
 * This class defines helper functions we use for formatting the Y axis of different graphs
 */
export class ChartYAxisFormatters {
  /**
   * The default handler for Y axis formatting of charts
   */
  static default(val: any): string {
    return val;
  }

  /**
   * Given speaking time in seconds returns it as a string value for better display
   */
  static speakingTimeFormatter(val: number): string {
    return format(addSeconds(new Date(0), val), "mm:ss");
  }
}

/**
 * This class defines helper functions we use for formatting the X axis of different graphs
 */
export class ChartXAxisFormatters {
  /**
   * The default X axis display function. Just returns whatever is given to it.
   */
  static default<T extends string>(val: T): string {
    return val;
  }

  /**
   * A time based X axis formatter that will utilize check if val is a valid date
   *  and will parse it into our expected format if so
   */
  static timeFormatter<T extends string | Date>(val: T) {
    if (val instanceof Date) return formatInTimeZone(val, "UTC", TIME_FORMAT);
    else if (Utility.ISO_8601_REGEX.test(val)) return formatInTimeZone(new Date(val), "UTC", TIME_FORMAT);
    else return val as string;
  }
}

/** Similar to @see {@link ChartXAxisFormatters} but these are specific to allow you to override them for comparison display */
export class ComparisonXAxisFormatters {
  /**
   * Similar to @see {@link ChartXAxisFormatters.timeFormatter} but uses duration time instead
   */
  static durationTimeFormatter<T extends string | Date>(startVal: Date, val: T) {
    let dateToUse: Date | undefined;
    if (val instanceof Date) dateToUse = val;
    else if (Utility.ISO_8601_REGEX.test(val)) dateToUse = new Date(val);
    if (dateToUse != null) {
      const newDate = new Date(startVal.getTime());
      newDate.setHours(0, 0, 0);
      const duration = dateToUse.getTime() - startVal.getTime();
      newDate.setTime(newDate.getTime() + duration);
      // Create a diff from start value
      return format(newDate, TIME_FORMAT);
    } else return val as string;
  }
}
