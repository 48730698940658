import { TDMSBase } from "../../base";

/**
 * This class defines a diarization for an audio file
 */
export class Diarization extends TDMSBase {
  /** The id given to this diarization grouping. This is normally an indicator of index */
  id: number;

  /** The start time, in seconds, from the start of the audio file for when this speaker was considered speaking */
  start: number;

  /** The end time, in seconds, from the start of the audio file for when this speaker stopped speaking for this block */
  end: number;

  /** The index of the speaker to track which # speaker this actually was */
  speaker: string;

  constructor(id: number, start: number, end: number, speaker: string) {
    super();
    this.id = id;
    this.start = start;
    this.end = end;
    this.speaker = speaker;
  }
}
