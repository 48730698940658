import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  ComparisonOption,
  ComparisonOptions,
  DataStoreFile,
  DataStoreTopics,
  LogicalOperator,
  LogicalOperators,
  PopulatedSearchOption,
  SearchableOption,
  SearchRequest,
  SearchResponse,
  TDMSWebSocketMessage,
  User,
} from "@tdms/common";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { SearchResult } from "@tdms/frontend/modules/data-store/components/tabs/search/search.data";
import { Route_URLs } from "@tdms/frontend/modules/routing/models/url";
import { RouterService } from "@tdms/frontend/modules/routing/services/router.service";
import { SessionService } from "@tdms/frontend/modules/session/services/session.service";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { Configuration } from "@tdms/frontend/modules/shared/models/config";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { BehaviorSubject, Subject } from "rxjs";

/**
 * The search service handles all business logic and backend requests pertaining to data store searching.
 */
@Injectable({
  providedIn: "root",
})
export class SearchService extends Service {
  /**
   * The available search options that the backend provided.
   * We assert that this is set because we are certain that these should be initialized when a user logs in.
   * Since these should not be used before a user logs in, this should be a safe assumption.
   */
  private availableOptionSubject: Subject<SearchableOption[]> = new Subject<SearchableOption[]>();
  /**
   * This is the list of available term options that can be queried on.
   */
  private availableOptions?: SearchableOption[];

  /**
   * This represents the currently available search results that have been setup via the query bar.
   */
  currentSearchResults: SearchResult = [];

  /**
   * This is the list of current search options built out by the user using either the query bar or query builder dialog.
   */
  currentSearchOptions: PopulatedSearchOption[] = [];

  /**
   * This is a reactive subject that gets updated with the latest search result object.
   */
  private searchResultSubject: BehaviorSubject<SearchResult> = new BehaviorSubject(this.currentSearchResults);
  /**
   * This is a reactive subject that gets updated with the latest search options as they change.
   */
  private searchOptionSubject: BehaviorSubject<PopulatedSearchOption[]> = new BehaviorSubject(
    this.currentSearchOptions
  );

  /**
   * This flag represents that a query is currently running on the backend.
   * When this is true, don't invoke any extra queries to prevent spamming the server.
   */
  querying = false;

  constructor(
    private wsService: WebSocketService,
    private sessionService: SessionService,
    private routerService: RouterService,
    private configService: ConfigService,
    private snackbar: MatSnackBar
  ) {
    super();
    this.loadSearchOptions = this.loadSearchOptions.bind(this);
  }

  /**
   * When the logged in user changes, load the latest available search options.
   * @param _
   */
  override async onUserChanged(_: User): Promise<void> {
    if (this.configService.configData?.dataStore.enabled) await this.loadSearchOptions();
  }

  override async onBackendDisconnected(): Promise<void> {
    this.currentSearchResults = [];
    this.currentSearchOptions = [];
    this.availableOptions = undefined;
    this.searchOptionSubject.next(this.currentSearchOptions);
    this.searchResultSubject.next(this.currentSearchResults);
    this.availableOptionSubject.next([]);
  }

  /**
   * Load the latest available search options from the backend.
   */
  async loadSearchOptions() {
    const response = await this.wsService.sendAndReceive(new TDMSWebSocketMessage(DataStoreTopics.getSearchOptions));
    this.availableOptions = SearchableOption.fromPlainArray(response.payload as SearchableOption[]);
    this.availableOptionSubject.next(this.availableOptions);
    this.updateEnforcedOptions(this.availableOptions);
  }

  /**
   * Get the supported comparison options for the given search term.
   * Currently, this just restricts string types to '=' only.
   * In the future, it might be good to add a 'contains' comparison option for string wildcard matching.
   * @param option The search term to check.
   * @returns The supported options.
   */
  getSupportedComparisonOptions(option: SearchableOption): readonly ComparisonOption[] {
    switch (option.dataType) {
      case "string":
        return ["="];
      case "date":
      case "number":
        return ComparisonOptions;
    }
  }

  /**
   * Get the supported logical operators for a given search term.
   * Currently, all terms support all logical operators [AND, OR].
   * @param _option The search term to check.
   * @returns The supported operators.
   */
  getSupportedLogicalOperators(_option: SearchableOption): readonly LogicalOperator[] {
    return LogicalOperators;
  }

  /**
   * Observe the search result object as it changes.
   * @returns An observable.
   */
  observeSearchResults() {
    return this.searchResultSubject.asObservable();
  }

  /**
   * Observe the search option object as it changes.
   * @returns An observable.
   */
  observeSearchOptions() {
    return this.searchOptionSubject.asObservable();
  }

  /**
   * Observe available search options as they change
   * @returns An observable.
   */
  observeAvailableSearchOptions() {
    return this.availableOptionSubject.asObservable();
  }

  /**
   * Delete the given term from the current search option list and update observables as necessary.
   * Called by components or other services when a search option is deleted by the user or elsewhere.
   * @param option The option to delete.
   */
  onSearchOptionDeleted(option: PopulatedSearchOption) {
    const index = this.currentSearchOptions.indexOf(option);
    if (index < 0) return;
    this.currentSearchOptions.splice(index, 1);
    this.searchOptionSubject.next(this.currentSearchOptions);
  }

  /**
   * Given a new set of search options, updates any currently selected search as well as any current search
   *  options to include the available enforced search options.
   */
  updateEnforcedOptions(options: SearchableOption[]) {
    for (let option of options) {
      if (option.options.length !== 0) {
        // Update any current set options
        for (let setOption of this.currentSearchOptions)
          if (setOption.fullName === option.fullName) setOption.options = option.options;
        // Update any available options
        if (this.availableOptions)
          for (let availOption of this.availableOptions)
            if (availOption.fullName === option.fullName) availOption.options = option.options;
      }
    }
    this.searchOptionSubject.next(this.currentSearchOptions);
    if (this.availableOptions) this.availableOptionSubject.next(this.availableOptions);
  }

  /**
   * Update the given term in the current search option list and update observables as necessary.
   * Called by components or other services when a search option is updated by the user or elsewhere.
   * Currently, this just emits a new event on the observable subject.
   * NOTE: This method assumes that the same list reference contained here in the service is used by all components,
   * and thus no actual list update needs to happen.
   * If this isn't the case, the service list should be updated or this method adjusted accordingly.
   */
  onSearchOptionUpdated(_option: PopulatedSearchOption) {
    this.searchOptionSubject.next(this.currentSearchOptions);
  }

  /**
   * Add the given term to the current search option list and update observables as necessary.
   * Called by components or other services when a search option is added by the user or elsewhere.
   * We take the given SearchableOption and create a PopulatedSearchOption from it.
   * The new option is given logical default value based on it's data type.
   * @param option The option to add.
   */
  onSearchOptionAdded(option: SearchableOption) {
    let value;

    switch (option.dataType) {
      case "date":
        value = new Date();
        break;
      case "number":
        value = 0;
        break;
      case "string":
        value = "";
        break;
    }

    const populated = PopulatedSearchOption.fromPlain({ ...option.clone() });

    populated.value = value as any;
    populated.nextLogicalOperator = "AND";
    populated.comparisonOption = "=";

    this.currentSearchOptions.push(populated);
    this.searchOptionSubject.next(this.currentSearchOptions);
  }

  /**
   * Execute a user-initiated search action.
   * This essentially calls @queryDataStore but will also navigate to the search results page if necessary.
   * @param options The options to run the query against.
   */
  async startInteractiveQuery(options?: PopulatedSearchOption[], navigateToSearchPage = true) {
    options ??= this.currentSearchOptions;
    const searchStarted = await this.queryDataStore(options);

    if (searchStarted && navigateToSearchPage) {
      return this.navigateToSearchPage();
    }
  }

  /**
   * Navigate to the search page in response to a user-initiated search action.
   */
  private async navigateToSearchPage() {
    return await this.routerService.redirectTo(Route_URLs.dataManagement, {
      ...this.routerService.getAllQueryParams(),
      dataStoreTab: 1,
    });
  }

  /**
   * Execute the actual query on the data store.
   * This will only run if the given query is valid.
   * We don't actually return anything here, but instead update @currentSearchResults when the query is finished.
   * This is to facilitate the rate limiting mechanism that will only run one query at a time.
   * @param options The options to query on.
   * @returns true or false indicating if the search started or not.
   */
  async queryDataStore(options: PopulatedSearchOption[]): Promise<boolean> {
    if (!this.validateSearchQuery(options) || options.length === 0) {
      return false;
    }

    if (this.querying) {
      /// Since we are already querying, we won't execute another one.
      /// We will instead flag query to update after the current one, and update the query options to use.
      /// We only store the most recent query terms this way, but any intermediate ones are no longer relevant.
      return false;
    }

    this.querying = true;

    const request = new TDMSWebSocketMessage<SearchRequest>(
      DataStoreTopics.search,
      this.sessionService.currentSession?.id,
      new SearchRequest(options)
    );

    let response = await this.wsService.sendAndReceive<SearchResponse>(request);
    this.querying = false;
    // Handle failures
    if (!response.payload.success) {
      this.snackbar.open(
        response.payload.errorMessage || "Failed to execute query",
        "close",
        Configuration.ErrorSnackbarConfig
      );
      return false;
    }

    const dataStoreFiles = response.payload.files.map((file) => DataStoreFile.fromPlain(file));
    this.currentSearchResults = [];

    for (const file of dataStoreFiles) {
      const session = await this.sessionService.lookupSessionById(file.session.id);

      if (session == null) {
        throw new Error(
          `Could not find session matching id ${file.session.id} specified by data store file ${file.fileName}`
        );
      }

      if (file.fileName == null) {
        throw new Error(`Cannot store search result for data store file with no filename! ${file.filePath}`);
      }

      this.currentSearchResults.push({
        sessionName: session.name,
        filename: file.uncompressedFileName,
        file: file,
        startDate: session.startDate,
        endDate: session.endDate,
      });
    }

    this.searchResultSubject.next(this.currentSearchResults);
    this.querying = false;

    return true;
  }

  /**
   * Make sure that the current search terms are all valid and the query can be performed.
   * Queries are currently considered valid as long as every option has a value and a comparison option.
   */
  private validateSearchQuery(options: PopulatedSearchOption[]): boolean {
    for (const option of options) {
      if (option.value == null) return false;
      if (option.dataType === "string" && option.value == "") return false;
      if (option.comparisonOption == null) return false;
    }

    return true;
  }
}
