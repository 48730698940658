/**
 * Expose the selectors that combine our state of SettingCollection and SettingValue objects from the frontend state.
 * The separation between SettingCollection and SettingValue gives the store the ability to granularly update small pieces
 * without causing overfiring of events.
 * Singular setting values are linked to SettingCollections by the collectionId field, which is the plugin name.
 * This linking is setup by the SettingService when the settings are initially loaded from the backend.
 * Then, the setting collection and setting value reducer actions are initiated to granularly handle updates to the settings.
 */
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SettingCollection } from "@tdms/frontend/modules/settings/store/collection/collection.model";
import { SettingValue } from "@tdms/frontend/modules/settings/store/value/setting.value.model";

export const selectSettingCollections = createFeatureSelector<ReadonlyArray<SettingCollection>>("settingCollections");
export const selectSettingValues = createFeatureSelector<ReadonlyArray<SettingValue>>("settingValues");

export const selectPluginSettings = (plugin: string) =>
  createSelector(selectSettingCollections, selectSettingValues, (collections, settings) => {
    const collection = collections.find((collection) => collection.name == plugin);
    if (collection == null) return undefined;
    const matchingSettings = settings.filter((setting) => setting.collectionId == plugin);
    return matchingSettings;
  });

export const selectPluginSetting = (plugin: string, settingId: string) =>
  createSelector(selectPluginSettings(plugin), (collection) => {
    if (collection == null) return undefined;
    return collection.find((setting) => setting.name === settingId);
  });
