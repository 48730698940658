import { Directive, HostBinding, Input, OnChanges, OnInit, ViewContainerRef } from "@angular/core";
import { MatButton } from "@angular/material/button";
import { MatMenuItem } from "@angular/material/menu";
import { MatTooltip } from "@angular/material/tooltip";
import { ConfigMessage, CustomTypes } from "@tdms/common";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { SplitButtonComponent } from "@tdms/frontend/modules/shared/components";
import { AngularCustomTypes } from "@tdms/frontend/modules/shared/models/angular.custom.types";

/** Properties support by the config directive */
export type ConfigDirectiveProperties = CustomTypes.PropertyPaths<ConfigMessage>;

/** This directive can apply disabled states based on config field properties based out of the config service.
 *  You can use this by attaching it to your component you wish to disable like:
 *
 * ```html
 * <button mat-button [configDisable]="'user.allowCreation'" disabledStateMessage="User creation is disabled">
 *  Create User
 * </button>
 * ```
 * This field will automatically consider existing disabled state and will apply the configuration value boolean to the existing
 *  to make sure to maintain you original state.
 *
 * **This directive can only be used with the following components:**
 *  - mat-button (including mat-raised-button, mat-flat-button, etc.)
 *  - mat-menu-item
 *  - shared-split-button
 */
@Directive({
  selector:
    "[configDisable][mat-button],[configDisable][mat-raised-button],[configDisable][mat-flat-button],[configDisable][mat-icon-button],[configDisable][mat-stroked-button],[configDisable][mat-menu-item], shared-split-button",
  providers: [],
})
export class ConfigDirective implements OnChanges, OnInit {
  /**
   * This is the field we wish to find from the loaded config data and utilize as a disabled/enabled state. We assume that this
   *    value is an `enabled` state. So we invert it to determine disabled state.
   *
   * You can also pass an array of properties to this. If any of these are enabled, we will allow the button to be clicked
   *
   * See {@link ConfigDirective}.
   */
  @Input() configDisable!: ConfigDirectiveProperties | ConfigDirectiveProperties[] | undefined;

  /** The message to display when this element is disabled by the config data. Applied to the matTooltip position. See {@link ConfigDirective}. */
  @Input() configDisabledStateMessage: string | undefined;

  @Input() @HostBinding("disabled") disabled!: boolean;

  /** The currently loaded element we've determined by the dependency injector */
  private readonly currentElement: MatButton | MatMenuItem | SplitButtonComponent | undefined;

  constructor(private configService: ConfigService, private viewContainerRef: ViewContainerRef) {
    this.currentElement = this.getRelevantElement();
    if (this.currentElement == null) throw new Error("Failed to load current element based on supported options");
  }

  get configValueEnabled() {
    const properties = Array.isArray(this.configDisable)
      ? this.configDisable
      : ([this.configDisable] as ConfigDirectiveProperties[]);
    const result = properties.map((property) => {
      const splits = property.split(".");
      let obj = this.configService.configData as Object;
      // Default case, return false if data not loaded yet
      if (obj == null) return false;
      // Else split the object and locate our specific key
      for (let split of splits) obj = obj[split as keyof Object] as Object;
      if (obj == null || typeof obj !== "boolean")
        throw new Error("Failed to split config data. Does this field exist?");
      return obj as boolean;
    });
    return result.find((x) => x === true) != null;
  }

  /** Updates disabled state of element + any other relevant elements */
  private updateDisabledState(globallyDisabled: boolean = this.disabled) {
    if (this.configDisable != null) {
      const configValueDisabled = !this.configValueEnabled;
      this.disabled = configValueDisabled || globallyDisabled;
      if (this.currentElement) this.currentElement.disabled = this.disabled;
      this.updateTooltip(configValueDisabled);
    }
  }

  /** Tries to get the given injector and returns undefined if it fails */
  private tryGetInjector<T>(comp: CustomTypes.ConstructorFunction<T>) {
    try {
      return this.viewContainerRef.injector.get(comp) as T;
    } catch {
      return undefined;
    }
  }

  /** Attempts to update the tooltip based on disabled state and available tooltip config */
  private updateTooltip(configValueDisabled: boolean) {
    if (configValueDisabled) {
      const tooltip = this.configDisabledStateMessage || "";
      // Handle a special case
      if (this.currentElement instanceof SplitButtonComponent) this.currentElement.mainButtonTooltip = tooltip;
      else {
        // Only apply tooltip if we're using it and the config value is disabled
        const tooltipInjector = this.tryGetInjector(MatTooltip);
        if (tooltipInjector) tooltipInjector.message = this.configDisabledStateMessage || "";
      }
    }
  }

  /** Attempts to locate what type of component we are using with the dependency injector */
  private getRelevantElement() {
    return (
      this.tryGetInjector(MatButton) || this.tryGetInjector(MatMenuItem) || this.tryGetInjector(SplitButtonComponent)
    );
  }

  ngOnInit(): void {
    this.updateDisabledState();
  }

  ngOnChanges(changes: AngularCustomTypes.BaseChangeTracker<ConfigDirective>): void {
    this.updateDisabledState(changes.disabled?.currentValue);
  }
}
