import { Component, ContentChild, Input, TemplateRef } from "@angular/core";
import { SessionSummaryAdvancedGroupSeparation } from "@tdms/common";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";

/** This component is used to display a construct table for each metric */
@Component({
  selector: "session-summary-construct-table[groups]",
  templateUrl: "./construct-table.component.html",
  styleUrls: ["./construct-table.component.scss"],
})
export class SessionSummaryConstructTableComponent {
  /** The groups to render */
  @Input() groups!: SessionSummaryAdvancedGroupSeparation[] | undefined;

  /** If the time row at the bottom should be displayed. */
  @Input() displayTimeRow = true;

  /** Headers we may want to display instead of time series values. Used on the help page. */
  @Input() headerOverride: string[] | undefined;

  /** Reference to the cell template so the developer can override it */
  @ContentChild("cellTemplate") cellDisplayRef!: TemplateRef<any> | null;

  constructor(private configService: ConfigService) {}

  get gaugeRange() {
    return this.configService.configData!.sessionSummary.gaugeRange;
  }

  shouldRenderGroup(group: SessionSummaryAdvancedGroupSeparation) {
    return group.metrics.length > 0;
  }

  isFirstVisibleGroup(group: SessionSummaryAdvancedGroupSeparation) {
    if (this.groups == null) return false;
    const renderedGroups = this.groups.filter(this.shouldRenderGroup);
    return renderedGroups[0] === group;
  }

  isLastVisibleGroup(group: SessionSummaryAdvancedGroupSeparation) {
    if (this.groups == null) return false;
    const renderedGroups = this.groups.filter(this.shouldRenderGroup);
    return renderedGroups[renderedGroups.length - 1] === group;
  }

  /** Returns the color for the given value determined by the gauge range */
  getGaugeConfigByValue(val: number) {
    return (
      this.gaugeRange.find((x) => x.min <= val && x.max >= val) || {
        textColor: "black",
        color: "gray",
        name: "Unknown",
      }
    );
  }
}
