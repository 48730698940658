<shared-dialog-wrapper [showCloseButton]="false" [header]="properties.header">
  <div class="confirmation-container">
    <div class="description">{{ properties.description }}</div>
    <div class="buttons">
      <!-- Cancel -->
      <button mat-raised-button [color]="properties.cancelButtonColor" (click)="cancelClick()">
        {{ properties.cancelButtonText }}
      </button>
      <!-- Confirm -->
      <button mat-raised-button [color]="properties.confirmButtonColor" (click)="confirmClick()">
        {{ properties.confirmButtonText }}
      </button>
    </div>
  </div>
</shared-dialog-wrapper>
