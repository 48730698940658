<div class="logical-operator-container">
  <mat-form-field *ngIf="supportedOperators.length > 0" appearance="outline" color="accent">
    <mat-select [formControl]="dropdownControl" (selectionChange)="onDropdownChanged()" color="accent">
      <mat-option *ngFor="let option of supportedOperators" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
    <mat-icon matSuffix [matTooltip]="tooltip" color="accent">help</mat-icon>
  </mat-form-field>
</div>
