<div class="file-uploader-container">
  <div class="row">
    <!-- Allow children to be rendered next to the input -->
    <ng-content></ng-content>
    <!-- File input -->
    <div class="file-input-container">
      <!-- Upload button -->
      <button class="upload-button" matPrefix mat-flat-button color="accent" (click)="fileInput.click()">
        <mat-icon>upload</mat-icon>
      </button>
      <!-- Input for displaying the name of our current file -->
      <mat-form-field appearance="fill" class="file-input">
        <input disabled matInput type="text" [value]="inputDisplay" autocomplete="off" />
        <!-- Hidden input to actually do the file upload -->
        <input
          hidden
          (change)="fileChange($any($event))"
          (click)="$any($event.target).value = null"
          #fileInput
          type="file"
          [accept]="supportedFileTypes"
          [multiple]="canSelectMultipleFiles()"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="row plugin-row" *ngIf="!isAggregateUpload">
    <!-- Plugin Selection -->
    <shared-improved-select
      class="type-select plugin-type-select"
      appearance="fill"
      color="var(--text-color)"
      dropdownName="Plugin"
      [selectedValue]="selectedPluginType"
      [dropdownOptions]="availablePluginUploads"
      [selectedValueChanged]="selectedPluginTypingChange.bind(this)"
    ></shared-improved-select>

    <!-- Data Type Selection -->
    <shared-improved-select
      class="type-select data-type-select"
      appearance="fill"
      color="var(--text-color)"
      dropdownName="Data Source"
      [selectedValue]="selectedUploadType"
      [dropdownOptions]="uploadTypesForCurrentPlugin"
      [selectedValueChanged]="selectedUploadTypeChanged.bind(this)"
    >
      <!-- Replace the rendering of the standard data -->
      <ng-template replace="optionDisplay" let-value="value" let-valueClean="valueClean">
        <mat-icon class="info-icon" *ngIf="value.description" [matTooltip]="value.description">info</mat-icon>
        <span>{{ valueClean }}</span>
      </ng-template>
    </shared-improved-select>
  </div>

  <div class="row" *ngIf="!isAggregateUpload">
    <!-- Checkbox for identifying session files post session creation -->
    <mat-checkbox
      *ngIf="canUploadNewSessionCreationFile"
      [(ngModel)]="nextFileIsSessionCreation"
      matTooltip="This session was created without a session file to provide metrics. Checking this box will allow this next file uploaded to act like the metric generation file."
      (change)="updateUploadTypes()"
    >
      Act as Session Creation?
    </mat-checkbox>
  </div>
</div>
