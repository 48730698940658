<div
  class="tag"
  [ngStyle]="{ backgroundColor: color }"
  [ngClass]="{ removable: canRemove, clickable: canClick }"
  (click)="canClick ? onClick.emit($event) : undefined"
>
  <!-- Flex placeholder -->
  <div class="flex"></div>
  <span class="content">
    <mat-icon *ngIf="icon" class="icon">{{ icon }}</mat-icon>
    {{ name }}
    <ng-content></ng-content>
  </span>
  <!-- Buttons -->
  <div class="button-row flex">
    <!-- Edit button -->
    <button
      mat-icon-button
      class="edit-button"
      *ngIf="canEdit"
      (click)="tagEdit($event)"
      matTooltip="Opens a dialog to edit this tag"
      matTooltipPosition="right"
      [configDisable]="'tag.allowEditing'"
      configDisabledStateMessage="Tag editing is disabled"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <!-- Remove button -->
    <button
      mat-icon-button
      class="remove-button"
      *ngIf="canRemove"
      (click)="tagRemove($event)"
      matTooltip="Removes this tag from the current element"
      matTooltipPosition="right"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
