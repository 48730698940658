import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface HelpDialogProperties {
  title: string;
  body: string | undefined;
}

@Component({
  selector: "dialog-help",
  templateUrl: "./help-dialog.component.html",
  styleUrls: ["./help-dialog.component.scss"],
})
export class HelpDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public properties: HelpDialogProperties) {}
}
