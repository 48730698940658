import { SelectionChange, SelectionModel } from "@angular/cdk/collections";
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, SortDirection } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { CustomTypes } from "@tdms/common";
import { AngularCustomTypes } from "@tdms/frontend/modules/shared/models/angular.custom.types";
import { FrontendUtility } from "@tdms/frontend/modules/shared/models/utility";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { kebabCase } from "lodash-es";

abstract class GenericTableColumn {
  /**
   * flag to indicate whether this column is backed by data or just a superfluous column added on to each row.
   */
  isDataBacked: boolean;

  /**
   * Unique internal name for the column. Used to reference the column internally, should not be diplayed to the user.
   */
  name: string;

  /**
   * The title for this column that will be displayed to the user.
   */
  title: string;

  /** If we should hide this column on the table but include it in the export only */
  onlyShowInExport: boolean;

  constructor(name: string, title: string, isDataBacked: boolean = true, onlyShowInExport = false) {
    this.name = name;
    this.title = title;
    this.isDataBacked = isDataBacked;
    this.onlyShowInExport = onlyShowInExport;
  }

  /**
   * Given a row in the table, format and return the data that should be displayed for this cell.
   */
  abstract getDataValue(rowData: any): any;
}

/**
 * Defines a table column that is not backed by and data in the table but instead used for a superfluous component display (i.e. buttons for actions).
 */
export class DatalessColumn extends GenericTableColumn {
  constructor(name: string, title: string) {
    super(name, title, false);
  }

  override getDataValue(_: any) {
    return undefined;
  }
}

export class DataColumn<RowDataType, ColumnDataType> extends GenericTableColumn {
  /**
   * This is the field on each row that contains the value associated with this column.
   * Since parent components can provide a custom rendering template,
   * we no longer type check this as string | bool | Date | number and let it be anything.
   */
  displayField: CustomTypes.PropertyNames<RowDataType, ColumnDataType>;

  /**
   * An optional formatter for the cell view that will be applied to the data before being displayed.
   */
  cellViewFormatter?: { (value: any, orgObject: RowDataType): any };

  constructor(
    displayValue: CustomTypes.PropertyNames<RowDataType, ColumnDataType>,
    title: string,
    cellViewFormatter?: { (value: ColumnDataType, orgObject: RowDataType): string },
    name?: string,
    onlyShowInExport = false
  ) {
    super(name ?? displayValue, title, true, onlyShowInExport);
    this.displayField = displayValue;
    this.cellViewFormatter = cellViewFormatter;
    this.onlyShowInExport = onlyShowInExport;
  }

  /**
   * Given a row in the table, fetch the data associated to this column, format it and return the value.
   * @cellViewFormatter will be applied with the raw data from the row before returning.
   * @param rowData The row in the table to fetch cell value from.
   * @returns The fetched and formatted cell value.
   */
  override getDataValue(rowData: RowDataType) {
    let data = rowData[this.displayField] as any;
    if (this.cellViewFormatter != null) data = this.cellViewFormatter(data, rowData);
    // Don't display undefined values
    if (data == null) data = "";
    return data;
  }
}

/**
 * This component is a generic table that can be utilized in multiple customizable aspects
 *  across the application
 */
@Component({
  selector: "shared-generic-table[data][displayedColumns][tableName]",
  templateUrl: "./generic-table.component.html",
  styleUrls: ["./generic-table.component.scss"],
})
export class GenericTableComponent<T extends Object & { isProcessing?: boolean }>
  extends SubscribingComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnChanges
{
  /**
   * The name of this table. Used primarily for exporting and other identification.
   * @default "Generic Table"
   */
  @Input() tableName = "Generic Table";

  /**
   * This is the supported data that should be displayed in this table.
   */
  @Input() data!: T[] | undefined | null;

  /**
   * Our local table data source for more control over the given data
   */
  dataSource = new MatTableDataSource<T>();

  /**
   * The columns to display on our table
   */
  @Input() displayedColumns!: GenericTableColumn[];

  /** Holds the columns that should be rendered based on displayed columns + internal columns */
  renderedColumns: GenericTableColumn[] = [];

  /**
   * If the filter box should appear in this table or not
   */
  @Input() filterable: boolean = true;

  /**
   * If given, will add an add button to the table to add new rows
   */
  @Input() addRowCallback: { (): void } | undefined;

  /**
   * If given, allows the rows to be clicked and fire this callback
   */
  @Input() clickCallback: { (data: T): void } | undefined;

  /**
   * The value in the filter input
   */
  filterValue: string = "";

  /**
   * The field name that we are currently filtering on from filterableFields
   */
  filterOn!: CustomTypes.PropertyNames<T, any>;

  /**
   * Fields that are searchable for the dropdown.
   *
   * Takes displayed columns by default and turns them into this object
   */
  @Input() filterableFields: { value: string; viewValue: string }[] | undefined = undefined;

  /**
   * The paginator element
   */
  @ViewChild("contentTablePaginator") paginator!: MatPaginator;

  /**
   * The table element
   */
  @ViewChild("genericTable") table!: MatTable<any>;

  /**
   * Mat sort for table that allows us to sort by column
   */
  @ViewChild(MatSort) sort!: MatSort;

  /**
   * Reference to the cell template so the developer can override it
   */
  @ContentChild("cellTemplate") cellDisplayRef!: TemplateRef<any> | null;

  /**
   * Reference to the button row so the developer can override it
   */
  @ContentChild("buttonRow") buttonRowRef!: TemplateRef<any> | null;

  /**
   * The sort header to start with
   */
  @Input() defaultSortHeader: CustomTypes.PropertyNames<T, any> | undefined;

  /**
   * The default direction to perform our sorting
   */
  @Input() defaultHeaderSortDirection: SortDirection = "desc";

  /**
   * The paginator page size to use
   * @default 10
   */
  @Input() paginatorPageSize: number = 10;

  /**
   * If we should attempt to guess how many rows we can fit per page to max out the size of
   *  it's current container. Disabling this may result in a larger table than it's container
   * @default true
   */
  @Input() shouldGuessPaginatorPageSize = true;

  /**
   * If the first last buttons should be shown on the paginator, if enabled.
   * @default true
   */
  @Input() showFirstLastButtons = true;

  /**
   * Controls if the export button should be displayed in the table so the user can export this data to
   *  a .csv format.
   * @default true
   */
  @Input() exportEnabled = true;

  /**
   * The height of each mat row used to guess paginator sizing for table. In pixels.
   */
  @Input() matRowHeight = 50;

  /**
   * How wide the table is in pixels
   */
  protected tableWidth: number = 0;

  /**
   * This input allows you to add selection boxes to tables for executing mass functionality against. Passing a function here
   *  will cause the selection boxes to appear.
   */
  @Input() selectionUpdate: { (currSelection: T[]): void } | undefined;

  /** Selection model for use with the selection boxes */
  selection = new SelectionModel<T>(true, []);

  /** The column information for displaying the selection boxes */
  selectionColumn = new DatalessColumn("select", "");

  /** Tracks what rows should be considered "active". This is not a required value */
  @Input() activeRows: T[] | undefined;

  constructor(private cdr: ChangeDetectorRef) {
    super();
    this.getCellValue = this.getCellValue.bind(this);
    this.addSubscription(this.selection.changed.subscribe(this.selectionStateChange.bind(this)));
  }

  ngAfterViewChecked(): void {
    // After the view has been checked, verify the sizing of the table width and re detect to get the updates
    this.tableWidth = this.tableWidthFromElement;
    // Fixes an issue where the page doesn't set the paginator size correctly when switching views
    this.guessPaginatorPageSize();
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: AngularCustomTypes.BaseChangeTracker<GenericTableComponent<any>>): void {
    // Update column status in the event internal ones are later added
    this.setRenderedColumns(
      (changes.selectionUpdate?.currentValue || this.selectionUpdate) != null,
      changes.displayedColumns?.currentValue || this.displayedColumns
    );
    if (changes.data) this.setDataSourceData(changes.data.currentValue);
  }

  /**
   * Attempts to guess how many rows we can use for this table to fit in it's container.
   */
  @HostListener("window:resize", ["$event"])
  guessPaginatorPageSize() {
    if (this.shouldGuessPaginatorPageSize) {
      // The mat row CSS defaults to this
      const totalHeight = document.querySelector("#generic-table-container")?.clientHeight || 0;
      const utilityRowHeight = document.querySelector("#generic-table-utility-row")?.clientHeight || 0;
      const paginatorHeight = document.querySelector("#generic-table-paginator")?.clientHeight || 0;
      let guessedRows = Math.floor((totalHeight - utilityRowHeight - paginatorHeight) / this.matRowHeight) - 1;
      if (guessedRows < 1) guessedRows = 1;
      this.paginatorPageSize = guessedRows;
      this.paginator?._changePageSize(guessedRows);
    }
  }

  /**
   * Returns the table elements width from DOM
   */
  get tableWidthFromElement() {
    return (this.table as any as { _elementRef: ElementRef })._elementRef.nativeElement.clientWidth;
  }

  /**
   * Replaces the current table data source with new data and copies over
   *  the important functions
   */
  setDataSourceData(data: T[]) {
    // Copy relevant info
    const newSource = new MatTableDataSource(data);
    newSource.paginator = this.paginator;
    newSource.filterPredicate = this.filterTableData.bind(this) as any;
    newSource.sort = this.sort;
    this.dataSource = newSource;
    this.selection.clear();
  }

  ngAfterViewInit(): void {
    // Assign paginator
    this.dataSource.paginator = this.paginator;
    // Assign sort
    this.dataSource.sort = this.sort;
    this.guessPaginatorPageSize();
    // Force detection for initial page size guess
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    // Set default filterable fields
    if (this.filterableFields == null)
      this.filterableFields = this.displayedColumns
        /// Only allow filtering on fields that are data backed
        .filter((field) => field.isDataBacked && !field.onlyShowInExport)
        .map((x) => {
          return { value: x.name, viewValue: x.title };
        });
    // Set default filter
    if (this.filterOn == null && this.filterableFields != null) this.filterOn = this.filterableFields[0].value as any;
    if (this.defaultSortHeader == null && this.filterableFields != null)
      this.defaultSortHeader = this.filterableFields[0].value as any;
  }

  /**
   * Get the list of header names from our configured columns.
   */
  get headers() {
    return this.displayedColumns.map((x) => x.name);
  }

  /** Returns all headers for including rendered columns.
   * @see {@link renderedColumns}
   */
  get renderedHeaders() {
    return this.renderedColumns.map((x) => x.name);
  }

  /** Sets the rendered columns to consider additional internal columns */
  setRenderedColumns(shouldDisplaySelection = this.selectionUpdate != null, columns = this.displayedColumns) {
    let renderCols = !shouldDisplaySelection ? columns : [this.selectionColumn].concat(columns);
    // Filter out certain columns
    renderCols = renderCols.filter((x) => {
      if (x instanceof DataColumn) return !x.onlyShowInExport;
      else return true;
    });
    this.renderedColumns = renderCols;
  }

  /**
   * This function determines if the given data matches the current filter. This allows
   *  us to change what the table shows.
   */
  filterTableData(data: T, filter: string) {
    let dataToCheck = `${data[this.filterOn]}`;
    // Get matching column so we can execute the conversion function if it exists
    const displayColumn = this.displayedColumns.find((x) => x.name === this.filterOn);
    dataToCheck = displayColumn?.getDataValue(data);
    return `${dataToCheck}`.trim().toLowerCase().includes(filter);
  }

  /**
   * Given an event, filters the table
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    // Apply filter value
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Clears the current filer
   */
  clearFilter() {
    this.filterValue = "";
    this.dataSource.filter = this.filterValue;
  }

  /**
   * Handles when the search on changes so we can re trigger the filter
   */
  searchOnChange() {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
  }

  getCellValue(rowData: any, column: GenericTableColumn) {
    return column.getDataValue(rowData);
  }

  /**
   * When called, exports all the data and columns loaded into the table into a .csv format and
   *  allows the user to download it.
   */
  exportTableData() {
    if (this.data) {
      const fileName = kebabCase(`${this.tableName}-table-data`);
      // Build the CSV string based on data and content
      const headersToIncludeInOutput = this.displayedColumns.filter((x) => x.isDataBacked);
      const tableHeaders = headersToIncludeInOutput.map((x) => x.name).join(",");
      let csvString = `${tableHeaders}\n`;
      // Iterate over all of our data and add it to the export string
      for (let chartData of this.data) {
        // Iterate over every header to include
        csvString += headersToIncludeInOutput
          .map((header) => {
            const val = header.getDataValue(chartData);
            if (val == null) return undefined;
            else return `"${val}"`;
          })
          .filter((x) => x != null)
          .join(",");
        // Don't forget the ending newline
        csvString += "\n";
      }
      FrontendUtility.saveFile("csv", csvString, fileName);
    }
  }

  /**
   * Returns if the selected set of data matches the length of our data source
   */
  isAllSelected() {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  /** Handles what to do when the selection mapping of any checkbox changes */
  selectionStateChange(_changes: SelectionChange<T>) {
    if (this.selectionUpdate) this.selectionUpdate(this.selection.selected);
  }

  /**
   * Selects all rows if they are not all selected; otherwise clear selection.
   */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }
}
