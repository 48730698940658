import { createFeatureSelector, createSelector } from "@ngrx/store";
import { TagState } from "@tdms/frontend/modules/tag/store/tag.state";
import * as TagReducer from "./tag.reducer";

/** Given a state selects the tags */
const selectTagState = createFeatureSelector<TagState>("tag");

/** Returns all tags */
const selectAllTags = createSelector(TagReducer.selectAll, (entities) => entities);

/**
 * Returns, from state, the current tags
 */
export const selectAllTagsFromState = createSelector(selectTagState, selectAllTags);
