<shared-div-progress-component
  [ready]="!isUploading"
  [notReadyMessage]="uploadingText"
  [progress]="uploadingProgress"
  class="session-extras-progress"
>
  <div class="extra-files-container">
    <div class="extra-files">
      <data-store-extra-files
        [files]="currentExtraFiles"
        header="Upload additional files to this session"
        (filesUpdated)="updateExtraFiles($event)"
      ></data-store-extra-files>
    </div>
  </div>

  <!-- Submit button for uploading -->
  <div class="submit-container">
    <span>
      <button mat-flat-button color="accent" (click)="submitCallback()" [disabled]="!submitButtonAvailable">
        Upload
      </button>
    </span>
  </div>
</shared-div-progress-component>
