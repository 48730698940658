<div class="file-tree-container">
  <div *ngIf="files.length === 0 && sessionFiles.length === 0" class="no-files-container">{{ noFilesMessage }}</div>
  <mat-tree *ngIf="files.length !== 0" class="file-tree" [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding class="tree-node">
      <data-store-file-tree-node
        [node]="node"
        [nodeLevel]="getNodeLevel(node)"
        [isBulkUpload]="isBulkUpload"
        (selectNodeToEdit)="selectNodeToEdit($event)"
        (selectNodeForUpload)="selectNodeForUpload($event)"
        [deleteCallback]="deleteCallback"
      ></data-store-file-tree-node>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node *matTreeNodeDef="let node; when: nodeHasChild" matTreeNodePadding class="tree-node">
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
        <mat-icon>
          {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
        </mat-icon>
        <span *ngIf="getNodeLevel(node) === 0">{{ node.name }}</span>
      </button>
      <data-store-file-tree-node
        [node]="node"
        [nodeLevel]="getNodeLevel(node)"
        [isBulkUpload]="isBulkUpload"
        (selectNodeToEdit)="selectNodeToEdit($event)"
        (selectNodeForUpload)="selectNodeForUpload($event)"
        [deleteCallback]="deleteCallback"
      ></data-store-file-tree-node>
    </mat-tree-node>
  </mat-tree>
</div>
