<div class="date-time-picker-container">
  <!-- Date Picker -->
  <mat-form-field appearance="outline" color="accent" class="date-picker-form-field">
    <mat-label>{{ textPrefix ? textPrefix + " " : "" }}Date</mat-label>
    <input
      matInput
      readonly
      [matDatepicker]="picker"
      [formControl]="control"
      [min]="min"
      [max]="max"
      (dateChange)="dateChange($event)"
    />
    <mat-hint>MM/DD/YYYY</mat-hint>
    <button mat-icon-button matSuffix [disabled]="control.disabled" type="button" (click)="picker.open()">
      <mat-icon>calendar_month</mat-icon>
    </button>
    <mat-datepicker
      #picker
      [disabled]="control.disabled"
      (opened)="datePickerOpened()"
      (closed)="datePickerClosed()"
    ></mat-datepicker>
    <mat-error *ngIf="control.invalid">{{ invalidText }}</mat-error>
  </mat-form-field>
  <!-- Time Picker -->
  <shared-time-picker
    class="time-picker-form-field"
    [control]="control"
    [showSeconds]="showSeconds"
    [textPrefix]="textPrefix"
    [min]="min"
    [max]="max"
  ></shared-time-picker>
</div>
