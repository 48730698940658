/**
 * An enum of router URL's that have components assigned to them and can be navigated to
 */
export enum Route_URLs {
  login = "login",
  sessionSummary = "session-summary",
  dashboard = "dashboard",
  sessionSelection = "session-selection",
  sessionComparison = "session-comparison",
  dataManagement = "data-management",
  communication = "communication",
  userManagement = "user-control",
  /** The default route to always redirect back to */
  default_route = "session-selection",
}
