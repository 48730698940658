import { Type } from "class-transformer";
import "reflect-metadata";
import { TDMSBase } from "../base";

/**
 * A class that allows us to track when a role or user is speaking
 */
export class SpeakingInstance extends TDMSBase {
  /**
   * The primary key ID related to this instance of speaking data
   */
  id: number;

  /**
   * The FK to the speaking data overarching object that this instance is a part of
   */
  speakingDataId: number;

  /**
   * If the user/role is speaking at this time.
   */
  isSpeaking: boolean;

  /**
   * Returns the bool in number format.
   */
  get speakingAsNumber() {
    return this.isSpeaking ? 1 : 0;
  }

  /**
   * The time associated to this instance
   */
  @Type(() => Date)
  time: Date;

  constructor(id: number | undefined, speakingDataId: number | undefined, isSpeaking: boolean, time: Date) {
    super();
    this.id = id!;
    this.speakingDataId = speakingDataId!;
    this.isSpeaking = isSpeaking;
    this.time = time;
  }
}
