<shared-dialog-wrapper class="string-search-help-dialog" header="String Search Help">
  <div class="container">
    <span class="description">
      The string search capability within the datastore provides some unique options to make your searching more
      customizable. These additional capabilities are listed below.
    </span>

    <!-- Option explanation -->
    <ul class="option-list">
      <!-- Wildcards -->
      <li>
        Wildcard support
        <ul>
          <li>
            Wildcards are a character or sequence of characters that can be used to represent one or more other
            characters for pattern matching.
          </li>
          <li>
            The data store strings support the ability to use wildcards within it's string search terms using the
            characters: * or %.
          </li>
          <li>
            Examples
            <ol>
              <li>
                Let's say there are multiple sessions named like "Session Red N" and "Session Blue N".
                <ul>
                  <li>
                    If I just specifically want any session name that includes "Red" I can type *red* in my search term
                    and I'll get those corresponding matches.
                  </li>
                </ul>
              </li>
              <li>If I want to get every session that starts with SOMS, I can use SOMS* as my search term.</li>
              <li>If I want to get every session I can just use * by itself to match everything.</li>
            </ol>
          </li>
        </ul>
      </li>
      <!-- Case insensitive -->
      <li>
        Case Sensitivity
        <ul>
          <li>String searches are case insensitive meaning TEST and test will result in the same content.</li>
        </ul>
      </li>
    </ul>
  </div>
</shared-dialog-wrapper>
