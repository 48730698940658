import { Component } from "@angular/core";
import { Utility, WaveformChartData, WaveformChartSeries } from "@tdms/common";
import { BookmarkChartBaseComponent } from "@tdms/frontend/modules/charts/shared/bookmark-base/bookmark.base.component";
import { ExtendedChartOptions } from "@tdms/frontend/modules/charts/shared/plugins/plugin.typing";
import { ChartOptions, ChartType } from "chart.js";
import { merge } from "lodash-es";

/**
 * This chart defines a waveform component. It's built off of a line chart that instead renders bars where the height is based on the given data
 *  in a certain range.
 *
 * The certain range we are aiming for is 0-100. This will be equalized on either side of this and is expected to be a decimal percentage of bar fill.
 */
@Component({
  selector: "charts-waveform[data][colorLookup][configuration]",
  templateUrl: "../shared/base/base.component.html",
  styleUrls: ["../shared/base/base.component.scss"],
})
export class WaveformChartComponent<
  ChartDataType extends WaveformChartData = WaveformChartData
> extends BookmarkChartBaseComponent<ChartDataType, "line"> {
  /** Default color of the waveform if there is no color identity associated */
  static readonly DEFAULT_IDENTITY_COLOR = "#000000";
  override chartType: ChartType = "line";

  override getChartData(dataSet: ChartDataType[]) {
    return this.filterDataset(dataSet).map((x) => {
      const enforcedColors = x.series.map((z) => z.color || WaveformChartComponent.DEFAULT_IDENTITY_COLOR);
      return {
        label: x.name,
        data: x.series.flatMap((z) => {
          /**
           * Use the data value as both the min and max range. All values should be assumed to be calculated from `convertAudioDataToWaveform`
           *  which means they will be in a 0 - 1 range
           */
          const value = parseFloat((z.value * 100).toFixed(0));
          const y = [-value, value];
          return {
            y,
            x: z.name.getTime(),
          };
        }),
        backgroundColor: enforcedColors, // Necessary for tooltip display
        borderColor: enforcedColors,
        type: "bar" as any, // Type casting issues as it expects a "line",
        // Enforce gaps
        categoryPercentage: 1,
        barPercentage: 1,
      };
    });
  }

  override chartOptionOverrides(coreOptions: ChartOptions<"line">): ExtendedChartOptions<"line"> {
    const superOptions = super.chartOptionOverrides(coreOptions);
    const newOptions = {
      scales: {
        x: {
          type: "time",
        },
        y: {
          min: -100,
          max: 100,
          display: false,
        },
      },
    } as Partial<ExtendedChartOptions<"line">>;
    return merge(superOptions, newOptions);
  }

  /**
   * Given the audio context, converts the audio data to visualization content based on a range of communication.
   *
   * Can be used like:
   *
   * ```ts
   * WaveformChartComponent.convertAudioDataToWaveForm(await new AudioContext().decodeAudioData(await blob.arrayBuffer()))
   * ```
   * @param channel The channel we should pull data from. Default is 0.
   * @param sampleSeparation How many samples per second we should pull from the data so we can have more increments of bars
   * @see https://css-tricks.com/making-an-audio-waveform-visualizer-with-vanilla-javascript/
   */
  private static convertAudioDataToWaveform(ctx: AudioBuffer, channel = 0, sampleSeparation = 10) {
    const rawData = ctx.getChannelData(channel); // We only need to work with one channel of data
    // Number of samples ios determined off audio file duration where each second is a sample
    const samples = Math.ceil(ctx.duration * sampleSeparation);
    const blockSize = Math.floor(rawData.length / samples); // the number of samples in each subdivision
    const filteredData = [];
    for (let i = 0; i < samples; i++) {
      const blockStart = blockSize * i; // the location of the first sample in the block
      let sum = 0;
      for (let j = 0; j < blockSize; j++) sum = sum + Math.abs(rawData[blockStart + j]); // find the sum of all the samples in the block
      filteredData.push(sum / blockSize); // divide the sum by the block size to get the average
    }
    // Normalize the data to scale it so loudest samples are measured as 1
    const multiplier = Math.pow(Math.max(...filteredData), -1);
    return filteredData
      .map((n) => n * multiplier)
      .map((x, i) => {
        const time = i * (1 / sampleSeparation);
        return {
          /** The Y axis number val */
          val: x,
          /** The time into the buffer this data value is for, in seconds */
          timePos: time,
        };
      });
  }

  /**
   * Given an audio buffer and a start date, turns the given buffers channel data into chart data that can be rendered.
   * @param fileDuration How long the file is for determining how many samples to render. Should be in seconds.
   * @param channel The channel we should pull data from. Default is 0.
   */
  static bufferToChartData(buff: AudioBuffer, start: Date, fileDuration: number, channel = 0) {
    // How many samples we want to render. Based on the length of the audio file to not create too many points that wouldn't matter once data gets small enough.
    const numberOfSamples = Utility.dynamicScaledValue(fileDuration, [90, 1500], [10, 1]);
    const content = WaveformChartComponent.convertAudioDataToWaveform(buff, channel, numberOfSamples);
    return content.map((x) => {
      const tempDate = new Date(); // Temp date considering offset from start date
      tempDate.setTime(start.getTime() + x.timePos * 1000);
      return new WaveformChartSeries(x.val, tempDate, x.timePos);
    });
  }
}
