import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Tag, TagRequestReply, TagTopics, TDMSWebSocketMessage, WebSocketCommunication } from "@tdms/common";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { TagActions } from "@tdms/frontend/modules/tag/store/tag.action";
import { TagState } from "@tdms/frontend/modules/tag/store/tag.state";

@Injectable({
  providedIn: "root",
})
export class TagService extends Service {
  constructor(private store: Store<TagState>, private wsService: WebSocketService) {
    super();
  }

  override async onUserChanged() {
    const response = await this.wsService.sendAndReceive<Tag[]>(new TDMSWebSocketMessage(TagTopics.getAll));
    const tags = Tag.fromPlainArray(response.payload);
    this.store.dispatch(TagActions.emptytags({}));
    this.store.dispatch(TagActions.addtags({ options: tags }));
  }

  /** Given a tag, utilizes the websocket system to tell the backend to register that as a new tag */
  async add(tag: Tag) {
    return await this.wsService.sendAndReceive<TagRequestReply>(
      new TDMSWebSocketMessage(TagTopics.add, undefined, TagRequestReply.fromPlain({ tag }))
    );
  }

  /** Listens for new tags created */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<TagRequestReply>>(TagTopics.add)
  async tagAddReceived(data: TDMSWebSocketMessage<TagRequestReply>) {
    this.store.dispatch(TagActions.addtag({ tag: Tag.fromPlain(data.payload.tag) }));
  }

  /** Given a tag, utilizes the websocket system to tell the backend to update the information of the tag */
  async update(tag: Tag) {
    return await this.wsService.sendAndReceive<TagRequestReply>(
      new TDMSWebSocketMessage(TagTopics.update, undefined, TagRequestReply.fromPlain({ tag }))
    );
  }

  /** Listens for tags updated */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<TagRequestReply>>(TagTopics.update)
  async tagUpdatereceived(data: TDMSWebSocketMessage<TagRequestReply>) {
    this.store.dispatch(TagActions.updatetag({ tag: Tag.fromPlain(data.payload.tag) }));
  }

  /**
   * Given a tag, utilizes the websocket system to tell the backend to  delete that tag. The backend will handle informing the relevant
   *    objects that a tag they are using has been removed
   */
  async delete(tag: Tag) {
    return await this.wsService.sendAndReceive<Tag>(new TDMSWebSocketMessage(TagTopics.delete, undefined, tag));
  }

  /** Listens for deleted tags */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<Tag>>(TagTopics.delete)
  async tagDeleteReceived(data: TDMSWebSocketMessage<Tag>) {
    this.store.dispatch(TagActions.removetag({ tag: Tag.fromPlain(data.payload) }));
  }
}
