<div class="login-wrapper">
  <!-- Background -->
  <div class="background">
    <!-- TDMS logo -->
    <img class="logo-light display-logo" />
    <!-- Version -->
    <div class="version-container">
      <div class="version">
        {{ frontendVersion }}
      </div>
    </div>
    <!-- Triangle covers -->
    <div class="cover cover-br"></div>
    <div class="cover cover-tl"></div>
  </div>

  <!-- The actual login card -->
  <mat-card class="login-card mat-elevation-z8">
    <mat-card-content>
      <!-- Left split image -->
      <div class="left-split">
        <shared-img-wrapper class="filler-image" src="../../assets/login/data-analysis.png"></shared-img-wrapper>
      </div>

      <!-- Right split login card -->
      <div class="right-split">
        <div class="header">Welcome!</div>

        <!-- Notifications -->
        <div class="notifications">
          <div
            *ngFor="let notification of notifications"
            [style.background-color]="
              notification.type === 'accent'
                ? 'var(--accent-color)'
                : notification.type === 'error'
                ? 'var(--warn-color)'
                : 'green'
            "
            class="notification"
          >
            <span class="text">{{ notification.message }}</span>
          </div>
        </div>

        <!-- Login form -->
        <form [formGroup]="loginForm" class="login-form" (submit)="submitLogin($event)">
          <mat-form-field color="accent">
            <mat-label for="username">Username</mat-label>
            <input matInput id="username" type="text" autocomplete="username" formControlName="username" />
            <!-- Validators -->
            <mat-error *ngIf="loginForm.controls['username'].errors?.required">Username is required</mat-error>
          </mat-form-field>

          <mat-form-field color="accent">
            <mat-label for="password">Password</mat-label>
            <input matInput id="password" type="password" autocomplete="current-password" formControlName="password" />
            <!-- Validators -->
            <mat-error *ngIf="loginForm.controls['password'].errors?.required">Password is required</mat-error>
          </mat-form-field>

          <div class="button-container">
            <button mat-raised-button color="accent" class="login-button" [disabled]="loginIsDisabled">Login</button>
            <button mat-raised-button color="accent" class="login-button cac" disabled>CAC Login</button>
          </div>
        </form>
      </div>
    </mat-card-content>
  </mat-card>

  <!-- Attribution -->
  <div class="attribution-container">
    <a class="image-ref" target="_blank" href="https://www.vecteezy.com/">Vectors by Vecteezy</a>
  </div>
</div>
