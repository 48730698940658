import { Type } from "class-transformer";
import { TDMSBase } from "../../base";
import { User } from "../../user";
import { DataStoreFile } from "./data.store";

/**
 * This class tracks instances of data access occurring in the data store
 */
export class DataStoreAccessLog extends TDMSBase {
  /**
   * Uniquely generated id that identifies this Access Log Entry.
   */
  id!: number;

  /**
   * The full user object associated to this access log
   */
  @Type(() => User)
  user!: User;

  /**
   * The FK to the user who accessed this file
   */
  userId!: number;

  /**
   * The data store file related to this access log data
   */
  @Type(() => DataStoreFile)
  dataStoreFile!: DataStoreFile;

  /**
   * The ID of this data store file in the db
   */
  dataStoreFileId!: number;

  /**
   * The user given reason they accessed this file
   */
  reason!: string;

  /**
   * The way this file was accessed via TDMS
   */
  systemAccess!: "download" | "search";

  /**
   * The date at which this file was accessed
   */
  @Type(() => Date)
  accessedOn: Date = new Date();

  get username() {
    return this.user?.username;
  }
}
