import { createReducer, on } from "@ngrx/store";
import { DataStoreState } from "../data.store.state";
import { DataStoreActions } from "./data.store.action";

/**
 * Reducer for **data store** data
 */
export const dataStoreReducer = createReducer(
  { supportedUploadTypes: [], files: [], recycleBin: [] } as DataStoreState,
  on(DataStoreActions.addUploadOptions, (state, data) => {
    return { ...state, supportedUploadTypes: data.options };
  }),
  // Current session files
  on(DataStoreActions.addFile, (state, data) => ({ ...state, files: [...state.files, data] })),
  on(DataStoreActions.addFiles, (state, data) => ({ ...state, files: [...state.files, ...data.files] })),
  on(DataStoreActions.removeFile, (state, data) => ({ ...state, files: state.files.filter((x) => x.id !== data.id) })),
  on(DataStoreActions.removeFiles, (state, data) => ({
    ...state,
    files: state.files.filter((x) => data.files.every((z) => x.id === z.id)),
  })),
  on(DataStoreActions.emptyFiles, (state, _) => ({ ...state, files: [] })),
  // -------- Recycle Bin --------
  on(DataStoreActions.addRecycleBinFile, (state, data) => {
    return { ...state, recycleBin: [...state.recycleBin, data] };
  }),
  on(DataStoreActions.removeRecycleBinFile, (state, data) => {
    return { ...state, recycleBin: state.recycleBin.filter((x) => x.id !== data.id) };
  }),
  on(DataStoreActions.emptyRecycleBinFiles, (state, _) => {
    return { ...state, recycleBin: [] };
  }),
  on(DataStoreActions.addRecycleBinFiles, (state, data) => {
    return { ...state, recycleBin: [...state.recycleBin, ...data.files] };
  })
);
