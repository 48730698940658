<div class="advanced-container">
  <div class="header">
    This page displays an overview in increments of how each metric progressed through the session. This data reinforces
    the overview page.
  </div>
  <!-- What to say if this session has no data -->
  <ng-container *ngIf="currentSessionSummary?.advancedBreakdown?.length === 0 && !currentSession?.isProcessing">
    <h1 class="no-data">No Data Available</h1>
  </ng-container>

  <!-- Render normal content until we know we have no data -->
  <ng-container
    *ngIf="
      currentSessionSummary?.advancedBreakdown == null ||
      currentSession?.isProcessing ||
      (currentSessionSummary?.advancedBreakdown && currentSessionSummary!.advancedBreakdown.length > 0)
    "
  >
    <div class="gauge-row">
      <!-- Flex placeholder -->
      <div></div>

      <!-- Gauge display -->
      <session-summary-gauge-legend [gaugeRange]="gaugeRange"></session-summary-gauge-legend>

      <!-- Help button -->
      <button
        mat-button
        [matTooltip]="
          hasHelpDialogContent
            ? 'Opens the help modal for how these ranges were calculated for each metric.'
            : 'No content available'
        "
        class="help-button"
        (click)="openHelpDialog()"
        [disabled]="!hasHelpDialogContent"
      >
        <mat-icon>help</mat-icon>
      </button>
    </div>

    <!-- Table, ish, display separated by grouping and by metrics -->
    <shared-div-progress-component
      [ready]="currentSessionSummary?.advancedBreakdown != null && !currentSession?.isProcessing"
      class="table-progress-container"
    >
      <session-summary-construct-table [groups]="currentSessionSummary?.advancedBreakdown">
        <!-- Override cell display for pretty colors -->
        <ng-template #cellTemplate let-config="config" let-val="val" let-addClasses="addClasses">
          <td
            class="value-cell"
            *ngVar="config"
            [ngClass]="addClasses"
            [ngStyle]="{ backgroundColor: config.color, color: config.textColor }"
            [matTooltip]="val + ', ' + config.name"
          ></td>
        </ng-template>
      </session-summary-construct-table>
    </shared-div-progress-component>
  </ng-container>
</div>
