import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ComparisonOption, PopulatedSearchOption, SearchableOption } from "@tdms/common";
import { StringSearchHelpDialogComponent } from "@tdms/frontend/modules/data-store/components/shared/string-search-help/string-search-help.component";
import { AngularCustomTypes } from "@tdms/frontend/modules/shared/models/angular.custom.types";
import autoBind from "auto-bind";

/**
 * Query Segment represents a single editable search term in the query bar, and all the components that allow editing it.
 * It is composed of an input element (string, number, date picker), a comparison operator selector (dropdown),
 * and a logical operator selector (dropdown) in the case of multiple joined terms.
 */
@Component({
  selector: "query-segment",
  templateUrl: "./query-segment.component.html",
  styleUrls: ["./query-segment.component.scss"],
})
export class QuerySegmentComponent implements OnInit, OnChanges {
  @Input() searchOption!: PopulatedSearchOption;

  @Input() availableOptions?: SearchableOption[];

  @Input() isSearching!: boolean;

  @Input() optionDeleted!: (option: PopulatedSearchOption) => void;
  @Input() optionUpdated!: (option: PopulatedSearchOption) => void;

  /** Used to fire a search start */
  @Input() onSearchStarted!: { (): void };

  optionControl = new FormControl();
  comparisonControl = new FormControl<ComparisonOption>("=");
  startDate = new Date();

  constructor(private dialog: MatDialog) {
    autoBind(this);
    this.onDeleteClicked = this.onDeleteClicked.bind(this);
    this.onComparisonChanged = this.onComparisonChanged.bind(this);
    this.onTermChanged = this.onTermChanged.bind(this);
  }

  ngOnInit() {
    this.checkOptionControlEnable();

    if (this.searchOption.dataType == "date") {
      /// This is to guarantee that date inputs start with a date prepopulated for consistency.
      /// For some reason, date pickers starting as null is not really an option that I could get to work.
      /// Therefore, we set everything to the current date as a default workaround.
      if (this.searchOption.value == null) {
        this.optionControl.setValue(this.startDate);
      } else {
        this.optionControl.setValue(this.searchOption.value);
      }
    } else if (
      !this.searchOption.value &&
      this.searchOption.dataType === "string" &&
      this.searchOption.options?.length > 0
    ) {
      this.searchOption.value = this.searchOption.options[0];
      this.optionControl.setValue(this.searchOption.value);
    }
  }

  ngOnChanges(changes: AngularCustomTypes.BaseChangeTracker<QuerySegmentComponent>): void {
    if (changes.isSearching != null) this.checkOptionControlEnable();
    if (changes.searchOption != null) this.optionControl.setValue(this.searchOption.value);
  }

  /**
   * Check if the input control should be disabled based on the searching status.
   * Angular doesn't want us to use the [disabled] property on input controls for some reason, so we do this instead.
   */
  checkOptionControlEnable() {
    if (this.isSearching) {
    } else if (this.searchOption.dataType != "date") {
      /// Only re-enable the control if we aren't a date type input.
      this.optionControl.enable();
    }
  }

  onDeleteClicked() {
    this.optionDeleted(this.searchOption);
  }

  onDateTimeChanged(event: any) {
    this.searchOption.value = event.value;
    this.optionUpdated(this.searchOption);
  }

  onNumberValueChanged() {
    this.searchOption.value = Number.parseFloat(this.optionControl.value);
    this.optionUpdated(this.searchOption);
  }

  onStringValueChanged() {
    this.searchOption.value = this.optionControl.value.trim();
    this.optionUpdated(this.searchOption);
  }

  onDropdownValueChanged(val?: string) {
    this.searchOption.value = val?.trim();
    this.optionUpdated(this.searchOption);
  }

  onComparisonChanged(comparison: ComparisonOption) {
    this.searchOption.comparisonOption = comparison;
    this.optionUpdated(this.searchOption);
  }

  /**
   * When the term changes, update the component search option and all field data.
   * We also need to handle adjusting the state of our form controls to reflect changes from date to string type fields.
   * @param term The new term selected.
   */
  onTermChanged(term: SearchableOption) {
    this.searchOption.dataType = term.dataType;
    this.searchOption.name = term.name;
    this.searchOption.source = term.source;
    this.searchOption.unit = term.unit;
    this.searchOption.options = term.options;
    this.searchOption.value = undefined;

    // Fix miss typed values
    if (!this.searchOption.validateDataToType())
      if (this.searchOption.dataType === "date") this.searchOption.value = this.startDate;
      else if (this.searchOption.dataType === "number") this.searchOption.value = 0;
      else if (this.searchOption.dataType === "string") {
        // Set dropdown options if given
        if (this.searchOption.options?.length > 0) this.searchOption.value = this.searchOption.options[0];
        else this.searchOption.value = "";
      }

    // Line option control back up
    this.optionControl.setValue(this.searchOption.value);

    this.optionUpdated(this.searchOption);
  }

  /**
   * Actions to take when the enter key is hit in an input field
   */
  searchOptionInputSubmit() {
    // Fire the search
    this.onSearchStarted();
  }

  /** Opens a dialog with string search help information */
  openStringHelp() {
    this.dialog.open(StringSearchHelpDialogComponent);
  }
}
