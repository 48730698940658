import { UpdateNum } from "@ngrx/entity/src/models";
import { createActionGroup, props } from "@ngrx/store";
import { Transcription } from "@tdms/common";

/**
 * Handles adding and removing transcription audio information from the store
 */
export const TranscriptionActions = createActionGroup({
  source: "Transcription",
  events: {
    Add: props<{ data: Transcription[] }>(),
    Update: props<UpdateNum<Transcription>>(),
    Empty: props<any>(),
  },
});
