<shared-div-progress-component [ready]="this.readyToDisplay | async" notReadyMessage="Loading ...">
  <div class="dashboard-container">
    <!-- Render session data if it is set in place -->
    <ng-container *ngIf="(this.readyToDisplay | async) && this.currentMetricDataStore != null">
      <!-- Render the chart grid -->
      <metric-grid
        [dataStore]="currentMetricDataStore!"
        [getExportPrefix]="getExportPrefix"
        [zoomDomainUpdater]="metricService.zoomDomainUpdate"
      ></metric-grid>
    </ng-container>
  </div>
</shared-div-progress-component>
