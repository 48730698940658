import { CustomTypes } from "../../models";

/**
 * Given an array of objects, extract a shared property and create a map of that property -> object.
 * @param value The array of objects to convert
 * @param key The shared property key to extract.
 * @returns The converted map.
 */
export function arrayToMap<ArrayObjects>(
  value: ArrayObjects[],
  key: CustomTypes.PropertyNames<ArrayObjects, string>
): Map<string, ArrayObjects> {
  const map: Map<string, ArrayObjects> = new Map();
  for (const entity of value) {
    map.set(entity[key] as string, entity);
  }

  return map;
}

/**
 * Convert a map object back to an array (just extract the values).
 */
export function mapToArray<ArrayObjects>(value: Map<any, ArrayObjects>) {
  return Array.from(value.values());
}
