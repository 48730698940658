import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Transcription } from "@tdms/common";
import AudioService from "@tdms/frontend/modules/audio/services/audio.service";
import { selectAllTranscriptionsFromState } from "@tdms/frontend/modules/audio/store/transcription.selector";
import { TranscriptionState } from "@tdms/frontend/modules/audio/store/transcription.state";
import { SessionService } from "@tdms/frontend/modules/session/services/session.service";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogProperties,
  DialogWrapperComponent,
} from "@tdms/frontend/modules/shared/components";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

/** A component that provides a button to display for regenerating session metric data based on the transcriptions of a session*/
@Component({
  selector: "audio-regen-button",
  templateUrl: "./regen-button.component.html",
  styleUrls: ["./regen-button.component.scss"],
})
export class RegenButtonComponent extends SubscribingComponent {
  /** Text to display on the button for this transcription definition */
  @Input() text = "From Transcriptions";

  @Input() buttonType: "menu" | "raised" = "raised";

  @Input() disabled: boolean = false;

  transcriptions: Transcription[] = [];

  constructor(
    private store: Store<TranscriptionState>,
    public sessionService: SessionService,
    public audioService: AudioService,
    private dialog: MatDialog
  ) {
    super();
    this.addSubscription(
      this.store.select(selectAllTranscriptionsFromState).subscribe((x) => (this.transcriptions = x))
    );
  }

  ngOnInit(): void {}

  get spinnerActive() {
    return !this.sessionService.currentSession?.isProcessing;
  }

  /** Confirms that we want to regenerate metrics based on transcriptions then asks the backend to do such via websockets */
  regenerateMetricData() {
    const ref = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        header: "Metric Regeneration",
        description:
          "Are you sure you want to regenerate the metrics of this session based on the transcription? This cannot be undone!",
        confirmButtonText: "Confirm",
        confirmButtonColor: "primary",
        confirmClickCallback: () => this.audioService.regenerateMetricsWithTranscription(),
        cancelClickCallback: () => ref.close(),
      } as Partial<ConfirmationDialogProperties>,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }
}
