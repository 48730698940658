import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ChartConfiguration } from "@tdms/common";
import { selectCurrentSession, selectSessionFromId } from "../../session/store/session.selector";
import { MetricState } from "./metric.state";

/**
 * Base metric selector from state
 */
const selectMetrics = createFeatureSelector<MetricState>("metric");

/**
 * Get's the metric configuration from state
 */
export const selectMetricConfiguration = createSelector(selectMetrics, (metrics) => metrics.availableConfiguration);

/**
 * Selector that returns all of the metric data loaded into the frontend currently
 */
export const selectAllMetricData = createSelector(selectMetrics, (metrics) => {
  return metrics.data;
});

/**
 * Selects all metrics from the current session. Utilizes the base level metric selector as well as the base level session selector.
 */
export const selectMetricsForCurrentSession = createSelector(
  selectCurrentSession,
  selectAllMetricData,
  (currentSession, metricData) => {
    if (currentSession == null) return undefined;
    else return metricData[currentSession.id];
  }
);

/** Given the name of a metric, selects it from the current metric session data */
export const selectSpecificMetricForCurrentSession = (metricName: string) =>
  createSelector(selectMetricsForCurrentSession, (data) => {
    if (data) return data[metricName];
    else return undefined;
  });

/**
 * Given a metric configuration, selects the metric data, off the current session, for that configuration.
 * Utilizes `selectMetricData`
 */
export const selectMetricDataForCurrentSession = (config: ChartConfiguration) =>
  createSelector(selectMetricsForCurrentSession, (metricData) => {
    if (metricData == null) return undefined;
    return metricData[config.metricName];
  });

/**
 * Given a metric configuration and a session id, locates the corresponding metric data for that session
 */
export const selectMetricDataForGivenSession = (config: ChartConfiguration, sessionId: number) =>
  createSelector(selectSessionFromId(sessionId), selectAllMetricData, (sessionData, metricData) => {
    if (sessionData == null) return undefined;
    return metricData[sessionData.id][config.metricName];
  });

/**
 * Returns an array of numbers of what sessions we already have metrics loaded for in the data store
 */
export const selectLoadedMetricSessions = createSelector(selectMetrics, (metrics) => {
  return Object.keys(metrics.data);
});

/**
 * Selects the current session and it's related metrics and returns all bookmarks associated to those metrics from the store.
 * @param includeChartSpecific If we should include bookmarks that only appear to a specific chart. Default is false
 */
export const selectAllBookmarksForCurrentSession = (includeChartSpecific = false) =>
  createSelector(selectCurrentSession, selectMetricsForCurrentSession, (session, metrics) => {
    const bookmarks = session?.bookmarks || [];
    if (!metrics || !includeChartSpecific) return bookmarks;
    else {
      for (let metricName of Object.keys(metrics)) {
        const metric = metrics[metricName];
        bookmarks.push(...metric.bookmarks);
      }
      return bookmarks;
    }
  });
