import { Component, Input } from "@angular/core";
import { GaugeRange } from "@tdms/common";

/** A component to display the gauges amounts for a legend like view */
@Component({
  selector: "session-summary-gauge-legend[gaugeRange]",
  templateUrl: "./gauge-legend.component.html",
  styleUrls: ["./gauge-legend.component.scss"],
})
export class GaugeLegendComponent {
  /** The gauge content to render */
  @Input() gaugeRange!: GaugeRange[];
  constructor() {}
}
