import { AfterContentChecked, Directive, ElementRef } from "@angular/core";
import { SubscribingComponent } from "./subscribing_component";

/**
 * A generic component that provides scroll tracking to the given element to track when we have a scrollbar.
 * Can be used to customize styling based on scrollbar state
 */
@Directive({})
export abstract class ScrollTrackingComponent extends SubscribingComponent implements AfterContentChecked {
  /** Tracks if we have a scrollbar or not in the container as defined by `containerElement`*/
  elementHasScrollbar: boolean = false;

  /**
   * The container element we are checking scrolling for. You'll need to handle this with a ViewChild like:
   * `@ViewChild("foobar") containerElement: ElementRef<HTMLElement> | undefined;
   */
  protected abstract containerElement: ElementRef<HTMLElement> | undefined;

  /** Updates `elementHasScrollbar` if we will have a scrollbar on the containerElement */
  private checkContainerHasScroll() {
    const innerElement = this.containerElement?.nativeElement;
    if (innerElement) this.elementHasScrollbar = innerElement.clientHeight < innerElement.scrollHeight;
    else this.elementHasScrollbar = false;
  }

  ngAfterContentChecked(): void {
    this.checkContainerHasScroll();
  }
}
