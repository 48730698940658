<shared-dialog-wrapper class="speaker-identity-dialog" header="New Speaker Identity">
  <h1 class="instructions">Please input your new speaker identity below and click the submit button</h1>

  <form [formGroup]="form" (submit)="complete()" class="form">
    <mat-form-field appearance="outline" color="accent" class="input">
      <mat-label>Speaker Identity</mat-label>
      <input matInput placeholder="Speaker N" formControlName="speaker" />
      <mat-error *ngIf="form.controls.speaker.valid">Speaker is required</mat-error>
    </mat-form-field>
  </form>

  <div class="buttons">
    <button mat-raised-button color="warn" (click)="dialogRef.close()">Cancel</button>
    <button
      mat-raised-button
      color="accent"
      [disabled]="!form.valid"
      [matTooltip]="form.valid ? '' : 'Please make sure all fields are filled out'"
      matTooltipPosition="below"
      (click)="complete()"
    >
      Submit
    </button>
  </div>
</shared-dialog-wrapper>
