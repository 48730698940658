import { DialogRef } from "@angular/cdk/dialog";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { sessionFile } from "@tdms/frontend/modules/data-store/components/uploader/bulk-file-upload/bulk-file-upload.component";

/**
 * A component that takes in a sessionFile and renames the sessionName located
 *  in that sessionFile when user clicks the rename button in the file tree.
 */

@Component({
  selector: "app-rename-session-file-dialog",
  templateUrl: "./rename-session-file-dialog.component.html",
  styleUrls: ["./rename-session-file-dialog.component.scss"],
})
export class RenameSessionFileDialogComponent implements OnInit {
  /**
   * The name the user wants to rename the sessionFile to.
   */
  nameToDisplay: string = "";

  constructor(
    public dialogRef: DialogRef<RenameSessionFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: sessionFile
  ) {
    this.nameToDisplay = data.sessionName;
  }

  ngOnInit(): void {}

  /**
   * The function to submit the new name for the sessionFile.
   */
  submitDialog() {
    this.data.sessionName = this.nameToDisplay;
    this.dialogRef.close();
  }
}
