import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngularMaterialModule } from "@tdms/frontend/modules/material/material.module";
import { SharedModule } from "@tdms/frontend/modules/shared/shared.module";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { LoginComponent } from "./components/login/login.component";
import { UserEditComponent } from "./components/user-edit/user-edit.component";
import { UserIconComponent } from "./components/user-icon/user-icon.component";
import { UserManagementComponent } from "./components/user-management/user-management.component";
import { PermissionGuard } from "./guard/permission.guard";
import { UserService } from "./services/user.service";

const USER_COMPONENTS = [
  UserIconComponent,
  UserManagementComponent,
  UserEditComponent,
  ChangePasswordComponent,
  LoginComponent,
];

@NgModule({
  declarations: USER_COMPONENTS,
  imports: [AngularMaterialModule, CommonModule, SharedModule, FormsModule, ReactiveFormsModule],
  exports: USER_COMPONENTS,
  providers: [UserService, PermissionGuard],
})
export class UserModule {}
