import { Component, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AggregateFileRequest } from "@tdms/common";

import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

/** The properties to display for the aggregate heading selection */
export interface AggregateFileDialogProps {
  request: AggregateFileRequest;
  onComplete: Function;
  onCancel: Function;
}

/**
 * This component is used as a dialog to handle selecting a header you wish to split aggregate data from
 */
@Component({
  selector: "aggregate-file-component-dialog",
  templateUrl: "./aggregate-file-component.html",
  styleUrls: ["./aggregate-file-component.scss"],
})
export class AggregateFileComponent extends SubscribingComponent implements OnDestroy {
  currentSelection: string = "";
  constructor(@Inject(MAT_DIALOG_DATA) public properties: AggregateFileDialogProps) {
    super();
  }

  /** Handles updating a header selection */
  handleHeaderSelection(data: any) {
    this.properties.request.selection = data;
  }

  /** Returns if this set of data is valid or not with an error message */
  get validityError() {
    if (!this.properties.request.selection) {
      return "You must select a header";
    }
    return undefined;
  }
}
