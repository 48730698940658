<shared-div-progress-component [ready]="wsService.isConnected" notReadyMessage="Connecting...">
  <div class="main-content-wrapper">
    <mat-sidenav-container class="sidenav-container" [@.disabled]="!shouldRenderNav">
      <mat-sidenav class="sidenav-content" [(opened)]="drawerShown" [mode]="drawerMode" #drawer *ngIf="shouldRenderNav">
        <app-sidenav class="main-content-sidenav"></app-sidenav>
      </mat-sidenav>
      <mat-sidenav-content class="main-content">
        <div class="main-content">
          <!-- Header Bar -->
          <mat-card class="header-card card" *ngIf="shouldRenderNav">
            <menu-button [show]="menuButtonShown" [onClick]="onMenuButtonClicked"></menu-button>
            <mat-card-content class="content">
              <div class="header">{{ currentRoute }}</div>
              <!-- End of header -->
              <div class="header-end">
                <span *ngIf="configService.configData?.isDevBuild" class="dev-build-text">Development Build</span>
                <img class="logo logo-sizing" />
              </div>
            </mat-card-content>
          </mat-card>
          <!-- Render active route -->
          <div class="router-content">
            <div class="router-content-inner" [ngClass]="{ 'nav-rendered': shouldRenderNav }">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</shared-div-progress-component>
