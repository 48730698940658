<div class="session-comparison-container">
  <!-- Display help info -->
  <div class="help" *ngIf="shouldDisplayHelp">
    <span class="text">To get started, select your sessions to compare below</span>
    <shared-img-wrapper src="../../assets/comparison/a-bOptimized.png" class="comparison-graphic"></shared-img-wrapper>
  </div>

  <!-- Session Selection -->
  <div class="session-selection-card" [ngClass]="{ 'full-size': !shouldDisplayHelp }">
    <mat-card class="card" *ngIf="currentSessions">
      <mat-card-content class="content">
        <span class="first-session-selector">
          <!-- Current session selector -->
          <button
            *ngIf="(currentSession | async) != null"
            mat-flat-button
            color="accent"
            matTooltip="Select current Session"
            (click)="selectCurrentSession()"
            class="current-session-selector"
            [disabled]="(currentSession | async) === sessionsToCompare[0]"
          >
            <mat-icon>done</mat-icon>
          </button>
          <!-- First session -->
          <shared-improved-select
            [selectedValue]="sessionsToCompare[0]"
            [selectedValueChanged]="$any(selectedSessionChanged.bind(this, 0))"
            [dropdownOptions]="getSessionOptions(sessionsToCompare[1])"
            dropdownName="Session A"
            [filterEnabled]="true"
            [paginatorEnabled]="true"
            [itemsPerPage]="5"
            [clearable]="true"
            class="session-selector"
          ></shared-improved-select>
        </span>
        <span class="label"><mat-icon>compare_arrows</mat-icon></span>
        <!-- Second session -->
        <shared-improved-select
          [selectedValue]="sessionsToCompare[1]"
          [selectedValueChanged]="$any(selectedSessionChanged.bind(this, 1))"
          [dropdownOptions]="getSessionOptions(sessionsToCompare[0])!"
          dropdownName="Session B"
          [filterEnabled]="true"
          [paginatorEnabled]="true"
          [itemsPerPage]="5"
          [clearable]="true"
          class="session-selector"
        ></shared-improved-select>
        <!-- Compare button -->
        <button mat-raised-button color="accent" (click)="compare()" [disabled]="!readyToCompare">
          <span>Compare</span>
        </button>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- Display the metric content -->
  <div *ngIf="!shouldDisplayHelp" class="metric-display">
    <metric-grid
      *ngIf="currentlyComparedSessions[0] && currentlyComparedSessions[1]"
      [dataStore]="chartDataStore!"
      [getExportPrefix]="getExportPrefix"
      [zoomDomainUpdater]="metricService.zoomDomainUpdate"
      [isSessionComparison]="true"
    ></metric-grid>
  </div>
</div>
