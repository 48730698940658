import { TDMSBase } from "../../base";

/**
 * This class contains the information necessary to delete some files
 */
export class DataStoreDeleteRequest extends TDMSBase {
  constructor(filesToDelete: number[], force: boolean = false) {
    super();
    this.filesToDelete = filesToDelete;
    this.force = force;
  }
  /**
   * An array of ID's of files to delete
   */
  filesToDelete: number[] = [];

  /**
   * If these files should skip the recycle bin and get deleted permanently.
   *
   * **WARNING: Using this cannot be undone!**
   */
  force = false;
}
