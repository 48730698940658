import { Component, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { differenceInHours, differenceInMinutes, differenceInSeconds, setMinutes, setSeconds } from "date-fns";
import { cloneDeep } from "lodash-es";

/** Time fields to help typing in some function parameters */
type TimeFields = "hour" | "minute" | "second";

/**
 * A generic component that provides time picking capabilities to extend into the date time picker
 */
@Component({
  selector: "shared-time-picker[control]",
  templateUrl: "./time-picker.component.html",
  styleUrls: ["./time-picker.component.scss"],
})
export class TimePickerComponent extends SubscribingComponent {
  /** Text to place before Date and Time. You could pass "Start" to this to get "Start Date" and "Start Time" */
  @Input() textPrefix = "";

  /** If seconds should be supported */
  @Input() showSeconds = true;

  /** The minimum date allowed for these inputs */
  @Input() min: Date | undefined;

  /** The maximum date allowed for these inputs */
  @Input() max: Date | undefined;

  /** The formControl for our date/time object */
  @Input() control!: FormControl;

  /** Tracks when the time selection menu is open */
  menuOpened = false;

  /** Returns the number with a padded leading 0 */
  getPaddedNumber(num: number | undefined) {
    if (num == null) return "";
    return num.toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false });
  }

  /** Returns the time in a pretty string format */
  get timeDisplay() {
    // Use the main object so we can track when actual values are updated
    const hour = this.getPaddedNumber(this.dateValueFromForm?.getUTCHours());
    const minute = this.getPaddedNumber(this.dateValueFromForm?.getUTCMinutes());
    const second = this.getPaddedNumber(this.dateValueFromForm?.getUTCSeconds());
    if (!hour || !minute || !this.showSeconds || !second) return "";
    return `${hour}:${minute}${this.showSeconds ? `:${second}` : ""}`;
  }

  get dateValueFromForm() {
    return this.control.value as Date | undefined;
  }

  /** Sets a default date val if we don't have one */
  setDefaultDateVal() {
    if (this.dateValueFromForm == null) {
      const newDate = new Date();
      newDate.setUTCHours(0, 0, 0, 0);
      this.control.setValue(newDate);
    }
  }

  /** Handles what to do as time fields are updated */
  timeValChanged(field: TimeFields, event: any) {
    this.setDefaultDateVal();
    const val = parseInt(event.target.value);
    let newDateVal = cloneDeep(this.dateValueFromForm!);
    if (field === "second") {
      newDateVal = setSeconds(newDateVal, val);
      event.target.value = newDateVal.getUTCSeconds(); // Reset field
    } else if (field === "minute") {
      newDateVal = setMinutes(newDateVal, val);
      event.target.value = newDateVal.getUTCMinutes(); // Reset field
    } else if (field === "hour") {
      newDateVal.setUTCHours(val);
      event.target.value = newDateVal.getUTCHours(); // Reset field
    }
    // Verify min/max
    if (this.max && this.max.getTime() < newDateVal.getTime()) newDateVal = cloneDeep(this.max);
    if (this.min && this.min.getTime() > newDateVal.getTime()) newDateVal = cloneDeep(this.min);
    // Set the value back to the form group
    this.control.setValue(newDateVal);
    // Update validity of form incase we are using range values
    TimePickerComponent.checkFormGroup(this.control.root as FormGroup);
  }

  /** Rechecks the entire given form group for validity */
  static checkFormGroup(group: FormGroup) {
    // Update validity of form incase we are using range values
    const root = group.controls;
    for (let controlKey of Object.keys(root)) {
      const control = root[controlKey];
      control.updateValueAndValidity();
      control.markAllAsTouched();
    }
  }

  /** Internal handling of how to actually increment/decrement to centralize functionality */
  private incrementDecrementVal(field: TimeFields, handling: "increment" | "decrement") {
    this.setDefaultDateVal();
    let val: number;
    if (field === "hour") val = this.dateValueFromForm!.getUTCHours();
    else if (field === "minute") val = this.dateValueFromForm!.getUTCMinutes();
    else val = this.dateValueFromForm!.getUTCSeconds();
    if (handling === "increment") val += 1;
    else val -= 1;
    this.timeValChanged(field, { target: { value: val } });
  }

  /** Increments the given time value by 1, keeping in mind restrictions */
  incrementVal(field: TimeFields) {
    this.incrementDecrementVal(field, "increment");
  }

  /** Decrements the given time value by 1 */
  decrementVal(field: TimeFields) {
    this.incrementDecrementVal(field, "decrement");
  }

  get isRequired() {
    return this.control.hasValidator(Validators.required);
  }

  get invalidText() {
    return TimePickerComponent.getDateTimeControlInvalidText(this.control);
  }

  /** Returns text to display if an input is invalid for the current form control */
  static getDateTimeControlInvalidText(control: FormControl) {
    if (control.getError("same")) return "Values can't be the same";
    else if (control.getError("notLessThan")) return "Start must be < End";
    else return "Data invalid";
  }

  /** Centralized function to determine if we can increment/decrement based on {@link min} and {@link max} */
  private canIncrementDecrement(date: Date | undefined, key: TimeFields, type: "increment" | "decrement") {
    if (this.min == null && this.max == null) return true; // Short circuit for performance
    if (date == null) return false;
    const clonedDate = cloneDeep(date);
    const compareDate = type === "increment" ? cloneDeep(this.max) : cloneDeep(this.min);
    if (key === "hour") {
      clonedDate.setUTCMinutes(0);
      clonedDate.setUTCSeconds(0);
      compareDate?.setUTCMinutes(0);
      compareDate?.setUTCSeconds(0);
      return compareDate == null || Math.abs(differenceInHours(clonedDate, compareDate)) >= 1;
    } else if (key === "minute") {
      return compareDate == null || Math.abs(differenceInMinutes(clonedDate, compareDate)) >= 1;
    } else if (key === "second") {
      return compareDate == null || Math.abs(differenceInSeconds(clonedDate, compareDate)) >= 1;
    } else return false;
  }

  canIncrement(field: TimeFields) {
    return this.canIncrementDecrement(this.dateValueFromForm, field, "increment");
  }

  canDecrement(field: TimeFields) {
    return this.canIncrementDecrement(this.dateValueFromForm, field, "decrement");
  }
}
