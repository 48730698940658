<!-- Form field -->
<mat-form-field appearance="outline" color="accent" class="time-picker-form-field">
  <mat-label>{{ textPrefix ? textPrefix + " " : "" }}Time</mat-label>
  <input matInput [inputMask]="timeDisplay" [required]="isRequired" [formControl]="control" readonly />
  <mat-hint>HH:MM:SS (UTC)</mat-hint>
  <button
    class="time-picker-menu-button"
    mat-icon-button
    matSuffix
    [matMenuTriggerFor]="timeMenu"
    (click)="$event.stopPropagation(); $event.preventDefault()"
    (menuOpened)="menuOpened = true"
    (menuClosed)="menuOpened = false"
    [ngClass]="{ active: menuOpened }"
    type="button"
    [disabled]="control.disabled"
  >
    <mat-icon>schedule</mat-icon>
  </button>
  <mat-error *ngIf="control.invalid">{{ invalidText }}</mat-error>
</mat-form-field>

<!-- Time menu -->
<mat-menu #timeMenu class="time-menu" xPosition="before">
  <div class="time-picker-container" (click)="$event.stopPropagation()" (keydown.tab)="$event.stopPropagation()">
    <div class="time-row-container">
      <!-- Hours -->
      <div class="column">
        <button mat-icon-button class="val-change" (click)="incrementVal('hour')" [disabled]="!canIncrement('hour')">
          <mat-icon>expand_less</mat-icon>
        </button>
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Hour</mat-label>
          <input
            matInput
            type="number"
            [ngModel]="getPaddedNumber(dateValueFromForm?.getUTCHours())"
            (change)="timeValChanged('hour', $event)"
            tabindex="1"
          />
        </mat-form-field>
        <button mat-icon-button class="val-change" (click)="decrementVal('hour')" [disabled]="!canDecrement('hour')">
          <mat-icon>expand_more</mat-icon>
        </button>
      </div>
      <span class="separator">:</span>
      <!-- Minutes -->
      <div class="column">
        <button
          mat-icon-button
          class="val-change"
          (click)="incrementVal('minute')"
          [disabled]="!canIncrement('minute')"
        >
          <mat-icon>expand_less</mat-icon>
        </button>
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Minute</mat-label>
          <input
            matInput
            type="number"
            [ngModel]="getPaddedNumber(dateValueFromForm?.getUTCMinutes())"
            (change)="timeValChanged('minute', $event)"
            tabindex="2"
          />
        </mat-form-field>
        <button
          mat-icon-button
          class="val-change"
          (click)="decrementVal('minute')"
          [disabled]="!canDecrement('minute')"
        >
          <mat-icon>expand_more</mat-icon>
        </button>
      </div>
      <span class="separator" *ngIf="showSeconds">:</span>
      <!-- Seconds -->
      <div class="column">
        <button
          mat-icon-button
          class="val-change"
          (click)="incrementVal('second')"
          [disabled]="!canIncrement('second')"
        >
          <mat-icon>expand_less</mat-icon>
        </button>
        <mat-form-field *ngIf="showSeconds" appearance="outline" color="accent">
          <mat-label>Second</mat-label>
          <input
            matInput
            type="number"
            [ngModel]="getPaddedNumber(dateValueFromForm?.getUTCSeconds())"
            (change)="timeValChanged('second', $event)"
            tabindex="3"
          />
        </mat-form-field>
        <button
          mat-icon-button
          class="val-change"
          (click)="decrementVal('second')"
          [disabled]="!canDecrement('second')"
        >
          <mat-icon>expand_more</mat-icon>
        </button>
      </div>
    </div>
  </div>
</mat-menu>
