<div class="node-row" *ngIf="node.originalData">
  <!-- Node Name Row with conditional name styling -->
  <span
    *ngIf="nodeLevel === 1"
    matTooltip="{{ node.errorMessage }}"
    matTooltipPosition="right"
    [ngClass]="!node.originalData.uploadError ? 'node-name-singular-session' : 'node-name-error'"
  >
    {{ node.name }}
  </span>
  <span *ngIf="nodeLevel === 2" class="node-name-associated-file">{{ node.name }}</span>
  <!-- Session related file buttons dependent on configuration -->
  <div class="session-file-buttons">
    <!-- Info button if this file created this session content -->
    <button
      mat-icon-button
      disableRipple
      *ngIf="node.originalData.isDelayedSessionCreation || node.originalData.isSessionCreation"
      matTooltip="This file created this session's data"
      matTooltipPosition="right"
      class="session-creation end-button"
    >
      <mat-icon>info</mat-icon>
    </button>

    <!-- Bulk upload options -->
    <span *ngIf="isBulkUpload">
      <!-- Edit Node Button -->
      <button
        mat-icon-button
        disableRipple
        (click)="selectNodeToEdit.emit(node.originalData)"
        *ngIf="node.originalData.isDelayedSessionCreation || node.originalData.isSessionCreation"
        [matTooltip]="node.originalData.fileType.canCreateSessions ? 'Rename this session file' : ''"
        matTooltipPosition="right"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <!-- Upload button -->
      <button
        *ngIf="deleteCallback && node.originalData != null && nodeLevel === 1"
        (click)="selectNodeForUpload.emit(node.originalData)"
        matTooltip="Upload additional files"
        matTooltipPosition="right"
        mat-flat-button
        color="accent"
      >
        <mat-icon>upload</mat-icon>
      </button>
    </span>

    <!-- Delete button -->
    <button
      mat-icon-button
      *ngIf="deleteCallback && node.originalData != null && node.originalData.removable"
      (click)="deleteCallback(node.originalData)"
      matTooltip="Delete File"
      matTooltipPosition="right"
      class="end-button"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
