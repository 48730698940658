import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import {
  CoreTopics,
  PayloadType,
  ServerConnectionInit,
  ServerStatus,
  TDMSWebSocketMessage,
  WebSocketCommunication,
} from "@tdms/common";
import { selectCurrentSession } from "@tdms/frontend/modules/session/store/session.selector";
import { SessionState } from "@tdms/frontend/modules/session/store/session.state";
import { Configuration } from "@tdms/frontend/modules/shared/models/config";
import { filter, firstValueFrom, Observable, Subject } from "rxjs";
import { environment } from "../../../environments/environment";
import { Service } from "../../shared/services/base.service";

/**
 * This service allows you to treat websockets like a pub/sub messaging architecture utilizing
 *  a header value (topic) and a payload for your data object.
 */
@Injectable()
export class WebSocketService extends Service {
  /**
   * The client connection associated to this
   */
  protected client: WebSocket | undefined = undefined;

  /** A unique client identifier to identify this current connection to other clients. The backend will give us this on initial connection. */
  readonly clientIdentifier!: string;

  /**
   * Last URL that we attempt to connect to
   */
  lastConnectedURL: string | undefined = undefined;

  /**
   * How long should be waited between reconnect attempts, in seconds.
   */
  reconnectTimeout: number = 1;

  /**
   * Subscription model to allow for functionality to be called on connect
   */
  protected onClientConnectSub = new Subject();

  /**
   * This subject allows you to listen for when the connection status changes
   */
  connectedStatusChange = new Subject<boolean>();

  /**
   * How long should we wait to assume a connection to the server timed out?
   */
  connectTimeout: number = 30000;

  /**
   * Tracks what our last connection status was. Helps prevent connection status updating
   *  more than it should.
   */
  lastConnectionStatus: boolean = false;

  /**
   * The currently initialized session id, if one is set
   */
  currentSessionId: number | undefined;

  constructor(private store: Store<SessionState>, private snackbar: MatSnackBar) {
    super();
    // Listen for store updates
    this.store.select(selectCurrentSession).subscribe((x) => (this.currentSessionId = x?.id));
    // Subscribe to websocket internal requests to send external
    WebSocketCommunication.WebSocketMessageSending.subscribe((x) => {
      if (x.message.responseType !== "originalClient")
        console.error("Frontend cannot send to any other clients but the backend.");
      else WebSocketCommunication.sendToClient(x.client, x.message.payload);
    });
  }

  override async initialize(): Promise<void> {
    await this.connect(this.getWebSocketURL());
  }

  /**
   * Verifies the client exists. If not, throws an error.
   */
  protected checkClient(client: WebSocket | undefined): asserts client is NonNullable<WebSocket | undefined> {
    if (client == null) throw new Error("Client not initialized. Did you initialize the client before using it?");
  }

  /**
   * Returns if this service is connected to the websocket
   */
  get isConnected() {
    try {
      this.checkClient(this.client);
    } catch (e) {
      return false;
    }
    return this.client.readyState == WebSocket.OPEN;
  }

  /**
   * Attempts to assume the best connection address for your websocket.
   */
  getWebSocketURL() {
    if (environment.webSocketUrlOverride) return environment.webSocketUrlOverride;
    else {
      // Attempt to guess the connection info
      const guessedConType = `${window.location.protocol === "https:" ? "wss" : "ws"}`; // We assume we want to upgrade to match SSL
      // No port means we should assume we are using a reverse proxy. If we are 4200, we should be in dev mode, else use the port direct.
      const guessedPort =
        window.location.port === "" ? "" : window.location.port === "4200" ? `:9000` : `:${window.location.port}`;
      const guessedHostName = window.location.hostname; // We assume the backend is on the same server as the frontend
      return `${guessedConType}://${guessedHostName}${guessedPort}/ws`;
    }
  }

  /**
   * Given a connection URL and some options, initializes a new WebSocket connection.
   * Waits for the connection to open before returning the websocket.
   * @param url The full URL to connect to.
   */
  async connect(url: string) {
    this.lastConnectedURL = url;
    this.client = new WebSocket(url);
    this.client.addEventListener("message", this.onMessageReceived.bind(this));
    this.client.addEventListener("close", this.onClientDisconnect.bind(this));
    this.client.addEventListener("open", () => {
      if (!this.lastConnectionStatus) {
        this.connectedStatusChange.next(true);
        this.lastConnectionStatus = true;
      }

      this.onClientConnectSub.next(undefined);
    });
  }

  /**
   * Handles what should be done when the client disconnects. An attempt will be made to reconnect.
   */
  onClientDisconnect(_ev: CloseEvent) {
    if (this.lastConnectionStatus) {
      this.connectedStatusChange.next(false);
      this.lastConnectionStatus = false;
    }
    // Attempt reconnect
    const lastConnectedURL = this.lastConnectedURL;
    if (lastConnectedURL) setTimeout(() => this.connect(lastConnectedURL), this.reconnectTimeout * 1000);
  }

  /**
   * Given a callback, adds it to the set of listeners to execute once the
   *  client connects to it's given endpoint.
   */
  onClientConnect(callback: { (): void }) {
    this.onClientConnectSub.subscribe(callback);
  }

  /**
   * This function handles calling necessary callbacks when messages are received.
   */
  protected onMessageReceived(ev: MessageEvent<string>) {
    const parsedData = TDMSWebSocketMessage.fromPlainString(ev.data);
    WebSocketCommunication.WebSocketMessageReceived.next({
      message: parsedData as TDMSWebSocketMessage,
      client: this.client!,
    });
  }

  /**
   * Sends this given message to the backend for processing.
   *
   * **Note**: If you do not assign the session id to your message, this will auto fill it in
   * @param addSessionId Add the session Id automatically if it's missing. Default: true
   */
  public send(data: TDMSWebSocketMessage, addSessionId = true) {
    if (addSessionId && (data.sessionId == null || data.sessionId === 0) && this.currentSessionId != null)
      data.sessionId = this.currentSessionId;
    if (this.isConnected) this.client?.send(data.toJSONString());
    else throw new Error("Failed to send websocket message, client not connected.");
  }

  /**
   * Send the given web socket message to the backend, wait for a response on the same topic and return the message as expected.
   * A response is deemed as matching a request if it arrives after the request and has the same topic, appID and sessionId.
   * No type casting or conversion is done here, consumers of this function are expected to handle type conversion themselves.
   * @param data The data to send on the socket
   * @returns The response received by the backend, with payload casted to the provided type.
   */
  public async sendAndReceive<
    DataType extends PayloadType,
    MessageType extends TDMSWebSocketMessage<DataType> = TDMSWebSocketMessage<DataType>
  >(data: TDMSWebSocketMessage) {
    return WebSocketCommunication.sendAndReceive<DataType, MessageType>(this.client as any, data);
  }

  /**
   * Similar to sendAndReceive desrcibed above. This will send your websocket data and add a timeout to the websocket connection.
   * You can specify the amount of time it takes to timeout.
   * @param data The data to send on the socket
   * @param timeoutMillis How long to wait (in milliseconds) before timing out the request. Default is 30000 milis (or 30 seconds)
   * @returns The response received by the backend, with payload casted to the provided type.
   */
  public async sendAndReceiveTimeout<
    DataType extends PayloadType,
    MessageType extends TDMSWebSocketMessage<DataType> = TDMSWebSocketMessage<DataType>
  >(data: TDMSWebSocketMessage, timeoutMillis: number = 30000) {
    return WebSocketCommunication.sendAndReceive<DataType, MessageType>(this.client as any, data, timeoutMillis);
  }

  /**
   * Initiate a collection of requests to the backend, wait for responses from all of them asynchronously and then return the collection of gathered responses for further parsing.
   * A response is deemed as matching a request if it arrives after the request and has the same topic, appID and sessionId.
   * No type casting or conversion is done here, consumers of this function are expected to handle type conversion themselves.
   * @param data The collection of requests to initiate.
   * @returns The collection of responses that matched the request.
   */
  public async sendAllAndReceive<T extends PayloadType>(
    data: TDMSWebSocketMessage[]
  ): Promise<TDMSWebSocketMessage<T>[]> {
    if (data.length == 0) {
      return [];
    }

    const responses: TDMSWebSocketMessage<T>[] = [];
    const observable = new Observable<TDMSWebSocketMessage<T>[]>((subscriber) => {
      WebSocketCommunication.WebSocketMessageReceived.pipe(
        filter(
          ({ message }) =>
            data.find(
              (dataMessage) =>
                dataMessage.topic == message.topic &&
                dataMessage.appID == message.appID &&
                dataMessage.sessionId == message.sessionId &&
                dataMessage.messageId == message.messageId
            ) != undefined
        )
      ).subscribe(({ message }) => {
        responses.push(message as TDMSWebSocketMessage<T>);

        if (responses.length == data.length) {
          // We have received responses to all the messages now, add to our observable.
          subscriber.next(responses);
          // Calling complete here cleans up all memory references to subscribers and this observable.
          // For more information, reference: https://bytethisstore.com/articles/pg/rxjs-unsubscribe
          // And rxjs/src/internal/Subscriber.ts complete(), which calls _complete(), which calls unsubscribe().
          subscriber.complete();
        }
      });
    });

    for (let message of data) {
      // Send all the messages to the backend.
      this.send(message);
    }

    return firstValueFrom(observable);
  }

  /**
   * Listen for initial connection status messages as we receive them
   */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<ServerConnectionInit>>(CoreTopics.initialConnectionStatus)
  protected async initConnectStatusReceived(data: TDMSWebSocketMessage<ServerConnectionInit>) {
    Configuration.BACKEND_VERSION = data.payload.backendVersion;
    (this.clientIdentifier as any) = data.payload.clientIdentifier;
  }

  /**
   * Listens for server status errors and displays them as a snackbar notification
   */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<ServerStatus>>(CoreTopics.serverStatus)
  protected async serverStatusReceived(data: TDMSWebSocketMessage<ServerStatus>) {
    const snackbarRef: { message: string } | undefined = this.snackbar._openedSnackBarRef?.instance?.data;
    // Only open if the message has actually changed
    if (snackbarRef?.message !== data.payload.message)
      this.snackbar.open(data.payload.message, "close", Configuration.ErrorSnackbarConfig);
  }
}
