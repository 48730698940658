import { Component, Input } from "@angular/core";
import AudioService from "@tdms/frontend/modules/audio/services/audio.service";
import { UploadService } from "@tdms/frontend/modules/data-store/services/upload.service";
import { RouteConfig } from "@tdms/frontend/modules/routing/models/route.config";
import { RouteGroupConfig } from "@tdms/frontend/modules/routing/models/route.group.config";
import { RouterService } from "@tdms/frontend/modules/routing/services/router.service";
import { SessionService } from "@tdms/frontend/modules/session/services/session.service";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { ServiceManager } from "@tdms/frontend/modules/shared/services/service.manager";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { UserService } from "@tdms/frontend/modules/user/services/user.service";

/**
 * A component that can apply button styling and handling to route config options
 */
@Component({
  selector: "sidenav-router-button[config]",
  templateUrl: "./router.button.component.html",
  styleUrls: ["./router.button.component.scss"],
})
export class RouterButtonComponent extends SubscribingComponent {
  /** The configuration used for this button */
  @Input() config!: RouteConfig;

  /** The parent to this route config, if available */
  @Input() parentConfig?: RouteGroupConfig;

  constructor(
    private userService: UserService,
    // The below are public to support access by the route configuration
    public configService: ConfigService,
    public routerService: RouterService,
    public uploadService: UploadService,
    public audioService: AudioService,
    public serviceManager: ServiceManager,
    public sessionService: SessionService
  ) {
    super();
  }

  get currentUser() {
    return this.userService.currentAuthenticatedUser;
  }

  get currentSession() {
    return this.sessionService.currentSession;
  }

  /** Returns if the parent config is disabled */
  get parentDisabled() {
    if (this.parentConfig && this.parentConfig.sidenavOptions?.enabled) {
      const parentIsEnabled = this.parentConfig.sidenavOptions.enabled.call(this);
      if (!parentIsEnabled) return true;
    } else return false;
  }

  /** Local instance of {@link menuButtonIsDisabled} */
  menuButtonIsDisabled() {
    const ignoreParentOptions = this.config.sidenavOptions?.ignoreParentOptions ?? false;
    const buttonDisabled = RouterButtonComponent.menuButtonIsDisabled(this.config, this);
    if (!ignoreParentOptions) return this.parentDisabled || buttonDisabled;
    else return buttonDisabled;
  }

  /**
   * Given a route for the sidenav, determines if it should be disabled.
   */
  static menuButtonIsDisabled(route: RouteConfig, localThis: RouterButtonComponent) {
    // Check relevant guards
    const guardResults = [
      route.enabledGuardCheck?.call(localThis, localThis.configService),
      route.sidenavOptions?.enabled?.call(localThis),
      localThis.currentUser?.hasPermissions(route.requiredPermissions, false),
    ].filter((x) => x != null) as boolean[];
    const falseValueExists = guardResults.find((x) => x === false);
    return falseValueExists != null;
  }

  /**
   * Returns the given routes text tooltip to use
   */
  getDisabledTooltip() {
    const route = this.config;
    // Determine where to get the content from
    const disabledContent = this.parentDisabled
      ? this.parentConfig?.sidenavOptions?.disabledTooltip
      : route.sidenavOptions?.disabledTooltip;
    // Fallback case
    if (disabledContent == null) return "";
    else if (typeof disabledContent === "string") return disabledContent;
    else return disabledContent.call(this);
  }

  handleClick(event: MouseEvent) {
    this.config.sidenavOptions?.clickOverride?.call(this, event);
  }
}
