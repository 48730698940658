import { Component } from "@angular/core";
import { AccessLogComponent } from "@tdms/frontend/modules/data-store/components/tabs/access-log/access-log.component";
import { HomeComponent } from "@tdms/frontend/modules/data-store/components/tabs/home/home.component";
import { RecycleBinComponent } from "@tdms/frontend/modules/data-store/components/tabs/recycle-bin/recycle-bin.component";
import { SearchComponent } from "@tdms/frontend/modules/data-store/components/tabs/search/search.component";
import { RouterParamTypes } from "@tdms/frontend/modules/routing/services/router.service";
import { Tab, TabComponent } from "@tdms/frontend/modules/shared/components";

/**
 * This component is the main data store capability page, which primarily exposes a tab bar with the main views.
 */
@Component({
  selector: "data-store",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DataStoreDashboardComponent extends TabComponent {
  constructor() {
    super(RouterParamTypes.dataStoreTab);
  }

  get tabs(): Tab[] {
    return [
      { component: HomeComponent, name: "Home" },
      { component: SearchComponent, name: "Search" },
      { component: RecycleBinComponent, name: "Recycle Bin" },
      { component: AccessLogComponent, name: "Access Log" },
    ];
  }
}
