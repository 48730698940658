<div class="div-progress-container" [ngClass]="{ loaded: ready, loading: !ready }">
  <!-- Spinner data -->
  <div class="spinner-container" *ngIf="!ready">
    <mat-spinner
      color="primary"
      class="spinner"
      *ngIf="showSpinner"
      [diameter]="spinnerDiameter"
      [mode]="progress == null ? 'indeterminate' : 'determinate'"
      [value]="progress"
    ></mat-spinner>
    <div *ngIf="notReadyMessage != null" class="header">{{ notReadyMessage }}</div>
  </div>
  <!-- Render normal content -->
  <div class="content-wrapper">
    <ng-content class="content-wrapper"></ng-content>
  </div>
</div>
