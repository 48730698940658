import { Component, Input, OnInit } from "@angular/core";

/**
 * A generic component to provide image wrapping capabilities  to images to handle slow loading
 *  as well as various other functionality.
 *
 * Usage: `<shared-img-wrapper></shared-img-wrapper>`
 */
@Component({
  selector: "shared-img-wrapper[src]",
  templateUrl: "./img-wrapper.component.html",
  styleUrls: ["./img-wrapper.component.scss"],
})
export class ImgWrapperComponent implements OnInit {
  /** The source to load the image from */
  @Input() src!: string;

  /** Tracks if the image is loaded and ready for display */
  imageReady = false;

  constructor() {}

  ngOnInit(): void {}
}
