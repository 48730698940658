import { Component, Input, OnInit } from "@angular/core";
import { PopulatedSearchOption, SearchableOption } from "@tdms/common";
import { SearchService } from "@tdms/frontend/modules/data-store/services/search.service";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

@Component({
  selector: "data-store-query-builder",
  templateUrl: "./query-builder.component.html",
  styleUrls: ["./query-builder.component.scss"],
})
export class QueryBuilderComponent extends SubscribingComponent implements OnInit {
  /**
   * This is called whenever the search button is clicked.
   * Search options provided are not validated by this component.
   * It is up to the listening component to validate.
   */
  @Input() onSearchStarted!: (options: PopulatedSearchOption[]) => void;
  /**
   * This should be a list of search options that failed to validate as expected.
   * This should be updated in response to an onSearchStarted invocation and should contain items from the list passed to that invocation.
   */
  @Input() formErrors!: PopulatedSearchOption[];

  @Input() isSearching: boolean = false;

  currentSearchTerms!: PopulatedSearchOption[];

  availableSearchOptions?: SearchableOption[];

  constructor(private searchService: SearchService) {
    super();
    this.onNewSearchTermAdded = this.onNewSearchTermAdded.bind(this);
    this.onSearchTermDeleted = this.onSearchTermDeleted.bind(this);
    this.onSearchTermChanged = this.onSearchTermChanged.bind(this);
    this.onSearchButtonClicked = this.onSearchButtonClicked.bind(this);
  }

  ngOnInit(): void {
    this.searchService.observeSearchOptions().subscribe((options) => (this.currentSearchTerms = options));
    this.addSubscription(
      this.searchService.observeAvailableSearchOptions().subscribe((options) => (this.availableSearchOptions = options))
    );
  }

  onSearchButtonClicked() {
    this.onSearchStarted(this.currentSearchTerms);
  }

  onSearchTermDeleted(option: PopulatedSearchOption) {
    this.searchService.onSearchOptionDeleted(option);
  }

  onSearchTermChanged(option: PopulatedSearchOption) {
    this.searchService.onSearchOptionUpdated(option);
  }

  onNewSearchTermAdded(option: SearchableOption) {
    this.searchService.onSearchOptionAdded(option);
  }
}
