import { Component } from "@angular/core";
import { GaugeChartColors, GaugeChartData } from "@tdms/common";
import { ChartType } from "chart.js/auto";
import { ChartBaseComponent } from "../shared/base/base.component";
import { NeedleChartDataSet } from "../shared/plugins/needle";
import { ExtendedChartOptions } from "../shared/plugins/plugin.typing";

/**
 * A gauge style chart display that can be used to display a chart to show how some data scored
 */
@Component({
  selector: "charts-gauge[data][colorLookup][configuration]",
  templateUrl: "../shared/base/base.component.html",
  styleUrls: ["../shared/base/base.component.scss"],
})
export class GaugeChartComponent<T extends GaugeChartData> extends ChartBaseComponent<T, "doughnut"> {
  override chartType: ChartType = "doughnut";

  override getChartData(dataSet: T): NeedleChartDataSet<"doughnut">[] {
    return [
      {
        label: "Score",
        data: [50, 25, 25],
        backgroundColor: GaugeChartColors,
        needleValue: dataSet.value,
      },
    ];
  }

  override getChartLabels(_dataSet: T): (string | number | Date)[] {
    return GaugeChartColors;
  }

  override chartOptionOverrides(): ExtendedChartOptions<"doughnut"> {
    return {
      rotation: 270,
      circumference: 180,
      plugins: {
        customTooltip: {
          snappingIntersect: true,
        },
        needle: {
          // Enable needle display
          enabled: true,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    };
  }
}
