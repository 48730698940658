import { Component, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { ChartConfiguration, Session } from "@tdms/common";
import { MetricCardDataStore } from "@tdms/frontend/modules/metrics/components/metric-card/models/metric.configuration";
import { MetricGridDataStore } from "@tdms/frontend/modules/metrics/components/metric-grid/models/metric-grid.configuration";
import { MetricService } from "@tdms/frontend/modules/metrics/services/metric.service";
import { selectMetricConfiguration } from "@tdms/frontend/modules/metrics/store/metric.selector";
import { MetricState } from "@tdms/frontend/modules/metrics/store/metric.state";
import { RouterParamTypes, RouterService } from "@tdms/frontend/modules/routing/services/router.service";
import { SessionComparisonService } from "@tdms/frontend/modules/session/services/session.comparison.service";
import {
  selectAllSessionsFromState,
  selectCurrentSession,
} from "@tdms/frontend/modules/session/store/session.selector";
import { SessionState } from "@tdms/frontend/modules/session/store/session.state";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { cloneDeep } from "lodash-es";
import { combineLatest, first, firstValueFrom, map, Observable, of } from "rxjs";

@Component({
  selector: "app-session-comparison",
  templateUrl: "./session-comparison.component.html",
  styleUrls: ["./session-comparison.component.scss"],
})
export class SessionComparisonComponent extends SubscribingComponent implements OnDestroy {
  /**
   * The current sessions we can pick from
   */
  currentSessions: Session[] = [];

  /**
   * The currently selected session so we can use elsewhere
   */
  currentSession: Observable<Session | undefined>;

  /**
   * The sessions we will be comparing
   */
  sessionsToCompare: [Session | undefined, Session | undefined] = [undefined, undefined];

  /**
   * Sessions the metric grid should be displaying
   */
  currentlyComparedSessions: [Session | undefined, Session | undefined] = [undefined, undefined];

  /**
   * Controls when we should display the actual comparison graphs. This is used
   *  to display the initial "help" page or the actual graphs.
   */
  displayComparison = false;

  /**
   * The chart configuration to display our metrics being compared
   */
  chartConfiguration: Observable<ChartConfiguration[] | undefined>;

  /**
   * The current metric grid data store for the active comparison.
   */
  chartDataStore: MetricGridDataStore | null;

  constructor(
    private store: Store<{ metric: MetricState; session: SessionState }>,
    private routerService: RouterService,
    private sessionComparisonService: SessionComparisonService,
    protected metricService: MetricService
  ) {
    super();
    this.getExportPrefix = this.getExportPrefix.bind(this);
    this.chartDataStore = null;
    this.currentSession = this.store.select(selectCurrentSession);
    this.addSubscription(
      this.store.select(selectAllSessionsFromState).subscribe((sessions) => (this.currentSessions = sessions))
    );

    // Don't attempt to auto set sessions from URL until data is ready to go
    combineLatest([this.store])
      .pipe(first(([state]) => state.session?.ids.length !== 0 && state.metric?.availableConfiguration.length !== 0))
      .subscribe(() => {
        this.autoSetSessions();
      });

    // Modify chart config to only be comparison enabled ones
    this.chartConfiguration = this.store
      .select(selectMetricConfiguration)
      .pipe(map((x) => x.filter((z) => z.comparison?.enabled).map((x) => x)));
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    // Wipe session comparison on leave
    this.routerService.setQueryParam(RouterParamTypes.comparedSessions, null);
  }

  /**
   * Handles setting the initial comparison sessions based on route url params
   */
  autoSetSessions() {
    // Listen for when we have session data so we can auto select based on query params if given
    const queryParamComparedSessions = this.routerService.getQueryParam(RouterParamTypes.comparedSessions) as number[];
    if (queryParamComparedSessions)
      this.store.pipe(first((x) => selectAllSessionsFromState(x).length !== 0)).subscribe((state) => {
        const currentSessions = selectAllSessionsFromState(state);
        // Auto select
        this.selectedSessionChanged(
          0,
          currentSessions.find((x) => x.id === queryParamComparedSessions[0])
        );
        this.selectedSessionChanged(
          1,
          currentSessions.find((x) => x.id === queryParamComparedSessions[1])
        );
      });
    // Auto set compare request
    this.compare();
  }

  /**
   * A function to handle updating our currently selected sessions to compare to each other
   */
  selectedSessionChanged(index: number, session: Session | undefined) {
    this.sessionsToCompare[index] = session;
    // If the session values are somehow equal, reset one
    if (this.sessionsToCompare[0] === this.sessionsToCompare[1]) this.sessionsToCompare[1] = undefined;
  }

  /**
   * Sets the current session as the index 0 element
   */
  async selectCurrentSession() {
    const currentSession = await firstValueFrom(this.store.select(selectCurrentSession));
    this.selectedSessionChanged(0, currentSession);
  }

  /**
   * Returns if we should be displaying the comparator help message or not
   */
  get shouldDisplayHelp() {
    return !this.displayComparison;
  }

  /**
   * Returns if we are ready to press the compare button
   */
  get readyToCompare() {
    // If we have the param, make sure they have actually changed so we know we should compare
    const queryParamComparedSessions = this.routerService.getQueryParam(RouterParamTypes.comparedSessions) as
      | number[]
      | undefined;
    return (
      this.sessionsToCompare[0] != null &&
      this.sessionsToCompare[1] != null &&
      (queryParamComparedSessions == null ||
        queryParamComparedSessions[0] !== this.sessionsToCompare[0].id ||
        queryParamComparedSessions[1] !== this.sessionsToCompare[1].id)
    );
  }

  /**
   * Returns the current session options excluding the one you gave, if given.
   */
  getSessionOptions(exclusion: Session | undefined) {
    if (exclusion != null) return this.currentSessions.filter((session) => session.id !== exclusion.id);
    else return this.currentSessions;
  }

  /**
   * Finalizes the compare request for the current sessions to begin displaying metrics
   */
  async compare() {
    if (this.sessionsToCompare[0] == null || this.sessionsToCompare[1] == null) return;

    const sessionADuration =
      new Date(this.sessionsToCompare[0].endDate).getTime() - new Date(this.sessionsToCompare[0].startDate).getTime();
    const sessionBDuration =
      new Date(this.sessionsToCompare[1].endDate).getTime() - new Date(this.sessionsToCompare[1].startDate).getTime();

    let chartStartDate, chartEndDate;

    if (sessionADuration > sessionBDuration) {
      chartStartDate = this.sessionsToCompare[0].startDate;
      chartEndDate = this.sessionsToCompare[0].endDate;
    } else {
      chartStartDate = this.sessionsToCompare[1].startDate;
      chartEndDate = this.sessionsToCompare[1].endDate;
    }

    // Instruct the session comparison service to construct the metric grid data store for the sessions to compare.
    this.chartDataStore = await this.sessionComparisonService.startSessionComparison(
      // The store might lose typing
      Session.fromPlain(this.sessionsToCompare[0]),
      Session.fromPlain(this.sessionsToCompare[1])
    );

    if (this.chartDataStore) {
      this.chartDataStore.startDate = of(chartStartDate);
      this.chartDataStore.endDate = of(chartEndDate);
    }

    this.currentlyComparedSessions = cloneDeep(this.sessionsToCompare);
    this.displayComparison = true;
  }

  /**
   * Get the export filename prefix for our current session comparison upon file export.
   * @param _
   * @returns The prefix in format (sessionAName)-(sessionBName)
   */
  getExportPrefix(_: MetricCardDataStore) {
    if (this.currentlyComparedSessions[0] == null || this.currentlyComparedSessions[1] == null) return "";
    return `${this.currentlyComparedSessions[0]!.name}-${this.currentlyComparedSessions[1]!.name}`;
  }
}
