import { Type } from "class-transformer";
import { v4 } from "uuid";
import { TDMSBase } from "./base";

/** The base set of information required for a role mapping. Requests will fill this out further */
export class RoleMapping extends TDMSBase {
  /** Unique ID associated to this role mapping. Used to realign the parsed data after user responses */
  uniqueId = v4();

  /** Channel number associated to a role mapping. */
  @Type(() => Number)
  channel: number;

  /** The name that was originally auto generated */
  generatedName: string;

  /** The given name from the user to "make it pretty" */
  userGivenName: string;

  /** A playback path for snippets of audio to help the user determine a mapping */
  playbackPath: string | undefined;

  /**
   * The associated user to utilize during association of a role to a session so we can always
   *  link specific roles to user for later callback.
   *
   * If this is left as undefined, a user will be auto generated or auto populated based on their role name.
   */
  associatedUserId: number | undefined;

  /**
   * In the event a file contains multiple sets of sessions, this field will allow us to separate
   *  the roles into different sessions. In the event only one group exists, we will not append additional
   *  naming information to a session creation.
   */
  sessionGrouping: string | undefined;

  constructor(
    generatedName: string,
    userGivenName: string,
    sessionGrouping: string,
    channel: number,
    associatedUserId?: number
  ) {
    super();
    this.generatedName = generatedName;
    this.channel = channel;
    this.userGivenName = userGivenName;
    this.sessionGrouping = sessionGrouping;
    this.associatedUserId = associatedUserId;
  }
}

/** A class to associate role mappings and the user id's that go with each role mapping.
 *    Since our Role class has the fk for each User and RoleMapping, we need to associate them to make displaying easier
 *    inside the frontend. It's used within the role mapping dialog.
 */
export class MappingsAndUserid extends TDMSBase {
  /** The role mapping to display */
  @Type(() => RoleMapping)
  mapping!: RoleMapping;

  /** The User id that is associated with the mapping we are trying to display that was retrieved from Role*/
  userId: number | undefined;
}

/** A request for role mappings to the user so they can identify specific roles and associate them to users/improved names */
export class RoleMappingRequest extends TDMSBase {
  /** The current role mappings that we would like more information for */
  @Type(() => RoleMapping)
  mappings: MappingsAndUserid[] = [];

  /** If session groupings (so the split like AFT or FRONT) should be editable */
  allowSessionGroupEdits = true;

  /** If channel numbers can be changed. Normally useful in session files and not audio files */
  allowChannelEdits = true;

  /** A display message to override the UI with */
  displayMessage?: string;

  /** An indicator that tells the backend to not send out an error message when a user
   *    closes the role mapping dialog.
   */
  disableRoleMappingError?: boolean;

  /** Tracks if we are editing role mappings. This helps in role mapping requests to either associate a user
   *    to the role mapping or not.
   */
  roleMapEditing?: boolean;
}

/** A response to role mapping request containing the users configured data */
export class RoleMappingResponse extends TDMSBase {
  /** The result mappings that we consider to be clean and ready to go */
  @Type(() => RoleMapping)
  mappings: MappingsAndUserid[] = [];
  /** Tracks if the user completes or cancels. False is cancels, true is completes. We cancel uploads on false. */
  complete: boolean = false;
}
