import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  ChartConfiguration,
  GaugeChartData,
  Session,
  SessionSummary,
  SessionSummaryRequest,
  SessionSummaryTopics,
  TDMSWebSocketMessage,
} from "@tdms/common";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { ColorThemeService } from "@tdms/frontend/modules/material/services/themes.service";
import {
  MetricCardDataStore,
  MetricServiceDataStore,
} from "@tdms/frontend/modules/metrics/components/metric-card/models/metric.configuration";
import { MetricGridDataStore } from "@tdms/frontend/modules/metrics/components/metric-grid/models/metric-grid.configuration";
import { SessionSummaryState } from "@tdms/frontend/modules/session-summary/store/summary.state";
import { selectCurrentSession } from "@tdms/frontend/modules/session/store/session.selector";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { firstValueFrom, of } from "rxjs";
import { SessionSummaryActions } from "../store/summary.adapter";
import { selectSessionSummaryById, selectSessionSummaryForCurrentSession } from "../store/summary.selector";

/**
 * Service for session summary handling
 */
@Injectable({ providedIn: "root" })
export default class SessionSummaryService extends Service {
  /**
   * This chart configuration is used for the session score gauge chart
   *  to display roughly how well a session went.
   */
  static readonly SCORE_PLUGIN_CONFIG = new ChartConfiguration({
    type: "gauge",
    metricName: "session-score",
    title: "Session Score*",
    getBySessionIdQueue: "session-score",
    position: 0,
    exportAllowed: false,
    shouldBeUsedForTimeline: false,
    sizing: "half",
    footer: "* Overall scores are still being refined and may be inaccurate",
  });

  constructor(
    private store: Store<SessionSummaryState>,
    private configService: ConfigService,
    private wsService: WebSocketService,
    private themeService: ColorThemeService
  ) {
    super();
  }

  override async onSessionChanged(session?: Session): Promise<void> {
    this.loadSessionSummaryData(session);
  }

  override async onBackendDisconnected(): Promise<void> {
    this.store.dispatch(SessionSummaryActions.empty({}));
  }

  override async onSessionDataRefresh(session: Session) {
    this.loadSessionSummaryData(session);
  }

  private async loadSessionSummaryData(session: Session | undefined) {
    if (this.configService.configData && this.configService.configData.sessionSummary.enabled)
      if (session) await this.getSessionSummaryForSession(session);
  }

  /**
   * Given a session, requests from the backend the session summary content and adds
   *    it to the data store.
   */
  async getSessionSummaryForSession(session: Session) {
    const message = new TDMSWebSocketMessage(
      SessionSummaryTopics.getBySessionId,
      session.id,
      SessionSummaryRequest.fromPlain({ sessionId: session.id })
    );
    const response = await this.wsService.sendAndReceive<SessionSummary>(message);
    const newSummary = SessionSummary.fromPlain(response.payload);
    const sessionSummary = await firstValueFrom(this.store.select(selectSessionSummaryById(session.id)));
    if (sessionSummary == null) this.store.dispatch(SessionSummaryActions.add(newSummary));
    else this.store.dispatch(SessionSummaryActions.update(newSummary));
  }

  /**
   * Returns the metric data for our session summary graph for later display
   */
  async getSessionScoreMetric(config: ChartConfiguration = SessionSummaryService.SCORE_PLUGIN_CONFIG) {
    const session = await firstValueFrom(this.store.select(selectCurrentSession));
    const data = await firstValueFrom(this.store.select(selectSessionSummaryForCurrentSession));
    const renderData = GaugeChartData.fromPlain({ name: "Session Score", value: data?.sessionScore?.score });
    const colorMap = this.themeService.observeColorsFor(renderData);
    return new MetricCardDataStore(
      new MetricServiceDataStore(renderData, [], data == null || session?.isProcessing),
      config,
      colorMap,
      of(true),
      of(true)
    );
  }

  /**
   * Returns the metric grid data store object for metrics that should be displayed on the session summary page
   *  for graphing capabilities
   */
  async getMetricGridDataStore() {
    return new MetricGridDataStore(
      [of(await this.getSessionScoreMetric())],
      undefined,
      undefined,
      of(new Date()),
      of(new Date()),
      of([])
    );
  }
}
