import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { StoreModule } from "@ngrx/store";
import { AngularMaterialModule } from "@tdms/frontend/modules/material/material.module";
import { SharedModule } from "@tdms/frontend/modules/shared/shared.module";
import { TagDisplayComponent } from "./components/tag-display/tag-display.component";
import { TagInfoComponent } from "./components/tag-info/tag-info.component";
import { TagOverflowDisplayComponent } from "./components/tag-overflow-display/tag-overflow-display.component";
import { TagComponent } from "./components/tag/tag.component";
import { TagService } from "./services/tag.service";
import { TagReducer } from "./store/tag.reducer";

/** Components provided by the module */
const COMPONENTS = [TagDisplayComponent, TagComponent, TagInfoComponent, TagOverflowDisplayComponent];

/** A module that provides tagging capabilities */
@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    SharedModule,
    AngularMaterialModule,
    FormsModule,
    // Apply the data store module
    StoreModule.forFeature("tag", TagReducer),
  ],
  providers: [TagService],
  exports: COMPONENTS,
})
export class TagModule {}
