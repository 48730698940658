<shared-dialog-wrapper header="Advanced Query Builder">
  <div class="dialog-container">
    <data-store-query-builder
      [onSearchStarted]="properties.onSearchStarted"
      [formErrors]="properties.formErrors"
      [isSearching]="properties.isSearching"
    >
    </data-store-query-builder>
  </div>
</shared-dialog-wrapper>
