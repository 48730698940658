<mat-tab-group
  mat-stretch-tabs="false"
  mat-align-tabs="start"
  class="tab-group"
  [selectedIndex]="selectedIndex.value"
  (selectedTabChange)="updateCurrentTab($event)"
>
  <mat-tab *ngFor="let tab of tabs; trackBy: getElementTracker" [label]="tab.name">
    <ng-template matTabContent>
      <div class="tab-content">
        <ng-container *ngComponentOutlet="tab.component"></ng-container>
      </div>
    </ng-template> </mat-tab
></mat-tab-group>
