import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { SessionSummary } from "@tdms/common";
import { SessionSummaryActions } from "./summary.adapter";
import { SessionSummaryState } from "./summary.state";

/** NGRX entity adapter */
export const sessionSummaryAdapter: EntityAdapter<SessionSummary> = createEntityAdapter<SessionSummary>({
  selectId: (sum) => sum.sessionId,
});

/** NGRX Reducer for Session Summary */
export const sessionSummaryReducer = createReducer(
  sessionSummaryAdapter.getInitialState({} as SessionSummaryState),
  on(SessionSummaryActions.add, (state, data) => sessionSummaryAdapter.addOne(data, state)),
  on(SessionSummaryActions.update, (state, data) =>
    sessionSummaryAdapter.updateOne({ id: data.sessionId, changes: data }, state)
  ),
  on(SessionSummaryActions.empty, (state, _data) => sessionSummaryAdapter.removeAll(state))
);
