import { MatSnackBarConfig } from "@angular/material/snack-bar";
import { Configuration as ConfigBase } from "@tdms/common";

/**
 * Configuration options for usage across the application
 */
export class Configuration extends ConfigBase {
  /**
   * Tracks the backend version for later display
   */
  static BACKEND_VERSION = "UNKNOWN";

  /**
   * Overarching snackbar config to apply to all snackbars
   */
  static SnackbarConfig: MatSnackBarConfig<any> = {
    duration: 8000,
    panelClass: ["accent-snackbar"],
  };

  /**
   * Snackbar config that extends upon `SnackbarConfig` but applies error styling.
   */
  static ErrorSnackbarConfig: MatSnackBarConfig<any> = { ...this.SnackbarConfig, panelClass: ["error-snackbar"] };
}
