<shared-dialog-wrapper
  class="role-mapping-wrapper"
  header="Aggregate File Splitter"
  [backdropClickCallback]="properties.onCancel"
  [closeDialogCallback]="properties.onCancel"
>
  <div class="aggregate-file-splitter-container">
    <h1 class="instructions">Please select one of the columns below that you would like to split on</h1>
    <shared-improved-select
      [selectedValueChanged]="handleHeaderSelection.bind(this)"
      [dropdownOptions]="this.properties.request.header"
      dropdownName="Columns"
      [filterEnabled]="true"
      [paginatorEnabled]="true"
      [itemsPerPage]="5"
      [clearable]="true"
      [shouldCleanName]="false"
      class="selector"
    ></shared-improved-select>
    <div class="buttons" *ngVar="validityError as validityMessage">
      <!-- Cancel -->
      <button mat-raised-button color="warn" (click)="properties.onCancel()">Cancel</button>
      <!-- Submit -->
      <button
        mat-raised-button
        color="accent"
        (click)="properties.onComplete()"
        [disabled]="validityMessage != null"
        [matTooltip]="validityMessage || ''"
      >
        Submit
      </button>
    </div>
  </div>
</shared-dialog-wrapper>
