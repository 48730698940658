import { Type } from "class-transformer";
import { TDMSBase } from "../../base";
import { Utility } from "../../utility";
import { DataStoreFile } from "./data.store";

/**
 * The types of supported searching capabilities
 */
export type SearchableOptionDataTypes = "string" | "number" | "date";

export const LogicalOperators = ["AND", "OR"] as const;

/**
 * Logical operators to apply to each searchable option to combine them together to form logic
 */
export type LogicalOperator = (typeof LogicalOperators)[number];

export const ComparisonOptions = ["<", "<=", "=", ">=", ">"] as const;

/**
 * The comparisons for the given data type for a request
 */
export type ComparisonOption = (typeof ComparisonOptions)[number];

/**
 * A searchable option is an item that provides what data is being searched on so the backend
 *  can determine what files should be included with the search term
 */
export class SearchableOption extends TDMSBase {
  /**
   * The name of this searchable option
   */
  name: string;

  /**
   * The type of data input that we support for this searchable option
   */
  dataType: SearchableOptionDataTypes;

  /**
   * The source that this option is part of. Allows us to do clarify the searchable option more specifically like `audio.sound` or core options like `user.DODID`.
   */
  source: string;

  /**
   * An array of options to enforce this type to be a dropdown
   *  so the user must select from this set
   */
  options: string[];

  /**
   * A unit to apply to this search option so the user can know what unit they are looking for
   */
  unit: string | undefined;

  /**
   * Returns the full name of this option
   */
  get fullName() {
    return `${this.source}.${this.name}`;
  }

  constructor(
    name: string,
    dataType: SearchableOptionDataTypes,
    source: string,
    options: string[] = [],
    unit: string | undefined = undefined
  ) {
    super();
    this.name = name;
    this.dataType = dataType;
    this.source = source;
    this.options = options;
    this.unit = unit;
  }
}

/**
 * While searchable options only provide the data for how we intend to actually provide the user with
 *  options to fill in, this is the resulting filled in data that we can use to tell the backend how to query.
 */
export class PopulatedSearchOption<T extends number | string = any> extends SearchableOption {
  /**
   * The value for the actual search
   */
  value!: T;

  /**
   * The option of how we are comparing to the given data. Some dataTypes will ignore this comparison option. For example, strings only support `=`.
   */
  comparisonOption: ComparisonOption = "=";

  /**
   * How this search option should effect the next search option. Allows us to do the logic like:
   *
   * `user.DODID = 123456789 OR user.name = "John Smith"`
   */
  nextLogicalOperator: LogicalOperator = "AND";

  /**
   * Validates that the data under `value` is of the same type of the data under `dataType`
   * @returns True for `this.value` being valid, and false for it not
   */
  validateDataToType() {
    if (this.dataType === "number" && typeof this.value === "number") return true;
    else if (this.dataType === "string" && typeof this.value === "string") return true;
    else if (this.dataType === "date" && typeof this.value === "string") {
      // Validate we match the expected ISO Date
      return Utility.ISO_8601_REGEX.test(this.value);
    } else return false;
  }
}

/**
 * This class tracks the search request capability to define what we intend to search on with params.
 */
export class SearchRequest extends TDMSBase {
  /**
   * A list of options to use as this query.
   */
  @Type(() => PopulatedSearchOption)
  options: PopulatedSearchOption[];

  constructor(options: PopulatedSearchOption[]) {
    super();
    this.options = options;
  }
}

/** A class to track the response to search requests to help give extra metadata */
export class SearchResponse extends TDMSBase {
  /** If the search was successful or not */
  success: boolean = true;

  /** Any error message related to a potential failure in the search */
  errorMessage: string | undefined;

  /** The files found in the search */
  files: DataStoreFile[] = [];
}
