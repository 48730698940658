import { Component, ElementRef, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { Session, SessionSummary, SessionSummaryMetric } from "@tdms/common";
import { MetricGridDataStore } from "@tdms/frontend/modules/metrics/components/metric-grid/models/metric-grid.configuration";
import SessionSummaryService from "@tdms/frontend/modules/session-summary/services/session.summary.service";
import { selectSessionSummaryForCurrentSession } from "@tdms/frontend/modules/session-summary/store/summary.selector";
import { SessionSummaryState } from "@tdms/frontend/modules/session-summary/store/summary.state";
import { selectCurrentSession } from "@tdms/frontend/modules/session/store/session.selector";
import { ScrollTrackingComponent } from "@tdms/frontend/modules/shared/utils/scroll.tracking.component";

@Component({
  selector: "app-overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["./overview.component.scss"],
})
export class OverviewComponent extends ScrollTrackingComponent {
  @ViewChild("overviewContainer") containerElement: ElementRef<HTMLElement> | undefined;
  /** The current session summary data for the current session */
  currentSessionSummary: SessionSummary | undefined;

  /** This is the metrics that should be displayed in our grid */
  currentMetricDataStore: MetricGridDataStore | undefined;
  currentSession: Session | undefined;

  constructor(private store: Store<SessionSummaryState>, private sessionSummaryService: SessionSummaryService) {
    super();
    this.addSubscription(
      this.store.select(selectCurrentSession).subscribe(async (session) => {
        this.currentSession = session;
        await this.updateMetricDataStore();
      })
    );
    this.addSubscription(
      this.store.select(selectSessionSummaryForCurrentSession).subscribe(async (summary) => {
        // Track the session summary data
        this.currentSessionSummary = summary;
        await this.updateMetricDataStore();
      })
    );
  }

  /** Updates the metric grid data store to display the overarching summary score */
  async updateMetricDataStore() {
    this.currentMetricDataStore = await this.sessionSummaryService.getMetricGridDataStore();
  }

  /** Returns the keys for the roles within the individual feedback section */
  getIndividualFeedbackKeys(metric: SessionSummaryMetric) {
    return Object.keys(metric.individualFeedback);
  }

  ngOnInit(): void {}
}
