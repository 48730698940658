import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";
import { RouteConfig } from "@tdms/frontend/modules/routing/models/route.config";
import { UserService } from "../services/user.service";

/**
 * This guard requires that a user has the required permissions before being able to activate a route
 */
@Injectable()
export class PermissionGuard implements CanActivate {
  constructor(private userService: UserService) {}
  /**
   * Handles determining if a user can activate a route
   */
  canActivate(route: ActivatedRouteSnapshot): boolean | Promise<boolean> {
    const config = route.routeConfig as RouteConfig;
    // Make sure we have our necessary permissions
    if (config.requiredPermissions == null)
      throw new Error("PermissionGuard is given but no permission list is defined.");
    // If no user, we definitely don't have permissions
    if (this.userService.currentAuthenticatedUser == null) return false;
    // Check if we have the permissions
    return this.userService.currentAuthenticatedUser.hasPermissions(config.requiredPermissions, false);
  }
}
