import { Component, Inject } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { User } from "@tdms/common";
import { UserService } from "@tdms/frontend/modules/user/services/user.service";

/** Properties the dialog should contain for this component when opened */
export interface ChangePasswordDialogProps {
  user: User;
  /** If an admin is the one doing the change password. If so, current password is not required. */
  adminMode: boolean;
}

/**
 * Dialog component that allows users to update their passwords
 */
@Component({
  selector: "user-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent {
  formGroup = new FormGroup({
    currentPassword: new FormControl("", [Validators.required]),
    password: new FormControl("", [Validators.required, this.confirmPasswordValidator.bind(this)]),
    confirmPassword: new FormControl("", [Validators.required, this.confirmPasswordValidator.bind(this)]),
  });

  adminMode: boolean = false;

  /** Tracks an error messages from previous attempts */
  currentErrorMessage: string | undefined = undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public properties: ChangePasswordDialogProps,
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    private userService: UserService
  ) {
    this.adminMode = properties.adminMode;
    // Clear current password requirement if admin
    if (this.adminMode) this.formGroup.get("currentPassword")?.clearValidators();
  }

  /**
   * Validator to verify the confirm password value is equal to that of the other password field
   */
  confirmPasswordValidator(_group: AbstractControl): ValidationErrors | null {
    const formGroup: FormGroup | undefined = this.formGroup;
    if (formGroup) {
      let pass = formGroup.get("password")?.value;
      let confirmPass = formGroup.get("confirmPassword")?.value;
      return pass === confirmPass ? null : { notSame: true };
    }
    return { notSame: true };
  }

  /**
   * Handles some basic functionality when fields are edited
   */
  onFieldEdit() {
    const formGroup: FormGroup | undefined = this.formGroup;
    if (formGroup) {
      const passControl = formGroup.get("password");
      const confirmPassControl = formGroup.get("confirmPassword");
      passControl?.updateValueAndValidity();
      confirmPassControl?.updateValueAndValidity();
    }
  }

  /**
   * Submits the password change request for the current user
   */
  async submit() {
    const values = this.formGroup.getRawValue();
    this.currentErrorMessage = undefined;
    const response = await this.userService.changePassword(
      this.properties.user,
      values.password!,
      values.currentPassword!
    );
    if (response.success) this.dialogRef.close();
    // Display error
    else this.currentErrorMessage = response.message;
  }
}
