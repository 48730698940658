import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { Bookmark, BookmarkType, Session } from "@tdms/common";
import AudioMetricService from "@tdms/frontend/modules/audio/services/audio.metric.service";
import AudioService from "@tdms/frontend/modules/audio/services/audio.service";
import { ChartJSDrawingHelper } from "@tdms/frontend/modules/charts/shared/plugins/drawing.helper";
import { ColorThemeService } from "@tdms/frontend/modules/material/services/themes.service";
import { MetricGridDataStore } from "@tdms/frontend/modules/metrics/components/metric-grid/models/metric-grid.configuration";
import { MetricGridService } from "@tdms/frontend/modules/metrics/services/metric.grid.service";
import { SessionService } from "@tdms/frontend/modules/session/services/session.service";
import { AudioPlayerComponent } from "@tdms/frontend/modules/shared/components";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { BehaviorSubject, map, startWith } from "rxjs";

@Component({
  selector: "audio-track",
  templateUrl: "./audio-track.component.html",
  styleUrls: ["./audio-track.component.scss"],
})
export class AudioTrackComponent extends SubscribingComponent implements AfterViewInit {
  /** The audio player that contains our content */
  @ViewChild("audioPlayer") audioPlayer: AudioPlayerComponent | undefined;

  /** Chart data for the waveform */
  metricGrid: MetricGridDataStore | undefined;

  /** The player's current time as a subject, separated so we can track it once the player is rendered */
  currentPlayerTime = new BehaviorSubject(0);

  constructor(
    public audioService: AudioService,
    public themeService: ColorThemeService,
    private metricGridService: MetricGridService,
    private sessionService: SessionService
  ) {
    super();
    // Subscribe to seek requests
    this.addSubscription(
      this.audioService.seekTimeSubject.subscribe(async (time: number) => this.audioPlayer?.seekTo(time, true))
    );
    // Populate the metric
    this.createMetricConfigs();
  }

  ngAfterViewInit(): void {
    this.addSubscription(
      this.audioPlayer?.currentPlayerTime.subscribe(async (time) => this.currentPlayerTime.next(time))
    );
  }

  /** Creates the metric card information to display charts based on our audio file */
  async createMetricConfigs() {
    this.metricGrid = await this.metricGridService.observeMetricDataForSession(
      [AudioMetricService.WAVEFORM_CHART_CONFIG],
      undefined,
      this.getPlaybackBookmark()
    );
  }

  /** Returns an observable of a bookmark that tracks the current playback time. */
  private getPlaybackBookmark(session: Session = this.sessionService.currentSession!) {
    const playbackBmk = new Bookmark(
      0,
      false,
      "Current Playback Time",
      new BookmarkType(0, "Playback", ChartJSDrawingHelper.defaultColor, "vertical", false, true, undefined, false),
      session.startDate
    );
    return this.currentPlayerTime.pipe(
      map((currentTime) => {
        playbackBmk.startTime = new Date(session.startDate.getTime() + currentTime * 1000);
        return [playbackBmk];
      }),
      startWith([playbackBmk])
    );
  }

  /**
   * Changes notReady message depending on various states
   */
  get notReadyMessage(): string {
    return "Loading Audio Playback";
  }
}
