import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

/** Properties for the speaker identity dialog */
export interface SpeakerIdentityDialogProperties {
  callback: { (speaker: string): void };
}

/** A generic dialog component that allows creation of speaker identities */
@Component({
  selector: "audio-speaker-identity",
  templateUrl: "./speaker-identity.component.html",
  styleUrls: ["./speaker-identity.component.scss"],
})
export class SpeakerIdentityComponent implements OnInit {
  readonly form = new FormGroup({
    speaker: new FormControl("Speaker N", [Validators.required]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public properties: SpeakerIdentityDialogProperties,
    public dialogRef: MatDialogRef<SpeakerIdentityComponent>
  ) {}

  complete() {
    if (this.form.valid) {
      this.properties.callback(this.form.controls.speaker.value!);
      this.dialogRef.close();
    }
  }

  ngOnInit(): void {}
}
