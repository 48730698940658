import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataStoreUploadMetadata, DataStoreUploadMetadataOption, UploadMetadataSupportedTypes } from "@tdms/common";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { snakeCase } from "lodash-es";

/** Properties for customizing the Upload Metadata dialog */
export interface UploadMetadataProps {
  request: DataStoreUploadMetadata;
  onComplete: Function;
  onCancel: Function;
}

@Component({
  selector: "data-store-upload-metadata",
  templateUrl: "./upload-metadata.component.html",
  styleUrls: ["./upload-metadata.component.scss"],
})
export class UploadMetadataComponent extends SubscribingComponent {
  formGroup: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public properties: UploadMetadataProps) {
    super();
    // Flatten the options to turn them into controls
    const options = properties.request.groups.flatMap((x) => x.options.flatMap((z) => ({ option: z, group: x })));
    const controls = options.reduce((x, curr) => {
      const control = new FormControl(this.getTypedValue(curr.option, curr.option.value), [Validators.required]);
      // Register to update the original object when this val changes
      this.addSubscription(
        control.valueChanges.subscribe((value: any) => {
          curr.option.value = this.getTypedValue(curr.option, value);
        })
      );
      return {
        ...x,
        [this.getFieldName(curr.option, curr.group.fileName)]: control,
      };
    }, {});
    this.formGroup = new FormGroup(controls);
  }

  /** Given a new value and the field, get's it as a typed value */
  getTypedValue<T extends UploadMetadataSupportedTypes>(field: DataStoreUploadMetadataOption<T>, newVal: T) {
    if (field.type === "Date") return new Date(newVal);
    else return newVal;
  }

  /** Returns the form control name for the given content */
  getFieldName(field: DataStoreUploadMetadataOption<any>, groupName: string) {
    return snakeCase(groupName + " " + field.name);
  }

  onSubmit() {
    // Update all options with their adjustments
    this.properties.onComplete();
  }
}
