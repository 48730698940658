import { cloneDeep } from "lodash";
import { LineChartSeries } from "../../charts";
import { Utility } from "../../utility";

/** Z value's for confidence interval calculations */
//prettier-ignore
const ZValueForConfidenceLevel = { 70: 1.036, 75: 1.15, 80: 1.282, 85: 1.44, 90: 1.645, 95: 1.96, 98: 2.326, 99: 2.576, 99.5: 2.807, 99.9: 3.291, 99.99: 3.891, 99.999: 4.417 } as const;
/** Type associated to the array values of {@link ZValueForConfidenceLevel} */
type ConfidenceIntervalOptions = keyof typeof ZValueForConfidenceLevel;

/** This class helps calculate relaxation time metrics in association to specific subsets of data from line series charts */
export class RelaxationTimeMath {
  /**
   * Given the data series, calculates all metrics related to relaxation time and returns them
   *
   * @throws An error if we fail to calculate the confidence interval
   * @param series The data we intend to calculate the relaxation time metrics of
   */
  static calculate(series: LineChartSeries[], requestedCI: ConfidenceIntervalOptions = 95) {
    const average = Utility.average(series.map((x) => x.value));
    const confidenceInterval = RelaxationTimeMath.calculateConfidenceInterval(series, average, requestedCI);
    if (confidenceInterval == null) throw new Error("Failed to calculate confidence interval.");
    const ciToUse = confidenceInterval.max;
    // Determine min date value
    const dates = series.map((x) => x.name.getTime());
    const minDate = new Date(Math.min(...dates));
    // Calculate some that require others
    const enactionCalc = RelaxationTimeMath.calculateEnaction(minDate.getTime(), series, ciToUse);
    return {
      ciMax: confidenceInterval.max,
      ciMin: confidenceInterval.min,
      enactionCalc,
      adaptationCalc: RelaxationTimeMath.calculateAdaptation(minDate.getTime(), series, enactionCalc?.dataUsed),
      recoveryCalc: RelaxationTimeMath.calculateRecovery(minDate.getTime(), series, ciToUse),
    };
  }

  /**
   * Calculates the confidence interval of the given line chart data and returns it.
   * @param sampleMean The average of the sample set
   * @param confidenceInterval The percentage confidence interval of this data set (100 > n > 0)
   * @param sampleSize Exactly what it sounds like. How many numbers are in this set.
   * @param standardDeviation The standard deviation to calculate the CI from.
   */
  private static calculateConfidenceInterval(
    sampleSet: LineChartSeries[],
    sampleMean: number,
    confidenceInterval: ConfidenceIntervalOptions,
    sampleSize = sampleSet.length,
    standardDeviation = this.calculateStandardDeviation(
      sampleSet.map((x) => x.value),
      sampleMean,
      sampleSize
    )
  ) {
    // Sample size is too small
    if (sampleSize === 0) return undefined;
    // The actual interval for +- our value
    const plusMinus = ZValueForConfidenceLevel[confidenceInterval] * (standardDeviation / Math.sqrt(sampleSize));
    return {
      min: Number((sampleMean - plusMinus).toFixed(4)),
      max: Number((sampleMean + plusMinus).toFixed(4)),
      plusMinus: plusMinus,
    };
  }

  /**
   * Calculates the standard deviation of the given data set and returns it.
   *
   * **This is a Population Standard Deviation calculation**
   * @param values The values to calculate the deviation from
   * @param mean Average of all values together
   */
  private static calculateStandardDeviation(values: number[], mean: number, sampleSize = values.length) {
    if (values.length === 0) return 0;
    return Math.sqrt(values.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / sampleSize);
  }

  /**
   * Calculates our enaction metric from given line chart data.
   *
   * Enaction: Time of first cross of entropy above the confidence interval minus time from window beginning.
   * @param windowBeginning The beginning time of the window to calculate from.
   * @param data The data to find the match from.
   * @param confidenceInterval The confidence interval to consider the condition where we reach above this value to be enaction.
   */
  private static calculateEnaction(windowBeginning: number, data: LineChartSeries[], confidenceInterval: number) {
    // Grab first instance where value is above confidence interval after a cross
    const dataToCheck = data.find((x, i) => {
      const lastVal = data[i - 1];
      if (lastVal == null) return false;
      return x.value > confidenceInterval && lastVal.value <= confidenceInterval;
    });
    if (dataToCheck) return { enaction: dataToCheck.name.getTime() - windowBeginning, dataUsed: dataToCheck };
    else return undefined;
  }

  /**
   * Calculates the adaptation for the given line chart data.
   *
   * Adaptation: The time of peak entropy above the confidence interval minus time from window beginning.
   * @param windowBeginning The beginning of the selection zone for calculation adaptation.
   * @param data The data to get our adaptation from
   * @param enactionValue The enaction value to grab adaptation after enaction
   */
  private static calculateAdaptation(
    windowBeginning: number,
    data: LineChartSeries[],
    enactionValue: LineChartSeries | undefined
  ) {
    if (enactionValue == null) return { adaptation: 0 };
    const enactionValTime = enactionValue.name.getTime();
    let maxVal: LineChartSeries | undefined;
    // Find max value after enaction value
    data.forEach((curr) => {
      if (curr.name.getTime() > enactionValTime && (maxVal == null || curr.value > maxVal.value)) maxVal = curr;
    });
    // If value is never above, return 0 time
    if (maxVal == null) return undefined;
    return { adaptation: maxVal.name.getTime() - windowBeginning, dataUsed: maxVal };
  }

  /**
   * Calculates the recovery for the given line chart data.
   *
   * Recovery: The last point in time that entropy is above the confidence interval minus time from window beginning.
   * @param windowBeginning The beginning time of the window to calculate from.
   * @param data The data to find the matching recover time from.
   * @param confidenceInterval The confidence interval to watch where we dip below to be the recovery time.
   */
  private static calculateRecovery(windowBeginning: number, data: LineChartSeries[], confidenceInterval: number) {
    // Reverse the array so we can move backwards to determine data
    const dataToCheck = (cloneDeep(data) as LineChartSeries[]).reverse().find((x) => x.value > confidenceInterval);
    if (dataToCheck) return { recovery: dataToCheck.name.getTime() - windowBeginning, dataUsed: dataToCheck };
    else return undefined;
  }
}
