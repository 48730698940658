<div class="query-builder-container">
  <div *ngFor="let option of currentSearchTerms; let i = index" class="segment-container">
    <data-store-query-grouping
      [searchOption]="option"
      [optionDeleted]="onSearchTermDeleted"
      [optionUpdated]="onSearchTermChanged"
      [isSearching]="isSearching"
      [availableOptions]="availableSearchOptions"
      [previousSearchOption]="currentSearchTerms[i - 1]"
    ></data-store-query-grouping>
  </div>
  <div class="bottom-bar-container">
    <data-store-new-term-selector
      [availableSearchTerms]="availableSearchOptions"
      [onTermSelected]="onNewSearchTermAdded"
    ></data-store-new-term-selector>
    <button
      mat-raised-button
      (click)="onSearchButtonClicked()"
      [disabled]="currentSearchTerms.length === 0"
      color="accent"
      class="query-button"
    >
      <mat-icon>search</mat-icon>
      Run Query
    </button>
  </div>
</div>
