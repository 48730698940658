<shared-div-progress-component
  *ngVar="loadingMessage as readyMsg"
  [ready]="readyMsg == null"
  [notReadyMessage]="readyMsg"
>
  <div class="session-selector-container">
    <shared-generic-table
      [data]="sessions"
      tableName="Session Selection"
      [displayedColumns]="displayedColumns"
      [clickCallback]="selectSession"
      [defaultSortHeader]="'startDate'"
    >
      <!-- Override cells -->
      <ng-template #cellTemplate let-value="value" let-column="column" let-element="element">
        <ng-container [ngSwitch]="column.name">
          <!-- Action row -->
          <div *ngSwitchCase="'actions'" class="action-row">
            <!-- Edit button -->
            <button
              mat-icon-button
              matTooltip="Edit this session"
              matTooltipPosition="right"
              (click)="sessionService.editSession(element, $event)"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          <tag-display *ngSwitchCase="'tags'" [tags]="element.tags"></tag-display>
          <!-- Standard values -->
          <span *ngSwitchDefault>
            {{ value }}
          </span>
        </ng-container>
      </ng-template>
    </shared-generic-table>
  </div>
</shared-div-progress-component>
