import { createFeatureSelector, createSelector } from "@ngrx/store";
import { selectCurrentSession } from "@tdms/frontend/modules/session/store/session.selector";
import { sessionSummaryAdapter } from "./summary.reducer";
import { SessionSummaryState } from "./summary.state";

/** Given a state selects the session summary part of the state */
export const selectSessionSummaryState = createFeatureSelector<SessionSummaryState>("sessionSummary");

/** Returns all session summaries */
export const selectAllSessionSummaries = (state: SessionSummaryState) =>
  sessionSummaryAdapter.getSelectors().selectAll(state);

/** Selects all session summary data for a given state */
export const selectAllSessionSummariesFromState = createSelector(selectSessionSummaryState, selectAllSessionSummaries);

/**
 * Selects the session summary for the current session utilizing the base level `selectCurrentSession` selector
 */
export const selectSessionSummaryForCurrentSession = createSelector(
  selectCurrentSession,
  selectAllSessionSummariesFromState,
  (currentSession, sessionSummaryData) => {
    if (currentSession == null) return undefined;
    else return sessionSummaryData.find((x) => x.sessionId === currentSession.id);
  }
);

/** Given a session Id, creates a selector to select that specific one from the store */
export const selectSessionSummaryById = (sessionId: number) =>
  createSelector(selectAllSessionSummariesFromState, (sessionSummaryData) => {
    return sessionSummaryData.find((x) => x.sessionId === sessionId);
  });
