<shared-dialog-wrapper class="bookmark-dialog" [header]="dialogTitle">
  <div class="bookmark-dialog-container">
    <!-- Disable Indicator -->
    <div *ngIf="bookmarkForm.disabled" class="help-info disabled-info">This bookmark cannot be edited</div>
    <!-- Help -->
    <div *ngIf="isCreationDialog" class="help-info">
      Select your bookmark type and add any additional notes below. Once complete, click create and you'll be able to
      start drawing your bookmark on the chart.
    </div>
    <!-- Form -->
    <form class="bookmark-creation-form" [formGroup]="bookmarkForm" class="bookmark-creation-form">
      <div class="row">
        <div class="column">
          <!-- Type selection -->
          <mat-form-field color="accent" class="type-form">
            <mat-label>Bookmark type</mat-label>
            <mat-select formControlName="bookmarkTypeSelection">
              <!-- Customize trigger -->
              <mat-select-trigger *ngIf="currentlySelectedBookmarkType" class="bookmark-type-select-trigger">
                <mat-icon [ngStyle]="{ color: currentlySelectedBookmarkType.color }" class="icon">circle</mat-icon>
                <span class="name">{{ currentlySelectedBookmarkType.name }}</span>
              </mat-select-trigger>
              <!-- Render options -->
              <mat-option *ngFor="let bookmarkType of selectableBookmarkTypes" [value]="bookmarkType.name">
                <span class="bookmark-type-option">
                  <mat-icon [ngStyle]="{ color: bookmarkType.color }">circle</mat-icon>
                  <span class="bookmark-type-option-name">{{ bookmarkType.name }}</span>
                </span>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-checkbox
            formControlName="isCritical"
            class="critical-checkbox"
            matTooltip="If checked, bookmark will show in comparison interface"
            matTooltipPosition="above"
          >
            Critical
          </mat-checkbox>

          <!-- Start Time -->
          <shared-date-time-picker
            *ngIf="!isCreationDialog"
            [control]="$any(bookmarkForm.get('startDate'))"
            [textPrefix]="isRangeBookmark ? 'Start' : ''"
            [min]="currentSession?.startDate"
            [max]="currentSession?.endDate"
          ></shared-date-time-picker>

          <!-- End Time, if it exists -->
          <shared-date-time-picker
            *ngIf="!isCreationDialog && isRangeBookmark"
            [control]="$any(bookmarkForm.get('endDate'))"
            textPrefix="End"
            [min]="currentSession?.startDate"
            [max]="currentSession?.endDate"
          ></shared-date-time-picker>
        </div>

        <div class="column note-column">
          <!-- Note input -->
          <mat-form-field appearance="fill" color="accent" class="note-form">
            <mat-label>Notes</mat-label>
            <textarea matInput [cdkTextareaAutosize]="false" formControlName="note"></textarea>
          </mat-form-field>
        </div>
      </div>

      <!-- Button row -->
      <div class="button-container">
        <!-- Creation dialog buttons -->
        <button
          mat-raised-button
          color="accent"
          *ngIf="isCreationDialog"
          (click)="createBookmark(true)"
          matTooltip="Similar to create but allows you to create a start and stop bookmark"
        >
          Create Range
        </button>
        <button mat-raised-button color="accent" *ngIf="isCreationDialog" (click)="createBookmark()">Create</button>
        <!-- Editing dialog buttons -->
        <button
          mat-raised-button
          color="warn"
          *ngIf="!isCreationDialog"
          (click)="deleteBookmark()"
          matTooltip="Opens a deletion confirmation modal for this bookmark"
          [disabled]="bookmarkForm.disabled"
        >
          Delete
        </button>

        <!-- Move button -->
        <shared-split-button
          [disabled]="bookmarkForm.disabled"
          [mainButtonClick]="startMove.bind(this, isRangeBookmark ? 'moveAll' : 'moveSingular')"
          mainButtonText="Move"
          mainButtonTooltip="Allows you to move where this bookmark is positioned"
          splitButtonTooltip="Additional range movement options"
          color="accent"
          *ngIf="!isCreationDialog"
          [displaySplit]="isRangeBookmark"
        >
          <!-- Range movement options -->
          <button
            mat-menu-item
            (click)="startMove('moveStart')"
            matTooltip="Start moving the start bookmark only"
            matTooltipPosition="left"
          >
            <mat-icon>first_page</mat-icon><span>Move Start</span>
          </button>
          <button
            mat-menu-item
            (click)="startMove('moveEnd')"
            matTooltip="Start moving the end bookmark only"
            matTooltipPosition="left"
          >
            <mat-icon>last_page</mat-icon><span>Move End</span>
          </button>
        </shared-split-button>

        <button
          mat-raised-button
          color="accent"
          *ngIf="!isCreationDialog"
          (click)="saveBookmark()"
          [disabled]="!bookmarkHasChanges || !bookmarkForm.valid || bookmarkForm.disabled"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</shared-dialog-wrapper>
