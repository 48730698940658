import { Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { CustomTypes, LineChartData, Transcription, Utility } from "@tdms/common";
import {
  SpeakerIdentityComponent,
  SpeakerIdentityDialogProperties,
} from "@tdms/frontend/modules/audio/components/speaker-identity/speaker-identity.component";
import AudioService from "@tdms/frontend/modules/audio/services/audio.service";
import { selectAllTranscriptionsFromState } from "@tdms/frontend/modules/audio/store/transcription.selector";
import { TranscriptionState } from "@tdms/frontend/modules/audio/store/transcription.state";
import { selectSpecificMetricForCurrentSession } from "@tdms/frontend/modules/metrics/store/metric.selector";
import { SessionService } from "@tdms/frontend/modules/session/services/session.service";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogProperties,
  DataColumn,
  DialogWrapperComponent,
} from "@tdms/frontend/modules/shared/components";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import autoBind from "auto-bind";
import { addMilliseconds, hoursToSeconds, minutesToSeconds, secondsToMilliseconds } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { firstValueFrom } from "rxjs";
import { AudioTrackComponent } from "../audio-track/audio-track.component";

/** An extension of the transcription type that also applies CLC tracking */
export type DashboardTranscription = Transcription & {
  clcOverlapPercentage: number | undefined;
};

/**
 * The main dashboard display for audio and displays
 */
@Component({
  selector: "communication-dashboard",
  templateUrl: "./audio.dashboard.component.html",
  styleUrls: ["./audio.dashboard.component.scss"],
})
export class AudioDashboardComponent extends SubscribingComponent {
  /** Reference to the current audio track component */
  @ViewChild("audioTrack") audioTrack!: AudioTrackComponent;

  /** If the users has the editing button clicked */
  edits = false;

  /** Tracks whether a change has actually been made */
  hasChanged = false;

  /** Holds our batch adjusted transcriptions */
  adjustedTranscriptions = new Map<number, Transcription>();

  /** Holds our unedited transcriptions so we can tell if they have been changed or not */
  originalAdjustedTranscriptions = new Map<number, Transcription>();

  displayedColumns = [
    new DataColumn<DashboardTranscription, string>("prettySpeaker", "Speaker"),
    new DataColumn<DashboardTranscription, number>("start", "Start", (value: number) => {
      return formatInTimeZone(addMilliseconds(new Date(0), secondsToMilliseconds(value)), "UTC", this.customTimeFormat);
    }),
    new DataColumn<DashboardTranscription, number>("end", "End", (value: number) =>
      formatInTimeZone(addMilliseconds(new Date(0), secondsToMilliseconds(value)), "UTC", this.customTimeFormat)
    ),
    new DataColumn<DashboardTranscription, number>("clcOverlapPercentage", "Is CLC?"),
    new DataColumn<DashboardTranscription, string>("text", "Text"),
  ];

  /** Since transcription times are stored with fractional seconds, we need a custom time formatter here to prevent false positive edits. */
  customTimeFormat = "HH:mm:ss.SSSS";
  timePattern: RegExp = /^[0-9][0-9]?:[0-9][0-9]?:[0-9][0-9]?\.?[0-9]{0,4}$/;

  /**
   * Current transcriptions to display on the table
   */
  transcriptions: DashboardTranscription[] = [];
  validationState: Map<number, Map<string, boolean>> = new Map();

  uniqueSpeakers: String[] = [];

  /** CLC data related to the current session */
  clc: LineChartData | undefined;

  constructor(
    private store: Store<TranscriptionState>,
    public audioService: AudioService,
    public configService: ConfigService,
    public sessionService: SessionService,
    private dialog: MatDialog
  ) {
    super();
    this.addSubscription(
      this.store.select(selectAllTranscriptionsFromState).subscribe((transcriptions) => {
        this.transcriptions = Transcription.fromPlainArray(transcriptions);
        this.assignValidationData();
        // Update the transcriptions with CLC
        this.assignCLCData(this.clc);

        this.uniqueSpeakers = Utility.getUniqueValues(
          transcriptions.map((item) => item.speaker!).filter((x) => x != null)
        );
      })
    );
    // Grab CLC data for separate column display
    this.addSubscription(
      this.store
        .select(selectSpecificMetricForCurrentSession("ClosedLoopCommunication"))
        .subscribe((metric) => this.assignCLCData(metric?.data?.at(0)))
    );
    autoBind(this);
    this.assignValidationData();
  }

  /**
   * Setup the initial validation data for every transcription object.
   */
  assignValidationData() {
    this.validationState.clear();

    for (const transcription of this.transcriptions) {
      this.validationState.set(transcription.id, new Map());

      for (const key of Object.keys(transcription)) {
        this.validationState.get(transcription.id)!.set(key, true);
      }
    }
  }

  /**
   * Assigns CLC data into the transcription for display on the table. Also assigns it to the instance variable of `clc`
   * @param clcData The data to assign. Normally defaults to the `this` level content
   */
  assignCLCData(clcData: LineChartData | undefined) {
    // Wipe all existing CLC
    this.transcriptions.forEach((x) => (x.clcOverlapPercentage = undefined));
    // Process new CLC
    this.clc = clcData;
    if (clcData == null) return;
    for (let clc of clcData.series) {
      const matchingTranscription = this.transcriptions.find((x) => x.text === clc.optional?.end);
      // If we have a matching transcription, set the overlap
      if (matchingTranscription) matchingTranscription.clcOverlapPercentage = Math.round(clc.value);
    }
  }

  get activeRows() {
    if (this.audioTrack == null) return undefined;
    const audioTrackTime = this.audioTrack.audioPlayer?.currentTime || 0;
    if (!audioTrackTime) return undefined;
    else return this.transcriptions.filter((x) => x.start <= audioTrackTime && x.end >= audioTrackTime);
  }

  /**
   * Asks the audio service to refresh our current transcription
   */
  async refreshTranscriptions() {
    const ref = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        header: "Transcription Refresh",
        description:
          "Are you sure you want to refresh the transcription and diarization in this table? This may take a moment and cannot be undone!",
        confirmButtonText: "Confirm",
        confirmButtonColor: "primary",
        confirmClickCallback: () => this.audioService.refreshAnalysis(),
        cancelClickCallback: () => ref.close(),
      } as Partial<ConfirmationDialogProperties>,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }

  /**
   * Controls look for editing vs viewing
   */
  batchEdit() {
    this.edits ? (this.edits = false) : (this.edits = true);
  }

  /**
   * Clears any adjustments made while editing
   */
  cancelChanges() {
    this.hasChanged = false;
    this.adjustedTranscriptions.clear();
    this.edits ? (this.edits = false) : (this.edits = true);
  }

  /** Opens a dialog to create a new speaker identity */
  openNewSpeakerIdentity(element: DashboardTranscription) {
    this.dialog.open(SpeakerIdentityComponent, {
      data: {
        callback: (speaker) => {
          if (!this.uniqueSpeakers.includes(speaker)) this.uniqueSpeakers.push(speaker);
          this.transcriptionUpdate(element, "speaker", { target: { value: speaker } });
          element.speaker = speaker;
        },
      } as Partial<SpeakerIdentityDialogProperties>,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }

  /** Handles performing updates based on the given transcription to the field with the given content */
  transcriptionUpdate<FieldType extends any>(
    element: DashboardTranscription,
    field: CustomTypes.PropertyNames<Transcription, FieldType>,
    val: { target: { value: any } }
  ) {
    if (this.edits) {
      if (!this.adjustedTranscriptions.has(element.id)) {
        const tempTranscription = element.clone();
        tempTranscription[field] = val.target.value; // Update val
        this.originalAdjustedTranscriptions.set(tempTranscription.id, element);
        this.adjustedTranscriptions.set(tempTranscription.id, tempTranscription!);
        this.hasChanged = this.changeDetector();
      } else {
        this.adjustedTranscriptions.get(element.id)![field] = val.target.value;
        this.hasChanged = this.changeDetector();
      }
    }
  }

  transcriptionTimeUpdate<FieldType extends any>(
    element: DashboardTranscription,
    field: CustomTypes.PropertyNames<Transcription, FieldType>,
    val: any
  ) {
    let input = val.target.value;
    const valid = this.timePattern.test(input);

    if (valid) {
      const pieces = input.split(":");
      input = hoursToSeconds(Number(pieces[0])) + minutesToSeconds(Number(pieces[1])) + Number(pieces[2]);
    }

    const validated = element.clone();
    this.validationState.get(element.id)!.set(field, valid);

    const index = this.transcriptions.findIndex((transcription) => element.id == transcription.id);
    this.transcriptions[index] = validated;

    return this.transcriptionUpdate(element, field, { target: { value: input } });
  }

  /**
   * Saves changes to transcription state and writes to DB
   */
  async saveChanges() {
    if (this.hasChanged) {
      var arr: Transcription[] = [];
      this.adjustedTranscriptions.forEach((adjustedTranscription, id) => {
        const index = this.transcriptions.findIndex((t) => t.id === id);
        if (index !== -1) {
          this.transcriptions[index].speaker = adjustedTranscription.speaker;
          this.transcriptions[index].text = adjustedTranscription.text;
        }
        arr.push(adjustedTranscription);
      });
      // Reset display to be inline with store
      this.transcriptions = Transcription.fromPlainArray(
        await firstValueFrom(this.store.select(selectAllTranscriptionsFromState))
      ) as DashboardTranscription[];
      this.edits = false;
      // Send only the updated transcription
      this.audioService.manuallyChangeTranscriptions(arr);
      this.adjustedTranscriptions.clear();
      this.hasChanged = false;
    }
  }

  /** Used to detect if our editing transcriptions have changed in comparison to the original values */
  changeDetector() {
    let hasChanged = false;
    this.adjustedTranscriptions.forEach((value, key) => {
      if (!value.equals(this.originalAdjustedTranscriptions.get(key)!)) {
        hasChanged = true;
      }
    });
    return hasChanged;
  }

  /**
   * Changes notReady message depending on various states
   */
  get notReadyMessage(): string {
    if (this.transcriptions.length == 0) {
      return "Generating Transcription";
    } else {
      return "Generating";
    }
  }

  inputValid(element: DashboardTranscription, field: any): boolean {
    return this.validationState.get(element.id)!.get(field)!;
  }

  get inputsValid(): boolean {
    for (const transcription of this.transcriptions) {
      const id = transcription.id;
      const validationData = this.validationState.get(id)!;
      const startValid = validationData.get("start");
      const endValid = validationData.get("end");

      if (!startValid || !endValid) {
        return false;
      }
    }

    return true;
  }

  /**
   * Seeks to a specific start time in the audio file
   * @param data The data row to seek to
   */
  seekTo(data: { isProcessing?: boolean | undefined; start?: number }) {
    this.audioService.seekToTime(data.start!);
  }
}
