import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Bookmark, BookmarkTopics, BookmarkType, TDMSWebSocketMessage, WebSocketCommunication } from "@tdms/common";
import { BookmarkTypeState } from "@tdms/frontend/modules/bookmark/store/bookmark.state";
import { BookmarkTypeActions } from "@tdms/frontend/modules/bookmark/store/bookmark.type.action";
import { SessionActions } from "@tdms/frontend/modules/session/store/session.action";
import { WebSocketService } from "../../communication/services/websocket.service";
import { Service } from "../../shared/services/base.service";

@Injectable({
  providedIn: "root",
})
export class BookmarkService extends Service {
  constructor(private store: Store<BookmarkTypeState>, private wsService: WebSocketService) {
    super();
  }

  override async onUserChanged(): Promise<void> {
    const response = await this.wsService.sendAndReceive(new TDMSWebSocketMessage(BookmarkTopics.getAllTypes));
    const bookmarkTypes = BookmarkType.fromPlainArray(response.payload as any);
    this.store.dispatch(BookmarkTypeActions.emptyBookmarkTypes({}));
    this.store.dispatch(BookmarkTypeActions.addMany({ bookmarkTypes: bookmarkTypes }));
  }

  /**
   * Given a new bookmark, tells the backend to add it to the current session
   */
  addBookmark(bookmark: Bookmark) {
    this.wsService.send(new TDMSWebSocketMessage(BookmarkTopics.addToSession, undefined, bookmark));
  }

  /**
   * Listens for new bookmarks being added from the backend websocket
   */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<Bookmark>>(BookmarkTopics.addToSession)
  protected async addBookmarkReceived(data: TDMSWebSocketMessage<Bookmark>) {
    this.store.dispatch(
      SessionActions.addBookmark({ sessionId: data.sessionId!, bookmark: Bookmark.fromPlain(data.payload) })
    );
  }

  /**
   * Given a bookmark, asks the backend to delete it from this session
   */
  deleteBookmark(bookmark: Bookmark) {
    this.wsService.send(new TDMSWebSocketMessage(BookmarkTopics.delete, undefined, bookmark));
  }

  /**
   * Listens for bookmark deletion requests
   */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<Bookmark>>(BookmarkTopics.delete)
  protected async deleteBookmarkReceived(data: TDMSWebSocketMessage<Bookmark>) {
    this.store.dispatch(SessionActions.deleteBookmark({ sessionId: data.payload.sessionId!, bookmark: data.payload }));
  }

  /**
   * Given a bookmark, asks the backend to update it's information
   */
  updateBookmark(bookmark: Bookmark) {
    this.wsService.send(new TDMSWebSocketMessage(BookmarkTopics.update, undefined, bookmark));
  }

  /**
   * Listens for bookmark update requests
   */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<Bookmark>>(BookmarkTopics.update)
  protected async updateBookmarkReceived(data: TDMSWebSocketMessage<Bookmark>) {
    this.store.dispatch(
      SessionActions.updateBookmark({ sessionId: data.payload.sessionId!, bookmark: Bookmark.fromPlain(data.payload) })
    );
  }
}
