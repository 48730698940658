<ng-container *ngVar="menuButtonIsDisabled() as buttonDisabled">
  <span
    *ngIf="!(config.sidenavOptions!.hideIfDisabled && buttonDisabled)"
    [matTooltip]="buttonDisabled ? getDisabledTooltip() : ''"
    matTooltipPosition="right"
    class="router-button"
  >
    <button
      mat-button
      class="button"
      [routerLink]="config.path != null ? config.path : undefined"
      queryParamsHandling="merge"
      [ngClass]="{ active: config.path != null ? routerService.isActiveRoute(config.path) : false }"
      (click)="handleClick($event)"
      [disabled]="buttonDisabled"
    >
      <mat-icon>{{ config.sidenavOptions!.icon }}</mat-icon>
      <span>{{ config.sidenavOptions!.title }}</span>
      <!-- Flex placeholder -->
      <div></div>
    </button>
  </span>
</ng-container>
