import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { MetricCardDataStore } from "@tdms/frontend/modules/metrics/components/metric-card/models/metric.configuration";
import { MetricGridDataStore } from "@tdms/frontend/modules/metrics/components/metric-grid/models/metric-grid.configuration";
import { MetricGridService } from "@tdms/frontend/modules/metrics/services/metric.grid.service";
import { MetricService } from "@tdms/frontend/modules/metrics/services/metric.service";
import { selectMetricConfiguration } from "@tdms/frontend/modules/metrics/store/metric.selector";
import { MetricState } from "@tdms/frontend/modules/metrics/store/metric.state";
import { SessionService } from "@tdms/frontend/modules/session/services/session.service";
import { ServiceManager } from "@tdms/frontend/modules/shared/services/service.manager";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

/** Dashboard intented to display metrics for the current metric data store based on the current session */
@Component({
  selector: "metric-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class MetricDashboardComponent extends SubscribingComponent {
  /**
   * This is the current metric grid data that should be displayed within our dashboard.
   * This is populated by the metric grid service via the observables that it creates in @observeMetricDataForSession
   */
  currentMetricDataStore: MetricGridDataStore | undefined;

  constructor(
    private serviceManager: ServiceManager,
    private metricGridService: MetricGridService,
    private sessionService: SessionService,
    public metricService: MetricService,
    private store: Store<MetricState>
  ) {
    super();
    /// These binds are important because child components call these and may not necessarily give us our proper component instance.
    this.getExportPrefix = this.getExportPrefix.bind(this);
    // Listen for metric config updates
    this.addSubscription(
      this.store.select(selectMetricConfiguration).subscribe((data) => {
        this.metricGridService
          .observeMetricDataForSession(data)
          .then((dataStore) => (this.currentMetricDataStore = dataStore));
      })
    );
  }

  /**
   * Get the filename that should be used to export the selected metric card.
   * This is handled at the dashboard/session comparison level because they name files slightly differently.
   * @param card
   */
  getExportPrefix(_: MetricCardDataStore): string {
    return this.sessionService.currentSession?.name || "";
  }

  /**
   * Returns if we are ready to display the dashboard
   */
  get readyToDisplay() {
    return this.serviceManager.onServicesLoadedUser;
  }
}
