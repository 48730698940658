import { createActionGroup, props } from "@ngrx/store";
import { Bookmark, Role, Session } from "@tdms/common";
/**
 * Supported NGRX store session actions
 */
export const SessionActions = createActionGroup({
  source: "Session",
  events: {
    Select: props<{ session: Session | undefined }>(),
    "Add Session": props<Session>(),
    "Add Many Sessions": props<{ sessions: Session[] }>(),
    "Update Session": props<Session>(),
    "Update Role": props<Role>(),
    "Delete Session": props<Session>(),
    "Delete Role": props<Role>(),
    "Empty Sessions": props<any>(),
    "Add Bookmark": props<{ sessionId: number; bookmark: Bookmark }>(),
    "Delete Bookmark": props<{ sessionId: number; bookmark: Bookmark }>(),
    "Update Bookmark": props<{ sessionId: number; bookmark: Bookmark }>(),
  },
});
