import { Inject, Injectable, Optional } from "@angular/core";
import { MAT_DATE_LOCALE, NativeDateAdapter } from "@angular/material/core";

/**
 * An extension upon the standard Native adapter provided by the angular team to help formatting
 *  the display appropriately to UTC. Utilizes internal date objects to javascript.
 *
 * For more info, [see this from the angular source code.](https://github.com/angular/components/blob/main/src/material/core/datetime/native-date-adapter.ts)
 *
 * You can implement this per component or module by adding it as a provider, like:
 *
 * ```ts
 * import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
 * import { NativeUTCAdapter } from './adapter';
 * ...
 * {
 *  providers: [ { provide: DateAdapter, useClass: NativeUTCAdapter, deps: [MAT_DATE_LOCALE] } ]
 * }
 * ```
 */
@Injectable()
export class NativeUTCAdapter extends NativeDateAdapter {
  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
    super(dateLocale);
  }

  override createDate(year: number, month: number, date: number): Date {
    // Check for invalid month and date
    if (month < 0 || month > 11) throw Error(`Invalid month index "${month}". Month index has to be between 0 and 11.`);
    if (date < 1) throw Error(`Invalid date "${date}". Date has to be greater than 0.`);

    // Create our actual date result
    const result = new Date();
    result.setUTCFullYear(year, month, date); // Use UTC here
    result.setHours(0, 0, 0, 0);

    // Check that the date wasn't above the upper bound for the month, causing the month to overflow
    if (result.getMonth() != month) throw Error(`Invalid date "${date}" for month with index "${month}".`);
    return result;
  }

  override format(date: Date, displayFormat: { [key: string]: "numeric" | "short" | "long" }): string {
    if (!this.isValid(date)) throw Error("NativeDateAdapter: Cannot format invalid date.");
    let returnContent: Array<string | number> = [];
    // Month
    if (displayFormat["month"] === "numeric") returnContent.push(this.padNumber(date.getUTCMonth() + 1));
    else if (displayFormat["month"] === "short") returnContent.push(date.toLocaleString("default", { month: "short" })); // I think month is the only one that can do short
    // Day
    if (displayFormat["day"]) returnContent.push(this.padNumber(date.getUTCDate()));
    // Year
    if (displayFormat["year"]) returnContent.push(date.getUTCFullYear());
    // Custom return formatting
    if (displayFormat["month"] === "short" && displayFormat["year"]) return returnContent.join(" ");
    else return returnContent.join("/");
  }

  override getDate(date: Date): number {
    // Fix the day being potentially wrong from a timezone issue
    return date.getUTCDate();
  }

  override getYear(date: Date): number {
    return date.getUTCFullYear();
  }

  override getMonth(date: Date): number {
    return date.getUTCMonth();
  }

  override getDayOfWeek(date: Date): number {
    return date.getUTCDay();
  }

  /** Given the 2 digit number, pads it to include leading zeroes as needed */
  private padNumber(num: number) {
    if (num < 10) return `0${num}`;
    else return num.toString();
  }
}
