<shared-dialog-wrapper header="Aggregate File Parser" class="upload-dialog">
  <div class="upload-dialog-container">
    <h1>Below you can select a single excel style file to separate into individual files by row based on a column</h1>
    <div>
      <data-store-file-uploader
        [isAggregateUpload]="true"
        [shouldDisplaySessionCreationActor]="false"
        (singleFileUpdate)="handleFileUpdate($event)"
        [allowMultiple]="false"
      ></data-store-file-uploader>
      <div class="submit-container">
        <span [matTooltip]="validationMessage || ''">
          <button mat-flat-button color="accent" (click)="submitCallback()" [disabled]="!submitButtonAvailable">
            Parse File
          </button>
        </span>
      </div>
    </div>
  </div>
</shared-dialog-wrapper>
