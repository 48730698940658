<div class="dialog-wrapper">
  <div class="header-row">
    <!-- Flex placeholder -->
    <div class="flex-placeholder"></div>
    <!-- Display title -->
    <div class="header">{{ header }}</div>
    <div class="header-button-row">
      <!-- Easy close button -->
      <button
        *ngIf="showCloseButton"
        mat-button
        color="primary"
        matTooltip="Close popup"
        class="dialog-wrapper-close"
        (click)="closeDialogCallback()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <!-- Render main content -->
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
