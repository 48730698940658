import { TDMSBase } from "../base";

export type CommonSettingMap = Map<string, CommonSetting>;

/**
 * The supported types that a setting value can be.
 * This helps us provide some type checking to setting values.
 * Custom types could be implemented using the string type and a JSON representation, or added to this enum.
 */
export enum CommonSettingType {
  boolean = "boolean",
  string = "string",
  number = "number",
  color = "color",
  colorTheme = "color_theme",
}

/**
 * A single setting name/value pair that should be attached to the collection defined for one plugin/metric.
 */
export interface CommonSetting {
  name: string;
  value: any;
  settingType: CommonSettingType;
}

/**
 * A collection of settings that is defined by a single plugin/metric and attached to a user.
 * Plugins defined the default set of settings that they support,
 * and whenever a new user is created, a collection will be generated to tie to that user.
 */
export interface CommonSettingCollection {
  plugin: string;
  settings: CommonSetting[];
}

/**
 * Setting descriptions provide a helpful title and description that can be presented on screen to the user.
 * These should be defined by the plugin/metric in the backend.
 */
export interface CommonSettingDescription {
  name: string;
  helpfulTitle: string;
  helpfulDescription: string;
}

/**
 * Setting collection descriptions contain a set of @CommonSettingDescriptions for each setting in the collection,
 * as well as a helpful title and description for the plugin/metric itself.
 */
export interface SettingCollectionDescription {
  name: string;
  helpfulTitle: string;
  helpfulDescription: string;
  settingDescriptions: CommonSettingDescription[];
}

/**
 * Represent a collection of color data attached to a theme.
 */
export interface CommonColorTheme {
  name: string;
  colors: string[];
}

/**
 * Request that asks the backend for all available user settings for the currently logged in user.
 * This will error if no user is logged in.
 */
export class GetUserSettingsRequest extends TDMSBase {}

/**
 * Response with all settings for the logged in user.
 */
export class GetUserSettingsReply extends TDMSBase {
  collections?: CommonSettingCollection[];

  constructor(collections?: CommonSettingCollection[]) {
    super();
    this.collections = collections;
  }
}

/**
 * Request that asks the backend for the available color themes.
 * Empty request as no parameters are required for this.
 */
export class GetColorThemesRequest extends TDMSBase {}

/**
 * Reply from the backend containing the available color themes.
 */
export class GetColorThemesReply extends TDMSBase {
  /**
   * Flag to indicate any errors processing the request.
   * Currently, there are no explicitly raised exceptions.
   */
  success: boolean;
  /**
   * Error Message will be a string describing an error that occurred, if success is false.
   * Empty string otherwise.
   */
  errorMessage: string;
  /**
   * The list of color themes that are defined in the backend.
   * Will be undefined or empty if an error occurred loading the themes.
   */
  themes?: CommonColorTheme[];

  constructor(success: boolean, errorMessage: string, themes?: CommonColorTheme[]) {
    super();
    this.themes = themes;
    this.success = success;
    this.errorMessage = errorMessage;
  }
}

/**
 * Request for the backend to get setting descriptions for the provided setting collections.
 * Should be called after settings are loaded for a given user account.
 */
export class GetSettingDescriptionsRequest extends TDMSBase {}

/**
 * Reply from the backend with the loaded setting descriptions.
 */
export class GetSettingDescriptionsReply extends TDMSBase {
  /**
   * Flag to indicate any errors processing the request.
   * Currently, there are no explicitly raised exceptions.
   */
  success: boolean = true;
  /**
   * The list of descriptions loaded from the backend.
   * Empty list or undefined if success is false.
   */
  descriptions?: SettingCollectionDescription[];
  /**
   * Error message will be a string describing the error that occurred if success is false.
   * Empty string otherwise.
   */
  errorMessage?: string;

  constructor(success: boolean, descriptions?: SettingCollectionDescription[], errorMessage?: string) {
    super();
    this.success = success;
    this.descriptions = descriptions;
    this.errorMessage = errorMessage;
  }
}
