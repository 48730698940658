import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { DataStoreFile, Session, User, Utility } from "@tdms/common";
import { DataStoreState } from "@tdms/frontend/modules/data-store/models/data.store.state";
import { selectRecycleBinFiles } from "@tdms/frontend/modules/data-store/models/store/data.store.selector";
import RecycleBinService from "@tdms/frontend/modules/data-store/services/recycle-bin.service";
import { SearchService } from "@tdms/frontend/modules/data-store/services/search.service";
import { DataColumn, DatalessColumn } from "@tdms/frontend/modules/shared/components";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import autoBind from "auto-bind";

/**
 * This component is the recycle bin tab of the data store management page.
 */
@Component({
  selector: "recycle-bin",
  templateUrl: "./recycle-bin.component.html",
  styleUrls: ["./recycle-bin.component.scss"],
})
export class RecycleBinComponent extends SubscribingComponent {
  /** Files that should be shown in the recycle bin */
  files: DataStoreFile[] = [];

  /** Tracks whatever columns the table currently has selected in preparation for mass delete/restore*/
  currentSelectedColumns: DataStoreFile[] = [];

  constructor(
    private store: Store<DataStoreState>,
    private recycleBinService: RecycleBinService,
    private searchService: SearchService
  ) {
    super();
    autoBind(this);
    this.addSubscription(
      this.store.select(selectRecycleBinFiles).subscribe((x) => (this.files = DataStoreFile.fromPlainArray(x)))
    );
  }

  /**
   * The columns we wish to display for our table
   */
  displayedColumns = [
    new DatalessColumn("space", ""),
    new DataColumn<DataStoreFile, Session>("session", "Session Name", (_, obj) => obj.session.name),
    new DataColumn<DataStoreFile, User>(
      "deleteRequestedUser",
      "Requested By",
      (_, obj) => obj.deleteRequestedUser?.username || ""
    ),
    new DataColumn<DataStoreFile, string>("uncompressedFileName", "File Name"),
    new DataColumn<DataStoreFile, Date>("deletionSchedule", "Auto Removal On", Utility.getDateAsZulu.bind(this)),
    new DatalessColumn("actions", "Actions"),
  ];

  /**
   * Handle permanently deleting the given entries
   */
  async deleteEntries(entries: DataStoreFile[]) {
    await this.recycleBinService.deleteFiles(entries, true);
  }

  /**
   * Handle restoring the given entries so they will no longer be deleted
   */
  async restoreEntries(entries: DataStoreFile[]) {
    await this.recycleBinService.restore(entries);
    await this.searchService.startInteractiveQuery(undefined, false); /// Rerun the query IN THE BACKGROUND when you restore entries
  }

  /**
   * Called whenever a new selection is fired from the generic table to update our local cache.
   */
  tableSelectionUpdate(items: DataStoreFile[]) {
    this.currentSelectedColumns = items;
  }
}
