import { TDMSBase } from "../../base";

/** This class provides the request base for session summary data */
export class SessionSummaryRequest extends TDMSBase {
  /** The session Id to request data for */
  sessionId: number;

  constructor(sessionId: number) {
    super();
    this.sessionId = sessionId;
  }
}
