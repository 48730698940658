import { Component, Input } from "@angular/core";
import { User } from "@tdms/common";

/**
 * A component used to help display user icon circles in various locations
 */
@Component({
  selector: "user-icon[user]",
  templateUrl: "./user-icon.component.html",
  styleUrls: ["./user-icon.component.scss"],
})
export class UserIconComponent {
  /**
   * The user to use to display information about
   */
  @Input() user!: User;

  constructor() {}

  /**
   * Returns the path to the users icon image
   */
  get currentUserIconImg() {
    return `../../assets/user-icons/${this.user.icon}.png`;
  }
}
