<shared-dialog-wrapper class="help-dialog" header="Session Summary Help">
  <!-- Each group should be it's own list item -->
  <div class="session-summary-advanced-help">
    <!-- Table weighting and mapping display. Left column is constructs and headers are the metrics -->
    <div class="construct-mapping-container row">
      <div class="header">Construct Table Mapping</div>
      <div class="description">
        Displays all the constructs and how they are mapped to the specific metrics. The percentages are the weights per
        metric for that construct. The colors are purely a visual piece to separate each weight by three importance
        levels displayed in the legend below.
      </div>
      <!-- Gauge -->
      <session-summary-gauge-legend class="weight-gauge" [gaugeRange]="gaugeRange"></session-summary-gauge-legend>
      <!-- Actual table weighting -->
      <session-summary-construct-table
        class="construct-table"
        [groups]="metrics"
        [displayTimeRow]="false"
        [headerOverride]="baselineMetricsAsHeaders"
      >
        <ng-template
          #cellTemplate
          let-header="header"
          let-addClasses="addClasses"
          let-group="group"
          let-metric="metric"
        >
          <td
            [ngClass]="addClasses"
            class="value-cell cell"
            *ngVar="getWeightConfigByAndName(group, metric.displayName, header) as weightConfig"
          >
            <ng-container *ngIf="weightConfig">
              <span
                *ngVar="getColorConfigFromWeight(weightConfig.weight) as config"
                class="weight-config-inner"
                [ngStyle]="{ backgroundColor: config?.color, color: config?.textColor }"
              >
                {{ (weightConfig.weightPercentage * 100).toFixed(2) }}%
              </span>
            </ng-container>
          </td>
        </ng-template>
      </session-summary-construct-table>
    </div>

    <!-- Groups -->
    <div class="groups row">
      <div class="header group-header">Metric Calculations</div>
      <div class="description">
        Displays all the metrics and a brief description of how they are calculated for the session summary page.
      </div>
      <!-- Actual metrics -->
      <ng-container *ngIf="baselineMetricGroup && groupHasHelpText(baselineMetricGroup)">
        <!-- List each metric and their content -->
        <ul *ngFor="let metric of baselineMetricGroup.metrics">
          <!-- Make sure we have help text -->
          <ng-container *ngIf="metric.helpContent">
            <!-- Metric name -->
            <li class="item metric-name">{{ metric.displayName }}</li>
            <ul>
              <!-- Metric help info -->
              <li class="item help-content" *ngFor="let text of metric.helpContent.text">{{ text }}</li>
              <!-- Metric team/individual feedback info -->
              <ng-container *ngIf="metric.helpContent.feedbackCutoff">
                <li class="item help-content">
                  This metric is included in the overview display for team/individual feedback.
                </li>
                <ul>
                  <li class="item help-content">
                    It will be displayed in the event the overall average is below
                    {{ metric.helpContent.feedbackCutoff.lowAverageValue }} ({{
                      metric.helpContent.feedbackCutoff.lowAveragePercentage
                    }}%).
                  </li>
                </ul>
              </ng-container>
            </ul>
          </ng-container>
        </ul>
      </ng-container>
    </div>

    <!-- Terms -->
    <div class="terms row">
      <div class="header terms-header">Terms</div>
      <ul>
        <li>
          Interval
          <ul>
            <li>
              The separation of time at the bottom of the session summary advanced view. This is how we turn the session
              into "chunks". The "chunk" size will differ per session.
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</shared-dialog-wrapper>
