import { createActionGroup, props } from "@ngrx/store";
import { ChartConfiguration } from "@tdms/common";
import { MetricServiceDataStore } from "@tdms/frontend/modules/metrics/components/metric-card/models/metric.configuration";

/**
 * Supported NGRX store **metric** actions
 */
export const MetricActions = createActionGroup({
  source: "Metric",
  events: {
    // Configuration of all metrics
    "Add Chart Configuration": props<{ configs: ChartConfiguration[] }>(),
    "Wipe Configuration": props<any>(),
    // Wipe metric data
    "Wipe Metrics": props<{
      /**
       * The session to wipe. Pass undefined to wipe all sessions
       */
      sessionId: number | undefined;
    }>(),
    // Specific metric data processing
    "Update metric data": props<{
      sessionId: number;
      metricName: string;
      data: Partial<MetricServiceDataStore<any>>;
    }>(),
    "Mass update metric data": props<{
      request: { sessionId: number; data: { metricName: string; data: Partial<MetricServiceDataStore<any>> }[] }[];
    }>(),
  },
});
