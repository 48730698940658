import { ChartConfiguration, CustomTypes } from "@tdms/common";
import { FrontendUtility } from "@tdms/frontend/modules/shared/models/utility";
import autoBind from "auto-bind";
import { ChartEvent, Plugin } from "chart.js";
import { merge } from "lodash-es";
import { ExtendedChartType, TDMSChartJSPlugins } from "./plugin.typing";

/** Base options for **external** options for all plugins to track */
export class PluginBaseExternalOpts {
  /** If this chart is enabled */
  enabled?: boolean = false;
}

/** Base options for **internal** options for all plugins to track */
export class PluginBaseInternalOpts {
  /** If the mouse cursor is currently in this chart */
  inChartArea?: boolean = false;

  /** Current X pos of the mouse in the chart */
  xPos?: number | undefined = undefined;
  /** Current Y position of the mouse in the chart */
  yPos?: number | undefined = undefined;
  /** The mouse event associated to the xPos of the mouse */
  lastMouseMoveEvent?: MouseEvent | undefined = undefined;
}

/** Base class that plugins should extend for common functionality */
export abstract class PluginBase<
  ExternalOpts extends PluginBaseExternalOpts, // The external options we support
  InternalOpts extends PluginBaseInternalOpts // The internal options we support
> implements Plugin<any>
{
  /** The current chart instance this is associated to. Will be set during base initialization. */
  readonly chart: ExtendedChartType | undefined;

  /** The name of this plugin for the registration. Used by Chart.JS. */
  readonly id: CustomTypes.PropertyNames<TDMSChartJSPlugins, any>;

  /**
   * Creates an instance of a Chart.JS plugin
   * @param pluginName The name of this plugin
   * @param externalOptsType The type supported for **external** options of this plugin
   * @param internalOptsType The type supported for **internal** options of this plugin
   */
  constructor(
    pluginName: CustomTypes.PropertyNames<TDMSChartJSPlugins, any>,
    private externalOptsType: CustomTypes.ConstructorFunction<ExternalOpts>,
    private internalOptsType: CustomTypes.ConstructorFunction<InternalOpts>
  ) {
    this.id = pluginName;
    autoBind(this);
  }

  /** Returns if this chart type supports the vertical time series line */
  get chartIsTimeSeries() {
    if (this.chart == null) return false;
    return this.chart.scales.x.type === "time";
  }

  /** Returns if this is a circular chart (like a gauge or a pie chart) */
  get chartIsCircular() {
    if (this.chart == null) return false;
    return (["gauge", "pie"] as ChartConfiguration["type"][]).includes(this.chart.tdmsType);
  }

  install(chart: ExtendedChartType) {
    (this as any).chart = chart;
    // Configure option references
    const existingExternalOpts = this.chart?.config.options.plugins[this.id];
    const existingInternalOpts = chart[this.id];
    chart.config.options.plugins[this.id] = merge(new this.externalOptsType(), existingExternalOpts);
    chart[this.id] = merge(new this.internalOptsType(), existingInternalOpts) as any;
    this.chartReady();
  }

  /**
   * A callback that will be executed when the chart is ready to perform. This is useful because sometimes the plugins will complete initialization
   *  and try to render before a chart is actually ready. This allows you to inject more code in this case.
   */
  chartReady() {}

  /** Options that can be configured outside this plugin in the chart options */
  get externalOpts() {
    return this.chart?.config.options.plugins[this.id]! as ExternalOpts;
  }

  /** Options related to this plugins internals */
  get internalOpts() {
    return this.chart![this.id]! as InternalOpts;
  }

  /** Returns if our plugin is enabled */
  get enabled() {
    return this.externalOpts?.enabled || false;
  }

  /**
   * Returns if we should execute functionality or not for this plugin by checking some defaults like:
   *    - If this plugin is enabled
   *    - If the chart canvas exists
   */
  shouldExecuteFunctionality(chart = this.chart) {
    return this.enabled && chart?.canvas != null;
  }

  /**
   * A function that will be called whenever a mouse is moved inside the current instanced chart.
   *
   * **NOTE:** Do not use this to draw elements to the canvas. You will need to draw them somewhere else like `afterDatasetDraw` or they will not appear!
   */
  mouseMove(e: MouseEvent) {
    // If we don't have an X value or are not in the chart area, don't update
    if (!this.internalOpts.inChartArea) return;
    let offsetX = e.offsetX;
    let offsetY = e.offsetY;
    // Firefox doesn't support offset X/Y but we need to use it so come up with that data here
    if (e.target && FrontendUtility.isFirefox) {
      const rect = (e.target as EventTarget & { getBoundingClientRect: Function }).getBoundingClientRect();
      offsetX = e.clientX - rect.left;
      offsetY = e.clientY - rect.top;
    }
    // Set data
    this.internalOpts.xPos = offsetX;
    this.internalOpts.yPos = offsetY;
    this.internalOpts.lastMouseMoveEvent = e;
  }

  /** A function that will be called whenever a mouse **leaves** the current chart */
  mouseLeave(_e: MouseEvent) {
    this.internalOpts.inChartArea = false;
    this.internalOpts.xPos = undefined;
    this.internalOpts.yPos = undefined;
    this.internalOpts.lastMouseMoveEvent = undefined;
  }

  /** Function to fire when a mouse down is detected for a click */
  mouseDown(_e: MouseEvent) {}

  /** Function to fire when a mouse up is detected for a click release*/
  mouseUp(_e: MouseEvent) {}

  start(chart: ExtendedChartType): void {
    chart.canvas.addEventListener("mouseup", this.mouseUp);
    chart.canvas.addEventListener("mousedown", this.mouseDown);
  }

  stop(chart: ExtendedChartType) {
    chart.canvas?.removeEventListener("mouseup", this.mouseUp);
    chart.canvas?.removeEventListener("mousedown", this.mouseDown);
  }

  afterEvent(_chart: ExtendedChartType, args: { event: ChartEvent; inChartArea: boolean; changed?: boolean }) {
    if (!this.shouldExecuteFunctionality()) return;
    // Set chart area tracker
    this.internalOpts.inChartArea = args.inChartArea;
    args.changed = true; // Tell the chart pipeline to update
    // Handle event types
    if (args.event.type === "mousemove") this.mouseMove(args.event.native as MouseEvent);
    else if (args.event.type === "mouseout") this.mouseLeave(args.event.native as MouseEvent);
  }
}
