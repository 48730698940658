import { createReducer, on } from "@ngrx/store";
import { MetricServiceDataStore } from "@tdms/frontend/modules/metrics/components/metric-card/models/metric.configuration";
import { MetricActions } from "./metric.action";
import { MetricState } from "./metric.state";

/**
 * Reducer for **metric** data
 */
export const metricReducer = createReducer(
  { availableConfiguration: [], data: {}, legend: [] } as MetricState,
  on(MetricActions.addChartConfiguration, (state, data) => {
    return { ...state, availableConfiguration: [...state.availableConfiguration, ...data.configs] };
  }),
  on(MetricActions.wipeConfiguration, (state) => {
    return { ...state, availableConfiguration: [] };
  }),
  on(MetricActions.updateMetricData, (state, data) => {
    let newState = { ...state };
    // If we don't have the overarching session data, create it
    if (newState.data[data.sessionId] == null) newState = { ...state, data: { ...state.data, [data.sessionId]: {} } };
    // This looks like a mess but we assign our new data into the metric name by also creating it if it doesn't exist
    newState = {
      ...newState,
      data: {
        ...newState.data,
        [data.sessionId]: {
          ...newState.data[data.sessionId],
          [data.metricName as any]: MetricServiceDataStore.fromPlain({
            ...newState.data[data.sessionId][data.metricName],
            ...data.data,
          }),
        },
      },
    };
    return newState;
  }),
  on(MetricActions.massUpdateMetricData, (state, data) => {
    let newState = { ...state };
    for (let request of data.request) {
      for (let metric of request.data) {
        // If we don't have the overarching session data, create it
        if (newState.data[request.sessionId] == null)
          newState = { ...state, data: { ...state.data, [request.sessionId]: {} } };
        // This looks like a mess but we assign our new data into the metric name by also creating it if it doesn't exist
        newState = {
          ...newState,
          data: {
            ...newState.data,
            [request.sessionId]: {
              ...newState.data[request.sessionId],
              [metric.metricName as any]: {
                ...newState.data[request.sessionId][metric.metricName],
                ...(newState.data[request.sessionId][metric.metricName] == null
                  ? MetricServiceDataStore.fromPlain(metric.data)
                  : metric.data),
              },
            },
          },
        };
      }
    }
    return newState;
  }),
  on(MetricActions.wipeMetrics, (state, data) => {
    return { ...state, data: data.sessionId == null ? {} : { ...state.data, [data.sessionId]: {} } };
  })
);
