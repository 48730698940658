import { Directive, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

/**
 * Subscribing Components maintain a list of subscriptions that are used throughout the inheriting components, and safely clean them up when destroyed.
 */
@Directive({})
export class SubscribingComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];

  /**
   * Add's subscriptions to the subscription array so they can be automatically
   *  removed when the component is destroyed.
   *
   * **Undefined subscriptions will be ignored**
   */
  addSubscription(...subscription: (Subscription | undefined)[]) {
    const subscriptionsToProcess = subscription.filter((x) => x != null) as Subscription[];
    this.subscriptions.push(...subscriptionsToProcess);
    return subscriptionsToProcess;
  }

  ngOnDestroy(): void {
    this.cleanupSubscriptions();
  }

  /**
   * Destroys any subscriptions in subscriptionArray by unsubscribing from them. Provided as a generic function for reuse.
   * @param subscriptionArray The array of subscriptions to unsubscribe from. Defaults to `this.subscriptions`.
   */
  cleanupSubscriptions(subscriptionArray = this.subscriptions) {
    for (const subscription of subscriptionArray) {
      subscription.unsubscribe();
    }
  }
}
