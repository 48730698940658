import { Type } from "class-transformer";
import { TDMSBase } from "../../base";

/** The available weights for metrics */
export type MetricWeight = "high" | "medium" | "low";
/** Group name for the overarching base layer metrics that are combined into constructs */
export const METRIC_GROUP_NAME = "Metrics";

/** A class that provides the cells to the advanced view for each breakdown of content */
export class SessionSumAdvancedMetricBreakdown extends TDMSBase {
  /**
   * The value of this metric at this point in time for the advanced view.
   *
   * This value uses the scale as determined by the plugin in the backend.
   */
  value: number;

  /** Time into session that this metric was calculated for */
  @Type(() => Date)
  time: Date;

  constructor(value: number, time: Date) {
    super();
    this.value = value;
    this.time = time;
  }
}

/** A class that provides an individual session summary metric per grouping to display in the advanced view */
export class SessionSummaryMetric extends TDMSBase {
  metricName: string;
  /** Not the same as metric name. This is purely used for display */
  displayName: string;
  /** The advanced view breakdown of content to display in a table like format. This is the averages over time for this metric. */
  @Type(() => SessionSumAdvancedMetricBreakdown)
  breakdown: SessionSumAdvancedMetricBreakdown[];
  /** Feedback that could be applied to the overarching team. Help menu content is populated by: `helpContent.feedbackCutoff` */
  teamFeedback: string[];
  /** Feedback that could be applied to each specific individual. Help menu content is populated by: `helpContent.feedbackCutoff` */
  individualFeedback: { [roleName: string]: string[] };
  /** The help information for how this content was turned from it's metric into actual range display */
  helpContent?: {
    /** The text to display of how this was calculated. Each index in the array will be a separate line. */
    text?: string[];
    /** Adds help output that this metric will provide feedback via team or individual feedback based on these values */
    feedbackCutoff?: {
      /** Should be a value between {@link SESSION_SUMMARY_MIN} and {@link SESSION_SUMMARY_MAX}. Any average value less than this, is considered a "low" score and will be prompted on the feedback display.  */
      lowAverageValue: number;
      /** 0 - 100 range percentage (based on the {@link SESSION_SUMMARY_MIN} to {@link SESSION_SUMMARY_MAX}) */
      lowAveragePercentage: number;
    };
    /** Configures the weight config to display for the help menu */
    weightConfig?: {
      /** Backend plugin name */
      metric: string;
      /** The display mapping of the backend metric name */
      displayName: string;
      /** Decimal percentage */
      weightPercentage: number;
      weight: MetricWeight;
    }[];
  };

  constructor(
    metricName: string,
    displayName: string,
    breakdown: SessionSumAdvancedMetricBreakdown[],
    teamFeedback: string[],
    individualFeedback: { [roleName: string]: string[] },
    helpContent?: { text: string[]; weight?: number }
  ) {
    super();
    this.metricName = metricName;
    this.displayName = displayName;
    this.breakdown = breakdown;
    this.teamFeedback = teamFeedback;
    this.individualFeedback = individualFeedback;
    this.helpContent = helpContent;
  }
}

/** Advanced data separation by group */
export class SessionSummaryAdvancedGroupSeparation extends TDMSBase {
  groupName: string;

  @Type(() => SessionSummaryMetric)
  metrics: SessionSummaryMetric[];

  /** The **second** based time separation that should be displayed at the bottom of the table  */
  timeSeparation: number[];

  constructor(groupName: string, metrics: SessionSummaryMetric[], timeSeparation: number[]) {
    super();
    this.groupName = groupName;
    this.metrics = metrics;
    this.timeSeparation = timeSeparation;
  }
}

/** Information that relates directly to the session score and related breakdown */
export class SessionScore extends TDMSBase {
  /** The overall score to apply to this session */
  score: number;
  /** Things that went well in this session for the score */
  pros: string[];
  /** Things that didn't go so well in this session */
  cons: string[];

  constructor(score: number, pros: string[], cons: string[]) {
    super();
    this.score = score;
    this.pros = pros;
    this.cons = cons;
  }
}

/** A class used to define the session summary data for a session */
export class SessionSummary extends TDMSBase {
  sessionId: number;

  /** Session score breakdown */
  @Type(() => SessionScore)
  sessionScore: SessionScore;

  /** The advanced view breakdown for the researchers */
  @Type(() => SessionSummaryAdvancedGroupSeparation)
  advancedBreakdown: SessionSummaryAdvancedGroupSeparation[];

  constructor(
    sessionId: number,
    sessionScore: SessionScore,
    advancedBreakdown: SessionSummaryAdvancedGroupSeparation[]
  ) {
    super();
    this.sessionId = sessionId;
    this.sessionScore = sessionScore;
    this.advancedBreakdown = advancedBreakdown;
  }
}
