import { Component, ElementRef, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TagType } from "@tdms/common";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogProperties,
  DialogWrapperComponent,
} from "@tdms/frontend/modules/shared/components";

/** A singular tag to be rendered based on given information */
@Component({
  selector: "tag[name]",
  templateUrl: "./tag.component.html",
  styleUrls: ["./tag.component.scss"],
})
export class TagComponent {
  /** Mat icon to render before the name if given */
  @Input() icon: string | undefined;
  @Input() name!: string;
  @Input() type: TagType | undefined;
  @Input() color: string = "var(--accent-color)";

  /** If the X should be displayed to be able to remove this tag */
  @Input() canRemove = false;
  @Output() onRemove = new EventEmitter();

  /** If we should allow clicking to fire an event emitter. {@link onClick} */
  @Input() canClick = false;
  @Output() onClick = new EventEmitter<MouseEvent>();

  /** If we should allow editing of this tag */
  @Input() canEdit = false;
  @Output() onEdit = new EventEmitter();

  constructor(private dialog: MatDialog, public elementRef: ElementRef) {}

  tagEdit(event: MouseEvent) {
    event.stopPropagation();
    this.onEdit.emit();
  }

  tagRemove(event: MouseEvent) {
    event.stopPropagation();
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        description: "Are you sure you want to remove this tag? You can add it back later.",
        confirmButtonText: "Remove",
        confirmClickCallback: () => {
          this.onRemove.emit();
        },
        cancelClickCallback: undefined,
      } as Partial<ConfirmationDialogProperties>,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }
}
