<div class="extra-files-uploader-container">
  <!-- When options are available -->
  <ng-container *ngIf="uploadTypes.length > 0">
    <h1 class="header">{{ header }}</h1>
    <div class="uploading-row">
      <!-- Uploader -->
      <data-store-file-uploader
        #extraFilesUploader
        [displayType]="'exclude-session-creation'"
        (fileUpdate)="updateExtraFiles($event)"
        [displayPreviouslyUploadedFiles]="false"
        [currentFiles]="files"
        [uploadTypes]="uploadTypes"
        [shouldDisplaySessionCreationActor]="shouldDisplaySessionCreationActor"
        class="extra-files-uploader"
      ></data-store-file-uploader>
      <!-- Tree Display -->
      <data-store-file-tree
        class="extra-files-tree"
        noFilesMessage="Uploaded files on the left will show up here"
        [files]="files"
        [deleteCallback]="extraFileRemove.bind(this)"
      ></data-store-file-tree></div
  ></ng-container>
  <!-- When options are not available -->
  <ng-container *ngIf="uploadTypes.length === 0">
    <h1 class="header">No additional upload options available</h1>
  </ng-container>
</div>
