export namespace RoleConfiguration {
  export const plugin = "RoleConfiguration";

  export enum Names {
    colorTheme = "color_theme",
  }

  export enum HelpfulTitles {
    plugin = "Role Configuration",
    colorTheme = "Color Theme",
  }

  export enum HelpfulDescriptions {
    plugin = "Configuration related to roles that are used within sessions",
    colorTheme = "The theme that decides coloration of the dashboard charts.",
  }
}
