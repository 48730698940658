import { CustomTypes } from "@tdms/common";
import { cloneDeep } from "lodash-es";
import { LegendDisplayObject } from "./legend.display.object";

/**
 * A base class to provide legend capabilities
 */
export class LegendDisplay {
  /**
   * The name to give this grouping
   */
  groupName: string | undefined;

  /**
   * The options to display with this grouping
   */
  options!: LegendDisplayObject[];

  /**
   * Flattens the options of all given data to a single level of data
   */
  static flattenOptions(data?: LegendDisplay[]) {
    return data?.flatMap((x) => x.options).filter((z) => z != null) || [];
  }

  /**
   * Given the legend display and the element, locates the matching element and returns it and it's positioning
   * @param legendData The legend data to check against
   * @param element The element object you are looking for. You can also provide a string if you just want to locate the name.
   */
  static findSpecificElement(legendData: LegendDisplay[], element: LegendDisplayObject | string) {
    // Determine what field should be compared. Original name mapping takes priority.
    const elementValueLookup: CustomTypes.PropertyNames<LegendDisplayObject, string> | undefined =
      typeof element === "string" ? undefined : element.originalNameMapping == null ? "name" : "originalNameMapping";
    for (let [legendSlot, data] of legendData.entries()) {
      for (let [optionSlot, option] of data.options.entries())
        if (
          elementValueLookup == null
            ? (option.originalNameMapping || option.name) === element
            : option[elementValueLookup] === (element as LegendDisplayObject)[elementValueLookup]
        ) {
          return { legendSlot, optionSlot, element: option };
        }
    }
    return undefined;
  }

  /**
   * Given a legend array and an element, updates the element in that legend array and returns the array. Returns the original
   *  array even if nothing is found.
   *
   * **Note** The original array will not be updated
   */
  static updateSpecificElement(legendData: LegendDisplay[], element: LegendDisplayObject) {
    const match = LegendDisplay.findSpecificElement(legendData, element);
    if (!match) return legendData;
    else {
      const clonedLegend = cloneDeep(legendData);
      clonedLegend[match.legendSlot].options[match.optionSlot] = element;
      return clonedLegend;
    }
  }

  constructor(options: Partial<LegendDisplay>) {
    Object.assign(this, options);
  }
}
