import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import { PopulatedSearchOption, SearchableOption } from "@tdms/common";

@Component({
  selector: "data-store-query-term-selector",
  templateUrl: "./query-term-selector.component.html",
  styleUrls: ["./query-term-selector.component.scss"],
})
export class QueryTermSelectorComponent implements OnChanges {
  @Input() currentOption?: PopulatedSearchOption;
  @Input() availableSearchTerms?: SearchableOption[];

  @Input() onTermSelected!: (option: SearchableOption) => void;

  dropdownControl = new FormControl();

  constructor() {
    this.onDropdownChanged = this.onDropdownChanged.bind(this);
  }

  ngOnInit() {
    this.dropdownControl.setValue(this.currentOption?.fullName);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentOption) {
      this.dropdownControl.setValue(this.currentOption?.fullName);
    }
  }

  onDropdownChanged() {
    const fullName = this.dropdownControl.value;
    const option = this.availableSearchTerms?.find((option) => option.fullName == fullName);
    if (option != null) this.onTermSelected(option);
  }
}
