<shared-dialog-wrapper header="Upload Additional Files" class="upload-dialog">
  <div class="file-name">
    <p>Uploading files to this session file:</p>
    <h4>{{ this.properties.selectedSession.file.file.name }}</h4>
  </div>
  <div class="uploader-file-tree-container">
    <data-store-file-uploader
      [allowMultiple]="true"
      [currentFiles]="files"
      (fileUpdate)="updateFiles($event)"
      [shouldDisplaySessionCreationActor]="false"
      [isBulkUpload]="true"
    ></data-store-file-uploader>

    <data-store-file-tree
      class="session-uploader"
      noFilesMessage="Uploaded files will show up here"
      [files]="files"
      [isBulkUpload]="false"
      [deleteCallback]="fileRemove.bind(this)"
    ></data-store-file-tree>
  </div>
  <div class="submit-container">
    <button [disabled]="!files.length" (click)="addFilesToSession()" mat-flat-button color="accent">
      Upload Files
    </button>
  </div>
</shared-dialog-wrapper>
