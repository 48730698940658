<shared-generic-table
  [data]="files"
  tableName="Recycle Bin"
  [displayedColumns]="displayedColumns"
  [defaultSortHeader]="'deletionSchedule'"
  [shouldGuessPaginatorPageSize]="false"
  [selectionUpdate]="tableSelectionUpdate"
>
  <!-- Custom columns -->
  <ng-template #cellTemplate let-value="value" let-column="column" let-element="element">
    <ng-container [ngSwitch]="column.name">
      <div *ngSwitchCase="'actions'" class="actions-cell">
        <button
          mat-button
          class="restore-entry-button"
          matTooltip="Restore this file, preventing it from being deleted."
          (click)="restoreEntries([element])"
        >
          <mat-icon class="icon">restore_page</mat-icon>
          <span class="username">Restore</span>
        </button>
        <button
          mat-button
          class="delete-entry-button"
          matTooltip="Delete this file immediately. PERMANENT!"
          matTooltipPosition="right"
          (click)="deleteEntries([element])"
          [configDisable]="'dataStore.deleteEnabled'"
          configDisabledStateMessage="Deletion is currently disabled"
        >
          <mat-icon class="icon">delete_forever</mat-icon>
          <span class="username">Delete Now</span>
        </button>
      </div>
      <span *ngSwitchDefault>
        {{ value }}
      </span>
    </ng-container>
  </ng-template>

  <!-- Custom end buttons -->
  <ng-template #buttonRow>
    <!-- Mass Restore -->
    <button
      mat-raised-button
      color="accent"
      (click)="restoreEntries(currentSelectedColumns)"
      [disabled]="currentSelectedColumns.length === 0"
      [matTooltip]="currentSelectedColumns.length === 0 ? 'Please select some rows' : ''"
      matTooltipPosition="below"
    >
      <mat-icon>repartition</mat-icon> Restore selected
    </button>

    <!-- Mass Delete -->
    <button
      mat-raised-button
      color="warn"
      (click)="deleteEntries(currentSelectedColumns)"
      [disabled]="currentSelectedColumns.length === 0"
      matTooltip="Please select some rows"
      matTooltipPosition="below"
      [configDisable]="'dataStore.deleteEnabled'"
      configDisabledStateMessage="Deletion is currently disabled"
    >
      <mat-icon>delete_sweep</mat-icon> Delete selected now
    </button>
  </ng-template>
</shared-generic-table>
