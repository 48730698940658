import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavigationStart } from "@angular/router";
import { Store } from "@ngrx/store";
import {
  TDMSWebSocketMessage,
  User,
  UserChangePasswordReply,
  UserChangePasswordRequest,
  UserLockUnlockRequest,
  UserLoginReply,
  UserLoginRequestJWT,
  UserLoginRequestPassword,
  UserLogoutReply,
  UserLogoutRequest,
  UserLookupReply,
  UserLookupRequest,
  UserRegisterReply,
  UserRegisterRequest,
  UserTopics,
  UserUpdateReply,
  UserUpdateRequest,
  Utility,
  WebSocketCommunication,
} from "@tdms/common";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { RouteHelper } from "@tdms/frontend/modules/routing/models/helper";
import { Route_URLs } from "@tdms/frontend/modules/routing/models/url";
import { RouterParamTypes, RouterService } from "@tdms/frontend/modules/routing/services/router.service";
import { SessionSelectedGuard } from "@tdms/frontend/modules/session/guard/session.selected.guard";
import { SessionActions } from "@tdms/frontend/modules/session/store/session.action";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { Configuration } from "@tdms/frontend/modules/shared/models/config";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { UserActions } from "@tdms/frontend/modules/user/store/user.action";
import { selectCurrentUser } from "@tdms/frontend/modules/user/store/user.selector";
import { UserState } from "@tdms/frontend/modules/user/store/user.state";
import { filter, firstValueFrom, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserService extends Service {
  /**
   * The key for the JWT in storage
   */
  readonly jwtKey = "jwt";

  /**
   * A kind set of words to greet the user with.
   */
  currentGreeting: string = this.regenerateGreeting();

  /**
   * Tracks the previously login attempt so we can get the error message for display if need be
   */
  previousLoginResponse: UserLoginReply | undefined;

  /**
   * Subscription model to allow for listening when the authentication state changes
   */
  onClientConnectAuthenticate = new Subject<{ user: User | undefined; wasJWTLogin: boolean }>();

  /**
   * A boolean to utilize as we request logins or receive the response
   */
  isAwaitingLoginStatus = false;

  /**
   * The current authenticated user
   */
  currentAuthenticatedUser: User | undefined;

  constructor(
    private store: Store<UserState>,
    private routerService: RouterService,
    private wsService: WebSocketService,
    private snackBar: MatSnackBar,
    private configService: ConfigService
  ) {
    super();

    this.routerService.browserNavigatedSubject.subscribe(async (event: NavigationStart) => {
      if (event.url.includes(Route_URLs.login)) {
        /// We are navigating to the login page, so we should clean up our logged in user.
        await this.logout();
      }
    });
    // Keep reference updated
    this.store
      .select(selectCurrentUser)
      .subscribe((user) => this.onCurrentUserUpdate(this.currentAuthenticatedUser, user));
  }

  override async onBackendConnected(): Promise<void> {
    return this.tryJWTLogin();
  }

  override async onBackendDisconnected(): Promise<void> {
    // Reset authentication status on connect lost
    this.setAuthenticationState(undefined);
    this.isAwaitingLoginStatus = false;
    this.previousLoginResponse = undefined;
  }

  override async onUserChanged(_user: User, wasJWTLogin: boolean): Promise<void> {
    this.store.dispatch(UserActions.emptyUsers());
    await this.requestAndUpdateAllUsers();
    // Handle redirects after config data has been loaded
    if (wasJWTLogin) await Utility.delay(500);
    // Move on to default page
    await this.handleDefaultRedirect();
    // Reset previous login response
    this.previousLoginResponse = undefined;
  }

  /**
   * Requests all users from the backend and sets them into the user state
   */
  async requestAndUpdateAllUsers() {
    const response = await this.wsService.sendAndReceive<Object[]>(
      new TDMSWebSocketMessage(UserTopics.getAll, undefined)
    );
    const users = User.fromPlainArray(response.payload);
    this.store.dispatch(UserActions.addMany({ users }));
  }

  /**
   * Given a user, checks the current route permissions and validates they have them. If they do not, they are redirected back
   *  to the default page.
   */
  checkCurrentRoutePermissions(user: User) {
    const currentRoute = this.routerService.currentRouteConfig;
    if (currentRoute && !user.hasPermissions(currentRoute.requiredPermissions, false))
      this.routerService.redirectTo(Route_URLs.default_route, this.routerService.getAllQueryParams());
  }

  /**
   * Handles executing some changes when our current user is updated.
   * @param originalUser The original user reference so we can compare the updated user to
   * @param updatedUser The new updates of a user we want to compare to
   */
  private async onCurrentUserUpdate(originalUser: User | undefined, updatedUser: User | undefined) {
    if (originalUser && updatedUser) {
      this.currentAuthenticatedUser = updatedUser;
      this.checkCurrentRoutePermissions(updatedUser);
      // Handle permission updates
      if (originalUser.admin !== updatedUser.admin)
        if (updatedUser.admin)
          // We are upgrading permissions
          await this.requestAndUpdateAllUsers();
        // We are downgrading
        else {
          this.store.dispatch(UserActions.emptyUsers());
          this.store.dispatch(UserActions.add(updatedUser));
        }
    }
  }

  /**
   * Centralized function to allow updating authentication state
   */
  setAuthenticationState(user: User | undefined, wasJWTLogin: boolean = false) {
    this.currentAuthenticatedUser = user;
    this.onClientConnectAuthenticate.next({ user, wasJWTLogin });
  }

  /**
   * Parses login response from backend server.
   * @param response The backend server response.
   */
  async parseLoginRequest(response: UserLoginReply): Promise<void> {
    this.isAwaitingLoginStatus = false;
    if (response.success) {
      // Handle user data
      if (response.user != null) {
        const user = User.fromPlain(response.user);
        this.setAuthenticationState(user, response.isJWTLogin);
        // Add user to the store
        this.store.dispatch(UserActions.add(user));
        // Set that as the selected user
        this.store.dispatch(UserActions.selectById({ id: user.id }));
      }
      // Handle JWT, if given
      if (response.jwt) this.setJWT(response.jwt);
      // If this is a JWT login, add some time padding to not just skip straight past the login causing bad looking transitions
      if (response.isJWTLogin) this.previousLoginResponse = response;
    } else {
      // Inform of reason of failure
      this.previousLoginResponse = response;
      //  Make sure JWT is emptied incase that caused it
      this.setJWT(undefined);
    }
  }

  /**
   * Handles the default redirect case for when a login is complete. Determines if we need to redirect to a non session URL,
   *  or if we should just go to session selection.
   */
  private async handleDefaultRedirect() {
    const urlToPathTo =
      this.routerService.getQueryParam(RouterParamTypes.originalRequestURL) || Route_URLs.default_route;
    // Verify that if this redirect requires a session, we check that.
    const redirectRequiresSession =
      RouteHelper.findConfigByRoute(urlToPathTo)?.canActivate?.includes(SessionSelectedGuard);
    // If we don't require a session, and we don't have a session, fire the redirect
    if (!redirectRequiresSession) await this.routerService.redirectConsideringParams(false, true, true);
    else await this.routerService.redirectTo(Route_URLs.default_route, this.routerService.getAllQueryParams());
  }

  /**
   * Sends a login request to the backend
   */
  async login(username: string, password: string): Promise<void> {
    this.isAwaitingLoginStatus = true;
    const response = await this.wsService.sendAndReceive<UserLoginReply>(
      new TDMSWebSocketMessage(UserTopics.login, undefined, new UserLoginRequestPassword(username, password))
    );
    return this.parseLoginRequest(response.payload as UserLoginReply);
  }

  /**
   * Sends a login request to the backend utilizing a JWT
   */
  async loginWithJWT(jwt: string): Promise<void> {
    this.isAwaitingLoginStatus = true;
    const response = await this.wsService.sendAndReceive<UserLoginReply>(
      new TDMSWebSocketMessage(UserTopics.loginJWT, undefined, new UserLoginRequestJWT(jwt))
    );
    return this.parseLoginRequest(response.payload);
  }

  /**
   * This function is used to finalize some functionality of TDMS when the logout message is returned
   */
  async logoutComplete(logoutMessage: UserLogoutReply) {
    if (logoutMessage.success) {
      this.setAuthenticationState(undefined);
      // Move on to login page
      this.routerService.redirectTo(Route_URLs.login, undefined);
      // Reset selected user
      this.store.dispatch(UserActions.selectById({ id: undefined }));
      // Wipe JWT
      this.setJWT(undefined);
      // Reset selected session
      this.store.dispatch(SessionActions.select({ session: undefined }));
      // Inform them that they have logged out successfully
      this.snackBar.open(logoutMessage.message, "close", { ...Configuration.SnackbarConfig, duration: 3000 });
    }
  }

  /**
   * Listens for out of sync logout messages so we can handle when the backend forcibly logs us out
   */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<UserLogoutReply>>(UserTopics.logout)
  async logoutReply(data: TDMSWebSocketMessage<UserLogoutReply>) {
    this.logoutComplete(data.payload);
  }

  /**
   * Requests the current user to logout from the websocket
   */
  async logout() {
    const jwtIsValid = this.checkUserJwtValidation();
    this.wsService.send(
      new TDMSWebSocketMessage(
        UserTopics.logout,
        undefined,
        new UserLogoutRequest(!jwtIsValid ? "sessionExpired" : "normal")
      )
    );
  }

  /**
   * Regenerates a kind set of words to greet the user with and returns it..
   *
   * Current dictionary: Hello, Hi, Welcome, Howdy.
   */
  regenerateGreeting() {
    const greetings = ["Hello", "Hi", "Welcome", "Howdy"];
    return greetings[Math.floor(Math.random() * greetings.length)];
  }

  /**
   * Tries to login with JWT if it exists. Returns false if no JWT was available.
   */
  async tryJWTLogin() {
    const jwt = this.getJWT();
    if (jwt != null) return this.loginWithJWT(jwt);
  }

  /**
   * Grabs the JWT from local storage
   */
  getJWT() {
    return localStorage.getItem(this.jwtKey);
  }

  /**
   * Sets the JWT into local storage. You can pass undefined
   *  to remove it from local storage.
   */
  setJWT(jwt: string | undefined) {
    if (jwt == null) localStorage.removeItem(this.jwtKey);
    else localStorage.setItem(this.jwtKey, jwt);
  }

  /**
   * Checks user's local jwt expiration time and compares it to current time.
   * Returns true if timeNow is less than the expiry time in milliseconds.
   * Returns false if jwt is undefined or expired.
   */
  checkUserJwtValidation() {
    let jwt = localStorage.getItem(this.jwtKey);
    let userIsValidated = false;
    if (jwt != null) {
      const jwtPayload = JSON.parse(window.atob(jwt.split(".")[1]));
      let timeNow = Date.now();
      if (timeNow <= jwtPayload.exp * 1000) {
        userIsValidated = true;
      }
    }
    return userIsValidated;
  }

  /**
   * Helper function to lookup a username object by a given id
   * We don't support looking up a complete User object to prevent retrieving
   * any potentially sensitive information about other users.
   * @param id The user id
   * @returns The matched username object or undefined.
   */
  async lookupUsernameById(id: number | undefined): Promise<string | undefined> {
    if (id == null) return undefined;

    const response = await this.wsService.sendAndReceive<UserLookupReply>(
      new TDMSWebSocketMessage(UserTopics.lookupUserData, undefined, new UserLookupRequest(id))
    );

    return response.payload.username;
  }

  async waitForCurrentUser(): Promise<User> {
    return firstValueFrom(this.store.select(selectCurrentUser).pipe(filter((user): user is User => user != null)));
  }

  /**
   * Listens for newly registered users from the backend
   */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<User>>(UserTopics.userRegistered)
  async newUserRegistered(data: TDMSWebSocketMessage<User>) {
    this.store.dispatch(UserActions.add(User.fromPlain(data.payload)));
  }

  /**
   * Listens for user updates
   */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<UserUpdateReply>>(UserTopics.userUpdate)
  async userUpdate(data: TDMSWebSocketMessage<UserUpdateReply>) {
    if (data.payload.user) this.store.dispatch(UserActions.update(User.fromPlain(data.payload.user)));
  }

  /**
   * Sends a user registration request to the backend
   */
  async registerUser(user: User) {
    return (
      await this.wsService.sendAndReceive<UserRegisterReply>(
        new TDMSWebSocketMessage(UserTopics.register, undefined, new UserRegisterRequest(user))
      )
    ).payload;
  }

  /**
   * Asks the backend to lock the account of the given user
   */
  async lockAccount(user: User) {
    this.wsService.send(
      new TDMSWebSocketMessage(UserTopics.lockAccount, undefined, new UserLockUnlockRequest(user.id))
    );
  }

  /**
   * Asks the backend to unlock the account of the give n user
   */
  async unlockAccount(user: User) {
    this.wsService.send(
      new TDMSWebSocketMessage(UserTopics.unlockAccount, undefined, new UserLockUnlockRequest(user.id))
    );
  }

  /**
   * Sends out an update request for the given user so we can update the database
   */
  async updateUser(user: User) {
    return (
      await this.wsService.sendAndReceive<UserUpdateReply>(
        new TDMSWebSocketMessage(UserTopics.userUpdate, undefined, new UserUpdateRequest(user))
      )
    ).payload;
  }

  /**
   * Given some information, updates the password
   */
  async changePassword(targetUser: User, newPassword: string, currentPassword?: string) {
    return (
      await this.wsService.sendAndReceive<UserChangePasswordReply>(
        new TDMSWebSocketMessage(
          UserTopics.changePassword,
          undefined,
          new UserChangePasswordRequest(targetUser, newPassword, currentPassword)
        )
      )
    ).payload;
  }

  /**
   * Returns if a given user is the demo user
   */
  userIsDemoUser(user: User | undefined) {
    if (user == null) return false;
    return this.configService.configData?.demoMode.defaultUsername === user.username;
  }
}
