import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { AngularMaterialModule } from "@tdms/frontend/modules/material/material.module";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { PluginSettingsService } from "@tdms/frontend/modules/settings/services/settings.service";
import { SharedModule } from "@tdms/frontend/modules/shared/shared.module";
import { SettingsDialogComponent } from "./components/dialog/settings.dialog.component";
import { SettingFieldComponent } from "./components/setting-field/setting.field.component";
import { SettingPanelComponent } from "./components/setting-panel/setting.panel.component";
import { settingCollectionReducer } from "./store/collection/collection.reducer";
import { settingValueReducer } from "./store/value/setting.value.reducer";

const COMPONENTS = [SettingsDialogComponent, SettingFieldComponent, SettingPanelComponent];

/** A module that provides session capabilities */
@NgModule({
  declarations: COMPONENTS,
  exports: COMPONENTS,
  imports: [
    CommonModule,
    SharedModule,
    AngularMaterialModule,
    StoreModule.forFeature("settingValues", settingValueReducer),
    StoreModule.forFeature("settingCollections", settingCollectionReducer),
  ],
  providers: [PluginSettingsService, ConfigService],
})
export class SettingsModule {}
