import { DialogRef } from "@angular/cdk/dialog";
import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { UploadOption } from "@tdms/common";
import { TrackedFile } from "@tdms/frontend/modules/data-store/components/uploader/file-tree/models/tracked.file";
import { DataStoreState } from "@tdms/frontend/modules/data-store/models/data.store.state";
import { selectDataStoreUploadOptions } from "@tdms/frontend/modules/data-store/models/store/data.store.selector";
import { UploadService } from "@tdms/frontend/modules/data-store/services/upload.service";
import { Configuration } from "@tdms/frontend/modules/shared/models/config";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { UserService } from "@tdms/frontend/modules/user/services/user.service";
import { cloneDeep } from "lodash-es";

import { RenameSessionFileDialogComponent } from "./rename-session-file-dialog/rename-session-file-dialog.component";
import { SessionFileDialogComponent, sessionFileProperties } from "./session-file-dialog/session-file-dialog.component";
/**
 * Interface to define our sessions. Since we need to wait for a session #, we need to associate
 *  each session (file) with it's associated files (filesAssociatedWithSession).
 */
export interface sessionFile {
  file: TrackedFile;
  filesAssociatedWithSession?: Array<TrackedFile>;
  sessionName: string;
}

export interface errorMessage {
  file: TrackedFile;
  message: any;
}
@Component({
  selector: "bulk-file-upload",
  templateUrl: "./bulk-file-upload.component.html",
  styleUrls: ["./bulk-file-upload.component.scss"],
})
export class BulkFileUploadComponent extends SubscribingComponent {
  /**
   * Tracks if we are uploading content or not
   */
  isUploading = false;

  /**
   * A list of files that we use from the file uploader component.
   *  These are eventually converted to session files.
   */
  files: TrackedFile[] = [];
  /**
   * A list of session files that are to be uploaded. Each session file is comprised of
   *  a tracked file array(filesAssociatedWithSession) and an individual tracked file(file).
   */
  sessionFiles = [] as Array<sessionFile>;

  /**
   * A list of file types that the user can upload.
   */
  uploadTypes: UploadOption[] = [];

  /**
   * Upload progress to display on the spinner
   */
  uploadingProgress: number | undefined = 0;

  /**
   * The text to display when some data is being uploaded.
   */
  uploadingText: string = "";

  /**
   * Overarching boolean to tell other components that we are bulk
   *  uploading files.
   */
  isBulkUpload: boolean = true;

  /**
   * A list of errors recieved when trying to upload a file.
   */
  errorMessages: any = [];

  /**
   * A boolean to show text in the component if a file fails to upload.
   */
  filesErroredDuringUpload: boolean = false;

  constructor(
    private store: Store<DataStoreState>,
    private dialogRef: DialogRef,
    private userService: UserService,
    public sessionFileDialog: MatDialog,
    public editSessionFileNameDialog: MatDialog,
    private uploadService: UploadService,
    private snackBar: MatSnackBar
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.select(selectDataStoreUploadOptions).subscribe((options) => {
      this.uploadTypes = options;
    });
  }

  //Takes files user selected, updates this.files, updates this.sessionFiles on selection event.
  updateFiles(files: TrackedFile[]) {
    this.files = cloneDeep(files);

    let sessionFile: sessionFile = {
      file: files[0],
      filesAssociatedWithSession: [],
      sessionName: "",
    };

    this.files.forEach((file) => (sessionFile.file = file), (sessionFile.filesAssociatedWithSession = []));
    sessionFile.file.isSessionCreation = true;
    this.sessionFiles.push(sessionFile);
  }

  /**
   * Removes files from this.files and this.sessionFiles when user deletes the from he file tree.
   */
  fileRemove(file: TrackedFile) {
    //Check if file is a session file
    let sessionIndex = this.sessionFiles.findIndex((a) => file.file.name === a.file.file.name);
    //Search for associated files if not found in session files.
    if (sessionIndex === -1) {
      this.sessionFiles.forEach((a) => {
        if (a.filesAssociatedWithSession) {
          let index = a.filesAssociatedWithSession.findIndex((b) => b.file.name === file.file.name);
          if (index != -1) {
            a.filesAssociatedWithSession.splice(index, 1);
            this.sessionFiles = cloneDeep(this.sessionFiles);
            return;
          }
        }
      });
    }
    //Delete the sessionFile if it's not found in associated files.
    if (sessionIndex != -1) {
      this.sessionFiles.splice(sessionIndex, 1);
      this.sessionFiles = cloneDeep(this.sessionFiles);
      this.files.splice(sessionIndex, 1);
    }
    if (this.sessionFiles.length === 0) this.filesErroredDuringUpload = false;
  }

  /**
   * Finds the sessionFile the user selected, opens the session-file-dialog component, and
   *  sends that sessionFile data to the new dialog.
   */
  uploadToSession(sessionFile: TrackedFile) {
    let selectedSession = this.sessionFiles.find((a) => a.file.file.name === sessionFile.file.name);

    let dialogRed = this.sessionFileDialog.open(SessionFileDialogComponent, {
      data: {
        selectedSession: selectedSession,
      } as sessionFileProperties,
    });

    dialogRed.afterClosed().subscribe(() => {
      this.sessionFiles = this.sessionFiles.slice();
    });
  }

  /**
   * A TrackedFile from file tree is passed in to open Rename Session File Dialog component.
   *  This function also refreshes the sessionFiles object so that the file tree can refresh, otherwise
   *  Angular change detection doesn't pick up on array instance property changes.
   */
  editSessionName(sessionFile: TrackedFile) {
    let sessionFileToBeRenamed = this.sessionFiles.find((a) => a.file.file.name === sessionFile.file.name);
    if (sessionFileToBeRenamed?.sessionName === "")
      sessionFileToBeRenamed.sessionName = this.uploadService.getSessionNameFromFile(sessionFileToBeRenamed.file);

    let editingRef = this.editSessionFileNameDialog.open(RenameSessionFileDialogComponent, {
      data: sessionFileToBeRenamed,
    });
    //After closing, update angular change detection so file tree can see the name change.
    editingRef.afterClosed().subscribe(() => {
      this.sessionFiles = this.sessionFiles.slice();
    });
  }

  /**
   * A function that refreshes the reference to sessionFiles when uploading associated files to a session.
   */
  sessionFilesRefresh(_event: sessionFile) {
    this.sessionFiles = cloneDeep(this.sessionFiles);
  }

  /**
   * This function searches through the files with error messages, filters out the files that uploaded successfully,
   *  and clears the successful files from this.sessionFiles. Then it changes all the current files within this.sessionFiles
   *  to show an upload error so the file-tree can display the error.
   */
  cleanSuccessfulUploads() {
    let filesFailedToUpload = new Set(this.errorMessages.map((a: { file: any }) => a.file));

    this.sessionFiles = this.sessionFiles.filter((a) => filesFailedToUpload.has(a.file));
    this.sessionFiles.forEach(
      (z) => (
        (z.file.uploadError = true),
        (z.file.errorMessage = this.errorMessages.find((error: errorMessage) => z.file === error.file).message)
      )
    );
  }

  /**
   * Uploads all session files and associated session files to the data store
   *  using the upload service. Returns a snackbar with a message of completion.
   */
  async uploadAllSessions() {
    if (!this.userService.checkUserJwtValidation()) {
      this.dialogRef.close();
      this.userService.logout();
      return;
    }
    let successMessageCount: number = 0;
    this.isUploading = true;
    let startTime = performance.now();
    //Clearing any previous upload errors
    this.errorMessages = [];
    for (const session of this.sessionFiles) {
      try {
        let result = await this.uploadService.uploadToDataStore(
          session.file,
          undefined,
          session.sessionName === "" ? this.uploadService.getSessionNameFromFile(session.file) : session.sessionName,

          (_, totalProgress) => {
            this.uploadingProgress = totalProgress;
            if (totalProgress === 100) {
              this.uploadingText = "Processing files...";
              this.uploadingProgress = undefined;
            }
          },
          false,
          this.isBulkUpload
        );
        //Wait for response with session file id, then upload associated files with id.
        if (result) {
          const sessionId = result[0].sessionIds?.at(0);
          if (sessionId == null) throw new Error("Failed to process adding additional files to a session");

          if (session.filesAssociatedWithSession) {
            await this.uploadService.uploadToDataStore(session.filesAssociatedWithSession, sessionId, undefined);
          }

          if (result[0].sessionIds != null && result[0].sessionIds?.length >= 1)
            successMessageCount += result[0].sessionIds.length;
        }
      } catch (e) {
        let newError: errorMessage = {
          file: session.file,
          message: e,
        };
        this.errorMessages.push(newError);
      }
    }
    this.isUploading = false;
    let endTime = performance.now();
    const timeElapsed = Math.floor(endTime - startTime);
    if (successMessageCount >= 1) {
      const successMessage =
        successMessageCount == 1
          ? `Successfully created ${successMessageCount} session in ${timeElapsed}ms`
          : `Successfully created ${successMessageCount} sessions in ${timeElapsed}ms`;
      this.snackBar.open(successMessage, "close", Configuration.SnackbarConfig);
    }

    //Check to see if files failed to upload. If so display which files errored in file-tree by updating this.sessionFiles
    if (this.errorMessages.length > 0) {
      this.cleanSuccessfulUploads();
      this.filesErroredDuringUpload = true;
    } else {
      this.dialogRef.close();
    }
  }
}
