import { Bookmark, BookmarkType, RelaxationTimeMath } from "@tdms/common";
import { SelectionZoneData } from "../line/line.component";

/**
 * A class that helps apply different mathematical functionality to line chart data.
 */
export class GATechLineMetrics {
  /** A Bookmark type for **max** confidence interval display */
  static readonly ConfidenceIntervalMaxType = BookmarkType.fromPlain({
    name: "CI - Max",
    color: "#AA336A", // Pink
    renderStyle: "horizontal",
    canBeDrawnByUser: false,
    shouldDisplay: true,
  });

  /** A Bookmark type for **min** confidence interval display */
  static readonly ConfidenceIntervalMinType = BookmarkType.fromPlain({
    name: "CI - Min",
    color: "#FFB6C1", // Light Pink
    renderStyle: "horizontal",
    canBeDrawnByUser: false,
    shouldDisplay: true,
  });

  /** A bookmark type for Enaction vertical display */
  static readonly EnactionBookmarkType = BookmarkType.fromPlain({
    name: "Enaction",
    color: "#008000", // Green
    renderStyle: "vertical",
    canBeDrawnByUser: false,
    shouldDisplay: true,
    clickable: false,
    shouldRenderOnLegend: true,
  });

  /** A bookmark type for Adaptation vertical display */
  static readonly AdaptationBookmarkType = BookmarkType.fromPlain({
    name: "Adaptation",
    color: "#90EE90", // Light green
    renderStyle: "vertical",
    canBeDrawnByUser: false,
    shouldDisplay: true,
    clickable: false,
    shouldRenderOnLegend: true,
  });

  /** A bookmark type for Recovery vertical display */
  static readonly RecoveryBookmarkType = BookmarkType.fromPlain({
    name: "Recovery",
    color: "#ADD8E6", // Light blue
    renderStyle: "vertical",
    canBeDrawnByUser: false,
    shouldDisplay: true,
    clickable: false,
    shouldRenderOnLegend: true,
  });

  /**
   * Given some data, returns bookmarks for a line chart to display based on entropy calculations
   * @param leadingText If given, will apply this leading text to these bookmarks
   */
  static getGATechBookmarksForEntropy(
    data: SelectionZoneData,
    minSelection: Date,
    maxSelection: Date,
    leadingText?: string
  ) {
    const bookmarks: Bookmark[] = [];
    try {
      const relaxationTime = RelaxationTimeMath.calculate(data.values);
      const baseCIBookmarkData = Bookmark.fromPlain({
        startTime: minSelection,
        endTime: maxSelection,
        autoAdjustColor: false,
      });
      // Add Confidence Interval data
      bookmarks.push(
        Bookmark.fromPlain({
          ...baseCIBookmarkData,
          bookmarkType: this.ConfidenceIntervalMaxType,
          value: relaxationTime.ciMax,
          associatedRoleName: `${leadingText == null ? "" : leadingText + " "}95%`,
        }),
        Bookmark.fromPlain({
          ...baseCIBookmarkData,
          bookmarkType: this.ConfidenceIntervalMinType,
          value: relaxationTime.ciMin,
          associatedRoleName: `${leadingText == null ? "" : leadingText + " "}95%`,
        })
      );
      if (relaxationTime.enactionCalc && relaxationTime.adaptationCalc && relaxationTime.recoveryCalc) {
        bookmarks.push(
          Bookmark.fromPlain({
            bookmarkType: this.EnactionBookmarkType,
            startTime: new Date(minSelection.getTime() + relaxationTime.enactionCalc.enaction),
            value: relaxationTime.enactionCalc.enaction,
            associatedRoleName: leadingText,
            autoAdjustColor: false,
          })
        );
        bookmarks.push(
          Bookmark.fromPlain({
            bookmarkType: this.AdaptationBookmarkType,
            startTime: new Date(minSelection.getTime() + relaxationTime.adaptationCalc.adaptation),
            value: relaxationTime.adaptationCalc.adaptation,
            associatedRoleName: leadingText,
            autoAdjustColor: false,
          })
        );
        bookmarks.push(
          Bookmark.fromPlain({
            bookmarkType: this.RecoveryBookmarkType,
            startTime: new Date(minSelection.getTime() + relaxationTime.recoveryCalc.recovery),
            value: relaxationTime.recoveryCalc.recovery,
            associatedRoleName: leadingText,
            autoAdjustColor: false,
          })
        );
      }
    } catch {
    } finally {
      return bookmarks;
    }
  }
}
