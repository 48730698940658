<shared-dialog-wrapper class="change-password-dialog" header="Change Password">
  <div class="change-password-container">
    <!-- Help text -->
    <div class="help">Fill in the information below and click update</div>
    <!-- Alert message -->
    <div *ngIf="currentErrorMessage" class="alert-container">
      <span class="text">{{ currentErrorMessage }}</span>
    </div>
    <!-- Overarching form -->
    <form [formGroup]="formGroup" (ngSubmit)="submit()" class="form">
      <!-- Current Password -->
      <mat-form-field color="accent" *ngIf="!adminMode">
        <mat-label>Current Password</mat-label>
        <input
          matInput
          autocomplete="current-password"
          formControlName="currentPassword"
          type="password"
          (ngModelChange)="onFieldEdit()"
        />
      </mat-form-field>

      <!-- New Password -->
      <mat-form-field color="accent">
        <mat-label>New Password</mat-label>
        <input
          matInput
          autocomplete="new-password"
          formControlName="password"
          (ngModelChange)="onFieldEdit()"
          type="password"
        />
        <mat-error *ngIf="formGroup.hasError('notSame', 'password')">Passwords do not match</mat-error>
      </mat-form-field>

      <!-- Last Name -->
      <mat-form-field color="accent">
        <mat-label>Confirm New Password</mat-label>
        <input
          matInput
          autocomplete="new-password"
          formControlName="confirmPassword"
          (ngModelChange)="onFieldEdit()"
          type="password"
        />
        <mat-error *ngIf="formGroup.hasError('notSame', 'confirmPassword')">Passwords do not match</mat-error>
      </mat-form-field>

      <!-- Submit button -->
      <div class="buttons">
        <button mat-raised-button color="accent" type="submit" [disabled]="!formGroup.valid">Update</button>
      </div>
    </form>
  </div>
</shared-dialog-wrapper>
