import { Type } from "class-transformer";
import { TDMSBase } from "../base";
import { LineChartData } from "./line.chart.data";

/** Chart series data for waveforms that adds additional data for the waveform display. */
export class WaveformChartSeries extends TDMSBase {
  /**
   * The value to actually display on the chart
   */
  value: number;
  /**
   * The x axis separator value
   */
  @Type(() => Date)
  name: Date;

  /** How many seconds into the wave form this value takes place */
  seconds: number;

  /** The color that this specific chart series element should use */
  color: string | undefined;

  constructor(value: number, name: Date, seconds: number, color?: string) {
    super();
    this.value = value;
    this.name = name;
    this.seconds = seconds;
    this.color = color;
  }
}

/**
 * Waveform chart data structure check out {@link LineChartData} for more info
 */
export class WaveformChartData extends LineChartData<WaveformChartSeries> {}
