import { BitArray } from "easy-bits";

export type Vector = Array<number>;
export type Matrix = Array<Vector>;
export type Scalar = number;
export type VectorOrMatrix = Array<Scalar | Vector>;

export class ChannelBitField {
  bitField!: BitArray<number>;
  timestamp!: Date;

  /**
   * We can't use the builtin BitArray valueOf function because it only allows 31 bits for a number,
   * which is incorrect as javascript numbers are 64 bit by default.
   * So, we adapt the original valueOf function here removing that 31 bit check.
   * https://github.com/aesy/easy-bits/blob/master/src/BitArray.js#L311
   * @returns
   */
  valueOf(): number {
    return this.bitField.toArray().reduce((prev, curr) => {
      prev <<= 1;

      if (curr) {
        prev++;
      }

      return prev;
    }, 0);
  }

  static fromBitArray(bitArray: BitArray<number>, ts: Date): ChannelBitField {
    return this.fromArray(bitArray.toArray(), ts);
  }

  static fromArray(array: any[], ts: Date): ChannelBitField {
    const channelBitField = new ChannelBitField();
    channelBitField.bitField = BitArray.fromArray(array);
    channelBitField.timestamp = ts;
    return channelBitField;
  }
}

/**
 * An overarching math module for generic functions
 */
export module MetricMath {
  /**
   * Test if the variable is a one or two-dimensional array (i.e. array of arrays).
   */
  export function isMatrix(vectorOrMatrix: VectorOrMatrix): boolean {
    return vectorOrMatrix.every((element) => Array.isArray(element));
  }

  /**
   * Given a matrix, transposes that matrix and returns it.
   *
   * In linear algebra, the transpose of a matrix is an operator which flips a matrix over its diagonal;
   *    that is, it switches the row and column indices of the matrix A by producing another matrix, often denoted by Aᵀ.
   */
  export function transpose(matrix: Matrix) {
    return matrix[0].map((_col, i) => matrix.map((row) => row[i]));
  }

  /**
   * Given a matrix, computes the average mutual information and correlation of univiriate or
   *    bivariate time series for different values of time lag
   */
  export function ami(xy: Matrix, nBins: Matrix, nLags: number): [Matrix, Matrix] {
    let yBin: number;
    let xBin: number;
    let y: Vector = [];
    let x: Vector = [];
    try {
      if (arguments.length > 3 || arguments.length < 3) {
        throw "Error in ami";
      }
    } catch (e) {
      if (arguments.length < 3) {
        console.error("Insufficient no of arguments");
      } else {
        console.error("Too many no of arguments");
      }
      return [[], []];
    }
    let m = xy.length;
    let n = xy[0].length;
    if (n > m) {
      xy = MetricMath.transpose(xy);
      const temp = n;
      n = m;
      m = temp;
    }
    try {
      if (n > 2) {
        throw "Error in ami";
      } else if (n == 2) {
        x = xy.map((d) => d[0]);
        y = xy.map((d) => d[1]);
      } else if (m == 1 || n == 1) {
        const temp = MetricMath.transpose(xy);
        y = temp[0];
        x = temp[0];
      }
    } catch (e) {
      console.error("Invalid time series data: Time series should be univariate or bivariate");
      return [[], []];
    }
    let nBinsRowSize = nBins.length;
    let nBinsColSize = nBins[0].length;
    if (nBinsRowSize < nBinsColSize) {
      nBins = MetricMath.transpose(nBins);
      nBinsRowSize = nBins.length;
      nBinsColSize = nBins[0].length;
    }
    try {
      if (nBinsRowSize > 2 || nBinsColSize > 1) {
        throw "Error in ami";
      } else if (nBinsRowSize == 2 && n == 2) {
        xBin = Math.floor(nBins[0][0]);
        yBin = Math.floor(nBins[1][0]);
      } else if ((nBinsRowSize == 1 && n == 2) || n == 1) {
        xBin = Math.floor(nBins[0][0]);
        yBin = xBin;
      } else {
        throw "Error in ami";
      }
    } catch (e) {
      console.error("Invalid bin size: It should be either vector of 2 elements or scalar");
      return [[], []];
    }
    try {
      if (nLags < 0) {
        throw "Error in ami";
      }
      if (nLags > m) {
        throw "Error in ami";
      }
    } catch (e) {
      if (nLags < 0) {
        console.error("Invalid lag: It should be a positive scalar");
      }
      if (nLags > m) {
        console.error("Invalid lag: It should not be greater than length of time series data");
      }
      return [[], []];
    }
    nLags = Math.floor(nLags);
    const amis = Array(1);
    amis[0] = Array(nLags + 1).fill(0);
    const corrs = Array(1);
    corrs[0] = Array(nLags + 1).fill(0);
    let pxy: Matrix;
    for (let i = 0; i < nLags + 1; i++) {
      const xlag = x.slice(0, x.length - i);
      const ylag = y.slice(i, x.length);
      const [px, xBinComputed] = MetricMath.prob([xlag], xBin);
      const [py, yBinComputed] = MetricMath.prob([ylag], yBin);
      const ab = Array(xlag.length);
      for (let j = 0; j < xlag.length; j++) {
        ab[j] = Array(2).fill(0);
        ab[j][0] = xlag[j];
        ab[j][1] = ylag[j];
      }
      pxy = MetricMath.probxy(ab, xBinComputed, yBinComputed);
      let amixy = 0;
      for (let j = 0; j < xBinComputed; j++) {
        for (let k = 0; k < yBinComputed; k++) {
          if (pxy[j][k] != 0) {
            amixy = amixy + pxy[j][k] * Math.log2(pxy[j][k] / (px[j] * py[k]));
            amixy = Math.round(amixy * 10000) / 10000;
          }
        }
      }
      amis[0][i] = amixy;
      corrs[0][i] = Math.round(MetricMath.corrcoef(xlag, ylag, xlag.length) * 10000) / 10000;
    }
    return [MetricMath.transpose(amis), MetricMath.transpose(corrs)];
  }

  /**
   *
   */
  export function corrcoef(X: Vector, Y: Vector, n: number): number {
    let sum_X = 0,
      sum_Y = 0,
      sum_XY = 0;
    let squareSum_X = 0,
      squareSum_Y = 0;

    for (let i = 0; i < n; i++) {
      sum_X = sum_X + X[i];
      sum_Y = sum_Y + Y[i];
      sum_XY = sum_XY + X[i] * Y[i];

      squareSum_X = squareSum_X + X[i] * X[i];
      squareSum_Y = squareSum_Y + Y[i] * Y[i];
    }

    const corr =
      (n * sum_XY - sum_X * sum_Y) / Math.sqrt((n * squareSum_X - sum_X * sum_X) * (n * squareSum_Y - sum_Y * sum_Y));

    return corr;
  }

  /**
   *
   */
  export function prob(...args: (number | any[])[]): [Vector, number] {
    let tmpBin;
    let maxBins: number;
    try {
      if (args.length < 1 || args.length > 2) {
        throw "Error in prob";
      }
    } catch (e) {
      if (args.length < 1) {
        console.error("Insufficient no of arguments");
      } else {
        console.error("Too many no of arguments");
      }
    }
    const y = args[0];
    if (args.length == 1) {
      maxBins = 10;
    } else {
      maxBins = args[1] as number;
    }
    if (!Array.isArray(y)) {
      console.error("Y should be a vector");
      throw "Error in prob";
    }
    let preBin = 0;
    let isNotZeroBin = false;
    let iter = 0;
    let cBin: number = maxBins;
    let zeroBin = 0;
    let nonZeroBin = 0;
    while (preBin != cBin) {
      const zeroDistribution = MetricMath.isZeroDistribution(y, cBin);
      iter = iter + 1;
      if (!zeroDistribution) {
        if (iter == 1) {
          break;
        }
        tmpBin = cBin;
        nonZeroBin = cBin;
        cBin = Math.floor((zeroBin + nonZeroBin) / 2);
        preBin = tmpBin;
        isNotZeroBin = true;
      } else {
        if (!isNotZeroBin) {
          preBin = cBin;
          zeroBin = cBin;
          cBin = Math.floor(cBin / 2);
        } else {
          tmpBin = cBin;
          zeroBin = cBin;
          cBin = Math.floor((zeroBin + nonZeroBin) / 2);
          preBin = tmpBin;
        }
      }
    }
    const nBins = cBin;
    const py = MetricMath.rhist(y, nBins);
    return [py[0], nBins];
  }

  /**
   *
   */
  export function probxy(...args: (Matrix | Vector | number)[]): Matrix {
    let count: number;
    let edgeX: Vector = [];
    let nBinsY: any;
    let nBinsX: any;
    let X: Vector;
    let Y: Vector;
    try {
      if (args.length < 1 || args.length > 3) {
        throw "Error in probxy";
      }
    } catch (e) {
      if (args.length < 1) {
        console.error("Insufficient no of arguments");
      } else {
        console.error("Too many no of arguments");
      }
      return [];
    }
    let xy: Matrix = args[0] as Matrix;
    let m = xy.length;
    let n = xy[0].length;
    if (n > m) {
      xy = MetricMath.transpose(xy);
      m = xy.length;
      n = xy[0].length;
    }
    try {
      if (n != 2) {
        throw "Error in probxy";
      } else {
        X = xy.map((d) => d[0]);
        Y = xy.map((d) => d[1]);
      }
    } catch (e) {
      console.error("Invalid data size: XY should be two column vector");
      return [];
    }
    if (args.length - 1 == 0) {
      nBinsX = 10;
      nBinsY = 10;
    } else if (args.length - 2 == 0) {
      nBinsX = args[1];
      nBinsY = 10;
    } else {
      nBinsX = args[1];
      nBinsY = args[2];
    }
    if (!Array.isArray(nBinsX) && nBinsX > 0) {
      edgeX = MetricMath.computeEdge(X, nBinsX);
    } else if (nBinsX.length == 1 && nBinsX[0].length == 1 && nBinsX[0][0] > 0) {
      edgeX = MetricMath.computeEdge(X, nBinsX[0][0]);
    } else if (nBinsX.length == 1 || nBinsX[0].length == 1) {
      edgeX = [];
      count = 0;
      for (let i = 0; i < nBinsX[0].length; i++) {
        for (let j = 0; j < nBinsX.length; j++) {
          edgeX[count] = nBinsX[j][i];
          count++;
        }
      }
      nBinsX = edgeX.length - 1;
    }
    let edgeY = [];
    if (!Array.isArray(nBinsY) && nBinsY > 0) {
      edgeY = MetricMath.computeEdge(Y, nBinsY);
    } else if (nBinsY.length == 1 && nBinsY[0].length == 1 && nBinsY[0][0] > 0) {
      edgeY = MetricMath.computeEdge(Y, nBinsY[0][0]);
    } else if (nBinsY.length == 1 || nBinsY[0].length == 1) {
      edgeY = [];
      count = 0;
      for (let i = 0; i < nBinsY[0].length; i++) {
        for (let j = 0; j < nBinsY.length; j++) {
          edgeY[count] = nBinsY[j][i];
          count++;
        }
      }
      nBinsY = edgeY.length - 1;
    }
    const nn = Array(nBinsX);
    for (let i = 0; i < nBinsX; i++) {
      nn[i] = Array(nBinsY).fill(0);
    }
    for (let i = 0; i < nBinsX; i++) {
      const yFound = [];
      let k = 0;
      for (let j = 0; j < X.length; j++) {
        if (X[j] >= edgeX[i] && X[j] < edgeX[i + 1]) {
          yFound[k] = Y[j];
          k++;
        }
      }
      let nvec: Vector = Array(edgeY.length).fill(0);
      if (yFound.length != 0) {
        for (let j = 0; j < yFound.length; j++) {
          for (let p = 0; p < edgeY.length - 1; p++) {
            if (yFound[j] >= edgeY[p] && yFound[j] < edgeY[p + 1]) {
              nvec[p] = nvec[p] + 1;
            }
          }
        }
        nvec[nvec.length - 2] = nvec[nvec.length - 2] + nvec[nvec.length - 1];
      }
      nvec = nvec.slice(0, nvec.length - 1);
      for (let j = 0; j < nBinsY; j++) {
        nn[i][j] = nvec[j];
      }
    }
    const pxy: Matrix = new Array(nn.length);
    for (let i = 0; i < nn.length; i++) {
      pxy[i] = new Array(nn[0].length).fill(0);
      for (let j = 0; j < nn[0].length; j++) {
        pxy[i][j] = nn[i][j] / X.length;
        pxy[i][j] = Math.round(pxy[i][j] * 10000) / 10000;
      }
    }
    return pxy;
  }

  /**
   *
   */
  export function computeEdge(...args: (number | Vector)[]): Vector {
    let nBins: number;
    try {
      if (args.length < 1 || args.length > 2) {
        throw "Error in ComputeEdge";
      }
    } catch (e) {
      if (args.length < 1) {
        console.error("Insufficient no of arguments");
      } else {
        console.error("Too many no of arguments");
      }
    }
    const x = args[0] as Vector;
    if (args.length == 1) {
      nBins = 10;
    } else {
      nBins = args[1] as number;
    }
    const minX = Math.min(...x);
    const maxX = Math.max(...x);
    const binwidth = (maxX - minX) / nBins;
    const edge = [];
    for (let i = 1; i < nBins; i++) {
      edge[i] = ((minX + binwidth * i) * 10000) / 10000;
    }
    edge[0] = Number.NEGATIVE_INFINITY;
    edge[nBins] = Number.POSITIVE_INFINITY;
    return edge;
  }

  /**
   *
   */
  export function isZeroDistribution(vec: Vector, nBins: number) {
    const [nn, _x] = MetricMath.rhist(vec, nBins);
    let z = 0;
    for (let i = 0; i < nn.length; i++) {
      if (nn[i] == 0) {
        z++;
      }
    }
    if (z > 0) {
      return true;
    }
    return false;
  }

  /**
   *
   */
  export function rhist(...args: (Matrix | Vector | any)[]): Matrix {
    let x: number;
    try {
      if (args.length < 1) {
        throw "Error in rhist: args.length < 1";
      }
    } catch (e) {
      console.error("Insufficient no of arguments");
    }
    // let cax;
    if (args[0] == "Axes") {
      // cax = args[0];
      args = args.slice(1, args.length);
    }
    const nargs = args.length;
    const y = args[0];
    if (nargs == 1) {
      x = 10;
    } else {
      x = args[1];
    }
    const Y = [];
    let count = 0;
    for (let i = 0; i < y.length; i++) {
      for (let j = 0; j < y[0].length; j++) {
        Y[count] = y[i][j];
        count++;
      }
    }
    const m = Y.length;
    let nn: number[] = Array(x + 1).fill(0);
    const centers: number[] = [];
    const bins: number[] = [];
    const min = Math.min(...Y);
    const max = Math.max(...Y);
    const binWidth = (max - min) / x;
    bins[0] = Number.NEGATIVE_INFINITY;
    for (let i = 0; i <= x; i++) {
      bins[i + 1] = min + binWidth * i;
    }
    for (let i = 0; i < bins.length - 1; i++) {
      for (let j = 0; j < Y.length; j++) {
        if (Y[j] > bins[i] && Y[j] <= bins[i + 1]) {
          nn[i] = nn[i] + 1;
        }
      }
    }
    for (let i = 1; i < bins.length - 1; i++) {
      centers[i - 1] = (bins[i] + bins[i + 1]) / 2;
      centers[i - 1] = Math.round(centers[i - 1] * 10000) / 10000;
    }
    nn[1] = nn[0] + nn[1];
    nn = nn.slice(1, bins.length);
    for (let i = 0; i < nn.length; i++) {
      nn[i] = nn[i] / m;
      nn[i] = Math.round(nn[i] * 10000) / 10000;
    }
    if (nargs == 3) {
      for (let i = 0; i < nn.length; i++) {
        nn[i] = nn[i] / binWidth;
        nn[i] = Math.round(nn[i] * 10000) / 10000;
      }
    }
    return [nn, centers];
  }
}
