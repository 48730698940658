import { DialogRef } from "@angular/cdk/dialog";
import { Component } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomTypes } from "@tdms/common";
import { TrackedFile } from "@tdms/frontend/modules/data-store/components/uploader/file-tree/models/tracked.file";
import { UploadService } from "@tdms/frontend/modules/data-store/services/upload.service";
import { Configuration } from "@tdms/frontend/modules/shared/models/config";
import { UserService } from "@tdms/frontend/modules/user/services/user.service";
import { cloneDeep } from "lodash-es";

/**
 * A component that will provide default options when creating new sessions
 */
@Component({
  selector: "data-store-session-creation",
  templateUrl: "./session-creation.component.html",
  styleUrls: ["./session-creation.component.scss"],
})
export class SessionCreationComponent {
  /**
   * Extra files to be uploaded alongside the session
   */
  currentExtraFiles: TrackedFile[] = [];

  /** Files that should be displayed in extra files. This will include `sessionCreationFiles` */
  displayExtraFiles: TrackedFile[] = [];

  /**
   * The file that is being treated as a session creation
   */
  sessionCreationFiles: TrackedFile[] = [];

  /**
   * Tracks if we are uploading content or not
   */
  isUploading = false;

  /**
   * Upload progress to display on the spinner
   */
  uploadingProgress: number | undefined = 0;

  /**
   * The text to display when some data is being uploaded.
   */
  uploadingText: string = "";

  /**
   * The session name or prefix to use when uploading a session
   */
  sessionNameOrPrefix: string = "";

  constructor(
    private uploadService: UploadService,
    private snackBar: MatSnackBar,
    private dialogRef: DialogRef,
    private userService: UserService
  ) {}

  /**
   * Updates the extra files defined by the property name to be displayed in the file tree
   */
  updateExtraFiles(property: CustomTypes.PropertyNames<SessionCreationComponent, TrackedFile[]>, files: TrackedFile[]) {
    /**
     * Auto set the name in two cases
     * 1. The name is = the previous file name
     * 2. The name is not already filled in. We don't want to override if they type the name then select the file.
     */
    if (property === "sessionCreationFiles") {
      files.map((x) => {
        x.isSessionCreation = true;
        x.removable = false;
      });
      const lastFileName = this.uploadService.getSessionNameFromFile(this.sessionCreationFiles[0]);
      // If the current name is the last file name, feel free to update
      if (this.sessionNameOrPrefix === lastFileName || !this.sessionNameOrPrefix)
        this.sessionNameOrPrefix = this.uploadService.getSessionNameFromFile(files[0]);
    }
    // Clean extra files to not display the session creation files
    if (property === "currentExtraFiles") files = files.filter((x) => !x.isSessionCreation);
    // Clone deep to forcibly change the reference
    this[property] = cloneDeep(files);
    // Set custom display files to include session creation content
    if (property === "currentExtraFiles" || property === "sessionCreationFiles")
      this.displayExtraFiles = this.getExtraFiles();
  }

  /** Returns the extra files to display in the extra files component */
  getExtraFiles() {
    const sessionCreationFiles = cloneDeep(this.sessionCreationFiles);
    return sessionCreationFiles.concat(this.currentExtraFiles);
  }

  /**
   * Returns if the submit button should be clickable or not
   */
  get submitButtonAvailable() {
    return (
      this.sessionCreationFiles.length !== 0 && this.sessionNameOrPrefix != null && this.sessionNameOrPrefix !== ""
    );
  }

  /**
   * Returns the validation message to display in the event we cannot create a session
   */
  get validationMessage() {
    if (!this.sessionNameOrPrefix) return "Session name is required";
    else if (this.sessionCreationFiles.length === 0) return "A session file must be uploaded";
    return undefined;
  }

  /**
   * Fires a callback to attempt to create a session with the extra files
   */
  async submitCallback() {
    // Ignore if the form isn't complete or if user's jwt isn't authenticated
    if (!this.submitButtonAvailable || !this.userService.checkUserJwtValidation()) {
      this.dialogRef.close();
      this.userService.logout();
      return;
    }
    try {
      this.isUploading = true;
      // Upload session file so we can get the session info before uploading the actual extra files
      this.uploadingText = "Uploading session creation file...";
      const result = await this.uploadService.uploadToDataStore(
        this.sessionCreationFiles,
        undefined,
        this.sessionNameOrPrefix,
        (_, totalProgress) => {
          this.uploadingProgress = totalProgress;
          if (totalProgress === 100) {
            this.uploadingText = "Processing file...";
            this.uploadingProgress = undefined;
          }
        }
      );
      // We assume that we only had one response and only want the first response session
      if (result) {
        const sessionId = result[0].sessionIds?.at(0);
        if (sessionId == null) throw new Error("Failed to process adding additional files to session.");
        // Upload the remaining extra files
        if (this.currentExtraFiles.length > 0) {
          this.uploadingText = `Uploading ${this.currentExtraFiles.length} extra ${
            this.currentExtraFiles.length > 1 ? "files" : "file"
          }`;
          this.uploadingProgress = 0;
          await this.uploadService.uploadToDataStore(
            this.currentExtraFiles,
            sessionId,
            undefined,
            (_, totalProgress) => (this.uploadingProgress = totalProgress),
            false
          );
        }
        // Wrap up by closing the uploader and alerting the user of the status
        this.dialogRef.close();
        const successMessage =
          result[0].sessionIds != null && result[0].sessionIds?.length > 1
            ? `Successfully created ${result[0].sessionIds.length} sessions in ${result[0].timeElapsed}ms`
            : `Successfully created a new session in ${result[0].timeElapsed}ms ${
                this.currentExtraFiles.length > 0
                  ? `with ${this.currentExtraFiles.length} extra ${
                      this.currentExtraFiles.length > 1 ? "files" : "file"
                    }`
                  : ""
              }`;
        this.snackBar.open(successMessage, "close", Configuration.SnackbarConfig);
      }
    } catch (e) {
      this.snackBar.open(`${e}`, "close", Configuration.ErrorSnackbarConfig);
    } finally {
      this.isUploading = false;
    }
  }
}
