import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DataStoreAccessLog, DataStoreFile, DataStoreTopics, TDMSWebSocketMessage } from "@tdms/common";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import {
  NewAccessDialogComponent,
  NewAccessDialogProperties,
} from "@tdms/frontend/modules/data-store/components/tabs/access-log/new-access-dialog/new-access-dialog.component";
import { SessionService } from "@tdms/frontend/modules/session/services/session.service";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { UserService } from "@tdms/frontend/modules/user/services/user.service";

/**
 * A service that handles pulling access log information and parsing into the display format for the frontend access log tab.
 */
@Injectable({ providedIn: "root" })
export class AccessLogService extends Service {
  constructor(
    private wsService: WebSocketService,
    private sessionService: SessionService,
    private userService: UserService,
    private dialog: MatDialog
  ) {
    super();
  }

  /**
   * Load the current access log data from the backend.
   * @returns The loaded access log data.
   */
  async getAccessLog() {
    await this.sessionService.waitForSessionLoad();

    const response = await this.wsService.sendAndReceive<DataStoreAccessLog[]>(
      new TDMSWebSocketMessage(DataStoreTopics.getAccessLog, undefined)
    );

    return response.payload.map((log) => DataStoreAccessLog.fromPlain(log));
  }

  /**
   * Show the access log dialog, requiring the user to enter a reason for data access.
   * @param files The data store files that are being requested
   */
  async askAccessReason(files: DataStoreFile[]): Promise<string | undefined> {
    for (let file of files)
      if (file.fileName == null)
        throw new Error(`Cannot create access request for file that doesn't have a file name: ${file.filePath}`);

    const dateAccessed = new Date();
    const username = (await this.userService.waitForCurrentUser()).username;

    return new Promise((resolve, _reject) => {
      const ref = this.dialog.open(NewAccessDialogComponent, {
        disableClose: true,
        data: {
          filesAccessed: files,
          username: username,
          dateAccessed: dateAccessed,
          onDownloadCancelled: () => {
            ref.close();
            resolve(undefined);
          },
          onReasonProvided: (reason: string) => {
            if (reason == "") {
              return;
            }

            ref.close();
            resolve(reason);
          },
        } as NewAccessDialogProperties,
      });
    });
  }
}
