import { createReducer, on } from "@ngrx/store";
import { SettingValuesActions } from "./setting.value.action";
import { SettingValue } from "./setting.value.model";

export const initialState: ReadonlyArray<SettingValue> = [];

export const settingValueReducer = createReducer(
  initialState,
  on(SettingValuesActions.addSettings, (state, data) => {
    return [
      // Include all existing setting values, save for the new ones that are being added
      // New values are filtered out by a key-pair of (collectionId, setting.name).
      ...state.filter(
        (setting) =>
          data.settings.find(
            (newSetting) => setting.collectionId === newSetting.collectionId && setting.name === newSetting.name
          ) == null
      ),
      ...data.settings,
    ];
  }),
  on(SettingValuesActions.updateSetting, (state, data) => {
    // Find the existing setting value, keyed by (collectionId, setting.name).
    const current = state.find((setting) => setting.name == data.name && setting.collectionId == data.collectionId);
    if (current == null) return state;

    return [
      // Filter out the setting we are updating from our existing list.
      ...state.filter((setting) => setting.collectionId !== data.collectionId || setting.name !== data.name),
      { ...current, ...data },
    ];
  })
);
