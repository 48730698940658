import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PopulatedSearchOption, SearchableOption } from "@tdms/common";
import { SearchService } from "@tdms/frontend/modules/data-store/services/search.service";
import { DialogWrapperComponent } from "@tdms/frontend/modules/shared/components";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import autoBind from "auto-bind";
import { QueryBuilderDialogComponent } from "../query-builder/query-builder-dialog/query-builder-dialog.component";

/**
 * This component is the query bar shown above the home page and the search tab of the data store.
 * It allows users to interactively build a query of data and then execute a search.
 */
@Component({
  selector: "query-bar",
  templateUrl: "./query-bar.component.html",
  styleUrls: ["./query-bar.component.scss"],
})
export class QueryBarComponent extends SubscribingComponent implements OnInit {
  /**
   * This is called whenever the search button is clicked.
   * Search options provided are not validated by this component.
   * It is up to the listening component to validate.
   */
  @Input() onSearchStarted!: (options: PopulatedSearchOption[]) => void;
  /**
   * This should be a list of search options that failed to validate as expected.
   * This should be updated in response to an onSearchStarted invocation and should contain items from the list passed to that invocation.
   */
  @Input() formErrors: PopulatedSearchOption[] = [];

  @Input() isSearching: boolean = false;

  availableSearchOptions?: SearchableOption[];
  currentSearchTerms: PopulatedSearchOption[] = [];

  constructor(private searchService: SearchService, private dialog: MatDialog) {
    super();
    autoBind(this);
    // Update search options incase dropdown options have changed between initialization and this page loading
    this.searchService.loadSearchOptions();
  }

  ngOnInit(): void {
    this.addSubscription(
      this.searchService.observeSearchOptions().subscribe((options) => (this.currentSearchTerms = options))
    );
    this.addSubscription(
      this.searchService.observeAvailableSearchOptions().subscribe((options) => (this.availableSearchOptions = options))
    );
  }

  onSearchButtonClicked() {
    this.onSearchStarted(this.currentSearchTerms);
  }

  onSearchTermDeleted(option: PopulatedSearchOption) {
    this.searchService.onSearchOptionDeleted(option);
  }

  onSearchTermChanged(option: PopulatedSearchOption) {
    this.searchService.onSearchOptionUpdated(option);
  }

  onNewSearchTermAdded(option: SearchableOption) {
    this.searchService.onSearchOptionAdded(option);
  }

  /**
   * Display the advanced query builder dialog, and listen for search start clicked.
   * The search terms and options are all stored on the service, so we will get any updates made here from the service.
   */
  onAdvancedButtonClicked() {
    const ref = this.dialog.open(QueryBuilderDialogComponent, {
      ...DialogWrapperComponent.getDefaultOptions(),
      data: {
        formErrors: this.formErrors,
        isSearching: this.isSearching,
        onSearchStarted: (options: PopulatedSearchOption[]) => {
          ref.close();
          this.onSearchStarted(options);
        },
      },
    });
  }
}
