/**
 * The display object to show on the custom legend
 */
export class LegendDisplayObject {
  /**
   * The display name
   */
  name!: string;

  /**
   * If given, this will be used for color lookup and comparison instead of the display name
   */
  originalNameMapping?: string | undefined;

  /**
   * If this value should be shown
   */
  displayed!: boolean;

  constructor(options: Partial<LegendDisplayObject>) {
    Object.assign(this, options);
  }
}
