import { createActionGroup, props } from "@ngrx/store";
import { User } from "@tdms/common";
/**
 * Supported NGRX store user actions
 */
export const UserActions = createActionGroup({
  source: "User",
  events: {
    Add: props<User>(),
    "Add Many": props<{ users: User[] }>(),
    Update: props<User>(),
    Delete: props<User>(),
    "Select By ID": props<{ id: number | undefined }>(),
    "Empty Users": props,
  },
});
