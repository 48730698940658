<shared-dialog-wrapper
  class="role-mapping-wrapper"
  header="Role Mapping"
  [backdropClickCallback]="properties.onCancel"
  [closeDialogCallback]="properties.onCancel"
>
  <div class="role-mapping-container">
    <div class="header instructions">
      {{
        properties.request.displayMessage ||
          "The file uploaded doesn't contain the necessary metadata to create sessions. Please provide the additional information below by customizing role names and associated sessions."
      }}
    </div>

    <div class="info-container">
      <!-- Render each roles set of information -->
      <div class="info" *ngFor="let mapping of properties.request.mappings; index as i">
        <!-- Channel -->
        <mat-form-field class="channel field" appearance="fill" color="accent" *ngIf="mapping.mapping">
          <mat-label>Channel</mat-label>
          <input
            matInput
            [(ngModel)]="mapping.mapping.channel"
            required
            [disabled]="!properties.request.allowChannelEdits"
            type="number"
            min="0"
            oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
          />
        </mat-form-field>
        <!-- Grouping -->
        <mat-form-field class="group field" appearance="fill" color="accent">
          <mat-label>Session Grouping</mat-label>
          <input
            matInput
            [(ngModel)]="mapping.mapping.sessionGrouping"
            required
            [disabled]="!properties.request.allowSessionGroupEdits"
          />
        </mat-form-field>
        <!-- Name -->
        <mat-form-field class="name field" appearance="fill" color="accent">
          <mat-label>Name</mat-label>
          <input matInput [(ngModel)]="mapping.mapping.userGivenName" required />
        </mat-form-field>
        <!-- User Association -->
        <shared-improved-select
          [selectedValue]="getModifiedUserById(mapping)"
          [selectedValueChanged]="handleUserUpdate.bind(this, mapping)"
          [dropdownOptions]="availableUsers"
          dropdownName="Associated User"
          [filterEnabled]="true"
          [paginatorEnabled]="true"
          [itemsPerPage]="5"
          [clearable]="false"
          [shouldCleanName]="false"
          [prefixButtons]="
            canCreateUsers
              ? [
                  {
                    icon: 'add',
                    callback: openUserCreation.bind(this, mapping),
                    tooltip: 'Create New User',
                    configDirective: { path: 'user.allowCreation', tooltip: 'User creation is disabled' }
                  }
                ]
              : []
          "
          class="user-selector"
        ></shared-improved-select>
        <!-- Playback, if available -->
        <shared-audio-player
          class="audio-player"
          *ngIf="mapping.mapping.playbackPath && audioStore[mapping.mapping.playbackPath]"
          [src]="audioStore[mapping.mapping.playbackPath].url"
        ></shared-audio-player>
      </div>
    </div>

    <!-- Buttons -->
    <div class="buttons" *ngVar="validityError as validityMessage">
      <!-- Cancel -->
      <button mat-raised-button color="warn" (click)="properties.onCancel()">Cancel</button>
      <!-- Submit -->
      <button
        mat-raised-button
        color="accent"
        (click)="properties.onComplete()"
        [disabled]="validityMessage != null"
        [matTooltip]="validityMessage || ''"
      >
        Submit
      </button>
    </div>
  </div>
</shared-dialog-wrapper>
