import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";
import { RouteConfig } from "@tdms/frontend/modules/routing/models/route.config";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";

/**
 * This guard allows the endpoint to customize if it can be navigated to or not based on configuration.
 *
 * **Note** You should use this alongside `enabledGuardCheck` in your route configuration
 */
@Injectable()
export class EnabledGuard implements CanActivate {
  constructor(private configService: ConfigService) {}
  /**
   * Handles determining if a user can activate a route
   */
  canActivate(route: ActivatedRouteSnapshot): boolean | Promise<boolean> {
    const config = route.routeConfig as RouteConfig;
    // Make sure the function exists
    if (config.enabledGuardCheck == null) throw new Error("EnabledGuard is given but no guard check is defined.");
    return config.enabledGuardCheck(this.configService);
  }
}
