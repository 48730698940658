import { Type } from "class-transformer";
import { TDMSBase } from "../base";
import { Bookmark } from "./bookmark";

/**
 * Base level class for chart data to extend upon
 */
export abstract class ChartData extends TDMSBase {
  /**
   * The name of this chart data set.
   */
  name: string;

  /**
   * This function is used to convert the current chart data into a CSV
   *  format that could be opened in software like excel.
   *
   * **This should and will not return the header row, just the data**
   */
  static toCSV(_data: ChartData[] | ChartData): string {
    // Static abstract doesn't exist in typescript so all we can do for now is throw an error
    throw new Error("Not Implemented!");
  }

  constructor(name: string) {
    super();
    this.name = name;
  }
}

/**
 * Base class to include to any time series charts for additional information
 */
export abstract class TimeSeriesChartBase<SeriesType> extends ChartData {
  /**
   * The series data to be rendered as points in time
   */
  abstract series: SeriesType[];

  /**
   * Bookmarks to be shown on ONLY this time series. That means if this time series isn't visible,
   *  these will not be included.
   */
  @Type(() => Bookmark)
  seriesSpecificBookmarks: Bookmark[];

  constructor(name: string, seriesSpecificBookmarks: Bookmark[] = []) {
    super(name);
    this.seriesSpecificBookmarks = seriesSpecificBookmarks;
  }
}
