import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import {
  AudioTopics,
  CoreTopics,
  DataStoreFile,
  ServerStatus,
  Session,
  TDMSWebSocketMessage,
  Transcription,
  TranscriptionEdit,
  TranscriptionRefresh,
  WebSocketCommunication,
} from "@tdms/common";
import { TranscriptionState } from "@tdms/frontend/modules/audio/store/transcription.state";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { selectFilesForSession } from "@tdms/frontend/modules/data-store/models/store/data.store.selector";
import { DownloadService } from "@tdms/frontend/modules/data-store/services/download.service";
import { SessionService } from "@tdms/frontend/modules/session/services/session.service";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { Configuration } from "@tdms/frontend/modules/shared/models/config";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { TranscriptionActions } from "../store/transcription.adapter";

/**
 * Service for transcriptions and other audio related handling
 */
@Injectable({ providedIn: "root" })
export default class AudioService extends Service {
  /**
   * The name of the audio plugin in the backend so we can check if we have any audio files
   */
  static readonly AUDIO_PLUGIN_OPTION = "Audio";

  /**
   * An array of any current data store audio files available for the current session
   */
  currentSessionAudioFiles: DataStoreFile[] = [];

  /** A subject to track when a new audio blob is loaded */
  audioBlob = new BehaviorSubject<{ blob: Blob; url: SafeResourceUrl } | undefined>(undefined);

  /**
   * A behavior subject so other elements can directly influence when the audio player on the comm page decides when to play
   *  the audio file and at what time.
   */
  seekTimeSubject = new BehaviorSubject<number>(0);

  /**
   * A boolean to track if our last request caused a failure during transcription generation.
   */
  failureToGenerate = false;

  /** Tracks the last message we might have gotten during a failure to load a playback file */
  loadFailureMessage: string | undefined = undefined;
  /** Similar to loadFailureMessage but has a much more engineer-y failure message */
  extendedFailureMessage: string | undefined = undefined;

  constructor(
    private store: Store<TranscriptionState>,
    private sessionService: SessionService,
    private downloadService: DownloadService,
    private wsService: WebSocketService,
    private configService: ConfigService,
    private snackbar: MatSnackBar,
    private domSanitizer: DomSanitizer
  ) {
    super();
  }

  /**
   * The current audio file we are using to process
   */
  get audioFile(): DataStoreFile | undefined {
    // Attempt to locate a speech mixed and use that if available
    const mixed = this.currentSessionAudioFiles.find((x) => x.pluginType === "Speech-Mixed");
    return mixed == null ? this.currentSessionAudioFiles[0] : mixed;
  }

  override async initialize() {
    // Keep files up to date
    this.store.select(selectFilesForSession).subscribe((files) => {
      this.currentSessionAudioFiles = this.getAudioFiles(files);
    });
  }

  override async onSessionChangedPost(session?: Session): Promise<void> {
    if (this.configService.configData?.audio.enabled && this.configService.configData.dataStore.enabled) {
      this.resetSeek();
      if (session) {
        // Request transcription content based on available files
        const filesForSession = await firstValueFrom(this.store.select(selectFilesForSession));
        this.currentSessionAudioFiles = this.getAudioFiles(filesForSession);
        if (this.audioFile != null) {
          this.getAudioAnalysis(session);
          this.populateCurrentAudioBlob();
        }
      } else {
        this.audioBlob.next(undefined);
        this.store.dispatch(TranscriptionActions.empty({}));
        this.failureToGenerate = false;
        this.loadFailureMessage = undefined;
        this.extendedFailureMessage = undefined;
      }
    }
  }

  /**
   * Handles populating the current audio file for usage in displays. Be careful as this can cause
   *  slowdowns during the download.
   * @param downloadReasoning The reason for populating the audio blob. You should be good to use the default here.
   */
  async populateCurrentAudioBlob(downloadReasoning = "Waveform display") {
    try {
      if (this.audioFile == null) return;
      const blob = (await this.downloadService.downloadFile(this.audioFile.filePath, downloadReasoning, true))!;
      const url = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
      this.audioBlob.next({ blob, url });
      this.loadFailureMessage = undefined;
    } catch (e) {
      this.loadFailureMessage = "Failed to download audio file";
      this.extendedFailureMessage = `${(e as Error).message}`;
    }
  }

  /**
   * Given a time in the audio track, tells the subject to seek so wave surfer will in change seek
   *  to that time.
   */
  seekToTime(time: number) {
    this.seekTimeSubject.next(time);
  }

  /**
   * Resets the current audio track seeking
   */
  resetSeek() {
    this.seekTimeSubject.next(0);
  }

  /**
   * Given the data store file ID for the audio file, grabs the analyzed audio information from the backend
   */
  async getAudioAnalysis(session: Session) {
    let audioFile = this.audioFile;
    // We need the non mixed audio file as the backend will do it's own comparison of each
    if (this.audioFile?.pluginType === "Speech-Mixed") audioFile = this.currentSessionAudioFiles[0];
    if (audioFile == null) {
      console.error("No Audio file found");
      return;
    }
    this.store.dispatch(TranscriptionActions.empty({}));
    const message = new TDMSWebSocketMessage(AudioTopics.getForFile, session.id, audioFile.id);
    const response = await this.wsService.sendAndReceive<Transcription[]>(message);
    const updatedTranscriptions = response.payload as Transcription[];
    this.store.dispatch(TranscriptionActions.add({ data: updatedTranscriptions }));
  }

  /**
   * Requests a refresh of the audio analysis for the current audio file
   */
  async refreshAnalysis() {
    const audioFile = this.audioFile;
    // Don't process if we don't have an audio file
    if (audioFile == null) return;
    this.failureToGenerate = false;
    // Send the request. It'll use the endpoint to add the data because this could be a long running task
    const result = await this.wsService.sendAndReceive<TranscriptionRefresh>(
      new TDMSWebSocketMessage(
        AudioTopics.refreshAnalysis,
        this.sessionService.currentSession?.id,
        TranscriptionRefresh.fromPlain({ audioFileId: audioFile.id })
      )
    );
    if (result.payload.isError)
      this.snackbar.open(`${result.payload.errorMessage}`, undefined, Configuration.ErrorSnackbarConfig);
    else this.store.dispatch(TranscriptionActions.empty({}));
    return result.payload;
  }

  /**
   * Requests a refresh of the audio analysis for the current audio file
   */
  async manuallyChangeTranscriptions(transcriptions: Transcription[]) {
    const result = await this.wsService.sendAndReceive<TranscriptionEdit>(
      new TDMSWebSocketMessage(
        AudioTopics.manuallyChangeTranscriptions,
        this.sessionService.currentSession?.id,
        TranscriptionEdit.fromPlain({ transcriptions })
      )
    );
    // Handle error display
    if (result.payload.isError)
      this.snackbar.open(`${result.payload.errorMessage}`, undefined, Configuration.ErrorSnackbarConfig);
  }

  /** Listens for updates in transcriptions */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<TranscriptionEdit>>(
    AudioTopics.manuallyChangeTranscriptions
  )
  protected async manuallyChangeTranscriptionsReceived(data: TDMSWebSocketMessage<TranscriptionEdit>) {
    if (data.payload.isError) return; // Ignore errors
    // Update our transcriptions
    const transcriptions = Transcription.fromPlainArray(data.payload.transcriptions);
    transcriptions.forEach((x) => this.store.dispatch(TranscriptionActions.update({ id: x.id, changes: x })));
  }

  /**
   * Given an audio file and a list of data store files, determines the available audio files for that session
   * @param session The session to check against
   * @param files The array of files to process against
   */
  getAudioFiles(files: DataStoreFile[]) {
    return files.filter((x) => x.matchingPlugin === AudioService.AUDIO_PLUGIN_OPTION);
  }

  /**
   * Listens for new audio analysis updates and handles inserting that data where it needs to be
   */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<Transcription[]>>(AudioTopics.analyzeResults)
  async newAnalysisReceived(data: TDMSWebSocketMessage<Transcription[]>) {
    if (data.sessionId != null && data.sessionId === this.sessionService.currentSession?.id) {
      this.store.dispatch(TranscriptionActions.empty({}));
      this.store.dispatch(TranscriptionActions.add({ data: data.payload }));
    }
  }

  /**
   * Listens for server status errors
   */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<ServerStatus>>(CoreTopics.serverStatus)
  protected async serverStatusReceived(data: TDMSWebSocketMessage<ServerStatus>) {
    // I am only interested in audio transcription errors
    if (data.sessionId === this.sessionService.currentSession?.id && data.payload.subType === "audio-processing-error")
      this.failureToGenerate = true;
  }

  /** Requests the backend to regenerate metrics based on the current transcription */
  async regenerateMetricsWithTranscription() {
    this.wsService.send(new TDMSWebSocketMessage(AudioTopics.regenerateMetrics));
  }
}
