import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import AudioService from "../services/audio.service";

/**
 * This guard requires that an audio file exists before we are able to navigate to what route this guard is protecting
 */
@Injectable()
export class AudioGuard implements CanActivate {
  constructor(private audioService: AudioService) {}
  /**
   * Handles determining if the route can be activated based on if we have an available audio file
   */
  async canActivate() {
    return this.audioService.audioFile != null;
  }
}
