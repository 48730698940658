import { Component, Input, OnInit } from "@angular/core";

/**
 * A generic button component that allows you to have a split-button dropdown
 *  with an arrow to the side for additional nested options
 */
@Component({
  selector: "shared-split-button",
  templateUrl: "./split-button.component.html",
  styleUrls: ["./split-button.component.scss"],
})
export class SplitButtonComponent implements OnInit {
  /**
   * If this button should be clickable or not
   */
  @Input() disabled: boolean = false;

  /**
   * The main button click callback
   */
  @Input() mainButtonClick: Function = () => {};

  /**
   * The button text for the main button
   */
  @Input() mainButtonText: string = "Click";

  /**
   * The material color for the buttons
   * @default primary
   */
  @Input() color = "primary";

  /**
   * If the split menu and content should be rendered
   */
  @Input() displaySplit = true;

  /**
   * The tooltip to display for the main button
   */
  @Input() mainButtonTooltip: string = "";

  /**
   * The tooltip to display for the split button
   */
  @Input() splitButtonTooltip: string = "";

  constructor() {}

  ngOnInit(): void {}
}
