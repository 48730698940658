<div class="overview-container" #overviewContainer [ngClass]="{ 'has-scrollbar': elementHasScrollbar }">
  <div class="row">
    <!-- Render our singular session score metric -->
    <metric-grid [dataStore]="currentMetricDataStore!" class="metric-grid card"></metric-grid>

    <!-- Render pro/cons breakdown -->
    <shared-div-progress-component
      [ready]="currentSessionSummary != null && !currentSession?.isProcessing"
      class="breakdown card"
    >
      <mat-card class="inner-card">
        <mat-card-content>
          <!-- No data message -->
          <ng-container *ngIf="currentSessionSummary?.sessionScore == null">
            <div class="no-data">
              <h1>No Data Available</h1>
            </div>
          </ng-container>

          <!-- Normal data render -->
          <ng-container *ngIf="currentSessionSummary?.sessionScore != null">
            <div class="header">Session Score Breakdown</div>
            <div class="text-content">
              <!-- Pros -->
              <div class="pros">
                <div class="sub-header">Pros:</div>
                <ul>
                  <li *ngFor="let item of currentSessionSummary?.sessionScore?.pros" class="item">{{ item }}</li>
                </ul>
              </div>
              <!-- Cons -->
              <div class="cons">
                <div class="sub-header">Cons:</div>
                <ul>
                  <li *ngFor="let item of currentSessionSummary?.sessionScore?.cons" class="item">{{ item }}</li>
                </ul>
              </div>
            </div></ng-container
          >
        </mat-card-content>
      </mat-card>
    </shared-div-progress-component>
  </div>

  <div class="row header">
    <div class="separator-header">Constructive Feedback</div>
  </div>

  <div class="row">
    <!-- Render constructive feedback -->
    <shared-div-progress-component
      [ready]="currentSessionSummary != null && !currentSession?.isProcessing"
      class="feedback"
    >
      <div class="feedback-container">
        <!-- Team level feedback -->
        <mat-card class="card inner-card">
          <mat-card-content>
            <!-- No data message -->
            <ng-container *ngIf="currentSessionSummary?.sessionScore == null">
              <div class="no-data">
                <h1>No Data Available</h1>
              </div>
            </ng-container>

            <!-- Normal data render -->
            <ng-container *ngIf="currentSessionSummary?.sessionScore != null">
              <div class="header">Team</div>
              <div class="text-content">
                <ng-container *ngIf="currentSessionSummary">
                  <!-- Group -->
                  <ng-container *ngFor="let group of currentSessionSummary.advancedBreakdown">
                    <!-- Metric -->
                    <ul *ngFor="let metric of group.metrics">
                      <ng-container *ngIf="metric.teamFeedback != null && metric.teamFeedback.length > 0">
                        <li class="item">{{ metric.displayName }}</li>
                        <ul>
                          <!-- Team feedback -->
                          <li *ngFor="let item of metric.teamFeedback" class="item">{{ item }}</li>
                        </ul>
                      </ng-container>
                    </ul>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </mat-card-content>
        </mat-card>

        <!-- Individual level feedback -->
        <mat-card class="card inner-card">
          <mat-card-content>
            <!-- No data message -->
            <ng-container *ngIf="currentSessionSummary?.sessionScore == null">
              <div class="no-data">
                <h1>No Data Available</h1>
              </div>
            </ng-container>

            <!-- Normal data render -->
            <ng-container *ngIf="currentSessionSummary?.sessionScore != null">
              <div class="header">Individual</div>
              <div class="text-content">
                <ng-container *ngIf="currentSessionSummary">
                  <!-- Group -->
                  <ng-container *ngFor="let group of currentSessionSummary.advancedBreakdown">
                    <!-- Metric -->
                    <ul *ngFor="let metric of group.metrics">
                      <ng-container *ngIf="metric.individualFeedback != null">
                        <ng-container *ngVar="getIndividualFeedbackKeys(metric) as roles">
                          <ng-container *ngIf="roles.length > 0">
                            <li class="item">{{ metric.displayName }}</li>
                            <!-- Roles -->
                            <ul *ngFor="let role of roles">
                              <li class="item">{{ role }}</li>
                              <!-- Individual Feedback -->
                              <ul>
                                <li *ngFor="let item of metric.individualFeedback[role]" class="item">{{ item }}</li>
                              </ul>
                            </ul></ng-container
                          >
                        </ng-container>
                      </ng-container>
                    </ul>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </mat-card-content>
        </mat-card>
      </div>
    </shared-div-progress-component>
  </div>
</div>
