<div class="tags-container">
  <!-- Selection dropdown -->
  <shared-improved-select
    #tagSelection
    class="tag-selection"
    *ngIf="showSelection"
    [selectedValueChanged]="dropdownTagsChanged.bind(this)"
    [selectedValue]="tags"
    [dropdownOptions]="totalTags"
    [paginatorEnabled]="true"
    [filterEnabled]="true"
    [allowMultipleSelections]="true"
    dropdownName="Tag Selection"
  >
    <ng-template replace="optionDisplay" let-value="value" let-valueClean="valueClean">
      <span *ngIf="value.length">{{ value.length }} {{ value.length > 1 ? "Tags" : "Tag" }} Selected</span>
      <span *ngIf="!value.length">
        <tag
          [name]="value.name"
          [color]="value.color"
          [type]="value.type"
          [canEdit]="canEdit"
          (onEdit)="openTagInfo(value, 'edit'); tagSelection.select.close()"
        ></tag>
      </span>
    </ng-template>
  </shared-improved-select>
  <!-- Tag container -->
  <div #tagContainer class="tags" [ngClass]="{ wrap: shouldWrap }" overflowTracker #overflowResults="overflowResults">
    <!-- Create button -->
    <tag
      *ngIf="canCreate && configService.configData?.tag?.allowCreation == true"
      class="tag"
      name="Create"
      icon="add"
      color="var(--accent-darker-color)"
      [canClick]="true"
      matTooltip="Open the creation dialog to create new tags"
      matTooltipPosition="above"
      (onClick)="openTagInfo()"
    ></tag>
    <!-- Render each currently selected tag -->
    <tag
      *ngFor="let tag of tags"
      class="tag"
      [name]="tag.name"
      [color]="tag.color"
      [type]="tag.type"
      [canRemove]="canRemove"
      (onRemove)="onTagRemove(tag)"
    >
    </tag>
    <!-- Overflow tag. Placed after the overflow and will be directly manipulated by the overflowTracker -->
    <tag
      overflowLastElement
      class="tag"
      [name]="'+' + overflowResults.totalOverflows"
      color="var(--accent-color)"
      [canClick]="true"
      matTooltip="Open a dialog to see all selected tags"
      (onClick)="openTagDisplayDialog($event)"
    >
    </tag>
  </div>
</div>
