import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import { LogicalOperator, PopulatedSearchOption } from "@tdms/common";
import { SearchService } from "@tdms/frontend/modules/data-store/services/search.service";

/**
 * Logical Operator Selector represents a logical operator dropdown which joins two terms in the query bar.
 */
@Component({
  selector: "data-store-logical-operator-selector",
  templateUrl: "./logical-operator-selector.component.html",
  styleUrls: ["./logical-operator-selector.component.scss"],
})
export class LogicalOperatorSelectorComponent implements OnInit, OnChanges {
  @Input() option!: PopulatedSearchOption;

  @Input() isSearching!: boolean;

  /**
   * This is called whenever the comparison type changes.
   * It should update the populated search option and thus update this component as necessary.
   */
  @Input() onComparisonChanged!: (logicalOperator: LogicalOperator) => void;

  supportedOperators: readonly LogicalOperator[] = [];
  dropdownControl = new FormControl<LogicalOperator>("AND");

  @Input() tooltip: string =
    "Logical operators are used to combine the expression of different search terms together into one query.\nAND requires both combined terms to match, whereas OR requires only one term to match. Term grouping is indicated here by indentation.";

  constructor(private searchService: SearchService) {
    this.updateSupportedOptions = this.updateSupportedOptions.bind(this);
    this.onDropdownChanged = this.onDropdownChanged.bind(this);
  }

  ngOnInit(): void {
    this.updateSupportedOptions();
    this.dropdownControl.setValue(this.option.nextLogicalOperator);
  }

  ngOnChanges(changes: SimpleChanges): void {
    /// When the term changes, need to re-check the available supported options.
    if (changes.option != null) this.updateSupportedOptions();
  }

  /**
   * Grab supported options for our term from the search service and adjust the status of our dropdown accordingly.
   */
  updateSupportedOptions() {
    this.supportedOperators = this.searchService.getSupportedLogicalOperators(this.option);

    if (this.supportedOperators.length <= 1) {
      this.dropdownControl.disable();
    } else {
      this.dropdownControl.enable();
    }
  }

  onDropdownChanged() {
    if (this.dropdownControl.value == null) return;
    this.onComparisonChanged(this.dropdownControl.value);
  }
}
