<shared-dialog-wrapper class="user-edit-dialog" [header]="mode === 'add' ? 'Create User' : 'Edit User'">
  <shared-div-progress-component [ready]="!processing" notReadyMessage="Processing...">
    <div class="user-edit-container">
      <!-- Help text -->
      <div class="help">
        {{
          mode === "add"
            ? "Fill in the new users information below and click create"
            : "Update any information below and press update"
        }}
      </div>
      <!-- Overarching form -->
      <form [formGroup]="formGroup" (ngSubmit)="submit()" class="form">
        <!-- Username -->
        <mat-form-field color="accent">
          <mat-label>Username</mat-label>
          <input matInput placeholder="jdoe" formControlName="username" />
          <mat-error>This is a required field</mat-error>
        </mat-form-field>

        <!-- First Name -->
        <mat-form-field color="accent">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="John" formControlName="firstName" />
        </mat-form-field>

        <!-- Last Name -->
        <mat-form-field color="accent">
          <mat-label>Last Name</mat-label>
          <input matInput placeholder="Doe" formControlName="lastName" />
        </mat-form-field>

        <!-- DODID -->
        <mat-form-field color="accent">
          <mat-label>DODID</mat-label>
          <input matInput formControlName="dodId" />
          <mat-hint>The ID located on the back of the users CAC</mat-hint>
        </mat-form-field>

        <!-- Admin -->
        <mat-slide-toggle *ngIf="canSetAdmin" [formControl]="$any(formGroup.get('admin'))">Admin</mat-slide-toggle>

        <!-- Icon -->
        <div class="user-icon-container">
          <mat-form-field color="accent">
            <mat-label>User Icon</mat-label>
            <mat-select matNativeControl required formControlName="icon">
              <mat-option *ngFor="let option of iconOptions" [value]="option">{{ option }}</mat-option>
            </mat-select>
          </mat-form-field>
          <!-- Display the user icon that will be used -->
          <user-icon [user]="fakeUser"></user-icon>
        </div>

        <!-- Submit button -->
        <div class="buttons">
          <button mat-raised-button color="accent" type="submit" [disabled]="!formGroup.valid">
            {{ mode === "add" ? "Create" : "Update" }}
          </button>
        </div>
      </form>
    </div>
  </shared-div-progress-component>
</shared-dialog-wrapper>
