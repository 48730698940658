import { Component } from "@angular/core";
import { RouterParamTypes } from "@tdms/frontend/modules/routing/services/router.service";
import { AdvancedComponent } from "@tdms/frontend/modules/session-summary/components/tabs/advanced/advanced.component";
import { OverviewComponent } from "@tdms/frontend/modules/session-summary/components/tabs/overview/overview.component";
import { Tab, TabComponent } from "@tdms/frontend/modules/shared/components";

@Component({
  selector: "session-summary",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class SessionSummaryComponent extends TabComponent {
  constructor() {
    super(RouterParamTypes.sessionSummaryTab);
  }

  get tabs(): Tab[] {
    return [
      { component: OverviewComponent, name: "Overview" },
      { component: AdvancedComponent, name: "Advanced" },
    ];
  }
}
