import { Component } from "@angular/core";
import { PieChartData } from "@tdms/common";
import { ChartDataset, ChartTypeRegistry } from "chart.js";
import { ChartBaseComponent } from "../shared/base/base.component";
import { ExtendedChartOptions } from "../shared/plugins/plugin.typing";

/**
 * A generic pie chart component to use across the application
 */
@Component({
  selector: "charts-pie[data][colorLookup][configuration]",
  templateUrl: "../shared/base/base.component.html",
  styleUrls: ["../shared/base/base.component.scss"],
})
export class PieComponent<T extends PieChartData[]> extends ChartBaseComponent<T, "pie"> {
  override chartType: keyof ChartTypeRegistry = "pie";

  override getChartData(dataSet: T): ChartDataset<"pie", any>[] {
    const filteredData = this.filterDataset(dataSet);
    const flattenedData = filteredData.flatMap((x) => x.value);
    const colors = filteredData.flatMap((x) => this.getColor(x.name));
    return [
      {
        data: flattenedData,
        backgroundColor: colors,
        hoverOffset: 4,
      },
    ];
  }

  override chartOptionOverrides(): ExtendedChartOptions<"pie"> {
    return {
      plugins: {
        customTooltip: {
          snappingIntersect: true,
        },
      },
      layout: {
        padding: {
          top: 20,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
          },
        },
      },
    };
  }
}
