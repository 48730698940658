<query-bar [onSearchStarted]="onSearchStarted" [isSearching]="dataLoading"></query-bar>
<shared-div-progress-component
  class="search-progress"
  [ready]="!this.dataLoading"
  notReadyMessage="Loading ..."
  *ngIf="this.dataLoading"
>
</shared-div-progress-component>

<shared-generic-table
  tableName="Search Results"
  [data]="searchResult"
  [displayedColumns]="displayedColumns"
  [defaultSortHeader]="'startDate'"
  [shouldGuessPaginatorPageSize]="false"
  [filterable]="false"
  [selectionUpdate]="tableSelectionUpdate"
>
  <!-- Override the template for custom cell formatting -->
  <ng-template #cellTemplate let-value="value" let-column="column" let-element="element">
    <ng-container [ngSwitch]="column.name">
      <div *ngSwitchCase="'actions'" class="actions-cell">
        <button
          mat-button
          class="download-file-button"
          matTooltip="Download this file, creating an access log entry"
          matTooltipPosition="left"
          (click)="downloadFile([element])"
          [configDisable]="'dataStore.downloadEnabled'"
          configDisabledStateMessage="Downloading is currently disabled"
        >
          <mat-icon class="icon">file_download</mat-icon>
          <span class="username">Download</span>
        </button>
        <button
          mat-button
          class="delete-entry-button"
          [matTooltip]="recycleBinTooltip"
          matTooltipPosition="right"
          (click)="deleteFile([element])"
          [configDisable]="'dataStore.deleteEnabled'"
          configDisabledStateMessage="Deletion is currently disabled"
        >
          <mat-icon class="icon">auto_delete</mat-icon>
          <span class="username">Delete</span>
        </button>
      </div>
      <span *ngSwitchDefault>
        {{ value }}
      </span>
    </ng-container>
  </ng-template>

  <!-- Custom end buttons -->
  <ng-template #buttonRow>
    <!-- Mass Export -->
    <button
      mat-raised-button
      color="accent"
      (click)="downloadFile(currentSelectedColumns)"
      [disabled]="currentSelectedColumns.length === 0"
      matTooltip="Please select some rows"
      [configDisable]="'dataStore.downloadEnabled'"
      configDisabledStateMessage="Download is disabled"
    >
      <mat-icon>keyboard_double_arrow_down</mat-icon> Download selected
    </button>

    <!-- Mass Delete -->
    <button
      mat-raised-button
      color="warn"
      (click)="deleteFile(currentSelectedColumns)"
      [disabled]="currentSelectedColumns.length === 0"
      matTooltip="Please select some rows"
      matTooltipPosition="below"
      [configDisable]="'dataStore.deleteEnabled'"
      configDisabledStateMessage="Deletion is disabled"
    >
      <mat-icon>delete_sweep</mat-icon> Delete selected
    </button>
  </ng-template>
</shared-generic-table>
