import { Component, Inject, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { User, UserIconOptions, UserIconOptionsType, UserReplyBase } from "@tdms/common";
import { Configuration } from "@tdms/frontend/modules/shared/models/config";
import { UserService } from "@tdms/frontend/modules/user/services/user.service";

/** Properties the dialog should contain for this component when opened */
export interface UserToEditDialogProperties {
  userToEdit: User;
  /** If this dialog is for editing or adding. Default is edit. */
  mode: "add" | "edit";
  /** Called when the user is added or edited after the response is received from the backend successfully. */
  submitCallback?: { (user: User): void };
}

/**
 * This component provides capability to edit a user information and send out an update
 *  based on the given user.
 */
@Component({
  selector: "user-edit",
  templateUrl: "./user-edit.component.html",
  styleUrls: ["./user-edit.component.scss"],
})
export class UserEditComponent {
  @Input() userToEdit: User;

  /** If we are tracking this as an edit or add user */
  mode: "add" | "edit" = "edit";

  /** Form group to customize user input */
  formGroup = new FormGroup({
    username: new FormControl("", [Validators.required]),
    firstName: new FormControl(""),
    lastName: new FormControl(""),
    dodId: new FormControl(""),
    admin: new FormControl(false),
    icon: new FormControl("", [Validators.required]),
  });

  /** Tracks if we are waiting for a backend response for the spinner */
  processing = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public properties: UserToEditDialogProperties,
    private dialogRef: MatDialogRef<UserEditComponent>,
    private userService: UserService,
    private snackBar: MatSnackBar
  ) {
    this.userToEdit = properties.userToEdit;
    this.mode = properties.mode;
    this.setDefaultFormControlOptions();
  }

  /**
   * Based on the given user, sets the form controls values to use it's defaults
   */
  setDefaultFormControlOptions(user = this.userToEdit) {
    this.formGroup.setValue({
      username: user.username || "",
      firstName: user.firstName == null ? null : user.firstName,
      lastName: user.lastName == null ? null : user.lastName,
      dodId: user.dodId == null ? null : user.dodId,
      admin: user.admin,
      icon: user.icon,
    });
  }

  get iconOptions() {
    return UserIconOptions;
  }

  /**
   * Returns a fake user with the icon filled in so we can display the user icon image
   */
  get fakeUser() {
    return User.fromPlain({ icon: this.formGroup.getRawValue().icon as UserIconOptionsType });
  }

  get canSetAdmin() {
    return this.userService.currentAuthenticatedUser?.hasPermissions(["admin"], false);
  }

  /**
   * Submits the user creation or update based on form values
   */
  async submit() {
    if (this.formGroup.valid) {
      this.processing = true;
      // Update the object
      const user = this.userToEdit.clone();
      const values = this.formGroup.getRawValue();
      user.admin = values.admin || false;
      user.icon = values.icon as UserIconOptionsType;
      user.dodId = values.dodId || undefined;
      user.username = values.username!;
      user.firstName = values.firstName || undefined;
      user.lastName = values.lastName || undefined;
      let response: UserReplyBase;
      // Call the specific add/update
      if (this.mode === "add") response = await this.userService.registerUser(user);
      else response = await this.userService.updateUser(user);
      // If this was a good response, close the dialog. Else open the error snackbar.
      if (response.success) {
        this.dialogRef.close();
        this.properties.submitCallback?.call(this, User.fromPlain(response.user!));
      } else this.snackBar.open(response.message, "close", Configuration.ErrorSnackbarConfig);
      this.processing = false;
    }
  }
}
