import { Type } from "class-transformer";
import { decorate } from "ts-mixer";
import { TDMSBase } from "../base";
import { Tag } from "./tag";

/** Class for tag edit or creates to track when errors occur */
export class TagRequestReply extends TDMSBase {
  @decorate(Type(() => Tag))
  tag!: Tag;

  success = true;
  failureMessage: string | undefined;
}
