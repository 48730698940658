<shared-div-progress-component [ready]="spinnerActive" [spinnerDiameter]="20" class="button-spinner">
  <button
    mat-button
    [ngClass]="{ 'mat-raised-button': buttonType === 'raised', 'mat-menu-item': buttonType === 'menu' }"
    [color]="buttonType === 'menu' ? '' : 'accent'"
    (click)="regenerateMetricData()"
    [disabled]="transcriptions.length === 0 || disabled"
    [matTooltip]="
      sessionService.currentSession == null
        ? 'Please select this as the active session'
        : transcriptions.length === 0
        ? 'No transcription data available'
        : 'Regenerates session metric data based on the current transcription'
    "
    matTooltipPosition="below"
    [configDisable]="'audio.allowMetricRegeneration'"
    configDisabledStateMessage="Regeneration is disabled"
    class="regenerate-button"
  >
    <mat-icon>refresh</mat-icon><span>{{ text }}</span>
  </button>
</shared-div-progress-component>
