import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Tag } from "@tdms/common";

export interface TagOverflowDisplayProperties {
  tags: Tag[];
}

/** A component used to just display tags in a dialog when they overflow their other locations */
@Component({
  selector: "tag-overflow-display",
  templateUrl: "./tag-overflow-display.component.html",
  styleUrls: ["./tag-overflow-display.component.scss"],
})
export class TagOverflowDisplayComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public properties: TagOverflowDisplayProperties) {}
}
