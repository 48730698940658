<div id="generic-table-container" class="table-container">
  <!-- Filter form -->
  <div class="mat-elevation-z8 table-card" [ngClass]="{ filterable: filterable }">
    <!-- Utility row -->
    <div id="generic-table-utility-row" class="utility-row mat-table">
      <!-- Flex place holder -->
      <div *ngIf="!filterable"></div>
      <!-- Filter row -->
      <div *ngIf="filterable" class="filter-form">
        <!-- Select for what we're searching on -->
        <mat-form-field appearance="outline" color="accent" class="search-form-field">
          <mat-label>Search On</mat-label>
          <mat-select [(value)]="filterOn" (selectionChange)="searchOnChange()">
            <mat-option
              class="generic-table-filter-option"
              *ngFor="let field of filterableFields"
              [value]="field.value"
            >
              {{ field.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Search term -->
        <mat-form-field color="accent" appearance="outline" class="search-form-field">
          <mat-label>Search...</mat-label>
          <input matInput type="text" [(ngModel)]="filterValue" (keyup)="applyFilter($event)" autocomplete="off" />

          <!-- Clear button -->
          <button
            *ngIf="filterValue != null && filterValue.length != 0"
            matSuffix
            mat-icon-button
            aria-label="Search"
            (click)="clearFilter()"
            class="clear-button"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <!-- Buttons -->
      <div class="buttons">
        <!-- Add a new row -->
        <button *ngIf="addRowCallback" mat-raised-button color="accent" (click)="addRowCallback()">
          <mat-icon>add</mat-icon>Add New Row
        </button>
        <!-- Optional additional buttons at the end of the row -->
        <ng-container select="[replace=endingButtons]" *ngTemplateOutlet="buttonRowRef; context: {}"></ng-container>
        <!-- Export button -->
        <button
          *ngIf="exportEnabled"
          mat-raised-button
          color="accent"
          (click)="exportTableData()"
          matTooltip="Export table contents to a .csv"
        >
          <mat-icon>download</mat-icon>Export
        </button>
      </div>
    </div>

    <!-- Data table -->
    <table
      mat-table
      matSort
      [dataSource]="dataSource"
      [matSortActive]="defaultSortHeader || ''"
      [matSortDirection]="defaultHeaderSortDirection"
      #genericTable
    >
      <!-- Render columns dynamically -->
      <ng-container *ngFor="let column of renderedColumns; let i = index" [matColumnDef]="column.name">
        <!-- Header cells so we can define title -->
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.isDataBacked">
          <!-- Checkbox for selection column -->
          <mat-checkbox
            *ngIf="column.name === selectionColumn.name"
            (change)="$event ? toggleAllRows() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>

          <!-- Standard cells -->
          <ng-container *ngIf="column.name !== selectionColumn.name"> {{ column.title }}</ng-container>
        </th>
        <!-- Table cell -->
        <td mat-cell *matCellDef="let element">
          <!-- Spinner if the element has it enabled -->
          <shared-div-progress-component
            *ngIf="element.isProcessing && i === 0"
            class="generic-table-row-spinner"
            [ready]="false"
            [spinnerDiameter]="40"
            [ngStyle]="{ width: tableWidth + 'px', height: matRowHeight - 1 + 'px' }"
          >
          </shared-div-progress-component>

          <!-- Selection box. This needs rendered no matter if we have cell display because the user won't want to override this base capability. -->
          <ng-container *ngIf="column.name === selectionColumn.name">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(element) : null"
              [checked]="selection.isSelected(element)"
            >
            </mat-checkbox>
          </ng-container>

          <!-- Render the cell template provided by our parent component -->
          <ng-container
            select="[replace=cellDisplay]"
            *ngTemplateOutlet="
              cellDisplayRef;
              context: {
                value: getCellValue(element, column),
                column: column,
                element: element
              }
            "
          ></ng-container>

          <!-- Render normal cell if no template was provided -->
          <span *ngIf="!cellDisplayRef">
            <!-- Standard override -->
            <ng-container *ngIf="column.name !== selectionColumn.name">
              {{ getCellValue(element, column) }}
            </ng-container>
          </span>
        </td>
      </ng-container>

      <!-- Render columns headers to be displayed -->
      <tr mat-header-row *matHeaderRowDef="renderedHeaders"></tr>
      <!-- The actual element row -->
      <tr
        mat-row
        class="table-row"
        *matRowDef="let row; columns: renderedHeaders"
        [ngClass]="{ clickable: clickCallback != null && !row.isProcessing, active: activeRows?.includes(row) }"
        (click)="clickCallback != null && !row.isProcessing ? clickCallback(row) : null"
      ></tr>

      <!-- Row shown when there is no matching data for filter. -->
      <tr class="mat-row no-data-row" *matNoDataRow>
        <td class="mat-cell" [colSpan]="renderedColumns.length">
          <!-- If we are using a filter, -->
          <span *ngIf="filterValue != null && filterValue.length > 0">
            No data matching the filter "{{ filterValue }}"</span
          >
          <!-- If we just have no data -->
          <span *ngIf="filterValue == null || filterValue?.length === 0"> No data available</span>
        </td>
      </tr>
    </table>
    <!-- Paginator -->
    <mat-paginator
      id="generic-table-paginator"
      #contentTablePaginator
      [pageSize]="paginatorPageSize"
      [showFirstLastButtons]="showFirstLastButtons"
    ></mat-paginator>
  </div>
</div>
