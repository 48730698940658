<div class="custom-legend-container">
  <div class="custom-legend-container-inner">
    <!-- Split into rows, if that is what the values require -->
    <span class="legend-row" *ngFor="let value of values" [ngClass]="{ 'multiple-groups': values.length > 1 }">
      <!-- Title -->
      <span *ngIf="value.groupName != null && value.groupName !== ''" class="group-title">{{ value.groupName }}</span>
      <!-- Clickable legend button -->
      <button
        mat-stroked-button
        [ngClass]="{ hidden: !option.displayed }"
        *ngFor="let option of value.options"
        (click)="clickCallback == null ? undefined : legendElementClick(option)"
        [ngStyle]="{
          'border-color': colorGetter == null ? 'gray' : colorGetter(option.originalNameMapping || option.name)
        }"
      >
        <!-- Color indicator circle -->
        <div
          *ngIf="renderColorIndicator"
          class="color"
          [ngStyle]="{
            'background-color': colorGetter == null ? 'gray' : colorGetter(option.originalNameMapping || option.name)
          }"
        ></div>
        <div class="name">{{ option.name }}</div>
      </button>
    </span>
  </div>
</div>
