import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { ChartsModule } from "@tdms/frontend/modules/charts/charts.module";
import { AngularMaterialModule } from "@tdms/frontend/modules/material/material.module";
import { MetricsModule } from "@tdms/frontend/modules/metrics/metrics.module";
import { SharedModule } from "@tdms/frontend/modules/shared/shared.module";
import { QueryBarComponent } from "./query-bar/query-bar.component";
import { QueryBuilderDialogComponent } from "./query-builder/query-builder-dialog/query-builder-dialog.component";
import { QueryBuilderComponent } from "./query-builder/query-builder.component";
import { QueryGroupingComponent } from "./query-builder/query-grouping/query-grouping.component";
import { ComparisonSelectorComponent } from "./query-components/comparison-selector/comparison-selector.component";
import { LogicalOperatorSelectorComponent } from "./query-components/logical-operator-selector/logical-operator-selector.component";
import { NewTermSelectorComponent } from "./query-components/new-term-selector/new-term-selector.component";
import { QuerySegmentComponent } from "./query-components/query-segment/query-segment.component";
import { QueryTermSelectorComponent } from "./query-components/query-term-selector/query-term-selector.component";

/**
 * Components provided by this module
 */
const COMPONENTS = [
  QueryBarComponent,
  QueryBuilderComponent,
  QuerySegmentComponent,
  ComparisonSelectorComponent,
  QueryTermSelectorComponent,
  LogicalOperatorSelectorComponent,
  NewTermSelectorComponent,
  QueryGroupingComponent,
  QueryBuilderDialogComponent,
];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    SharedModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MetricsModule,
    ChartsModule,
    MatNativeDateModule,
    MatDatepickerModule,
  ],
  providers: [],
  exports: COMPONENTS,
})
export class QueryModule {}
