import { ContentChild, Directive, ElementRef, Input, OnChanges } from "@angular/core";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { debounceTime, fromEvent, Subject } from "rxjs";

/** A directive for the last element we want rendered in an overflow handler */
@Directive({ selector: "[overflowLastElement]" })
export class OverflowLastElementChildDirective {
  constructor(public elementRef: ElementRef<HTMLElement>) {}
  get element() {
    return this.elementRef.nativeElement;
  }
}

/** 
 * This directive is used as a way to handle automatic overflow hiding of children within dimensions of a parent. We provide some 
 *  common capabilities to add classes to these overflowed elements as well as provide you the ability
 *  to specify what you want rendered in place of the last element. You can use this functionality like this:
 * 
 * ```html 
  <div overflowTracker #overflowResults="overflowResults">
    <!-- Render each currently selected tag -->
    <tag *ngFor="let tag of tags"></tag>
    <!-- Overflow tag -->
    <tag
      overflowLastElement
      [name]="'+' + overflowResults.totalOverflows"
    >
    </tag>
  </div>
 * ```
 * 
 * In the example above, you can see a few things:
 *  1. We have an overarching div that implements this directive via `overflowTracker` and utilizes it's outputs via `overflowResults`
 *  2. We render a dynamic amount of elements (in this case, tags) to determine the size of the parent
 *  3. We can use a final tag with the `overflowLastElement` to force this element to be the last visible element. This
 *    will only be displayed in the event overflowing is occurring. **We recommend not using ngIf values on this so it is always rendered.**
 */
@Directive({ selector: "[overflowTracker]", exportAs: "overflowResults" })
export class OverflowDirective extends SubscribingComponent implements OnChanges {
  /** The class we apply whenever that child element is overflowing */
  static readonly OVERFLOW_CLASS_NAME = "overflowing";
  /** The id of the last element we're looking for to place at the end of the overflow */
  static readonly OVERFLOW_LAST_RENDER = "overflow-last-render";

  /** If we should actually check for overflows (true) or not (false) */
  @Input() overflowShouldCheck = true;

  /** Child directive viewer so we can get the last element */
  @ContentChild(OverflowLastElementChildDirective) lastRenderElement: OverflowLastElementChildDirective | undefined;

  /** How many children are overflowing */
  totalOverflows: number = 0;

  /** The main render trigger to handle debouncing the class applying */
  trigger = new Subject<void>();

  constructor(
    /** The parent element this directive is wrapping */
    private elementRef: ElementRef<HTMLElement>
  ) {
    super();
  }

  ngOnInit() {
    this.addSubscription(this.trigger.pipe(debounceTime(50)).subscribe(this.applyClasses.bind(this)));
    this.addSubscription(fromEvent(window, "resize").subscribe(() => this.trigger.next()));
  }

  ngAfterViewInit() {
    this.applyClasses();
    // Delay execution so we get updated child sizes
    setTimeout(this.applyClasses.bind(this));
  }

  ngOnChanges() {
    this.trigger.next();
  }

  /** Apply the overflow classes and hide the elements as need be */
  applyClasses() {
    const element = this.elementRef.nativeElement;
    if (!this.overflowShouldCheck) return; // Make sure we should check
    // Update all direct children that cause the overflow
    const childNodes = Array.from(element.childNodes).filter(
      (z) => z.nodeType === Node.ELEMENT_NODE
    ) as Array<HTMLElement>;
    // Remove element from array so it's not checked
    if (this.lastRenderElement)
      childNodes.splice(
        childNodes.findIndex((x) => x === this.lastRenderElement?.element),
        1
      );
    // Make all elements visible so calculations can be performed
    childNodes.forEach(this.showElement.bind(this));
    // Used to determine when we begin overflowing.
    let firstOverflowIndex = -1;
    for (let i = 0; i < childNodes.length; i++) {
      const child = childNodes[i];
      // If we are already passed the first overflow, always hide
      if (firstOverflowIndex !== -1 && firstOverflowIndex <= i) this.hideElement(child);
      else {
        // Check overflowing status
        const isOverflowing = this.checkParentOverflow(element, child);
        if (isOverflowing) {
          // Track that this is the first hidden element
          firstOverflowIndex = i;
          this.totalOverflows = childNodes.length - i;
          this.hideElement(child);
        }
      }
    }
    // Do some adjustment so the last element is shown if provided by the developer
    if (this.lastRenderElement) {
      // Hide this element if we have no overflows
      if (this.totalOverflows === 0) this.hideElement(this.lastRenderElement.element);
      else {
        // Else hide the one right before and show the lastRenderElement
        const elementBeforeOverflow = childNodes[firstOverflowIndex - 1];
        if (elementBeforeOverflow) this.hideElement(elementBeforeOverflow);
      }
    }
  }

  /** Sets the styling to apply the overflow hiding */
  private hideElement(element: HTMLElement) {
    element.style.setProperty("display", "none");
    element.classList.add(OverflowDirective.OVERFLOW_CLASS_NAME);
  }

  /** Set styling to say this element is not overflowing */
  private showElement(element: HTMLElement) {
    element.style.setProperty("display", "inherit");
    element.classList.remove(OverflowDirective.OVERFLOW_CLASS_NAME);
  }

  /** Returns if this child is overflowing the parent */
  checkParentOverflow(parent: HTMLElement, child: HTMLElement) {
    if (!child || !parent) return false;
    const elementRect = child.getBoundingClientRect();
    const parentRect = parent.getBoundingClientRect();
    // Check for overflow on all sides
    return (
      elementRect.top < parentRect.top ||
      elementRect.bottom > parentRect.bottom ||
      elementRect.left < parentRect.left ||
      elementRect.right > parentRect.right
    );
  }

  /** Returns if the given element is overflowing */
  checkOverflow(element: HTMLElement) {
    return element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;
  }
}
