<div class="query-bar-container">
  <div class="segments-container">
    <div *ngFor="let option of currentSearchTerms; let i = index" class="segment-container">
      <query-segment
        [searchOption]="option"
        [optionDeleted]="onSearchTermDeleted"
        [optionUpdated]="onSearchTermChanged"
        [isSearching]="isSearching"
        [availableOptions]="availableSearchOptions"
        [onSearchStarted]="onSearchButtonClicked"
      ></query-segment>
    </div>
  </div>

  <div class="bottom-bar-container">
    <data-store-new-term-selector
      [availableSearchTerms]="availableSearchOptions"
      [onTermSelected]="onNewSearchTermAdded"
    ></data-store-new-term-selector>
    <button
      mat-raised-button
      (click)="onAdvancedButtonClicked()"
      color="accent"
      class="query-button"
      matTooltip="Open the advanced query builder which provides more fine-grained control over query terms and how they combine with the use of Logical Operators (AND, OR). Terms are grouped using these operators to specify more exact search constraints."
    >
      <mat-icon>build</mat-icon>
      Advanced Query
    </button>
    <button
      mat-raised-button
      (click)="onSearchButtonClicked()"
      [disabled]="currentSearchTerms.length === 0"
      color="accent"
      class="query-button"
    >
      <mat-icon>search</mat-icon>
      Run Query
    </button>
  </div>
</div>
