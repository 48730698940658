<mat-expansion-panel *ngIf="collection != null" class="setting-panel" [expanded]="false" [hideToggle]="false">
  <mat-expansion-panel-header class="setting-header">
    <span class="title flex-placeholder">{{ helpfulTitle }}</span>
    <span class="description"> {{ helpfulDescription }}</span>
    <div class="flex-placeholder"></div>
  </mat-expansion-panel-header>
  <div class="setting-wrapper">
    <div class="settings-list" *ngFor="let settingName of collection.settingNames">
      <setting-field [settingName]="settingName" [correspondingPlugin]="collection!.plugin"></setting-field>
      <div class="section-separator"></div>
    </div>
    <div class="section-separator"></div>
  </div>
</mat-expansion-panel>
