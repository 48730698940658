import { Directive, Input } from "@angular/core";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { CartesianScaleOptions, CartesianTickOptions, GridLineOptions } from "chart.js";

/**
 * This class functions as a base level functionality holder for styling related to charts
 */
@Directive()
export abstract class ChartStylingComponent extends SubscribingComponent {
  /**
   * The currently used application theme. This is required so we can determine:
   *  1. When the theme updates
   *  2. What colors to currently display
   */
  @Input() applicationTheme!: "dark" | "light";

  /**
   * Returns the base color to use depending on application theme
   */
  private get baseColor() {
    return this.applicationTheme === "dark" ? "white" : "black";
  }

  /**
   * Returns scale X/Y styling options
   */
  get scaleTitleStyling(): Partial<CartesianScaleOptions["title"]> {
    return {
      color: this.baseColor,
      font: {
        size: 16,
        family: 'Roboto, "Helvetica Neue", sans-serif',
      },
    };
  }

  /**
   * Returns default grid styling to use
   */
  get gridStyling(): Partial<GridLineOptions> {
    return {
      color: this.applicationTheme === "dark" ? "#434351" : "lightgray",
    };
  }

  /**
   * Returns default tick styling
   */
  get tickStyling(): Partial<CartesianTickOptions> {
    return {
      color: this.baseColor,
    };
  }
}
