import { Component, OnInit } from "@angular/core";
import { ColorThemeService } from "@tdms/frontend/modules/material/services/themes.service";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

/**
 * The currently supported overarching application themes
 */
export type TDMSTheme = "dark" | "light";

@Component({
  selector: "app-theme-toggle",
  templateUrl: "./theme-toggle.component.html",
  styleUrls: ["./theme-toggle.component.scss"],
})
export class ThemeToggleComponent extends SubscribingComponent implements OnInit {
  /**
   * Class name associated to the dark theme for the root element
   */
  static DARK_THEME_CLASS = "dark-theme";
  /**
   * Class name associated to the light theme for the root element
   */
  static LIGHT_THEME_CLASS = "light-theme";

  /**
   * The current theme we are displaying
   */
  currentTheme: TDMSTheme = "dark";

  constructor(private colorThemeService: ColorThemeService) {
    super();
  }

  ngOnInit(): void {
    // Subscribe to theme change incase `prefers-color-theme` changes
    this.addSubscription(
      this.colorThemeService.applicationLevelTheme.subscribe((theme) => {
        this.currentTheme = theme;
      })
    );
  }

  /**
   * Determines if we are dark mode or not. Uses the set css class to determine that
   */
  get isDarkMode() {
    return this.currentTheme === "dark";
  }

  /**
   * Toggles between dark and light mode
   */
  toggleDarkMode() {
    const element = document.querySelector(":root")!;
    if (this.isDarkMode) {
      element.classList.replace(ThemeToggleComponent.DARK_THEME_CLASS, ThemeToggleComponent.LIGHT_THEME_CLASS);
      this.colorThemeService.applicationLevelTheme.next("light");
    } else {
      element.classList.replace(ThemeToggleComponent.LIGHT_THEME_CLASS, ThemeToggleComponent.DARK_THEME_CLASS);
      this.colorThemeService.applicationLevelTheme.next("dark");
    }
  }
}
