<shared-dialog-wrapper header="New Access Log Entry">
  <div class="dialog-container">
    <p>
      In order to access the contents of any files listed below, you must provide a reason of your access. Your provided
      reason along with all the details listed here will be recorded in the Access Log.
    </p>
    <br />
    <p>
      <b
        >By continuing with the file download, you are agreeing that you have acquired the appropriate permissions to
        download and transmit this data. If you are not sure that you have the appropriate permissions, please cancel
        this download.
      </b>
    </p>

    <form [formGroup]="formGroup" class="access-form access-details-container" (submit)="submit()">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>File(s)</mat-label>
        <input matInput placeholder="Filename" formControlName="files" />
        <mat-icon matSuffix>description</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline" color="accent">
        <mat-label>Username</mat-label>
        <input matInput placeholder="Username" formControlName="username" />
        <mat-icon matSuffix>account_circle</mat-icon>
      </mat-form-field>

      <!-- Date Accessed -->
      <shared-date-time-picker [control]="$any(formGroup.get('dateAccessed'))"></shared-date-time-picker>

      <mat-form-field appearance="outline" color="accent">
        <mat-label>Access Reason</mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          #autoSize="cdkTextareaAutosize"
          cdkAutosizeMinRows="3"
          cdkAutosizeMaxRows="5"
          formControlName="reason"
          (keydown.enter)="$event.preventDefault(); submit()"
        ></textarea>
        <mat-icon matSuffix>policy</mat-icon>
        <!-- Validators -->
        <mat-error *ngIf="formGroup.controls['reason'].errors?.required">Access Reason is required</mat-error>
      </mat-form-field>
      <div class="button-container">
        <button mat-raised-button color="accent" (click)="properties.onDownloadCancelled()">Cancel</button>
        <button
          mat-raised-button
          color="accent"
          [disabled]="!formGroup.valid"
          [matTooltip]="formGroup.valid ? '' : 'Please provide an access reason'"
          matTooltipPosition="below"
        >
          Download
        </button>
      </div>
    </form>
  </div>
</shared-dialog-wrapper>
