import { createActionGroup, props } from "@ngrx/store";
import { DataStoreFile, UploadOption } from "@tdms/common";

/**
 * Supported NGRX store **data store** actions
 */
export const DataStoreActions = createActionGroup({
  source: "DataStore",
  events: {
    "Add Upload Options": props<{ options: UploadOption[] }>(),
    // Files for the current session
    "Add file": props<DataStoreFile>(),
    "Add files": props<{ files: DataStoreFile[] }>(),
    "Empty files": props<any>(),
    "Remove file": props<DataStoreFile>(),
    "Remove files": props<{ files: DataStoreFile[] }>(),
    // -------- Recycle Bin --------
    "Add Recycle Bin Files": props<{ files: DataStoreFile[] }>(),
    "Empty Recycle Bin Files": props<any>(),
    "Add Recycle Bin File": props<DataStoreFile>(),
    "Remove Recycle Bin File": props<DataStoreFile>(),
  },
});
