import { TDMSBase } from "../../base";

/**
 * A class that can contain data store plugin information that can be used in displays
 */
export class DataStoreMetric extends TDMSBase {
  /**
   * This is a dictionary of each plugin with some basic information of it's data store usage
   */
  metricByPlugin: {
    [pluginName: string]: MetricPluginDescription;
  } = {};
}

export interface MetricPluginDescription {
  /**
   * The total hdd space used by this plugin, in bytes.
   */
  totalSpaceUsed: number;

  /**
   * The total count of files that this plugin has
   */
  totalFiles: number;
}
