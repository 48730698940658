import { createFeatureSelector, createSelector } from "@ngrx/store";
import { BookmarkTypeState } from "./bookmark.state";
import { bookmarkTypeAdapter } from "./bookmark.type.reducer";

/**
 * Given a state selects the bookmark type object
 */
export const selectBookmarkTypeState = createFeatureSelector<BookmarkTypeState>("bookmarkType");

/**
 * Returns all bookmark types
 */
export const selectAllBookmarkTypes = (state: BookmarkTypeState) => bookmarkTypeAdapter.getSelectors().selectAll(state);
/**
 * Returns, from state, the current bookmark types available
 */
export const selectAllBookmarkTypesFromState = createSelector(selectBookmarkTypeState, selectAllBookmarkTypes);
