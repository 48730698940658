import { Component } from "@angular/core";
import { Bookmark, BookmarkType, BookmarkTypeEnum, LineChartData, LineChartSeries } from "@tdms/common";
import { AngularCustomTypes } from "@tdms/frontend/modules/shared/models/angular.custom.types";
import { ChartOptions, ChartType } from "chart.js/auto";
import { merge } from "lodash-es";
import { GATechLineMetrics } from "../models/ga.tech.math";
import { BookmarkChartBaseComponent, BookmarkDisplay } from "../shared/bookmark-base/bookmark.base.component";
import { ExtendedChartOptions } from "../shared/plugins/plugin.typing";

/**
 * A type to help enforce what information we will find when calculating data
 *  for selection zones.
 */
export type SelectionZoneData = {
  name: string;
  avg: number;
  low: LineChartSeries;
  high: LineChartSeries;
  values: LineChartSeries[];
};

/**
 * A generic line chart component to use across the application
 */
@Component({
  selector: "charts-line[data][colorLookup][configuration][totalChartTimeFrame]",
  templateUrl: "../shared/base/base.component.html",
  styleUrls: ["../shared/base/base.component.scss"],
})
export class LineChartComponent<ChartDataType extends LineChartData = LineChartData> extends BookmarkChartBaseComponent<
  ChartDataType,
  "line"
> {
  override chartType: ChartType = "line";

  /**
   * The current **min** selection zone for the selection plugin
   */
  minSelection: Date | undefined = undefined;

  /**
   * The current **max** selection zone for the selection plugin
   */
  maxSelection: Date | undefined = undefined;

  override shouldChartUpdate(
    changes: AngularCustomTypes.BaseChangeTracker<LineChartComponent>,
    checkDomainRange: boolean = true
  ): boolean {
    let shouldUpdate = super.shouldChartUpdate(changes);
    if (this.currentChart) {
      // Check for domain changes
      if (checkDomainRange && changes.xDomainRange)
        if (this.maxSelection && this.minSelection) {
          // If we have a selection zone set, wipe it from domain change
          this.minSelection = undefined;
          this.maxSelection = undefined;
          this.updateChartOptions();
          shouldUpdate = true;
        }
    }

    return shouldUpdate;
  }

  override getChartData(dataSet: ChartDataType[]) {
    return this.filterDataset(dataSet).map((x) => {
      const colorMatch = this.getColor(x.name);
      return {
        label: x.name,
        data: x.series.flatMap((z) => {
          return { y: z.value, x: z.name.getTime() };
        }),
        borderColor: colorMatch,
        backgroundColor: colorMatch, // Necessary for tooltip display
        borderWidth: 1,
      };
    });
  }

  override getChartLabels(dataSet: ChartDataType[]) {
    // Override labels to return the series dates as the bottom labels
    return dataSet[0] == null ? [] : dataSet[0].series.map((x) => x.name.getTime());
  }

  /**
   * Based on selection zone, determines related important information. This includes things like the lowest, highest, and average values.
   */
  private getSelectionZoneData(minSelection = this.minSelection, maxSelection = this.maxSelection) {
    if (minSelection && maxSelection) {
      const minSelectionTimestamp = minSelection?.getTime();
      const maxSelectionTimestamp = maxSelection?.getTime();
      const values = this.data?.map((x) => {
        const filteredSeries = x.series.filter((z) => {
          const currTime = z.name.getTime();
          return currTime >= minSelectionTimestamp && currTime <= maxSelectionTimestamp;
        });
        // Fastest way to determine min/max values
        let lowest: LineChartSeries | undefined;
        let highest: LineChartSeries | undefined;
        let avg: number = 0;
        let tempVal: LineChartSeries | undefined;
        for (var i = filteredSeries.length - 1; i >= 0; i--) {
          tempVal = filteredSeries[i];
          avg += tempVal.value;
          if (lowest == null || tempVal.value < lowest.value) lowest = tempVal;
          if (highest == null || tempVal.value > highest.value) highest = tempVal;
        }
        return {
          name: x.name,
          avg: Number((avg / filteredSeries.length).toFixed(4)),
          low: lowest,
          high: highest,
          values: filteredSeries,
        } as SelectionZoneData;
      });
      return values;
    }
  }

  /**
   * Returns the bookmark type average from the array of types or a default one
   *  if it could not be located.
   */
  private getAverageBookmarkType() {
    return (
      this.bookmarkTypes.find((x) => x.id === BookmarkTypeEnum.Average) ||
      new BookmarkType(0, "Average", undefined, "horizontal", false)
    );
  }

  /**
   * Returns any bookmarks for the selection zone based on the current data set
   */
  private generateBookmarksForSelection() {
    const bookmarks: Bookmark[] = [];
    // Get selection data (averages)
    const selectionData = this.getSelectionZoneData();
    if (selectionData) {
      // Add selection data averages
      bookmarks.push(
        ...(selectionData
          .map((x) => {
            if (isNaN(x.avg)) return undefined;
            const bookmark = Bookmark.fromPlain({
              bookmarkType: this.getAverageBookmarkType(),
              value: x.avg,
              startTime: this.minSelection,
              endTime: this.maxSelection,
              associatedRoleName: selectionData.length > 1 ? x.name : undefined,
            });
            bookmark.bookmarkType.color = BookmarkChartBaseComponent.getBookmarkColor(bookmark, this.colorLookup);
            return bookmark;
          })
          .filter((x) => x != null) as Bookmark[])
      );
      // If we have selection data and we only have one set, apply confidence interval selections
      if (this.configuration.shouldCalculateEntropyMath) {
        bookmarks.push(
          ...selectionData.flatMap((data) =>
            GATechLineMetrics.getGATechBookmarksForEntropy(
              data,
              this.minSelection!,
              this.maxSelection!,
              selectionData.length > 1 ? data.name : undefined
            )
          )
        );
      }
    }
    return bookmarks;
  }

  /**
   * Handles updating the current selection data based on given start/end values
   * @param shouldUpdate If the chart should update with this progress callback
   */
  private dragProgressCallback(shouldUpdate = false, start: Date | undefined, end: Date | undefined) {
    this.minSelection = start;
    this.maxSelection = end;
    this.updateChartOptions();
    if (shouldUpdate) this.currentChart?.update("none");
  }

  override getBookmarks(): BookmarkDisplay[] {
    const overarchingBookmarks = super.getBookmarks();
    return [...overarchingBookmarks, { headerTitle: "Selection", bookmarks: this.generateBookmarksForSelection() }];
  }

  override startDrawingNewBookmark(partialBookmark: Bookmark, isRange: boolean) {
    // Clear selection range
    this.minSelection = undefined;
    this.maxSelection = undefined;
    this.updateChartOptions();
    super.startDrawingNewBookmark(partialBookmark, isRange);
  }

  override chartOptionOverrides(coreOptions: ChartOptions<"line">): ExtendedChartOptions<"line"> {
    const superOptions = super.chartOptionOverrides(coreOptions);
    const newOptions = {
      parsing: false, // Disable parsing as we can handle it internally for better performance
      scales: {
        x: {
          type: "time",
        },
      },

      plugins: {
        select: {
          enabled: this.configuration.supportsSelectionZone,
          minCurrentSelect: this.minSelection,
          maxCurrentSelect: this.maxSelection,
          strokeStartStopOnly: true,
          strokeDashed: true,
          dragProgressCallback: this.dragProgressCallback.bind(this, false),
          dragStartCallback: () => this.dragProgressCallback(false, undefined, undefined),
          dragCompleteCallback: this.dragProgressCallback.bind(this, true),
        },
      },
    } as ExtendedChartOptions<"line">;

    return merge(superOptions, newOptions);
  }
}
