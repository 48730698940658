<div class="communication-container">
  <!-- Display the waveform -->
  <audio-track #audioTrack class="audio-track"></audio-track>

  <!-- Display our table with the transcription capabilities -->
  <shared-div-progress-component
    [ready]="transcriptions.length !== 0 || audioService.failureToGenerate"
    [notReadyMessage]="notReadyMessage"
    class="communication-container"
  >
    <shared-generic-table
      class="transcription-table"
      tableName="Communication"
      [data]="transcriptions"
      [defaultSortHeader]="'start'"
      [displayedColumns]="displayedColumns"
      [clickCallback]="edits ? undefined : seekTo"
      defaultHeaderSortDirection="asc"
      [activeRows]="activeRows"
    >
      <!-- Override the template for custom cell formatting -->
      <ng-template #cellTemplate let-value="value" let-column="column" let-element="element">
        <ng-container [ngSwitch]="column.name">
          <div *ngSwitchCase="'prettySpeaker'" class="pretty-speaker" [ngClass]="{ enabled: edits }">
            <ng-container *ngIf="!edits; else otherContent"> {{ value }} </ng-container>
            <ng-template #otherContent>
              <mat-form-field color="accent">
                <mat-select
                  #select
                  class="mat-select"
                  (selectionChange)="transcriptionUpdate(element, 'speaker', { target: { value: $event.value } })"
                  [value]="value"
                >
                  <button
                    mat-menu-item
                    matTooltip="Opens a dialog to add a new speaker identity"
                    matTooltipPosition="right"
                    class="add-speaker-identity"
                    (click)="openNewSpeakerIdentity(element); select.close()"
                  >
                    <mat-icon>add</mat-icon> Speaker Identity
                  </button>
                  <mat-option *ngFor="let speaker of uniqueSpeakers" [value]="speaker">
                    {{ speaker }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-template>
          </div>
          <!-- CLC Checkmark -->
          <div *ngSwitchCase="'clcOverlapPercentage'" class="clc-overlap-cell">
            <ng-container *ngIf="value">
              <mat-icon [matTooltip]="'Overlap: ' + value + '%'">check</mat-icon>
            </ng-container>
          </div>
          <div *ngSwitchCase="'text'" class="transcription">
            <ng-container *ngIf="!edits; else otherContent"> {{ value }} </ng-container>
            <ng-template #otherContent>
              <mat-form-field color="accent" class="transcription-content-form-field">
                <input
                  matInput
                  autofocus
                  (input)="transcriptionUpdate(element, 'text', $any($event))"
                  (keyup.enter)="saveChanges()"
                  [value]="value"
                />
              </mat-form-field>
            </ng-template>
          </div>
          <div *ngSwitchCase="'start'">
            <ng-container *ngIf="!edits; else otherContent">{{ value }}</ng-container>
            <ng-template #otherContent>
              <mat-form-field color="accent">
                <input
                  matInput
                  #tooltip
                  matTooltip="HH:MM:SS.ssss"
                  [ngClass]="{ error: !inputValid(element, 'start') }"
                  (input)="transcriptionTimeUpdate(element, 'start', $event)"
                  (keup.enter)="saveChanges()"
                  [value]="value"
                />
              </mat-form-field>
            </ng-template>
          </div>
          <div *ngSwitchCase="'end'">
            <ng-container *ngIf="!edits; else otherContent"> {{ value }}</ng-container>
            <ng-template #otherContent>
              <mat-form-field color="accent">
                <input
                  matInput
                  #tooltip
                  matTooltip="HH:MM:SS.ssss"
                  [ngClass]="{ error: !inputValid(element, 'end') }"
                  (input)="transcriptionTimeUpdate(element, 'end', $event)"
                  (keup.enter)="saveChanges()"
                  [value]="value"
                />
              </mat-form-field>
            </ng-template>
          </div>
          <!-- Standard values -->
          <span *ngSwitchDefault>
            {{ value }}
          </span>
        </ng-container>
      </ng-template>

      <!-- Custom end buttons -->
      <ng-template #buttonRow>
        <!-- Regenerate metrics -->
        <audio-regen-button text="Regenerate Metrics" [disabled]="edits"></audio-regen-button>
        <!-- Reset transcription -->
        <button
          mat-raised-button
          color="accent"
          [disabled]="edits"
          (click)="refreshTranscriptions()"
          matTooltip="Deletes the current transcription and requests a new one to be generated"
          matTooltipPosition="below"
          [configDisable]="'audio.allowRefresh'"
          configDisabledStateMessage="Refresh is disabled"
        >
          <mat-icon>restart_alt</mat-icon> Refresh Transcription
        </button>
        <div *ngIf="edits; then thenBlock; else elseBlock"></div>
        <ng-template #thenBlock>
          <button
            color="accent"
            mat-raised-button
            [ngClass]="{ enabled: hasChanged && inputsValid }"
            class="save"
            [disabled]="!hasChanged || !inputsValid"
            (click)="saveChanges()"
            matTooltip="Save transcription changes to database"
            [configDisable]="'audio.allowEditing'"
            configDisabledStateMessage="Editing is disabled"
          >
            <mat-icon>save</mat-icon> Save
          </button>
          <button mat-raised-button color="warn" (click)="cancelChanges()" matTooltip="Cancel transcription changes">
            <mat-icon>cancel</mat-icon> Cancel
          </button></ng-template
        >
        <ng-template #elseBlock>
          <button
            mat-raised-button
            color="accent"
            (click)="batchEdit()"
            matTooltip="Manually edit transcription values"
            [configDisable]="'audio.allowEditing'"
            configDisabledStateMessage="Editing is disabled"
          >
            <mat-icon>edit</mat-icon> Edit
          </button></ng-template
        >
      </ng-template>
    </shared-generic-table>
  </shared-div-progress-component>
</div>
