import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { Tag } from "@tdms/common";
import { TagActions } from "./tag.action";

/**
 * NGRX entity tagAdapter
 */
export const tagAdapter: EntityAdapter<Tag> = createEntityAdapter<Tag>({
  selectId: (tag) => tag.id,
});

/**
 * Reducer for **tag** data
 */
export const TagReducer = createReducer(
  tagAdapter.getInitialState(),
  on(TagActions.addtag, (state, data) => tagAdapter.addOne(data.tag, state)),
  on(TagActions.addtags, (state, data) => tagAdapter.addMany(data.options, state)),
  on(TagActions.updatetag, (state, data) => tagAdapter.updateOne({ changes: data.tag, id: data.tag.id }, state)),
  on(TagActions.removetag, (state, data) => tagAdapter.removeOne(data.tag.id, state)),
  on(TagActions.emptytags, (state, _data) => tagAdapter.removeAll(state))
);

export const { selectAll, selectEntities, selectIds, selectTotal } = tagAdapter.getSelectors();
