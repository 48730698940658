import { Component, Input, OnInit } from "@angular/core";
import { MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Subject, Subscription } from "rxjs";

@Component({
  selector: "shared-dialog-wrapper[header]",
  templateUrl: "./dialog-wrapper.component.html",
  styleUrls: ["./dialog-wrapper.component.scss"],
})
/**
 * A component used to provide generic functionality to dialogs
 */
export class DialogWrapperComponent<T> implements OnInit {
  /**
   * If the close button should be showed so the user can click it to close
   */
  @Input() showCloseButton: boolean = true;

  /**
   * If given, this function will be called during the X button click instead of closing by default.
   */
  @Input() closeDialogCallback!: Function;

  /**
   * If given, this function will be called when the backdrop is clicked. Defaults to the same close
   *  as the X button.
   */
  @Input() backdropClickCallback!: Function;

  /**
   * If given, this dialog wrapper will auto close when the subscription changes. True
   *  should be the element is open, false is closed.
   */
  @Input() closeOnSubjectChange: Subject<boolean> | undefined;

  /**
   * The title to display in the header
   */
  @Input() header!: string;

  /**
   * Helps track the close subscription when related to closeOnSubjectChange
   */
  private closeOnSubscription: Subscription | undefined;

  constructor(public dialogRef: MatDialogRef<T>) {
    // Disable default closing capabilities so we have fully control
    this.dialogRef.disableClose = true;
  }

  /**
   * Returns any default mat dialog options we have deemed important for this modal
   */
  static getDefaultOptions() {
    return {
      panelClass: "dialog-container", // Adjust panel class to allow us to remove padding and allow the user more styling control
    } as MatDialogConfig<any>;
  }

  /**
   * Closes the wrapped dialog
   */
  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    // Handle close callbacks
    if (this.closeDialogCallback == null) this.closeDialogCallback = this.closeDialog.bind(this);
    if (this.backdropClickCallback == null) this.backdropClickCallback = this.closeDialog.bind(this);
    // Assign the backdrop click to allow for a close
    this.dialogRef.backdropClick().subscribe(this.backdropClickCallback.bind(this));
    // Handle auto closing from a subject
    if (this.closeOnSubjectChange) {
      this.closeOnSubscription = this.closeOnSubjectChange.subscribe((isClosed) => {
        // Close if false is given
        if (!isClosed) this.closeDialogCallback();
      });
      // Handle what to do when this ref closes
      const afterClosedSubscription = this.dialogRef.afterClosed().subscribe(() => {
        this.closeOnSubscription?.unsubscribe();
        afterClosedSubscription?.unsubscribe();
      });
    }
  }
}
