import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngularMaterialModule } from "@tdms/frontend/modules/material/material.module";
import { TimePickerComponent } from "@tdms/frontend/modules/shared/components/date-time-picker/time-picker/time-picker.component";
import { ServiceManager } from "@tdms/frontend/modules/shared/services/service.manager";
import {
  AudioPlayerComponent,
  ChangelogComponent,
  ConfirmationDialogComponent,
  DateTimePickerComponent,
  DialogWrapperComponent,
  DivProgressComponent,
  GenericTableComponent,
  HelpDialogComponent,
  ImgWrapperComponent,
  ImprovedSelectComponent,
  SplitButtonComponent,
  TabsBaseComponent,
  ThemeToggleComponent,
} from "./components";
import { ConfigDirective } from "./directive/config.directive";
import { NgVarDirective } from "./directive/ng-var.directive";
import { OverflowDirective, OverflowLastElementChildDirective } from "./directive/overflow.directive";
import { TextMaskDirective } from "./directive/text.mask.directive";

/**
 * Array of our components so we can easily declare and export them without duplicating
 */
const SHARED_COMPONENTS = [
  GenericTableComponent,
  DivProgressComponent,
  DialogWrapperComponent,
  HelpDialogComponent,
  ConfirmationDialogComponent,
  SplitButtonComponent,
  ImprovedSelectComponent,
  NgVarDirective,
  ImgWrapperComponent,
  TabsBaseComponent,
  AudioPlayerComponent,
  ImprovedSelectComponent,
  ThemeToggleComponent,
  DateTimePickerComponent,
  TimePickerComponent,
  TextMaskDirective,
  OverflowDirective,
  OverflowLastElementChildDirective,
  ConfigDirective,
  ChangelogComponent,
];

@NgModule({
  declarations: SHARED_COMPONENTS,
  imports: [CommonModule, AngularMaterialModule, ReactiveFormsModule, FormsModule],
  exports: SHARED_COMPONENTS,
  providers: [ServiceManager],
})
export class SharedModule {}
