import { createFeatureSelector, createSelector } from "@ngrx/store";
import { User } from "@tdms/common";
import { userAdapter } from "./user.reducer";
import { UserState } from "./user.state";

/**
 * Given a state selects the user object
 */
export const selectUserState = createFeatureSelector<UserState>("user");

/**
 * Returns all users
 */
export const selectAllUsers = (state: UserState) =>
  userAdapter
    .getSelectors()
    .selectAll(state)
    .map((x) => User.fromPlain(x));

/**
 * Returns, from state, the current users available
 */
export const selectAllUsersFromState = createSelector(selectUserState, selectAllUsers);

/**
 * Returns, from state, the currently selected user ID
 */
export const selectCurrentUserId = createSelector(selectUserState, (x) => x.selectedUserId);

/**
 * Returns, from state, the currently selected user object
 */
export const selectCurrentUser = createSelector(selectAllUsersFromState, selectCurrentUserId, (userEntities, userId) =>
  userEntities.find((x) => x.id === userId)
);

export const selectUserFromId = (userId: number) =>
  createSelector(selectAllUsersFromState, (userEntities) => userEntities.find((x) => x.id == userId));
