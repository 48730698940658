import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StoreModule } from "@ngrx/store";
import { ChartsModule } from "@tdms/frontend/modules/charts/charts.module";
import { AngularMaterialModule } from "@tdms/frontend/modules/material/material.module";
import { SharedModule } from "@tdms/frontend/modules/shared/shared.module";
import { MetricDashboardComponent } from "./components/dashboard/dashboard.component";
import { MetricCardComponent } from "./components/metric-card/metric-card.component";
import { MetricGridComponent } from "./components/metric-grid/metric-grid.component";
import { LegendService } from "./services/legend.service";
import { MetricGridService } from "./services/metric.grid.service";
import { MetricService } from "./services/metric.service";
import { metricReducer } from "./store/metric.reducer";

const METRIC_COMPONENTS = [MetricGridComponent, MetricCardComponent, MetricDashboardComponent];

@NgModule({
  declarations: METRIC_COMPONENTS,
  imports: [
    CommonModule,
    ChartsModule,
    AngularMaterialModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forFeature("metric", metricReducer),
  ],
  exports: METRIC_COMPONENTS,
  providers: [MetricGridService, MetricService, LegendService],
})
export class MetricsModule {}
