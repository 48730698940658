import { CustomTypes } from "./custom.types";

/**
 * A class that defines available permissions and what they are for. This is required for RBAC to define
 *  what capabilities a role can have
 */
export class RolePermission {
  /**
   * If the role should be considered an Admin. If true, all permissions below will be inherited. Admin will always superset any other overarching permissions.
   * @default false
   */
  admin: boolean = false;

  /**
   * If this role should have the ability to do the following to user accounts:
   *  1. Delete User accounts
   *  2. Lock User accounts
   *  3. Edit User account info
   *  4. Update Users
   * And other various user related capability
   * @default false
   */
  userManagement: boolean = false;

  /** If this role should have the ability to create users */
  createUser: boolean = true;

  /**
   * If this role should have the ability to do the following for sessions:
   * 1. Delete sessions
   * 2. Create Sessions
   * 3. Edit Sessions
   *
   * This **DOES NOT** apply to sessions created by the data store as of yet.
   * @default true
   */
  sessionManagement: boolean = true;
}

/** A type to help narrow down role permissions into single string values for lookups */
export type RolePermissionType = CustomTypes.PropertyNames<RolePermission, boolean>;
