import { Directive, Input, OnChanges, OnInit } from "@angular/core";
import { NgControl } from "@angular/forms";
import { AngularCustomTypes } from "@tdms/frontend/modules/shared/models/angular.custom.types";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

/**
 * This Angular directive was created with the intention of allowing you to display a different value in
 *  your formControl inputs than what the form control contains.
 *
 * Example:
 * ```
 * <input matInput [inputMask]="myValue" [formControl]="control" />
 * ```
 */
@Directive({ selector: "[inputMask]" })
export class TextMaskDirective extends SubscribingComponent implements OnInit, OnChanges {
  /** The actual value you want to display in this input to mask over the real value */
  @Input("inputMask") inputMask: Function | string | undefined;

  constructor(private readonly model: NgControl) {
    super();
  }

  setValue(value = this.inputMask) {
    this.model.valueAccessor?.writeValue(value);
  }

  ngOnInit(): void {
    // Set initial value
    this.setValue();
    // Register a subscription to update the value as it changes
    this.addSubscription(
      this.model.valueChanges?.subscribe((_oldValue) => this.model.valueAccessor?.writeValue(this.inputMask))
    );
  }

  ngOnChanges(changes: AngularCustomTypes.BaseChangeTracker<TextMaskDirective>) {
    this.setValue(changes.inputMask?.currentValue);
  }
}
