import { DialogRef } from "@angular/cdk/dialog";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { sessionFile } from "@tdms/frontend/modules/data-store/components/uploader/bulk-file-upload/bulk-file-upload.component";
import { TrackedFile } from "@tdms/frontend/modules/data-store/components/uploader/file-tree/models/tracked.file";

import { cloneDeep } from "lodash-es";

export interface sessionFileProperties {
  selectedSession: sessionFile;
  uploadFiles: { (sessionFile: sessionFile): void };
}

@Component({
  selector: "session-file-dialog",
  templateUrl: "./session-file-dialog.component.html",
  styleUrls: ["./session-file-dialog.component.scss"],
})
export class SessionFileDialogComponent implements OnInit {
  constructor(
    public dialogRef: DialogRef<SessionFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public properties: sessionFileProperties
  ) {
    if (properties.selectedSession.filesAssociatedWithSession)
      this.files = properties.selectedSession?.filesAssociatedWithSession;
  }

  files: TrackedFile[] = [];

  ngOnInit(): void {}

  updateFiles(files: TrackedFile[]) {
    this.files = cloneDeep(files);
  }

  fileRemove(file: TrackedFile) {
    const matchingIndex = this.files.findIndex((x) => x === file);
    if (matchingIndex !== -1) {
      this.files.splice(matchingIndex, 1);
      this.files = cloneDeep(this.files);
    }
  }

  addFilesToSession() {
    this.properties.selectedSession.filesAssociatedWithSession = this.files;
    this.dialogRef.close();
  }
}
