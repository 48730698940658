<shared-dialog-wrapper header="Changelog" class="changelog-dialog" #dialogWrapper>
  <div class="changelog-container">
    <!-- Overarching releases to add a legend  -->
    <div class="release-legend">
      <span class="total-releases">
        <b>{{ releases.length }}</b> Total Releases
      </span>
      <!-- Accordion for releases -->

      <mat-accordion class="release-legend-accordion">
        <mat-expansion-panel
          *ngFor="let releaseYear of groupedReleases; first as isFirst"
          [expanded]="isFirst"
          class="release-legend-panel"
        >
          <!-- Year based description -->
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>{{ releaseYear.year }}</b>
            </mat-panel-title>
            <!-- How many releases for that year -->
            <mat-panel-description> {{ releaseYear.releases.length }} Releases </mat-panel-description>
          </mat-expansion-panel-header>
          <!-- Each specific release to display -->

          <div
            class="release"
            *ngFor="let release of releaseYear.releases"
            (click)="selectRelease(release)"
            [ngClass]="{ active: selectedRelease === release }"
          >
            <span>
              <b>{{ release.prettyVersion }}</b>
            </span>
            <span>{{ getPrettyDate(release.date) }}</span>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <!-- The actual content for the current release -->
    <div class="release-content-container" *ngIf="selectedRelease">
      <span class="release-header">
        <span class="release-title">{{ selectedRelease.prettyVersion }}</span>
        <span class="release-date">{{ getPrettyDate(selectedRelease.date, true) }}</span>

        <!-- Total changes -->
        <div
          class="total-changes"
          [matTooltip]="'Added ' + selectedTotalInsertions + ', deleted ' + selectedTotalDeletions + ' lines of code.'"
        >
          <!-- Change count -->
          <span class="change-count">
            <span class="deletions">{{ selectedTotalDeletions }}-</span>
            <span class="insertions">{{ selectedTotalInsertions }}+</span>
          </span>
          <!-- Pretty indicator -->
          <mat-progress-bar class="progress" mode="determinate" [value]="getTotalChangesProgress()"></mat-progress-bar>
        </div>
      </span>
      <div class="release-content">
        <!-- New Features -->
        <ng-container *ngIf="selectedReleaseFeatures.length !== 0">
          <span class="header">Features</span>
          <ul>
            <li *ngFor="let feature of selectedReleaseFeatures">
              {{ feature.prettyMessage }}
            </li>
          </ul>
        </ng-container>
        <!-- Bugfixes -->
        <ng-container *ngIf="selectedReleaseBugfixes.length !== 0">
          <span class="header">Bugfixes</span>
          <ul>
            <li *ngFor="let feature of selectedReleaseBugfixes">
              {{ feature.prettyMessage }}
            </li>
          </ul></ng-container
        >
      </div>
    </div>
  </div>
</shared-dialog-wrapper>
