import { TextFieldModule } from "@angular/cdk/text-field";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { StoreModule } from "@ngrx/store";
import { ChartsModule } from "@tdms/frontend/modules/charts/charts.module";
import { AngularMaterialModule } from "@tdms/frontend/modules/material/material.module";
import { MetricsModule } from "@tdms/frontend/modules/metrics/metrics.module";
import { SharedModule } from "@tdms/frontend/modules/shared/shared.module";
import { DataStoreDashboardComponent } from "./components/dashboard/dashboard.component";
import { AggregateFileComponent } from "./components/shared/aggregate-file-splitter/aggregate-file-component";
import { QueryModule } from "./components/shared/query/query.module";
import { RoleMappingComponent } from "./components/shared/role-mapping/role-mapping.component";
import { StringSearchHelpDialogComponent } from "./components/shared/string-search-help/string-search-help.component";
import { AccessLogComponent } from "./components/tabs/access-log/access-log.component";
import { NewAccessDialogComponent } from "./components/tabs/access-log/new-access-dialog/new-access-dialog.component";
import { HomeComponent } from "./components/tabs/home/home.component";
import { RecycleBinComponent } from "./components/tabs/recycle-bin/recycle-bin.component";
import { SearchComponent } from "./components/tabs/search/search.component";
import { UploadMetadataComponent } from "./components/upload-metadata/upload-metadata.component";
import { AggregateUploaderComponent } from "./components/uploader/aggregate-uploader/aggregate-uploader.component";
import { BulkFileUploadComponent } from "./components/uploader/bulk-file-upload/bulk-file-upload.component";
import { RenameSessionFileDialogComponent } from "./components/uploader/bulk-file-upload/rename-session-file-dialog/rename-session-file-dialog.component";
import { SessionFileDialogComponent } from "./components/uploader/bulk-file-upload/session-file-dialog/session-file-dialog.component";
import { ExtraFilesComponent } from "./components/uploader/extra-files/extra-files.component";
import { FileTreeComponent } from "./components/uploader/file-tree/file-tree.component";
import { NodeComponent } from "./components/uploader/file-tree/node/node.component";
import { FileUploaderComponent } from "./components/uploader/file-uploader/file-uploader.component";
import { SessionCreationComponent } from "./components/uploader/session-creation/session-creation.component";
import { SessionExtrasComponent } from "./components/uploader/session-extras/session-extras.component";
import { UploaderComponent } from "./components/uploader/uploader-base/uploader.component";
import { dataStoreReducer } from "./models/store/data.store.reducer";
import { AccessLogService } from "./services/access-log.service";
import DataStoreMetricService from "./services/data.store.metrics.service";
import DataStoreService from "./services/data.store.service";
import { DownloadService } from "./services/download.service";
import RecycleBinService from "./services/recycle-bin.service";
import { SearchService } from "./services/search.service";
import { UploadService } from "./services/upload.service";

/**
 * Components provided by this module
 */
const COMPONENTS = [
  AggregateUploaderComponent,
  AggregateFileComponent,
  UploaderComponent,
  SessionCreationComponent,
  SessionExtrasComponent,
  FileUploaderComponent,
  FileTreeComponent,
  NodeComponent,
  ExtraFilesComponent,
  DataStoreDashboardComponent,
  HomeComponent,
  RecycleBinComponent,
  AccessLogComponent,
  SearchComponent,
  NewAccessDialogComponent,
  RoleMappingComponent,
  UploadMetadataComponent,
  BulkFileUploadComponent,
  SessionFileDialogComponent,
  RenameSessionFileDialogComponent,
  StringSearchHelpDialogComponent,
];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    SharedModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MetricsModule,
    ChartsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    QueryModule,
    TextFieldModule,
    // Apply the data store module
    StoreModule.forFeature("dataStore", dataStoreReducer),
  ],
  providers: [
    DownloadService,
    UploadService,
    DataStoreService,
    DataStoreMetricService,
    RecycleBinService,
    SearchService,
    AccessLogService,
  ],
  exports: COMPONENTS,
})
export class DataStoreModule {}
