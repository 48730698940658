import { Component, ContentChild, Input, OnChanges, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatFormFieldAppearance } from "@angular/material/form-field";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSelect } from "@angular/material/select";
import { ConfigDirectiveProperties } from "@tdms/frontend/modules/shared/directive/config.directive";
import { AngularCustomTypes } from "@tdms/frontend/modules/shared/models/angular.custom.types";
import { isObject } from "lodash";
import { isEqual, startCase } from "lodash-es";

/**
 * Sometimes you may want to include a button before the dropdown for a user to do some form of related functionality. This type defines
 *    what those buttons can do with some properties.
 */
export type ImprovedSelectPrefixButton = {
  icon: string;
  callback: Function;
  tooltip?: string;
  configDirective?: { path: ConfigDirectiveProperties; tooltip?: string };
};

export type ImprovedSelectSupportedObjectType = { name: string };
export type ImprovedSelectSupportedTypes =
  | string
  | ImprovedSelectSupportedObjectType
  | ImprovedSelectSupportedObjectType[];

/**
 * ## An improved mat-select that supports things like a filter and a paginator
 *
 * ### Single format display override:
 * You can override the display by doing the following:
 *
 * ```
 * <shared-improved-select>
 *  <!-- Replace the rendering of the standard data -->
 *  <ng-template replace="optionDisplay" let-value="value" let-valueClean="valueClean">
 *    <mat-icon class="tag-icon">{{ value }}</mat-icon>
 *    <span>{{ valueClean }}</span>
 *   </ng-template>
 *  </shared-improved-select>
 * ```
 *
 * In that above example we utilize the values given to us of `value` (the either option value or the selected value) and
 *  the `valueClean` (either the human readable option or the human readable selected value). We can then take these and
 *  replace how the options and selected would normally be displayed simply by passing our own ng template. This component will then
 *  handle the rest.
 *
 * It is vital that you understand that `value` is the original value option and `valueClean` is the original value ***converted to a more human
 *  readable format***.
 *
 * ### Multiple format display override:
 *  You can also override the display rendering multiple components by doing something like:
 * ```
 * <shared-improved-select>
 *  <!-- Replace the rendering of the standard data -->
 *  <ng-template replace="optionDisplay" let-value="value" let-valueClean="valueClean" let-multiValue="multiValue">
 *    <!-- Render our multiple tags -->
 *    <ng-container *ngIf="multiValue">
 *      <app-tag *ngFor="let tag of multiValue" [data]="tag"></app-tag>
 *    </ng-container>
 *    <!-- Render singular tags -->
 *    <app-tag *ngIf="!multiValue && value" [data]="value"></app-tag>
 *   </ng-template>
 *  </shared-improved-select>
 * ```
 *
 * This would allow us to customize the multi value render display for the top level dropdown and still customize the singular element
 *  display.
 *
 * ### Accepted data format:
 * This component supports data in two types:
 *
 * 1. A string array
 * 2. An object array: This must have a value called name like {name: string}.This is so we have something that the filter can apply on.
 */
@Component({
  selector: "shared-improved-select[dropdownOptions]",
  templateUrl: "./improved-select.component.html",
  styleUrls: ["./improved-select.component.scss"],
})
export class ImprovedSelectComponent<T extends ImprovedSelectSupportedTypes> implements OnInit, OnChanges {
  /**
   * The name to place in this select as a placeholder
   */
  @Input() dropdownName: string = "Select";
  /**
   * The current options to display
   */
  @Input() dropdownOptions: T[] = [];

  /** Allows you to provide a form control to handle the improved select instead of passing the selected value as needed */
  @Input() control: FormControl | undefined = new FormControl();

  /**
   * The current value we have selected
   * @deprecated Use reactive forms with {@link control}
   */
  @Input() selectedValue: T | T[] | undefined;

  /**
   * If the current dropdown element is clearable
   */
  @Input() clearable = false;

  /**
   * If the paginator should be displayed
   */
  @Input() paginatorEnabled = false;
  /**
   * How many items we want to display on each page of the paginator, if enabled
   */
  @Input() itemsPerPage = 10;

  /**
   * Minimum current index for items on the paginator
   */
  minValue: number = 0;

  /**
   * Maximum current index for items on the paginator
   */
  maxValue: number = 0;

  /**
   * If we should give the user the option to filter through the dropdown content
   */
  @Input() filterEnabled = false;

  /**
   * The current filter for the dropdown
   */
  filterValue: string = "";

  /**
   * Template reference for a different render for the option display
   */
  @ContentChild(TemplateRef) optionDisplayRef!: TemplateRef<any>;

  /**
   * This function will be called when your selected value is changed
   */
  @Input() selectedValueChanged!:
    | { (newValue: T | undefined): void }
    | { (newValue: T[] | undefined): void }
    | undefined;

  /**
   * Controls if the user should be able to select multiple elements in the select or not
   * @default false
   */
  @Input() allowMultipleSelections: boolean = false;

  /**
   * Accessor for the paginator
   */
  @ViewChild("dropdownPaginator") paginator!: MatPaginator;

  /**
   * Appearance to apply to the styling of the component
   */
  @Input() appearance: MatFormFieldAppearance = "outline";

  /**
   * The coloring to apply to the select component
   */
  @Input() color: string | "accent" | "primary" = "accent";

  /**
   * If the dropdown arrow should be hidden. It will always be hidden if it is clearable and an element is selected
   * @default false
   */
  @Input() hideDropdownArrow = false;

  /**
   * If the name should be cleaned to look more pretty during display.
   * @default true
   */
  @Input() shouldCleanName = true;

  /** Buttons to prefix the dropdown with in the event you want to show some other helpful callbacks */
  @Input() prefixButtons: ImprovedSelectPrefixButton[] = [];

  /** Central location for the options to display in the dropdown. We separate it into it's own object to reduce re-rendering. */
  displayOptions: { options: T[]; autoHideOptions?: T[] } = { options: [], autoHideOptions: [] };

  /** Accessor for the mat select */
  @ViewChild("select") select!: MatSelect;

  constructor() {}

  ngOnChanges(changes: AngularCustomTypes.BaseChangeTracker<ImprovedSelectComponent<any>>): void {
    if (changes.selectedValue) this.control?.setValue(changes.selectedValue.currentValue);
    if (changes.selectedValue || changes.dropdownOptions) this.displayOptions = this.getDisplayOptions();
  }

  ngOnInit(): void {
    this.maxValue = this.itemsPerPage;
    // Override internal control if we are using setValue mode
    if (this.selectedValue) this.control?.setValue(this.selectedValue);
    this.displayOptions = this.getDisplayOptions();
  }

  /**
   * Returns our filtered display options
   */
  get options() {
    return (
      this.dropdownOptions?.filter((x) => {
        const nameVal = isObject(x) ? (x as ImprovedSelectSupportedObjectType).name : (x as string);
        return nameVal.toLowerCase().trim().includes(this.filterValue.toLowerCase().trim());
      }) || []
    );
  }

  get nonFilteredOptions() {
    return this.dropdownOptions;
  }

  /**
   * Given a string to filter by, updates the option filter
   */
  filterOptions(filterValue: string) {
    this.filterValue = filterValue;
    // Reset page
    this.paginator?.firstPage();
    this.displayOptions = this.getDisplayOptions();
  }

  /**
   * Cleans the display name to make it more readable
   */
  cleanName(val: T | T[] | undefined) {
    if (Array.isArray(val) || val == null) return "";
    if (typeof val === "string")
      if (this.shouldCleanName) return startCase(val);
      else return val;
    else if (this.shouldCleanName) return startCase(val.name);
    else return val.name;
  }

  /** Handles when the selection changes from within the mat-select */
  selectionChange(val: any) {
    if (this.selectedValueChanged) this.selectedValueChanged(val);
  }

  /**
   * Clears the currently selected element
   */
  clearSelected(event: MouseEvent) {
    event.stopPropagation();
    this.selectedValueChanged?.call(this, undefined);
  }

  /**
   * Updates the paginator data set
   */
  updatePageData(event: PageEvent): PageEvent {
    this.minValue = event.pageIndex * event.pageSize;
    this.maxValue = this.minValue + event.pageSize;
    this.displayOptions = this.getDisplayOptions();
    return event;
  }

  get currentValue() {
    return this.control == null ? this.selectedValue : this.control.value;
  }

  /**
   * Returns the array data set of selected values if it exists
   */
  get multiSelectedValue() {
    if (Array.isArray(this.currentValue) && this.allowMultipleSelections) return this.currentValue;
    else return undefined;
  }

  /**
   * Returns options to be displayed. Can be options that shouldn't be displayed but should be created
   *  and standard options.
   */
  getDisplayOptions(): { options: T[]; autoHideOptions?: T[] } {
    if (!this.paginatorEnabled) return { options: this.options };
    else {
      const slicedOptions = this.options.slice(this.minValue, this.maxValue);
      let additionalValuesToShow: T[] = [];
      if (Array.isArray(this.currentValue)) {
        // Values from other pages that should be shown
        additionalValuesToShow = this.currentValue
          .map((selectedVal) => {
            const valueAlreadyShown = slicedOptions.findIndex((option) => isEqual(selectedVal, option)) != -1;
            if (valueAlreadyShown) return undefined;
            else return this.nonFilteredOptions.find((option) => isEqual(selectedVal, option));
          })
          .filter((x) => x != null) as T[];
      } else {
        if (this.currentValue) {
          const selectedAlreadyShown = slicedOptions.findIndex((option) => isEqual(this.currentValue, option)) != -1;
          if (!selectedAlreadyShown) additionalValuesToShow = [this.currentValue];
        }
      }
      return { options: slicedOptions, autoHideOptions: additionalValuesToShow as T[] };
    }
  }

  get selectedValueHasContent() {
    return this.currentValue != null;
  }

  matOptionCompare(o1: T, o2: T) {
    return isEqual(o1, o2);
  }

  /** Used for the ngFor tackBy to help isolate changes as they occur for the prefix buttons */
  buttonTrackBy(_index: number, identity: ImprovedSelectPrefixButton) {
    return identity.icon; // Icon should be unique enough
  }
}
