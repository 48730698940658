import { Type } from "class-transformer";
import { v4 as uuidv4 } from "uuid";
import { TDMSBase } from "../../models/base";
import { Configuration } from "../../models/config";
import { RolePermissionType } from "../../models/permission";

/** Supported payload types via the TDMS WebSocket Message */
export type PayloadType = Object | string | Array<any> | undefined;

/**
 * The base class for a websocket message for the TDMS architecture.
 *
 * In the following format of JSON:
 *
 * ```json
 * {
 *  "topic": "test",
 *  "payload": "foobar",
 *  "appID": "8c8ba470-9708-498a-9ea0-37ffa3aa778b",
 *  "timestamp": 1674657549535
 * }
 * ```
 */
export class TDMSWebSocketMessage<Payload extends PayloadType = Object> extends TDMSBase {
  /**
   * The payload (or data) to send with this message
   */
  payload: Payload;

  /**
   * The queue to associate to this message request/response data set.
   */
  topic: string;

  /**
   * The ID of this client associated to this websocket. This should be a UUID.
   */
  appID: string;

  /**
   * The time this message was sent.
   */
  @Type(() => Date)
  timestamp: Date;

  /**
   * The session id this payload and other data is currently utilizing. Most data sets
   *  that contain payloads should fill this field out.
   */
  sessionId: number | undefined;

  /**
   * The message id should be a unique UUID value that identifies this message amongst all others.
   * This should be generated by the client that initiates the request and used to identify the response message attached.
   * The backend should ensure that any responses have the same UUID as the request that initiated it.
   * If a value is not provided when the message is created, it will be generated using @crypto.randomUUID()
   */
  messageId: string;

  constructor(
    topic: string,
    sessionId?: number,
    payload?: Payload,
    appID: string = Configuration.APP_ID,
    messageId: string = uuidv4(),
    timestamp: Date = new Date()
  ) {
    super();
    this.payload = payload!;
    this.sessionId = sessionId;
    this.topic = topic;
    this.appID = appID;
    this.timestamp = timestamp;
    this.messageId = messageId;
  }

  /**
   * Given a request message and a payload, create a new message instance that will act as a response to that request.
   * @param request The request to respond to.
   * @param payload The payload to attach to the response.
   * @returns The new message formatted as a response.
   */
  static fromRequest<ResponsePayloadType extends Object | string | Array<any> | undefined = Object>(
    request: TDMSWebSocketMessage,
    payload: ResponsePayloadType
  ): TDMSWebSocketMessage<ResponsePayloadType> {
    return new TDMSWebSocketMessage(request.topic, request.sessionId, payload, request.appID, request.messageId);
  }
}

/**
 * The type of responses that our listeners can reply with
 *
 *  - `originalClient`: Will send this message back to the client that sent the original message triggering this event
 *  - `allClients`: Will send this message to all clients connected
 *  - `allClientsWithPermission`: Will send to all clients that are authenticated and match the given permission
 *  - `sameUser`: Will send to all clients that are the same user as the requestee that initiated this update.
 */
export type ResponseType = "originalClient" | "allClients" | "allClientsWithPermission" | "sameUser";

/**
 * This class defines what action should be taken from websocket listeners in the backend
 *  to be able to send messages back out
 */
export class WebSocketResponse {
  /**
   * The data to send out to a client
   */
  payload: TDMSWebSocketMessage;

  /**
   * Where to send the data.
   */
  responseType: ResponseType;

  /** Required permissions for the clients to send out on */
  requiredPermissions: RolePermissionType[] | undefined = undefined;

  constructor(
    payload: TDMSWebSocketMessage,
    responseType: ResponseType = "originalClient",
    requiredPermissions?: RolePermissionType[]
  ) {
    this.payload = payload;
    this.responseType = responseType;
    this.requiredPermissions = requiredPermissions;
  }
}
