import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Store } from "@ngrx/store";
import { selectSessionFromId, selectSessionState } from "@tdms/frontend/modules/session/store/session.selector";
import { SessionState } from "http2";
import { firstValueFrom } from "rxjs";

/**
 * This guard requires that a session is selected before passing this guard
 */
@Injectable()
export class SessionSelectedGuard implements CanActivate {
  constructor(private store: Store<SessionState>) {}
  /**
   * Handles determining if the route can be activated based on if we have a selected session or not
   */
  async canActivate() {
    const selectedSessionID = (await firstValueFrom(this.store.select(selectSessionState))).selectedSessionID;
    const selectedSession = await firstValueFrom(this.store.select(selectSessionFromId(selectedSessionID)));
    return selectedSessionID != null && selectedSession != null && !selectedSession.isProcessing;
  }
}
