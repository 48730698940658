import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from "@angular/core";

/** Context class for internal handling with ngVar */
class NgVarContext<T = unknown> {
  public $implicit: T = null!;
  public ngVar: T = null!;
}

/**
 * A directive that allows placement of variables inside angular templates to reduce duplicate
 *  calls to the same functionality. You can use this like:
 *
 * ```
 * <div *ngVar="45 as myVariableName">
 *  <span>{{myVariableName | "test"}}</span>
 * </div>
 * ```
 */
@Directive({
  selector: "[ngVar]",
})
export class NgVarDirective<T = unknown> {
  private _context: NgVarContext<T> = new NgVarContext<T>();
  private _viewRef: EmbeddedViewRef<NgVarContext<T>> | null = null;

  @Input()
  set ngVar(context: T) {
    this._context.$implicit = this._context.ngVar = context;
    if (!this._viewRef) this._viewRef = this._viewContainer.createEmbeddedView(this._templateRef, this._context);
  }
  static ngTemplateGuard_ngVar: "binding";

  // Passing down variable Type
  static ngTemplateContextGuard<T>(_dir: NgVarDirective<T>, _ctx: any): _ctx is NgVarContext<T> {
    return true;
  }

  constructor(private _viewContainer: ViewContainerRef, private _templateRef: TemplateRef<NgVarContext<T>>) {}
}
