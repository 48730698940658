import { Component, Input } from "@angular/core";
import { AngularCustomTypes } from "@tdms/frontend/modules/shared/models/angular.custom.types";
import { ChartOptions } from "chart.js";
import { merge } from "lodash-es";
import { BehaviorSubject, Observable } from "rxjs";
import { LineChartComponent } from "../line/line.component";
import { ExtendedChartOptions } from "../shared/plugins/plugin.typing";
import { TimelineChartBaseComponent } from "../shared/timeline-base/timeline.base.component";

/**
 * The content the zoom domain will output
 */
export type ZoomDomainContent = { domain: [Date, Date] | undefined; source: "timeline" | "external" };

/**
 * A centralized type to help enforce the event emitter capabilities that will be used when
 *  the timeline "zooms" into an area.
 */
export type ZoomDomainUpdateEmitter = BehaviorSubject<ZoomDomainContent>;

/**
 * Observable typing for timeline zoom
 */
export type ZoomDomainObservable = Observable<ZoomDomainContent>;

/**
 * A chart that extends upon the LineChart capability to apply a timeline style format incorporating zoom level capabilities.
 */
@Component({
  selector: "charts-timeline[data][colorLookup][configuration][domainUpdate]",
  templateUrl: "../shared/base/base.component.html",
  styleUrls: ["../shared/base/base.component.scss"],
})
export class TimelineChartComponent extends LineChartComponent {
  /**
   * The emitter for us to call when the zoom domain is updated. Allows external components to control
   *  what the timeline should display as "selected"
   */
  @Input() domainUpdate: ZoomDomainUpdateEmitter | undefined;

  override get kebabMenuEnabled() {
    return false;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    // Subscribe to the changes if the domain is updated externally
    if (this.domainUpdate) {
      // Grab current domain so we are up to date
      this.setDomainData({ ...this.domainUpdate.getValue(), source: "external" });
      // Listen for domain updates
      this.addSubscription(this.domainUpdate.subscribe(this.setDomainData.bind(this)));
    }
  }

  /** Updates our domain based on given zoom domain content */
  setDomainData(x: ZoomDomainContent) {
    if (x.source === "external") {
      if (x.domain == null) {
        this.minSelection = undefined;
        this.maxSelection = undefined;
      } else {
        this.maxSelection = x.domain[0];
        this.minSelection = x.domain[1];
      }
      // Update the chart to re render the plugin options
      this.currentChart?.update();
    }
  }

  override shouldChartUpdate(
    changes: AngularCustomTypes.BaseChangeTracker<TimelineChartBaseComponent<any, any>>
  ): boolean {
    return super.shouldChartUpdate(changes, false);
  }

  override chartOptionOverrides(coreOptions: ChartOptions<"line">): ExtendedChartOptions<"line"> {
    // Grab super options
    const superOptions = super.chartOptionOverrides(coreOptions);
    // Grab new options
    const newOptions: ExtendedChartOptions<"line"> = {
      scales: {
        x: {
          // Override the domain range usage as we are instead going to use it as the max/min for the timeline selection area
          min: null as any,
          max: null as any,
        },
      },
      plugins: {
        bookmark: {
          onClick: undefined,
          applyHoverStyling: false,
          shouldRenderHorizontal: false,
        },
        customTooltip: {
          enabled: false,
        },
        select: {
          // Utilize centralized functionality but override some defaults
          minCurrentSelect: this.minSelection,
          maxCurrentSelect: this.maxSelection,
          enabled: true,
          strokeStartStopOnly: false,
          dragCompleteCallback: (leftVal, rightVal) => {
            this.minSelection = leftVal;
            this.maxSelection = rightVal;
            if (leftVal && rightVal)
              this.domainUpdate?.next({ domain: [this.minSelection!, this.maxSelection!], source: "timeline" });
            else this.domainUpdate?.next({ domain: undefined, source: "timeline" });
          },
        },
      },
      layout: {
        padding: {
          left: 20,
        },
      },
    };
    return merge(superOptions, newOptions);
  }
}
