import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { UploadOption } from "@tdms/common";
import { TrackedFile } from "@tdms/frontend/modules/data-store/components/uploader/file-tree/models/tracked.file";
import { DataStoreState } from "@tdms/frontend/modules/data-store/models/data.store.state";
import { selectDataStoreUploadOptions } from "@tdms/frontend/modules/data-store/models/store/data.store.selector";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { cloneDeep } from "lodash-es";

import { FileUploaderComponent } from "../file-uploader/file-uploader.component";

/**
 * A component to display an uploader as well as the file tree
 *  when uploading extra files to a session
 */
@Component({
  selector: "data-store-extra-files",
  templateUrl: "./extra-files.component.html",
  styleUrls: ["./extra-files.component.scss"],
})
export class ExtraFilesComponent extends SubscribingComponent {
  /**
   * Title text to display
   */
  @Input() header = "Extra Files Uploader";

  /**
   * Files that are display in this extra files selector
   */
  @Input() files: TrackedFile[] = [];

  /**
   * An emitter for when our files are updated in this display
   */
  @Output() filesUpdated = new EventEmitter<TrackedFile[]>();

  /**
   * The supported types of uploads that were grabbed from the backend
   */
  @Input() uploadTypes: UploadOption[] = [];

  /** If we should display that this upload could act as a session creation */
  @Input() shouldDisplaySessionCreationActor = true;

  constructor(private store: Store<DataStoreState>) {
    super();

    this.addSubscription(
      this.store.select(selectDataStoreUploadOptions).subscribe((options) => {
        this.uploadTypes = FileUploaderComponent.getUploadTypesForOptions(options, "exclude-session-creation");
      })
    );
  }

  /**
   * Updates the extra files in the tree
   */
  updateExtraFiles(files: TrackedFile[]) {
    // Clone deep to forcibly change the reference
    this.files = cloneDeep(files);
    this.filesUpdated.next(this.files);
  }

  /**
   * Handles removing the given tracked file from the property array
   */
  extraFileRemove(file: TrackedFile) {
    const matchingIndex = this.files.findIndex((x) => x === file);
    if (matchingIndex !== -1) {
      this.files.splice(matchingIndex, 1);
      this.updateExtraFiles(this.files);
    }
  }
}
