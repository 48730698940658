import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

/**
 * The properties and inputs supported by the confirmation dialog
 */
export class ConfirmationDialogProperties {
  /**
   * The title text for the modal
   */
  header = "Delete Confirmation";
  /**
   * The description of what is occurring
   */
  description = "Are you sure you want to delete this element? You can't undo this action.";

  confirmButtonText = "Delete";
  confirmButtonColor = "warn";

  cancelButtonText = "Cancel";
  cancelButtonColor = "accent";

  /**
   * A function you would like execute when confirm is clicked
   */
  confirmClickCallback: Function | undefined;
  /**
   * A function you would like to execute when cancel is clicked
   */
  cancelClickCallback: Function | undefined;
}

/**
 * A generic confirmation dialog
 */
@Component({
  selector: "dialog-confirmation",
  templateUrl: "./confirmation.component.html",
  styleUrls: ["./confirmation.component.scss"],
})
export class ConfirmationDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public properties: ConfirmationDialogProperties
  ) {
    // Backdrop clicks count as cancel
    this.dialogRef.backdropClick().subscribe(this.cancelClick.bind(this));
    this.setDefaults();
  }

  /**
   * Sets missing values into properties
   */
  setDefaults() {
    const newInstance = new ConfirmationDialogProperties();
    Object.keys(newInstance).map((key) => {
      if ((this.properties as any)[key] == null) (this.properties as any)[key] = (newInstance as any)[key];
    });
  }

  /**
   * Handles confirming the modal
   */
  protected confirmClick() {
    if (this.properties.confirmClickCallback) this.properties.confirmClickCallback();
    this.dialogRef.close();
  }

  /**
   * Handles canceling the modal
   */
  protected cancelClick() {
    if (this.properties.cancelClickCallback) this.properties.cancelClickCallback();
    this.dialogRef.close();
  }

  ngOnInit(): void {}
}
