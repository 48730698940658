import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { User } from "@tdms/common";
import { UserActions } from "./user.action";
import { UserState } from "./user.state";

/**
 * NGRX entity userAdapter
 */
export const userAdapter: EntityAdapter<User> = createEntityAdapter<User>({
  selectId: (user) => user.id,
});

/**
 * Reducer for user NGRX Store
 */
export const userReducer = createReducer(
  userAdapter.getInitialState({
    selectedUserId: undefined,
  } as UserState),
  on(UserActions.add, (state, data) => {
    // Add User
    return userAdapter.addOne(data, state);
  }),
  on(UserActions.addMany, (state, data) => {
    // Add Many Users
    return userAdapter.addMany(data.users, state);
  }),
  on(UserActions.update, (state, data) => {
    // Update User
    return userAdapter.updateOne({ id: data.id, changes: data }, state);
  }),
  on(UserActions.delete, (state, data) =>
    // Delete User
    userAdapter.removeOne(data.id, state)
  ),
  on(UserActions.selectById, (state, data) => {
    // Select a User
    return { ...state, selectedUserId: data.id };
  }),
  on(UserActions.emptyUsers, (state, _data) => {
    // Empty all users
    return userAdapter.removeAll(state);
  })
);
