import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { DataStoreFile } from "@tdms/common";
import { TrackedFile } from "@tdms/frontend/modules/data-store/components/uploader/file-tree/models/tracked.file";
import { DataStoreState } from "@tdms/frontend/modules/data-store/models/data.store.state";
import {
  selectDataStoreUploadOptions,
  selectFilesForSession,
} from "@tdms/frontend/modules/data-store/models/store/data.store.selector";
import { UploadService } from "@tdms/frontend/modules/data-store/services/upload.service";
import { selectCurrentSessionId } from "@tdms/frontend/modules/session/store/session.selector";
import { Configuration } from "@tdms/frontend/modules/shared/models/config";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { cloneDeep } from "lodash-es";
import { first, firstValueFrom } from "rxjs";

/**
 * This component is used to upload extra files to the current selected session
 */
@Component({
  selector: "data-store-session-extras",
  templateUrl: "./session-extras.component.html",
  styleUrls: ["./session-extras.component.scss"],
})
export class SessionExtrasComponent extends SubscribingComponent {
  /**
   * Extra files to be uploaded alongside the session
   */
  currentExtraFiles: TrackedFile[] = [];

  /**
   * Tracks if we are uploading content or not
   */
  isUploading = false;

  /**
   * Upload progress to display on the spinner
   */
  uploadingProgress: number | undefined = 0;

  /**
   * The text to display during upload
   */
  uploadingText = "";

  constructor(
    private store: Store<DataStoreState>,
    private uploadService: UploadService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<any>
  ) {
    super();
    // Grab files that already exist in this session so the frontend can handle alerting if we can't have anymore of a given type.
    this.store
      .select(selectFilesForSession)
      .pipe(first((x) => x != null))
      .subscribe(async (files) => {
        const uploadOptions = await firstValueFrom(this.store.select(selectDataStoreUploadOptions));
        const sessionFiles = files.map((x) => {
          return TrackedFile.fromPlainExtended({
            file: { name: x.fileName?.replace(DataStoreFile.COMPRESSION_EXTENSION, "") } as File,
            // Locate and use matching upload option
            fileType: uploadOptions.find((z) => z.associatedPlugin === x.matchingPlugin && z.name === x.pluginType),
            removable: false,
            countsAsNew: false,
            isDelayedSessionCreation: x.createdSession,
          });
        });
        this.updateExtraFiles(sessionFiles);
      });
  }

  /**
   * Updates the extra files defined by the property name to be displayed in the file tree
   */
  updateExtraFiles(files: TrackedFile[]) {
    // Clone deep to forcibly change the reference
    this.currentExtraFiles = cloneDeep(files);
  }

  /**
   * Returns if the submit button should be clickable or not
   */
  get submitButtonAvailable() {
    return this.currentNewFiles.filter((z) => z.countsAsNew).length !== 0;
  }

  /**
   * Returns the file/files suffix to display based on current files length
   */
  get suffix() {
    const totalData = this.currentNewFiles.length;
    return totalData > 1 ? "files" : "file";
  }

  /**
   * Returns files that are considered new and not already uploaded
   */
  get currentNewFiles() {
    return this.currentExtraFiles.filter((z) => z.countsAsNew);
  }

  /**
   * Fires a callback to upload these extra files to the current session
   */
  async submitCallback() {
    // Upload extra files to the current session
    const sessionId = await firstValueFrom(this.store.select(selectCurrentSessionId));
    if (sessionId) {
      try {
        this.isUploading = true;
        this.uploadingText = `Uploading ${this.currentNewFiles.length} ${this.suffix}...`;
        const result = await this.uploadService.uploadToDataStore(
          this.currentNewFiles,
          sessionId,
          undefined,
          (_, totalProgress) => {
            this.uploadingProgress = totalProgress;
            if (totalProgress === 100) {
              this.uploadingText = "Processing uploads...";
              this.uploadingProgress = undefined;
            }
          },
          false
        );
        // Caused by authentication
        if (result == null) return;
        const snackbarMessage =
          result.length > 1 ? `Successfully Uploaded ${this.currentNewFiles.length} ${this.suffix}` : result[0].message;
        this.dialogRef.close();
        this.snackBar.open(snackbarMessage, "close", Configuration.SnackbarConfig);
      } catch (e) {
        this.snackBar.open(`${e}`, "close", Configuration.ErrorSnackbarConfig);
      } finally {
        this.isUploading = false;
      }
    }
  }
}
