import { InjectableWebsockets, Session, User } from "@tdms/common";

export abstract class Service extends InjectableWebsockets {
  /**
   * Initialize any data or values for this service on initial app launch.
   * No User or Session data is available yet.
   */
  async initialize(): Promise<void> {}

  /**
   * Initialize any data or values for this service once the backend connection is established.
   * No User or Session data is available yet.
   */
  async onBackendConnected(): Promise<void> {}

  /**
   * Handle losing connection to the backend.
   * Services should update to assume that requests to the backend will no longer succeed until onBackendConnected is called again.
   */
  async onBackendDisconnected(): Promise<void> {}

  /**
   * Initialize data for this service after the user has logged in or out.
   * @param user The user that has logged in or out (undefined if logged out).
   * @param wasJWTLogin If this was an automatic login. Helps perform a delay to help the user not see abrupt page changes.
   */
  async onUserChanged(_: User, _wasJWTLogin = false): Promise<void> {}

  /**
   * Handle user logging out of the application.
   */
  async onUserLoggedOut(): Promise<void> {}

  /**
   * Initialize data for this service after a session has been selected or changed.
   * @param session The newly loaded session.
   */
  async onSessionChanged(_?: Session): Promise<void> {}

  /**
   * In the event the current selected session is updated but not actually changed, this will be fired
   */
  async onSessionUpdated(_: Session): Promise<void> {}

  /**
   * **Invoked after all services have successfully processed {@link onSessionChanged}.**
   *
   * This separate function is designed to enable asynchronous callback functionality
   * **upon the completion of all {@link onSessionChanged} executions and their associated awaits.**
   *
   * While {@link onSessionChanged} typically serves as the recommended approach for handling session change
   *  usage, {@link onSessionChangedPost} offers a mechanism to execute logic **once all session information has
   *  been definitively retrieved and processed.**
   *
   * You'll normally want to use {@link onSessionChanged} as the use of this function should be minimal.
   */
  async onSessionChangedPost(_?: Session): Promise<void> {}

  /**
   * This function will be called whenever the backend says we have had a large change of data involving our current session. This will
   *  more than likely be similar functionality to {@link onSessionChanged}
   */
  async onSessionDataRefresh(_: Session): Promise<void> {}
}
