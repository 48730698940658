import { Type } from "class-transformer";
import { TDMSBase } from "../base";
import { CLCLineChartSeriesMetadata } from "../plugins";
import { TimeSeriesChartBase } from "./base";
import { Bookmark } from "./bookmark";

/**
 * This is a broken down type of the line series data set
 */
export class LineChartSeries extends TDMSBase {
  /**
   * The value to actually display on the chart
   */
  value: number;
  /**
   * The x axis separator value
   */
  @Type(() => Date)
  name: Date;

  /** Optional data to apply for this data instance. You can consider this `metadata` */
  optional?: CLCLineChartSeriesMetadata;

  constructor(value: number, name: Date) {
    super();
    this.value = value;
    this.name = name;
  }
}

/**
 * Supported format for line chart data including multi series and standard
 */
export class LineChartData<
  SeriesType extends LineChartSeries = LineChartSeries
> extends TimeSeriesChartBase<SeriesType> {
  @Type(() => LineChartSeries)
  series: SeriesType[];

  static override toCSV(data: TimeSeriesChartBase<LineChartSeries>[]) {
    let csvString = "timestamp," + data.map((x) => x.name).join(",") + "\n";
    // Append the new lines for the CSV
    for (let i = 0; i < data[0].series.length; i++) {
      // Grab all data sets in a row
      csvString += `${data[0].series[i].name.toISOString()},` + data.map((x) => x.series[i].value).join(",") + "\n";
    }
    return csvString;
  }

  constructor(name: string, series: SeriesType[], seriesSpecificBookmarks: Bookmark[] = []) {
    super(name, seriesSpecificBookmarks);
    this.name = name;
    this.series = series;
  }
}
