import { createFeatureSelector, createSelector } from "@ngrx/store";
import { transcriptionAdapter } from "./transcription.reducer";
import { TranscriptionState } from "./transcription.state";

/**
 * Given a state selects the transcription state
 */
export const selectTranscriptionState = createFeatureSelector<TranscriptionState>("transcription");

/**
 * Returns all transcriptions
 */
export const selectAllTranscriptions = (state: TranscriptionState) =>
  transcriptionAdapter.getSelectors().selectAll(state);

/**
 * Returns, from state, the current transcriptions
 */
export const selectAllTranscriptionsFromState = createSelector(selectTranscriptionState, selectAllTranscriptions);
