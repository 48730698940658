<div class="sidenav-container">
  <!-- User card -->
  <mat-card class="user-card card" *ngIf="currentUser">
    <mat-card-content class="content">
      <!-- User dropdown button -->
      <button mat-button class="user-dropdown" matTooltip="User settings" [matMenuTriggerFor]="userMenu">
        <user-icon [user]="currentUser"></user-icon>
        <span class="username">
          {{ currentUser.username }}
        </span>
      </button>

      <!-- User dropdown -->
      <mat-menu #userMenu="matMenu" class="user-menu" xPosition="before">
        <div class="user-header">
          <span class="user-header-name"> {{ userService.currentGreeting + ", " + currentUser.username }}</span>
        </div>
        <mat-divider></mat-divider>
        <!-- User settings -->
        <button
          mat-menu-item
          matTooltip="Open the user settings page"
          matTooltipPosition="right"
          (click)="openSettings()"
        >
          <mat-icon>settings</mat-icon>
          <span>Settings</span>
        </button>
        <!-- Changelog -->
        <button
          mat-menu-item
          matTooltip="View previous release history"
          matTooltipPosition="right"
          (click)="openChangelog()"
        >
          <mat-icon>history</mat-icon>
          <span>Changelog</span>
        </button>
        <!-- Change Password -->
        <button
          *ngVar="userService.userIsDemoUser(userService.currentAuthenticatedUser) as userIsDemoUser"
          mat-menu-item
          [matTooltip]="
            userIsDemoUser ? 'You cannot change the demo users password' : 'Change the current users password'
          "
          matTooltipPosition="right"
          (click)="openChangePassword()"
          [disabled]="userIsDemoUser"
          matTooltip=""
          [configDisable]="'user.allowPasswordChange'"
          configDisabledStateMessage="Changing this users password is disabled"
        >
          <mat-icon>lock_reset</mat-icon>
          <span>Change Password</span>
        </button>
        <!-- Logout button -->
        <button
          mat-menu-item
          matTooltip="Logout {{ currentUser.username }}"
          (click)="logout()"
          matTooltipPosition="right"
        >
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </mat-card-content>
  </mat-card>

  <!-- Current session -->
  <mat-card class="session-card card">
    <mat-card-content class="content">
      <div class="header">
        <div class="text">{{ currentSession?.name || "No selected Session" }}</div>
      </div>
      <div class="button-container">
        <!-- Session selector -->
        <button
          mat-button
          class="button"
          [ngClass]="{ active: routerService.isActiveRoute('session-selection') }"
          (click)="sessionSelectionClick()"
        >
          <mat-icon>fact_check</mat-icon>
          <span>Select Session</span>
          <!-- Flex placeholder -->
          <div></div>
        </button>

        <!-- Uploader -->
        <button
          mat-button
          (click)="openUploader()"
          class="button"
          matTooltip=""
          matTooltipPosition="right"
          [configDisable]="['dataStore.uploadEnabled', 'session.allowSessionCreationFromScratch']"
          configDisabledStateMessage="Uploading is disabled"
        >
          <mat-icon>upload</mat-icon>
          <span>Upload</span>
          <div>
            <!-- Display a spinner if we are uploading files -->
            <span
              *ngIf="backgroundUploadsAreRunning"
              [matTooltip]="'Background uploads are running'"
              matTooltipPosition="right"
            >
              <mat-spinner
                color="accent"
                [diameter]="30"
                [value]="backgroundUploadProgress"
                [mode]="backgroundUploadProgress === 100 ? 'indeterminate' : 'determinate'"
              ></mat-spinner>
            </span>
          </div>
        </button>
      </div>
    </mat-card-content>
  </mat-card>

  <!-- Menu card -->
  <mat-card class="menu-card card">
    <mat-card-content class="content">
      <div class="header">Menu</div>
      <!-- Overarching accordion -->
      <mat-accordion class="grouping" multi>
        <!-- Render each section -->
        <ng-container *ngFor="let sectionOrOption of menus">
          <ng-container *ngIf="hasAvailableRoutes(sectionOrOption)">
            <ng-container *ngVar="configIsSection(sectionOrOption) as isSection">
              <!-- Singular element rendering -->
              <sidenav-router-button
                class="singular-button"
                *ngIf="!isSection"
                [config]="$any(sectionOrOption)"
              ></sidenav-router-button>

              <!-- Section rendering -->
              <mat-expansion-panel *ngIf="isSection" class="mat-elevation-z0 expansion" [expanded]="true" hideToggle>
                <!-- Header -->
                <mat-expansion-panel-header class="expansion-header">
                  <mat-panel-title>
                    {{ sectionOrOption.sidenavOptions!.title }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <!-- Content -->
                <div class="button-container">
                  <!-- Render the buttons dynamically -->
                  <ng-container *ngFor="let route of $any(sectionOrOption).configs">
                    <sidenav-router-button
                      [config]="route"
                      [parentConfig]="$any(sectionOrOption)"
                    ></sidenav-router-button>
                  </ng-container>
                </div>
              </mat-expansion-panel>
            </ng-container>
          </ng-container>
        </ng-container>
      </mat-accordion>

      <!-- Footer -->
      <div class="footer">
        <app-theme-toggle></app-theme-toggle>
      </div>
    </mat-card-content>
  </mat-card>
</div>
