<span class="split-button-container">
  <!-- Main button -->
  <button
    mat-raised-button
    [color]="color"
    (click)="mainButtonClick()"
    [matTooltip]="mainButtonTooltip"
    [disabled]="disabled"
    class="main-button"
    [ngClass]="{ 'is-split': displaySplit }"
  >
    {{ mainButtonText }}
  </button>

  <!-- Split button dropdown -->
  <button
    *ngIf="displaySplit"
    mat-raised-button
    [color]="color"
    class="split-button-right"
    [matMenuTriggerFor]="splitMenu"
    [matTooltip]="splitButtonTooltip"
  >
    <mat-icon>expand_more</mat-icon>

    <!-- Split button menu for the actual dropdown -->
    <mat-menu class="split-button-menu" #splitMenu="matMenu">
      <!-- Render child content -->
      <ng-content></ng-content>
    </mat-menu>
  </button>
</span>
