import { createActionGroup, props } from "@ngrx/store";
import { BookmarkType } from "@tdms/common";

/**
 * Handles adding and removing bookmark types from our store as we need
 */
export const BookmarkTypeActions = createActionGroup({
  source: "BookmarkType",
  events: {
    Add: props<BookmarkType>(),
    "Add Many": props<{ bookmarkTypes: BookmarkType[] }>(),
    Delete: props<BookmarkType>(),
    "Empty Bookmark Types": props<any>(),
  },
});
