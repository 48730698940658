import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { Transcription } from "@tdms/common";
import { TranscriptionActions } from "./transcription.adapter";
import { TranscriptionState } from "./transcription.state";

/**
 * NGRX entity adapter
 */
export const transcriptionAdapter: EntityAdapter<Transcription> = createEntityAdapter<Transcription>({
  selectId: (transcription) => transcription.id,
});

/**
 * Reducer for speaking data NGRX Store
 */
export const transcriptionReducer = createReducer(
  transcriptionAdapter.getInitialState({} as TranscriptionState),
  on(TranscriptionActions.add, (state, data) => transcriptionAdapter.addMany(data.data, state)),
  on(TranscriptionActions.update, (state, data) => transcriptionAdapter.updateOne(data, state)),
  on(TranscriptionActions.empty, (state, _data) => transcriptionAdapter.removeAll(state))
);
