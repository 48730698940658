export namespace MetricBaseSettings {
  /**
   * A helper enum that contains the names attached to the basic metric settings.
   */
  export enum Names {
    chartEnabled = "chart_enabled",
    bookmarksDisplayed = "bookmarks_displayed",
  }

  /**
   * A helper enum that contains the helpful titles attached to the basic metric settings.
   */
  export enum HelpfulTitles {
    chartEnabled = "Chart Enabled",
    bookmarksDisplayed = "Bookmarks Displayed",
  }

  /**
   * A helper enum that contains the helpful descriptions attached to the basic metric settings.
   */
  export enum HelpfulDescriptions {
    chartEnabled = "Controls the display of the chart on the dashboard view.",
    bookmarksDisplayed = "Controls drawing of session bookmarks on this chart.",
  }
}
