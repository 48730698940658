import { createActionGroup, props } from "@ngrx/store";
import { SettingValue } from "./setting.value.model";

/**
 * This action group controls the actions that manipulate Setting Values, which
 * are the actual setting objects referenced by a Setting Collection.
 */
export const SettingValuesActions = createActionGroup({
  source: "SettingValues",
  events: {
    "Add Settings": props<{ settings: SettingValue[] }>(),
    "Update Setting": props<Partial<SettingValue> & { name: string }>(),
  },
});
