import { TDMSBase } from "./base";

/**
 * This class helps handle messages back from the backend when errors or success occurs
 */
export class ServerStatus extends TDMSBase {
  /**
   * The message related to the success boolean
   */
  message: string;

  /**
   * Sub types that can help narrow the server status message
   */
  subType?: "audio-processing-error";

  /**
   * If the original request was successful or not
   */
  success: boolean;

  override toString() {
    return this.message;
  }

  constructor(message: string, success: boolean) {
    super();
    this.message = message;
    this.success = success;
  }
}

/**
 * This class helps relay server connection messages
 */
export class ServerConnectionInit extends ServerStatus {
  backendVersion: string;
  clientIdentifier: string;

  constructor(message: string, backendVersion: string, success: boolean, clientIdentifier: string) {
    super(message, success);
    this.backendVersion = backendVersion;
    this.clientIdentifier = clientIdentifier;
  }
}
