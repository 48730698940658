<div class="gauge-container">
  <!-- Actual gauge -->
  <span
    class="gauge"
    *ngFor="let gauge of gaugeRange"
    [matTooltip]="gauge.tooltip || gauge.min + ' - ' + gauge.max"
    [ngStyle]="{ backgroundColor: gauge.color, color: gauge.textColor }"
  >
    {{ gauge.name }}
  </span>
</div>
