<shared-dialog-wrapper header="Rename Your Session File" class="renaming-wrapper">
  <p class="renaming-instructions">Change the name of the session that will be created in the box below.</p>
  <div class="bullet-list">
    <ul>
      <li>Each session name is originally generated using file names</li>
      <li>You may rename your session using the input box below</li>
      <li>Your current session name will be uploaded as shown</li>
    </ul>
  </div>
  <div class="input-row">
    <mat-form-field color="accent" appearance="outline">
      <mat-label>{{ data.sessionName ? data.sessionName : data.file.file.name }}</mat-label>
      <input matInput placeholder="Rename your session file" [(ngModel)]="nameToDisplay" />
    </mat-form-field>
  </div>
  <div class="button-row">
    <button mat-flat-button color="accent" (click)="submitDialog()">Submit Name</button>
  </div>
</shared-dialog-wrapper>
