import { createActionGroup, props } from "@ngrx/store";
import { Tag } from "@tdms/common";

/**
 * Supported NGRX store **tag** actions
 */
export const TagActions = createActionGroup({
  source: "Tag",
  events: {
    AddTags: props<{ options: Tag[] }>(),
    EmptyTags: props<any>(),
    AddTag: props<{ tag: Tag }>(),
    UpdateTag: props<{ tag: Tag }>(),
    RemoveTag: props<{ tag: Tag }>(),
  },
});
