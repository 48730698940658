import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from "@angular/forms";
import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { CustomTypes } from "@tdms/common";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import { NativeUTCAdapter } from "./adapter";
import { TimePickerComponent } from "./time-picker/time-picker.component";

/**
 * A generic component that provides date and time picker capabilities.
 *
 * Utilizes the built in date picker and our own home grown time input.
 */
@Component({
  selector: "shared-date-time-picker[control]",
  templateUrl: "./date-time-picker.component.html",
  styleUrls: ["./date-time-picker.component.scss"],
  providers: [
    // Initialize our own date adapter as we need to use UTC
    { provide: DateAdapter, useClass: NativeUTCAdapter, deps: [MAT_DATE_LOCALE] },
  ],
})
export class DateTimePickerComponent extends SubscribingComponent implements OnInit {
  /** Text to place before Date and Time. You could pass "Start" to this to get "Start Date" and "Start Time" */
  @Input() textPrefix = "";

  /** If seconds should be shown in our time picker */
  @Input() showSeconds = true;

  /** The minimum date allowed for these inputs */
  @Input() min: Date | undefined;

  /** The maximum date allowed for these inputs */
  @Input() max: Date | undefined;

  /** Emits whenever the date/time has changed */
  @Output() dateChanged = new EventEmitter<Date>();

  /** Form control to handle our date object */
  @Input() control!: FormControl;

  /** Current date val set when the date picker opens */
  private currentDateVal: Date | undefined;

  ngOnInit(): void {
    this.addSubscription(
      this.control.valueChanges.subscribe(() => {
        const dateVal = this.control.value;
        this.dateChanged.next(dateVal);
      })
    );
  }

  /** Called when the date picker opens to keep track of the current date value set */
  datePickerOpened() {
    this.currentDateVal = this.control.value;
  }

  /** Called when the date picker is closed. {@link datePickerOpened} */
  datePickerClosed() {
    this.currentDateVal = undefined;
  }

  /** Handles functionality when the date picker value changes */
  dateChange(event: MatDatepickerInputEvent<Date, any>) {
    const changedDate = event.value;
    // Re-align times from the time picker
    if (this.currentDateVal)
      changedDate?.setUTCHours(
        this.currentDateVal.getUTCHours(),
        this.currentDateVal.getUTCMinutes(),
        this.currentDateVal.getUTCSeconds(),
        this.currentDateVal.getUTCMilliseconds()
      );
    this.control.setValue(changedDate);
    // Make sure we validate incase of ranges
    TimePickerComponent.checkFormGroup(this.control.root as FormGroup);
  }

  get invalidText() {
    return TimePickerComponent.getDateTimeControlInvalidText(this.control);
  }

  /** Validator to make sure start and end date's can't be the same in a specific form group */
  static dateDifferenceValidator<T extends Object>(
    currentControl: AbstractControl,
    startDateField: CustomTypes.PropertyNames<T, Date> = "startDate" as any,
    endDateField: CustomTypes.PropertyNames<T, Date> = "endDate" as any
  ): ValidationErrors | null {
    const badData = { same: true };
    const form = currentControl.root;
    if (form) {
      const startDate = form.get(startDateField)?.value?.getTime();
      const endDate = form.get(endDateField)?.value?.getTime();
      return startDate === endDate ? badData : null;
    }
    return badData; // Bad data by default
  }

  /** Validator to make sure start dates are less than end dates in a specific form group */
  static dateLessThanValidator<T extends Object>(
    currentControl: AbstractControl,
    startDateField: CustomTypes.PropertyNames<T, Date> = "startDate" as any,
    endDateField: CustomTypes.PropertyNames<T, Date> = "endDate" as any
  ): ValidationErrors | null {
    const badData = { notLessThan: true };
    const form = currentControl.root;
    if (form) {
      const startDate = form.get(startDateField)?.value?.getTime();
      const endDate = form.get(endDateField)?.value?.getTime();
      return startDate >= endDate ? badData : null;
    }
    return badData; // Bad data by default
  }
}
