import { TDMSBase } from "../../base";

export class VADParameters extends TDMSBase {
  threshold: number = 0.5;
  sampling_rate: number = 48000;
  min_speech_duration_ms: number = 250;
  max_speech_duration_s: number = Infinity;
  min_silence_duration_ms: number = 100;
  window_size_samples: number = 1536;
  speech_pad_ms: number = 30;
  return_seconds: boolean = false;
  visualize_probs: boolean = false;
}

/**
 * @deprecated In support of only using {@link diarization}. The microservice will however continue to return this
 *  but you should only use the diarization object.
 */
export class VADSpeechRange extends TDMSBase {
  start_seconds!: number;
  end_seconds!: number;
  speaker!: string;
}
