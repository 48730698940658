import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { RouterParamTypes, RouterService } from "@tdms/frontend/modules/routing/services/router.service";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

/** The tab configuration object for rendering */
export type Tab = { component: any; name: string };

/**
 * A generic component that provides auto handling tab capability on a display. This includes handling redirects
 *  to automatically go to the selected tab as necessary.
 */
@Component({
  selector: "shared-tabs-base[tabs][routerParamType]",
  templateUrl: "./tabs-base.component.html",
  styleUrls: ["./tabs-base.component.scss"],
})
export class TabsBaseComponent extends SubscribingComponent {
  /** The tabs given by the user that should be rendered */
  @Input() tabs: Tab[] = [];

  /** The route param type we intend to store our current tab under */
  @Input() routerParamType!: RouterParamTypes;

  /** The current selected tab index */
  selectedIndex = new FormControl(0);

  /**
   * @param routerParamType The parameter name we should be utilizing in the URL to maintain state
   */
  constructor(private routerService: RouterService, private ref: ChangeDetectorRef) {
    super();
    this.onRouteChanged = this.onRouteChanged.bind(this);
  }

  ngOnInit() {
    const paramIndex = this.routerService.getQueryParam(this.routerParamType);
    if (paramIndex != null) this.selectedIndex.setValue(paramIndex);
    this.addSubscription(this.routerService.navigationEnd.subscribe(this.onRouteChanged.bind(this)));
  }

  onRouteChanged(_event: any) {
    const paramIndex = this.routerService.getQueryParam(this.routerParamType);
    if (paramIndex != null) this.selectedIndex.setValue(paramIndex);
    /// This line is necessary in tandem with the double redirection in @SearchService
    /// to ensure that angular properly updates the tab index after user clicks a tab group manually then hits search again.
    this.ref.detectChanges();
  }

  /**
   * Given a tab change event, updates the current tracking in the URL so refreshes will dump you back to the same page
   */
  updateCurrentTab(change: MatTabChangeEvent) {
    this.routerService.setQueryParam(this.routerParamType, change.index);
  }

  /**
   * Given the items index and the item itself, returns the value that should be used
   *  for the trackBy of an ngFor statement to help prevent cards from unnecessarily recreating themselves.
   */
  getElementTracker(_index: number, item: Tab) {
    return item.name;
  }
}

/** A base level generic class for components to extend that wish to implement the tab capabilities */
export abstract class TabComponent {
  /**
   * @param paramType The param type for usage with the tabs to track the index across refreshes
   */
  constructor(public paramType: RouterParamTypes) {}

  abstract get tabs(): Tab[];
}
