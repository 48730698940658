import { BaseError, TDMSBase } from "../../base";
import { Transcription, TranscriptionModelOptionsType } from "./transcription";
import { VADParameters } from "./vad";

/**
 * A class used to define the request to the audio microservice for analyzing audio files
 */
export class AudioAnalyzeRequest extends TDMSBase {
  /**
   * The URL the microservice should pull the file from for downloading
   */
  url!: string;

  /**
   * The JWT to authenticate for the download with
   */
  token!: string;

  /**
   * The Id of the DataStoreFile
   */
  dataStoreFileId!: number;

  /**
   * The model to utilize during the request
   */
  transcriptionModel: TranscriptionModelOptionsType = "medium.en";

  /**
   * If we should attempt to process our audio files utilizing CUDA capabilities, if available.
   *
   * Note: CUDA capabilities do not transcribe multiple files at once since the GPU cache's the transcription so the API is
   *  not flexible enough to support this.
   */
  useGPUIfAvailable: boolean = true;

  /** How many speakers are in the file for diarization. Helps tune the accuracy. */
  diarizeSpeakerCount: number = 3;

  /** If we should send this request to the diarization engine */
  diarize: boolean = false;

  /** If we should send this request to the transcription engine */
  transcribe: boolean = false;

  /** If we should send this request to the vad engine */
  vad: boolean = false;

  /** If this request should ignore the cache on the microservice and force a regeneration */
  bypassCache: boolean = false;

  /** The paramaters to control the VAD model invocation (default if empty). */
  vadParams: VADParameters = new VADParameters();
}

/** Class that defines a request to refresh transcription data. Same class will be used for returns. */
export class TranscriptionRefresh extends BaseError {
  audioFileId!: number;
}

/** Class that defines a request to edit transcription data. Same class will be used for the response */
export class TranscriptionEdit extends BaseError {
  transcriptions!: Transcription[];
}
