<div class="improved-select-container">
  <!-- Render prefix buttons we want -->
  <div class="prefix-buttons" *ngIf="prefixButtons.length > 0">
    <button
      mat-icon-button
      class="button"
      *ngFor="let button of prefixButtons; trackBy: buttonTrackBy"
      (click)="button.callback()"
      [matTooltip]="button.tooltip || ''"
      [configDisable]="button.configDirective?.path"
      [configDisabledStateMessage]="button.configDirective?.tooltip"
    >
      <mat-icon>{{ button.icon }}</mat-icon>
    </button>
  </div>
  <!-- Render the actual form field for the select -->
  <mat-form-field
    [appearance]="appearance"
    [color]="color"
    class="form-field improved-dropdown-container-field improved-form-field improved-filter-form-field"
    [ngClass]="{
      'has-content': selectedValueHasContent,
      'has-clear-button': clearable && selectedValue,
      'has-prefix-children': prefixButtons.length > 0
    }"
  >
    <mat-label>{{ dropdownName }}</mat-label>
    <mat-select
      #select
      [formControl]="$any(control)"
      class="improved-dropdown"
      [ngClass]="{ 'hide-arrow': hideDropdownArrow || (currentValue != null && clearable) }"
      panelClass="improved-dropdown-panel"
      (selectionChange)="selectionChange($event.value)"
      [multiple]="allowMultipleSelections"
      (closed)="filterOptions('')"
      [compareWith]="matOptionCompare"
    >
      <mat-select-trigger class="trigger">
        <!-- Render either given container -->
        <ng-container
          select="[replace=optionDisplay]"
          *ngTemplateOutlet="
            optionDisplayRef;
            context: {
              value: currentValue,
              valueClean: cleanName(currentValue),
              multiValue: multiSelectedValue
            }
          "
        ></ng-container>
        <!-- Or standard data -->
        <span *ngIf="!optionDisplayRef">{{ cleanName(currentValue) }}</span>
        <!-- Clear for selected option button -->
        <button
          *ngIf="clearable && currentValue"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          class="clear"
          (click)="clearSelected($event)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-select-trigger>
      <!-- Filter -->
      <mat-form-field
        *ngIf="filterEnabled"
        color="accent"
        appearance="outline"
        class="improved-filter-form-field filter"
      >
        <input
          matInput
          type="text"
          [(ngModel)]="filterValue"
          placeholder="Filter"
          (keyup)="filterOptions($any($event.target)?.value)"
        />
        <!-- Clear button for filter -->
        <button
          *ngIf="filterValue"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          class="clear-filter"
          (click)="filterOptions('')"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <div class="option-list">
        <!-- Auto Hide Options. Things that should be displayed in the main bar but not be shown in the option list -->
        <mat-option class="hidden-option" *ngFor="let option of displayOptions.autoHideOptions" [value]="option">
          {{ cleanName(option) }}
        </mat-option>
        <!-- Standard options -->
        <mat-option
          *ngFor="let option of displayOptions.options"
          [value]="option"
          [ngClass]="{ 'hidden-option': $any(option).shouldAutoHide }"
        >
          <!-- Render either given container -->
          <ng-container
            select="[replace=optionDisplay]"
            *ngTemplateOutlet="
              optionDisplayRef;
              context: {
                value: option,
                valueClean: cleanName(option)
              }
            "
          ></ng-container>
          <!-- Or standard data -->
          <span *ngIf="!optionDisplayRef">{{ cleanName(option) }}</span>
        </mat-option>
        <!-- No option warning -->
        <mat-option disabled class="no-options-warning" *ngIf="displayOptions.options.length === 0">
          No options available
        </mat-option>
      </div>
      <!-- Paginator to help not load so much at once -->
      <mat-paginator
        #dropdownPaginator
        *ngIf="paginatorEnabled"
        [length]="options.length"
        [pageSize]="itemsPerPage"
        [hidePageSize]="true"
        (page)="updatePageData($event)"
        aria-label="Select page"
        class="improved-paginator"
      >
      </mat-paginator>
    </mat-select>
  </mat-form-field>
</div>
