import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AngularMaterialModule } from "@tdms/frontend/modules/material/material.module";
import { EnabledGuard } from "@tdms/frontend/modules/routing/guard/enabled.guard";
import { SessionSelectedGuard } from "@tdms/frontend/modules/session/guard/session.selected.guard";
import { SharedModule } from "@tdms/frontend/modules/shared/shared.module";
import { AuthGuard } from "@tdms/frontend/modules/user/guard/auth.guard";
import { UserModule } from "@tdms/frontend/modules/user/user.module";
import { MenuButtonComponent } from "./components/sidenav/menu-button/menu-button.component";
import { RouterButtonComponent } from "./components/sidenav/router-button/router.button.component";
import { SidenavComponent } from "./components/sidenav/sidenav.component";
import { RouteHelper } from "./models/helper";
import { RouterService } from "./services/router.service";

const COMPONENTS = [SidenavComponent, RouterButtonComponent, MenuButtonComponent];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    RouterModule.forRoot(RouteHelper.getRoutingModuleRoutes()),
    AngularMaterialModule,
    UserModule,
    SharedModule,
  ],
  exports: COMPONENTS,
  providers: [RouterService, AuthGuard, SessionSelectedGuard, EnabledGuard],
})
export class AppRoutingModule {}
