import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import { PopulatedSearchOption, SearchableOption } from "@tdms/common";

@Component({
  selector: "data-store-new-term-selector",
  templateUrl: "./new-term-selector.component.html",
  styleUrls: ["./new-term-selector.component.scss"],
})
export class NewTermSelectorComponent implements OnChanges {
  @Input() currentOption?: PopulatedSearchOption;
  @Input() availableSearchTerms?: SearchableOption[];

  @Input() onTermSelected!: (option: SearchableOption) => void;

  dropdownControl = new FormControl();

  constructor() {
    this.onDropdownChanged = this.onDropdownChanged.bind(this);
  }

  ngOnInit() {
    this.dropdownControl.setValue(this.currentOption);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentOption) {
      this.dropdownControl.setValue(this.currentOption);
    }
  }

  onDropdownChanged() {
    this.onTermSelected(this.dropdownControl.value);
    this.dropdownControl.reset();
    this.dropdownControl.markAsUntouched();
  }
}
