import { CustomTypes, Utility as CommonUtility } from "@tdms/common";

/** Supported download types the utility download can process */
const SupportedDownloadTypes = ["csv", "plain", "blob"] as const;

/**
 * A class that provides common utility functions to the frontend
 */
export class FrontendUtility extends CommonUtility {
  /** Boolean that will tell you if the frontend is running in firefox */
  static readonly isFirefox = navigator.userAgent.toLowerCase().includes("firefox");

  /**
   * Given some information, asks the browser to save a file to the users file system
   *
   * @param fileContentType The type of content we wish to save
   * @param content The actual content of the file to save
   * @param fileName The name to set for the file. **You do not need to include the extension. It will be added for you.**
   */
  static saveFile(
    fileContentType: CustomTypes.ArrayPropertyNames<typeof SupportedDownloadTypes>,
    content: string | Blob,
    fileName: string
  ): void {
    const tempElement: HTMLAnchorElement = document.createElement("a");

    // Handle setting download type
    switch (fileContentType) {
      case "plain":
        tempElement.href = "data:text/plain;charset=utf-16,";
        tempElement.download = fileName + ".txt";
        tempElement.href += content;
        break;
      case "csv":
        tempElement.href = "data:text/csv;charset=utf-8,";
        tempElement.download = fileName + ".csv";
        tempElement.href += encodeURIComponent(content as string);
        break;
      case "blob":
        tempElement.download = fileName;
        tempElement.href = URL.createObjectURL(content as Blob);
        break;
      default:
        throw new Error("Unknown file type attempting to be saved.");
    }

    // Trigger the browsers save
    tempElement.click();
    // Remove our temp element
    tempElement.remove();
  }
}
