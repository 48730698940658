import { Component, ViewChild } from "@angular/core";
import { Utility } from "@tdms/common";
import { PluginSettingsService } from "@tdms/frontend/modules/settings/services/settings.service";
import { SettingCollection } from "@tdms/frontend/modules/settings/store/collection/collection.model";
import { DialogWrapperComponent } from "@tdms/frontend/modules/shared/components";
import { Configuration } from "@tdms/frontend/modules/shared/models/config";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";

/**
 * The Settings component creates the overarching settings dialog accessed from the dashboard.
 * It creates a collection of accordions for each setting collection provided by the backend.
 */
@Component({
  selector: "settings-dialog",
  templateUrl: "./settings.dialog.component.html",
  styleUrls: ["./settings.dialog.component.scss"],
})
export class SettingsDialogComponent extends SubscribingComponent {
  frontendVersion = $VERSIONING.VERSION;

  @ViewChild("dialogWrapper")
  declare dialogWrapper: DialogWrapperComponent<any>;

  settings: readonly SettingCollection[] = [];

  constructor(public settingService: PluginSettingsService) {
    super();
    // Load the reactive user settings observables from the settings service.
    this.addSubscription(this.settingService.observeSettingCollections().subscribe(this.onSettingsChanged.bind(this)));
  }

  onSettingsChanged(userSettings: readonly SettingCollection[]) {
    if (userSettings == null) return;
    this.settings = userSettings;
  }

  /**
   * Returns a ZULU format build date
   */
  get prettyBuildDate() {
    return Utility.getDateAsZulu(new Date($BUILD_DATE));
  }

  get backendVersion() {
    return Configuration.BACKEND_VERSION;
  }
}
