import { Component, OnInit } from "@angular/core";

/**
 * A dialog component that is intended to provide helpful information as
 *  people use the string search capability of the data store.
 */
@Component({
  selector: "data-store-string-search-help",
  templateUrl: "./string-search-help.component.html",
  styleUrls: ["./string-search-help.component.scss"],
})
export class StringSearchHelpDialogComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
