import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import {
  DataStoreTopics,
  Session,
  TDMSWebSocketMessage,
  UploadOption,
  UploadRequest,
  UploadResponse,
  Utility,
} from "@tdms/common";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { TrackedFile } from "@tdms/frontend/modules/data-store/components/uploader/file-tree/models/tracked.file";

import { DataStoreState } from "@tdms/frontend/modules/data-store/models/data.store.state";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { UserService } from "@tdms/frontend/modules/user/services/user.service";
import { groupBy } from "lodash";
import { startCase } from "lodash-es";
import { v4 as uuidv4 } from "uuid";
import { DataStoreActions } from "../models/store/data.store.action";
import DataStoreService from "./data.store.service";

/** A type to help isolate the overall progress of a total upload as it occurs */
type FileUploadProgressType<FileType extends TrackedFile> = { files: FileType[]; progress: number };
/** A callback type for updating some functionality as an update occurs */
type UploadProgressCallback<FileType extends TrackedFile> = {
  (ev: { files: FileType[]; progress: number }[], totalProgress: number): void;
};

/**
 * A service to provide data store uploading capabilities
 */
@Injectable({
  providedIn: "root",
})
export class UploadService extends Service {
  /**
   * Keeps track of if we are currently uploading anything
   */
  currentlyUploadingRequests: { id: string; files: any[]; progress: number }[] = [];

  constructor(
    private store: Store<DataStoreState>,
    private wsService: WebSocketService,
    private userService: UserService,
    private configService: ConfigService,
    private dataStoreService: DataStoreService,
    private matDialog: MatDialog
  ) {
    super();
  }

  override async onUserChanged(): Promise<void> {
    if (this.configService.configData?.dataStore.enabled) {
      const response = await this.wsService.sendAndReceive<UploadOption[]>(
        new TDMSWebSocketMessage(DataStoreTopics.getTypes)
      );
      this.store.dispatch(
        DataStoreActions.addUploadOptions({ options: UploadOption.fromPlainArray(response.payload) })
      );
    }
  }

  /**
   * Given some files, uploads them to the data store endpoints
   * @param files The files to process one by one to the data store http endpoint
   * @param sessionId The session Id to upload to the data store as. Not passing a session Id will result in an attempt to auto create a session from the **each** file being uploaded. if
   *  the type states it supports it.
   * @param sessionName The session name to use as the base in new sessions. This will only be utilized if the given sessionId is blank.
   * @param uploadProgress A progress function that will be called with all requests whenever one XMLHttpRequest alerts of progress.
   */
  async uploadToDataStore<FileType extends TrackedFile>(
    files: FileType | FileType[],
    sessionId?: number,
    sessionName?: string,
    uploadProgress?: UploadProgressCallback<FileType>,
    isAggregateUpload?: boolean,
    isBulkUpload?: boolean
  ) {
    // Fake session object to later use
    const fakeSession = Session.fromPlain({ id: sessionId, name: sessionName });
    //Handle user authentication. Check user's jwt, log them out if jwt is expired.
    if (!this.userService.checkUserJwtValidation()) {
      this.userService.logout();
      this.matDialog.closeAll();
      return undefined;
    }
    // Verify even singular files are an array
    files = !Array.isArray(files) ? [files] : files;
    // Condense files so types that support multiple files are uploaded as one request
    const filesThatCanGroup = Object.values(
      groupBy(
        files.filter((z) => z.fileType.canContainMoreThanOne),
        (x) => x.fileType.name
      )
    );
    const filesThatCantGroup = files.filter((x) => !x.fileType.canContainMoreThanOne);
    const totalFiles = filesThatCanGroup.concat(filesThatCantGroup) as Array<FileType> | Array<Array<FileType>>;
    // Track our total progress here
    const overarchingFileProgress: FileUploadProgressType<FileType>[] = [];
    // Create multiple promises for all resolved files
    const totalPromise = Promise.all(
      totalFiles.map((fileData, i) =>
        this.buildUploadPromise(
          Array.isArray(fileData) ? fileData : [fileData],
          i,
          fakeSession,
          overarchingFileProgress,
          uploadProgress,
          isAggregateUpload,
          isBulkUpload
        )
      )
    );
    return await totalPromise;
  }

  /** Builds an upload promise with XMLHttp requests and returns it */
  private buildUploadPromise<FileType extends TrackedFile>(
    files: FileType[],
    uploadIndex: number,
    session: Session,
    overarchingFileProgress: FileUploadProgressType<FileType>[],
    uploadProgress?: UploadProgressCallback<FileType>,
    isAggregateUpload = false,
    isBulkUpload?: boolean
  ) {
    const fileType = files[0].fileType;
    const isDelayedSessionCreation = files[0].isDelayedSessionCreation;
    const progressObject = (overarchingFileProgress[uploadIndex] = { files: files, progress: 0 });
    return new Promise<UploadResponse>((resolve, reject) => {
      const uploadingRequestId = uuidv4();
      const currentUploadRequestTracker = { id: uploadingRequestId, files: files, progress: 0 };
      this.currentlyUploadingRequests.push(currentUploadRequestTracker);
      // Function to remove the uploading status from the request array
      const removeUploadProgress = () =>
        this.currentlyUploadingRequests.splice(
          this.currentlyUploadingRequests.findIndex((x) => x.id === uploadingRequestId),
          1
        );
      const xhttp = new XMLHttpRequest();
      xhttp.open("POST", this.dataStoreService.getDataStoreEndpoint("upload"));
      xhttp.setRequestHeader("Authorization", `Bearer ${this.userService.getJWT()}`);
      xhttp.setRequestHeader("client-identifier", this.wsService.clientIdentifier);
      const formData = new FormData();
      // Append all files
      for (let file of files) formData.append("files", file.file);
      // Append request information
      formData.append(
        "request",
        // You technically only need the upload option name as it's unique
        UploadRequest.fromPlain({
          type: fileType,
          sessionId: session.id,
          sessionName: session.name,
          isDelayedSessionCreation: isDelayedSessionCreation,
          isAggregateUpload: isAggregateUpload,
          isBulkUpload: isBulkUpload,
        }).toJSONString()
      );
      // Callback to fire as progress of this upload occurs
      const updateProgress = (ev: ProgressEvent) => {
        progressObject.progress = (ev.loaded / ev.total) * 100;
        const totalProgress =
          (overarchingFileProgress.reduce((prev, curr) => prev + curr.progress, 0) /
            (overarchingFileProgress.length * 100)) *
          100;
        currentUploadRequestTracker.progress = totalProgress;
        if (uploadProgress) uploadProgress(overarchingFileProgress, totalProgress);
      };
      xhttp.upload.onloadstart = updateProgress;
      xhttp.upload.onprogress = updateProgress;
      xhttp.onerror = (e) => {
        removeUploadProgress();
        reject(e);
      };
      // Track ready state changes
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState === 4) {
          removeUploadProgress();
          if (!xhttp.response) reject("Invalid response message");
          else {
            const result = UploadResponse.fromPlainString(xhttp.response);
            if (!result.success) reject(result);
            else resolve(result);
          }
        }
      };
      // Send the data
      xhttp.send(formData);
    });
  }

  /**
   * Given a file, turns it into a "pretty" session name
   */
  getSessionNameFromFile(tracked: TrackedFile | undefined) {
    if (!tracked) return "";
    const fileName = tracked.file.name;
    return startCase(fileName.replace(Utility.getFileExtensionFromString(fileName) || "", ""));
  }
}
