import { ChartData } from "./base";

/** Colors for our gauge chart display */
export const GaugeChartColors = [
  "#a20025", // Red
  "#e3c800", // Yellow
  "#008a00", // Green
];

/** A class that allows chart display of gauge data */
export class GaugeChartData extends ChartData {
  /** The value to display on the gauge */
  value: number;

  constructor(name: string, value: number) {
    super(name);
    this.value = value;
  }
}
