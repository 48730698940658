import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SessionState } from "@tdms/frontend/modules/session/store/session.state";
import * as reducer from "./session.reducer";
/**
 * Given a state selects the session object
 */
export const selectSessionState = createFeatureSelector<SessionState>("session");

/**
 * Returns all sessions
 */
export const selectAllSessions = createSelector(reducer.selectAll, (entities) => entities);

/**
 * Returns, from state, the current sessions available
 */
export const selectAllSessionsFromState = createSelector(selectSessionState, selectAllSessions);

/**
 * Returns, from state, the currently selected session ID
 */
export const selectCurrentSessionId = createSelector(selectSessionState, (x) => x.selectedSessionID);

/**
 * Returns, from state, the currently selected session object
 */
export const selectCurrentSession = createSelector(
  selectAllSessionsFromState,
  selectCurrentSessionId,
  (sessionEntities, sessionId) => sessionEntities.find((x) => x.id === sessionId)
);

/**
 * Returns, from state, the currently selected session object
 */
export const selectSessionFromId = (sessionID: number | undefined) =>
  createSelector(selectAllSessionsFromState, (sessionEntities) => sessionEntities.find((x) => x.id === sessionID));
