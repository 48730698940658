<div class="previous-logical-operator-container">
  <div
    class="logical-operator-spacer outside-spacer"
    *ngIf="previousLogicalOperator != null && previousLogicalOperator == 'OR'"
  ></div>
  <data-store-logical-operator-selector
    [option]="previousSearchOption"
    [isSearching]="isSearching"
    [onComparisonChanged]="onLogicalOperatorChanged"
    *ngIf="previousSearchOption != null && previousLogicalOperator != null"
  >
  </data-store-logical-operator-selector>
</div>

<div class="main-grouping-container">
  <div class="logical-operator-spacer" *ngIf="previousLogicalOperator != null"></div>
  <div class="logical-operator-spacer" *ngIf="previousLogicalOperator != null && previousLogicalOperator == 'OR'"></div>
  <query-segment
    [searchOption]="searchOption"
    [optionDeleted]="optionDeleted"
    [optionUpdated]="optionUpdated"
    [isSearching]="isSearching"
    [availableOptions]="availableOptions"
  >
  </query-segment>
</div>
