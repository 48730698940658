import { ChartData } from "./base";

/**
 * This class is the format we expect while displaying anything in a bar chart format.
 */
export class BarChartData extends ChartData {
  /**
   * The value associated to this bar chart data
   */
  value: number;

  static override toCSV(data: BarChartData[]) {
    let csvString = "name,value\n";
    for (let chartData of data) csvString += `${chartData.name},${chartData.value}\n`;
    return csvString;
  }

  constructor(name: string, value: number) {
    super(name);
    this.value = value;
  }
}
