import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataStoreFile, Utility } from "@tdms/common";
import autoBind from "auto-bind";

export interface NewAccessDialogProperties {
  filesAccessed: DataStoreFile[];
  username: string;
  dateAccessed: Date;
  onReasonProvided: (reason: string) => void;
  onDownloadCancelled: () => void;
}

/**
 * This dialog is shown when a new file access is created and requires user to provide the reason for access.
 */
@Component({
  selector: "data-store-new-access-dialog",
  templateUrl: "./new-access-dialog.component.html",
  styleUrls: ["./new-access-dialog.component.scss"],
})
export class NewAccessDialogComponent implements OnInit {
  readonly formGroup = new FormGroup({
    reason: new FormControl({ value: "", disabled: false }, [Validators.required]),
    files: new FormControl({ value: "", disabled: true }, [Validators.required]),
    username: new FormControl({ value: "", disabled: true }, [Validators.required]),
    dateAccessed: new FormControl({ value: new Date(), disabled: true }, [Validators.required]),
  });

  constructor(@Inject(MAT_DIALOG_DATA) public properties: NewAccessDialogProperties) {
    autoBind(this);
    // Set initial value from given properties
    this.formGroup.setValue({
      reason: "",
      files: this.accessedFileNames,
      username: this.properties.username,
      dateAccessed: this.properties.dateAccessed,
    });
  }

  ngOnInit(): void {}

  get formattedAccessDate() {
    return Utility.getDateAsZulu(this.properties.dateAccessed);
  }

  /** Returns a string of all files accessed via an access request */
  get accessedFileNames() {
    return this.properties.filesAccessed.map((x) => x.uncompressedFileName).join(", ");
  }

  submit() {
    if (!this.formGroup.valid) return;
    const reason = this.formGroup.value.reason;
    // Reason will always be defined or else the form group won't get here
    this.properties.onReasonProvided(reason!);
  }
}
