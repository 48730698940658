import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Session, Tag, Utility } from "@tdms/common";
import { SessionService } from "@tdms/frontend/modules/session/services/session.service";
import { SessionActions } from "@tdms/frontend/modules/session/store/session.action";
import { selectAllSessionsFromState } from "@tdms/frontend/modules/session/store/session.selector";
import { SessionState } from "@tdms/frontend/modules/session/store/session.state";
import { ConfigService } from "@tdms/frontend/modules/settings/services/config.service";
import { DataColumn, DatalessColumn } from "@tdms/frontend/modules/shared/components";
import { ServiceManager } from "@tdms/frontend/modules/shared/services/service.manager";
import { SubscribingComponent } from "@tdms/frontend/modules/shared/utils/subscribing_component";
import autoBind from "auto-bind";

@Component({
  selector: "app-session-selector",
  templateUrl: "./session-selector.component.html",
  styleUrls: ["./session-selector.component.scss"],
})
export class SessionSelectorComponent extends SubscribingComponent {
  /**
   * The table data set to display for our sessions
   */
  sessions: Session[] = [];

  /**
   * The columns we wish to display for our table
   */
  displayedColumns = [
    new DatalessColumn("actions", "Actions"),
    new DataColumn<Session, string>("name", "Name"),
    new DataColumn<Session, Date>("startDate", "Start Date", Utility.getDateAsZulu.bind(this)),
    new DataColumn<Session, Date>("endDate", "End Date", Utility.getDateAsZulu.bind(this)),
    new DataColumn<Session, Tag[]>("tags", "Tags", (value) => value.map((x) => x.name).join(",")),
  ];

  constructor(
    private store: Store<SessionState>,
    private serviceManager: ServiceManager,
    public sessionService: SessionService,
    public configService: ConfigService
  ) {
    super();
    this.addSubscription(
      this.store
        .select(selectAllSessionsFromState)
        .subscribe((sessions) => (this.sessions = Session.fromPlainArray(sessions)))
    );
    autoBind(this);
  }

  /** Returns the loading status message for the progress spinner */
  get loadingMessage() {
    // Tracks if the services have completed processing on their "user" change. This is actually when session info is loaded.
    const userServicesReady = this.serviceManager.onServicesLoadedUser.value;
    // If the services have completed processing for the session, assuming we have a session
    const servicesReady = this.serviceManager.onServicesLoadedSession.value;
    if (!userServicesReady) return "Loading...";
    else if (!servicesReady) return "Loading Session...";
    else return undefined;
  }

  /**
   * Sets the given session as the current session
   */
  async selectSession(session: Session) {
    this.store.dispatch(SessionActions.select({ session: session }));
    // Redirect is handled in session service
  }
}
