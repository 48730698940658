import { EventEmitter, Injectable } from "@angular/core";
import { ConfigMessage, CoreTopics, TDMSWebSocketMessage } from "@tdms/common";
import { firstValueFrom } from "rxjs";
import { WebSocketService } from "../../communication/services/websocket.service";
import { Service } from "../../shared/services/base.service";

/**
 * This service provides some basic configuration data associated to the current connection
 *  and or user information.
 */
@Injectable({
  providedIn: "root",
})
export class ConfigService extends Service {
  /**
   * Configuration data to control different features according to how the backend
   *    things they should work.
   */
  configData: ConfigMessage | undefined;

  /**
   * A subscription to watch when config data arrives
   */
  configDataWatcher = new EventEmitter();

  constructor(private wsService: WebSocketService) {
    super();
  }

  override async onBackendConnected(): Promise<void> {
    const response: TDMSWebSocketMessage<ConfigMessage> = await this.wsService.sendAndReceive(
      new TDMSWebSocketMessage(CoreTopics.getConfig)
    );
    this.configData = response.payload;
    this.configDataWatcher.next(this.configData);
  }

  async waitForConfigData(): Promise<ConfigMessage> {
    if (this.configData != null) return this.configData;
    return firstValueFrom(this.configDataWatcher);
  }
}
