<shared-dialog-wrapper
  class="upload-metadata-wrapper"
  header="Metadata Request"
  [backdropClickCallback]="properties.onCancel"
  [closeDialogCallback]="properties.onCancel"
>
  <div class="wrapper">
    <span class="instructions">
      Some additional metadata is required for processing. Please fill in the fields below.
    </span>

    <!-- Table-like display -->
    <form class="form-wrapper" [formGroup]="formGroup" (submit)="onSubmit()">
      <table class="metadata-table outer">
        <tr>
          <th>File</th>
          <th>Metadata</th>
        </tr>
        <tr class="group" *ngFor="let group of properties.request.groups">
          <td class="file-name">{{ group.fileName }}</td>
          <td>
            <table class="metadata-table">
              <tr>
                <th>Name</th>
                <th>Input</th>
              </tr>
              <!-- Render the options that need updated -->
              <tr *ngFor="let option of group.options" [ngSwitch]="option.type">
                <td class="input-name">{{ option.name }}</td>
                <td class="input">
                  <!-- Number -->
                  <mat-form-field class="option" color="accent" *ngSwitchCase="'number'">
                    <mat-label>Number Input</mat-label>
                    <input matInput type="number" [formControlName]="getFieldName(option, group.fileName)" />
                    <mat-hint *ngIf="option.help">{{ option.help }}</mat-hint>
                  </mat-form-field>

                  <!-- String -->
                  <mat-form-field class="option" color="accent" *ngSwitchCase="'string'">
                    <mat-label>String Input</mat-label>
                    <input matInput type="string" [formControlName]="getFieldName(option, group.fileName)" />
                    <mat-hint *ngIf="option.help">{{ option.help }}</mat-hint>
                  </mat-form-field>

                  <!-- Date -->
                  <div *ngSwitchCase="'Date'" class="option date">
                    <shared-date-time-picker
                      [control]="$any(formGroup.get(getFieldName(option, group.fileName)))"
                    ></shared-date-time-picker>
                    <mat-hint class="hint" *ngIf="option.help">{{ option.help }}</mat-hint>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </form>

    <!-- Submit button for the metadata -->
    <button
      mat-raised-button
      color="accent"
      class="submit-button"
      (click)="onSubmit()"
      [disabled]="!formGroup.valid"
      [matTooltip]="!formGroup.valid ? 'Please fill in all required fields' : ''"
    >
      Submit
    </button>
  </div>
</shared-dialog-wrapper>
