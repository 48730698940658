import { Component, OnInit } from "@angular/core";
import { TDMSBase } from "@tdms/common";
import ReleaseNotes from "@tdms/frontend/assets/changelog.json";
import { format } from "date-fns";
import zonedTimeToUtc from "date-fns-tz/zonedTimeToUtc";
import { groupBy } from "lodash-es";

/**
 * A type to help define the type of change this change was
 */
export type ReleaseChangeTypes = "feature" | "bugfix";

/**
 * A class to define a specific change a release might have contained
 */
export class ReleaseChange extends TDMSBase {
  /**
   * Text options used to process to determine if this change is considered a bugfix
   */
  private static readonly BUGFIX_TEXT = ["bugfix", "bug", "fix"];

  /**
   * The simplified message to display
   */
  message: string;

  /**
   * The commit hash
   */
  hash: string;

  /**
   * The date this change was merged or committed
   */
  date: Date;

  /**
   * A more complex message
   */
  subject: string | undefined;

  /**
   * The total number of insertions in this change
   */
  insertions: number;

  /**
   * The total number of deletions in this change
   */
  deletions: number;

  /**
   * Gets the message and removes some common words from it
   */
  get prettyMessage() {
    const startingReplacers = ["Bugfix:", "bugfix:"];
    let message = this.message;
    // Replace starting with if applicable
    for (let replacer of startingReplacers) if (message.startsWith(replacer)) message = message.replace(replacer, "");
    return message.trim();
  }

  /**
   * Return's the type we consider this change to be for specific coloring
   */
  get type(): ReleaseChangeTypes {
    const message = this.message.toLowerCase();
    if (ReleaseChange.BUGFIX_TEXT.some((x) => message.includes(x))) return "bugfix";
    else return "feature";
  }

  constructor(message: string, hash: string, date: Date, insertions: number, deletions: number, subject?: string) {
    super();
    this.message = message;
    this.hash = hash;
    this.date = date;
    this.insertions = insertions;
    this.deletions = deletions;
    this.subject = subject;
  }
}

/**
 * The overarching changelog class to track each release and the changes that occurred in that release
 */
export class Release extends TDMSBase {
  /**
   * The title of this release. Normally a version number.
   */
  title!: string;

  /**
   * The date of this release
   */
  date!: Date;

  /**
   * The version of this release
   */
  version!: string;

  /**
   * All the changes involved in this release
   */
  changes: ReleaseChange[] = [];

  /**
   * Gets a cleaned up version to display
   */
  get prettyVersion() {
    return this.version.replace("v", "");
  }
}

@Component({
  selector: "shared-changelog",
  templateUrl: "./changelog.component.html",
  styleUrls: ["./changelog.component.scss"],
})
export class ChangelogComponent implements OnInit {
  /**
   * The releases to display. Keep as a variable to only generate once.
   */
  releases: Release[];

  /**
   * Releases grouped by year where the key is the year
   */
  groupedReleases: { year: number; releases: Release[] }[];

  /**
   * The current release we have selected
   */
  selectedRelease: Release | undefined;

  selectedReleaseBugfixes: ReleaseChange[] = [];
  selectedReleaseFeatures: ReleaseChange[] = [];
  selectedTotalInsertions: number = 0;
  selectedTotalDeletions: number = 0;

  constructor() {
    this.releases = this.processedReleaseNotes;
    this.releases.sort((a, b) => b.date.getFullYear() - a.date.getFullYear());
    const yearGroupedReleases = groupBy(this.releases, (element) => element.date.getUTCFullYear());
    this.groupedReleases = Object.keys(yearGroupedReleases).map((year) => ({
      year: Number(year),
      releases: yearGroupedReleases[year],
    }));
    this.groupedReleases.sort((a, b) => b.year - a.year);
    this.selectRelease(this.groupedReleases[0].releases[0]);
  }

  ngOnInit(): void {}

  /**
   * Returns the processed and cleaned up release notes we want to display
   */
  get processedReleaseNotes() {
    const releases = ReleaseNotes;
    return releases.map((release) => {
      // Break the changes down to a singular array since we're gonna process like normal
      const commitChanges = release.commits
        .filter((z) => z.message != null)
        .map((x) => new ReleaseChange(x.message!, x.hash, new Date(x.date), x.insertions, x.deletions));
      const mergeChanges = release.merges
        .filter((z) => z.message != null)
        .map((x) => new ReleaseChange(x.message!, x.hash, new Date(x.date), x.insertions, x.deletions, x.subject));
      let totalChanges = [...commitChanges, ...mergeChanges];
      // Remove any changes that match the default merge text
      const releaseToRegex = /release\s{1}to\s{1}v/;
      totalChanges = totalChanges.filter((x) => !releaseToRegex.test(x.message.toLowerCase()));
      return Release.fromPlain({
        title: release.title,
        // Changelog is generated in EST time. Auto populate the time from that
        date: zonedTimeToUtc(release.date, "America/New_York"),
        version: release.version,
        changes: totalChanges,
      });
    });
  }

  /**
   * Given a date, returns a pretty version of it
   */
  getPrettyDate(date: Date, includeYear = false) {
    if (includeYear) return format(date, "MMM, dd yyyy");
    else return format(date, "MMM, dd");
  }

  /**
   * Given a type and a release, returns all changes that match that type.
   */
  getChangesByType(type: ReleaseChangeTypes, release = this.selectedRelease) {
    return release?.changes.filter((x) => x.type === type) || [];
  }

  /**
   * Given a release, set it as the selected one
   */
  selectRelease(release: Release) {
    this.selectedRelease = release;
    this.selectedReleaseBugfixes = this.getChangesByType("bugfix");
    this.selectedReleaseFeatures = this.getChangesByType("feature");
    this.selectedTotalInsertions = this.selectedRelease.changes.reduce((prev, curr) => prev + curr.insertions, 0);
    this.selectedTotalDeletions = this.selectedRelease.changes.reduce((prev, curr) => prev + curr.deletions, 0);
  }

  /**
   * Get's the progress to show for our insertions/deletions indicator
   */
  getTotalChangesProgress() {
    const del = this.selectedTotalDeletions;
    const ins = this.selectedTotalInsertions;
    let val = (100 * ins) / (ins + del + 0);
    // Invert it since the progress bar is inverted
    return 100 - val;
  }
}
