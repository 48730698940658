import { ChartXAxisFormatters } from "@tdms/common";
import { ArcElement, BarElement, InteractionMode, Plugin as ChartJSPlugin, ScatterDataPoint } from "chart.js";
import { ChartJSDrawingHelper, DEFAULT_VERTICAL_LINE_WIDTH, LabelObject } from "./drawing.helper";
import { PluginBase, PluginBaseExternalOpts, PluginBaseInternalOpts } from "./plugin.base";
import { ExtendedChartType } from "./plugin.typing";

/** This plugin allows us to apply a custom tooltip format to Chart.JS charts */
export namespace CustomTooltipPlugin {
  /** External supported options */
  export class ExternalOptions extends PluginBaseExternalOpts {
    /** If we should show the text for the tooltip box */
    showText: boolean = true;
    /** If we should hide the tooltip body and only display the time value (on time series) */
    hideTooltipBody: boolean = false;
    /** How we choose to interact with this tooltip */
    snappingInteractionMode: "index" | "x" | "point" = "index";
    snappingIntersect = false;
    /** A callback to fire for printing different labels if requested */
    labelCallback?: { (data: ScatterDataPoint | number | [string, string, string, string, number]): string };
    /** A custom display callback to use for the time value at the top of the tooltip */
    timeDisplay = (val: Date) => ChartXAxisFormatters.timeFormatter(val);
    /** If we should allow the shift key to change the snapping interaction mode to "nearest" */
    allowShiftSnappingOverride = true;
    /** If the tooltip content should be hidden while a bookmark is drawn */
    hideTooltipContentOnBookmark = true;
    /** Some styling configuration capabilities */
    styling?: Partial<{
      /**
       * The color to make the vertical line.
       * @default "Accent color in css"
       */
      color: string | null;

      /**
       * How many pixels wide the line should be.
       * @default "DEFAULT_VERTICAL_LINE_WIDTH"
       */
      lineWidth: number | null;
    }>;
  }

  /** Options for internal use of this plugin only */
  export class InternalOptions extends PluginBaseInternalOpts {
    /** Tracks if the shift key is pressed in support of tooltip snapping */
    shiftPressed: boolean = false;
  }

  export class Plugin extends PluginBase<ExternalOptions, InternalOptions> implements ChartJSPlugin<any> {
    constructor() {
      super("customTooltip", ExternalOptions, InternalOptions);
    }

    /** Uses current configuration to draw the vertical line */
    drawVerticalLine(xPos = this.internalOpts.xPos!) {
      // Draw current tracking line
      ChartJSDrawingHelper.drawVerticalLine(this.chart!, xPos, {
        width: this.externalOpts.styling?.lineWidth || DEFAULT_VERTICAL_LINE_WIDTH,
        color: this.externalOpts.styling?.color,
      });
    }

    /**
     * Breaks the data sets down to an understandable format based on chart typing
     * @param currentTimeVal The current time val based on x position.
     * @param dataIndex The index of the data within the dataset to grab from. You must be careful using this and keep track
     *  if your data set is not always of equal lengths.
     */
    private breakdownDataSet(
      currentTimeVal: number,
      dataIndex: number
    ): Array<{ label?: string; displayText: string; color?: string }> {
      const chart = this.chart!;
      const labelCallback = this.externalOpts.labelCallback;
      switch (chart.tdmsType) {
        case "time-series-horizontal-bar":
          const flatData = chart.data.datasets.flatMap((x) =>
            x.data.map((z) => z as any as [string, string, string, string, number])
          );
          // Filter content not in range
          const filteredData = flatData.filter(
            (z) => new Date(z[0]).getTime() <= currentTimeVal && new Date(z[1]).getTime() >= currentTimeVal
          );
          return filteredData
            .sort((a, b) => a[4] - b[4])
            .map((data) => {
              const displayText = labelCallback
                ? labelCallback(data)
                : `${ChartXAxisFormatters.timeFormatter(data[0])} - ${ChartXAxisFormatters.timeFormatter(data[1])}`;
              return {
                label: data[2],
                color: data[3],
                displayText,
              };
            });
        case "waveform":
          // Determine if we are already outside the range
          const latestVal = Math.max(
            ...chart.data.datasets.map((x) => (x.data[x.data.length - 1] as ScatterDataPoint)?.x)
          );
          if (currentTimeVal > latestVal) return [];
          return chart.data.datasets
            .map((dataSet) => {
              const data = dataSet.data[dataIndex] as any as { y: [number, number] } | undefined;
              const noData = data == null || data.y[0] === 0;
              if (noData) return undefined;
              const displayText = labelCallback ? labelCallback(data as any) : `${data.y[1]}%`;
              return {
                label: dataSet.label!,
                color: (dataSet.backgroundColor as Array<string>)[0],
                displayText,
              };
            })
            .filter((x) => x) as any;
        case "line":
          // Determine if we are already outside the range
          const lastDatasetTimeVal = Math.max(
            ...chart.data.datasets.map((x) => (x.data[x.data.length - 1] as ScatterDataPoint)?.x)
          );
          if (currentTimeVal > lastDatasetTimeVal) return [];
          return chart.data.datasets.map((dataSet) => {
            const data = dataSet.data[dataIndex] as ScatterDataPoint;
            const yVal = data?.y == null ? "N/A" : data?.y?.toFixed(4);
            const displayText = labelCallback ? labelCallback(data) : yVal;
            return {
              label: dataSet.label!,
              color: dataSet.backgroundColor as string,
              displayText,
            };
          });
        case "gauge":
          // Get range for display
          const gaugeDataSet = chart.data.datasets[0];
          const gaugeData = gaugeDataSet.data as Array<number>;
          const min = gaugeData.slice(0, dataIndex).reduce((a, b) => a + b, 0);
          const max = gaugeData.slice(0, dataIndex + 1).reduce((a, b) => a + b, 0);
          // const gaugeColor = (gaugeDataSet.backgroundColor as Array<string>).at(dataIndex);
          return [{ displayText: `${min}% - ${max}%`, color: "#FFFFFF" }];
        case "pie":
        case "vertical-bar":
          // Center these bars?
          const label = (chart.data.labels?.at(dataIndex) as string) || "";
          const dataSet = chart.data.datasets[0];
          const data = dataSet.data.at(dataIndex) as number;
          if (!data) return []; // Handle blank data
          const color = (dataSet.backgroundColor as Array<string>).at(dataIndex);
          const displayText = labelCallback ? labelCallback(data) : data?.toString();
          return [{ label, displayText, color }];
        default:
          throw new Error("Unsupported chart type for tooltip");
      }
    }

    /**
     * Returns the data the text tooltip should display if available. Also includes the xPos including snap point consideration
     */
    private getDataForEvent(mode: InteractionMode = this.externalOpts.snappingInteractionMode!) {
      const labels: LabelObject[] = [];
      let xPos = this.internalOpts.xPos!;
      let yPos = this.chart!.chartArea.height / 2;
      // Adjust waveform tooltip positions to be higher as those charts tend to be small
      if (this.chart!.tdmsType === "waveform") yPos = this.chart!.chartArea.height / 4;
      if (this.internalOpts.lastMouseMoveEvent) {
        // Used to perform shift snapping to the nearest element instead of the original current position snapping
        const shiftPressed = this.externalOpts.allowShiftSnappingOverride && this.internalOpts.shiftPressed;
        if (shiftPressed || !this.chartIsTimeSeries) mode = "nearest";
        // Utilize the event
        const location = this.chart!.getElementsAtEventForMode(
          this.internalOpts.lastMouseMoveEvent,
          mode,
          { intersect: this.externalOpts.snappingIntersect },
          false
        )[0];
        // Adjust positioning based on some config values
        if (location) {
          if (shiftPressed || !this.chartIsTimeSeries) xPos = location.element.x;
          if (!this.chartIsTimeSeries) yPos = location.element.y;
          // Customize circular graphs positioning
          if (this.chartIsCircular) {
            const element = location.element as any as ArcElement & {
              outerRadius: number;
              innerRadius: number;
              startAngle: number;
              endAngle: number;
            };
            const radius = element.outerRadius;
            const angle = (element.startAngle + element.endAngle) / 2;
            xPos = location.element.x + radius * Math.cos(angle);
            yPos = location.element.y + radius * Math.sin(angle);
          } else if (this.chart!.tdmsType === "vertical-bar") {
            const element = location.element as any as BarElement & { width: number };
            xPos = element.x - element.width / 4;
          }
        }
        // Time val of current x position
        const timeVal = this.chart!.scales.x.getValueForPixel(xPos)!;
        const timeValDate = new Date(timeVal);
        // Push the time label
        if (this.chartIsTimeSeries)
          labels.push(
            LabelObject.fromPlain({
              text: this.externalOpts.timeDisplay(timeValDate),
              textAlign: "center",
              color: "#FFFFFF",
            })
          );
        if (
          location != null &&
          !this.externalOpts.hideTooltipBody &&
          (!this.externalOpts.hideTooltipContentOnBookmark ||
            (this.externalOpts.hideTooltipContentOnBookmark &&
              !this.chart?.config.options.plugins.bookmark?.currentDrawingBookmark))
        ) {
          const index = location.index; // Current data index based on mouse event
          // Breakdown the different types of data into consistent sets
          const dataBreakdown = this.breakdownDataSet(shiftPressed ? 0 : timeVal, index)!;
          // If we have index, lookup the data sets and create labels
          if (index != null && dataBreakdown.length !== 0) {
            if (labels[0]) labels[0].renderBorder = true; // Set border on time label
            labels.push(
              ...dataBreakdown.map((element) => {
                const label = element.label;
                const text = (label ? `${label}: ` : "") + `${element.displayText}`;
                return LabelObject.fromPlain({
                  text,
                  color: element.color,
                  textAlign: this.chart?.data.datasets.length === 1 ? "center" : "start",
                });
              })
            );
          }
        }
      }
      return { labels, xPos, yPos };
    }

    /**
     * Draw's the text for the tooltip box
     */
    drawText(
      data: LabelObject[],
      xPos: number = this.internalOpts.xPos!,
      yPos: number = this.chart!.chartArea.height / 2
    ) {
      // If no data, don't draw any text
      if (data.length === 0) return;
      ChartJSDrawingHelper.drawLabelToPos(this.chart!, yPos, xPos, data);
    }

    onKeyDown(event: KeyboardEvent) {
      if (this.shouldExecuteFunctionality() && event.shiftKey && this.internalOpts.inChartArea && !event.repeat) {
        // Shift is pressed
        this.internalOpts.shiftPressed = true;
        this.chart?.update("none");
      }
    }

    onKeyUp(event: KeyboardEvent) {
      if (this.shouldExecuteFunctionality() && this.internalOpts.shiftPressed && !event.shiftKey && !event.repeat) {
        // Shift is released
        this.internalOpts.shiftPressed = false;
        this.chart?.update("none");
      }
    }

    override start(chart: ExtendedChartType): void {
      super.start(chart);
      document.addEventListener("keydown", this.onKeyDown);
      document.addEventListener("keyup", this.onKeyUp);
    }

    override stop(chart: ExtendedChartType): void {
      super.stop(chart);
      document.removeEventListener("keydown", this.onKeyDown);
      document.removeEventListener("keyup", this.onKeyUp);
    }

    afterDatasetsDraw() {
      if (!this.shouldExecuteFunctionality() || this.internalOpts.xPos == null || !this.internalOpts.inChartArea)
        return;
      // Draw the line, consider some other plugins states
      if (!this.chart!.bookmark?.currentHoveredBookmark && !this.chart!.select?.isDragging) {
        const { labels, xPos, yPos } = this.getDataForEvent();
        if (this.chartIsTimeSeries) this.drawVerticalLine(xPos);
        if (this.externalOpts.showText) this.drawText(labels, xPos, yPos);
      }
    }
  }
}
