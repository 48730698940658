import { TDMSBase } from "../../base";

/**
 * A request class for handling restoring previously deleted data that still lives in the recycle bin
 */
export class DataStoreRestoreRequest extends TDMSBase {
  constructor(filesToRestore: number[]) {
    super();
    this.filesToRestore = filesToRestore;
  }
  /**
   * An array of Id's to the files we wish to restore
   */
  filesToRestore: number[] = [];
}
