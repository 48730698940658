import { Type } from "class-transformer";
import { TDMSBase } from "../base";
import { Transcription } from "../plugins";

/**
 * An enum list of bookmark type names.
 *
 * **Note**: This number of each enum is also their ID in the database
 */
export enum BookmarkTypeEnum {
  "Directness" = 1,
  "Assertiveness" = 2,
  "Too quiet" = 3,
  "Self talk" = 4,
  /**
   * A generic horizontal average type
   */
  "Average" = 5,
  /**
   * A comm step-on bookmark type
   */
  "StepOn" = 6,
}

/**
 * The different render formats for bookmark types
 */
export type BookmarkTypeRenderStyle = "vertical" | "horizontal";

/**
 * This class defines a type of bookmark so we can dynamically display
 *  whatever bookmark types we decide (or the user decides) from the database.
 */
export class BookmarkType extends TDMSBase {
  static defaultBookmarkTypeColor = "#635fd1";
  /**
   * The primary key
   */
  id: number;

  /**
   * The name of this bookmark type.
   */
  name: string;

  /**
   * The color of the bookmark. This should be a hex code.
   */
  color: string;

  /**
   * How the bookmark type should be rendered
   */
  renderStyle: BookmarkTypeRenderStyle = "vertical";

  /**
   * If this bookmark type can be drawn, edited, or even deleted by the user
   */
  canBeDrawnByUser = true;

  /**
   * If this bookmark should be rendered. This will not stop them from appearing within the TDMS Legend on the side
   */
  shouldDisplay: boolean = true;

  /**
   * If this bookmark type should render on the legend. This can allow vertical bookmarks to also render. Note, they must have a `value` in the
   *  bookmark to be able to render.
   */
  shouldRenderOnLegend: boolean = true;

  /**
   * If this bookmark should be clickable when rendered on the chart. This only applies to vertical bookmarks.
   */
  clickable: boolean = true;

  constructor(
    id: number,
    name: string,
    color: string = BookmarkType.defaultBookmarkTypeColor,
    renderStyle: BookmarkTypeRenderStyle = "vertical",
    canBeDrawnByUser = true,
    shouldDisplay = true,
    shouldRenderOnLegend = true,
    clickable = true
  ) {
    super();
    this.id = id;
    this.name = name;
    this.color = color;
    this.renderStyle = renderStyle;
    this.canBeDrawnByUser = canBeDrawnByUser;
    this.shouldDisplay = shouldDisplay;
    this.shouldRenderOnLegend = shouldRenderOnLegend;
    this.clickable = clickable;
  }
}

/**
 * This class defines a bookmark that should be displayed on the time series charts
 */
export class Bookmark extends TDMSBase {
  /**
   * The primary key of this bookmark
   */
  id: number;

  /**
   * The description/note that the user has inputted to define what this bookmark classifies.
   */
  note: string;

  /**
   * The bookmark type that is associated to this bookmark
   */
  @Type(() => BookmarkType)
  bookmarkType: BookmarkType;

  /**
   * Returns the name of this bookmark which is currently just the bookmark type name
   */
  get name() {
    return this.bookmarkType?.name || "";
  }

  /**
   * If this bookmark applies to a specific role, this name should be of that role.
   *
   * This will apply coloring to attempt to matching this associated role name.
   *
   * This will also prefix the name displayed for horizontal bookmarks.
   */
  associatedRoleName?: string;

  /**
   * Session ID associated with the bookmark
   */
  sessionId?: number;

  /**
   * If set to a specific role name, this value will allow this bookmark to be excluded if that role is actively hidden.
   */
  excludeWhenMissingName?: string;

  /**
   * If this bookmarks color should automatically be set to the `associatedRoleName` if configured.
   */
  autoAdjustColor = true;

  /**
   * **Vertical Bookmark:** The time to display this bookmark's start vertically. If this bookmark is not a range, you'll
   *  only get one line here.
   *
   * **Horizontal Bookmark:** The time value at which the line should begin. Undefined results in the session start.
   */
  @Type(() => Date)
  startTime!: Date;

  /**
   * If we are utilizing a horizontal bookmark style, this will contain that data
   */
  value?: number;

  /**
   * **Vertical Bookmark:** The time to display this bookmark's end vertically.
   *
   * **Horizontal Bookmark:** The time value at which the line should end. Undefined results in the session end.
   */
  @Type(() => Date)
  endTime?: Date;

  /**
   * An optional generic field to add additional data identified with a key
   */
  metadata?: { [key: string]: any; transcription?: Transcription[] };

  /**
   * If this bookmark should be considered `critical` and always display.
   *
   * This really takes effect on the session comparison page.
   */
  critical = false;

  constructor(
    id: number | undefined,
    critical: boolean,
    note: string,
    bookmarkType: BookmarkType,
    startTime: Date,
    endTime?: Date,
    associatedRoleName?: string
  );
  constructor(
    id: number | undefined,
    critical: boolean,
    note: string,
    bookmarkType: BookmarkType,
    horizontalValue: number,
    associatedRoleName?: string
  );
  constructor(
    id: number | undefined,
    critical: boolean,
    note: string,
    bookmarkType: BookmarkType,
    startOrHorizontal: Date | number,
    endTimeOrAssociatedRole?: Date | string
  ) {
    super();
    this.id = id!;
    this.critical = critical;
    this.note = note;
    this.bookmarkType = bookmarkType;
    if (typeof startOrHorizontal === "number") this.value = startOrHorizontal;
    else this.startTime = startOrHorizontal;
    if (typeof endTimeOrAssociatedRole === "string") this.associatedRoleName = endTimeOrAssociatedRole;
    else this.endTime = endTimeOrAssociatedRole;
  }
}
