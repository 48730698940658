import { Component, Input } from "@angular/core";
import { LegendDisplay } from "./models/legend.display";
import { LegendDisplayObject } from "./models/legend.display.object";

/**
 * The click callback when we click on a legend value
 */
export type CustomLegendClickCallback = { (obj: LegendDisplayObject): void };

/**
 * A custom legend to display separate of the charting suites build in legend
 *  to allow more customization.
 */
@Component({
  selector: "charts-custom-legend[values][colorGetter][clickCallback]",
  templateUrl: "./legend.component.html",
  styleUrls: ["./legend.component.scss"],
})
export class CustomLegendComponent {
  constructor() {}
  /**
   * String names of the values currently displayed on the graph
   */
  @Input() values!: LegendDisplay[];

  /**
   * A callback to be executed when a value in the legend is clicked
   */
  @Input() clickCallback!: CustomLegendClickCallback;

  /**
   * A function to populate colors for the current values
   */
  @Input() colorGetter!: { (value: string): string | undefined };

  /**
   * If the circle should be rendered to help define it's color
   */
  @Input() renderColorIndicator = true;

  /**
   * A function to call when an element is clicked on in the legend before calling the given click callback
   */
  legendElementClick(option: LegendDisplayObject) {
    // Validate that this option will leave legend values with remaining enabled fields
    const legend = LegendDisplay.flattenOptions(this.values);
    const enabledElementCounts = legend.filter((x) => x.displayed).length;
    const matchingElement = LegendDisplay.findSpecificElement(this.values, option);
    if (
      enabledElementCounts > 1 ||
      (matchingElement != null && enabledElementCounts === 1 && !matchingElement.element.displayed)
    ) {
      // Go ahead and flip the value for the end callback
      option.displayed = !option.displayed;
      this.clickCallback(option);
    }
  }
}
