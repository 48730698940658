import { createActionGroup, props } from "@ngrx/store";
import { SessionSummary } from "@tdms/common";

/** NGRX Store actions for the Session Summary part of the app */
export const SessionSummaryActions = createActionGroup({
  source: "SessionSummary",
  events: {
    Add: props<SessionSummary>(),
    Update: props<SessionSummary>(),
    Empty: props<any>(),
  },
});
