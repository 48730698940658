import { Observable, filter } from "rxjs";

/**
 * The purpose of this function is to convert from an observable of type | undefined to type by filtering out any thing that is null.
 * It is essentially a helper function to enforce to typescript that the observable will not be undefined anymore.
 * @param observable
 * @returns
 */
export function filterNulls<T>(observable: Observable<T | null | undefined>): Observable<T> {
  return observable.pipe(filter((metric): metric is T => metric != null && metric != undefined));
}
