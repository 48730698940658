import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TrackedFile } from "@tdms/frontend/modules/data-store/components/uploader/file-tree/models/tracked.file";
import { FileNode } from "../models/file.node";

/**
 * This component is used to display the node content of a file tree row based on given node content
 */
@Component({
  selector: "data-store-file-tree-node[node][nodeLevel][isBulkUpload]",
  templateUrl: "./node.component.html",
  styleUrls: ["./node.component.scss"],
})
export class NodeComponent implements OnInit {
  /** The current node we are rendering */
  @Input() node!: FileNode;

  /** The current node level depth for customizing displays */
  @Input() nodeLevel!: number;

  @Input() isBulkUpload!: boolean;
  /** Selects the current node that we wish to edit for bulk uploads */
  @Output() selectNodeToEdit = new EventEmitter<TrackedFile>();
  /** Selects the current node that we wish to upload additional files to for bulk uploads */
  @Output() selectNodeForUpload = new EventEmitter<TrackedFile>();
  /**
   * A callback that will be fired if you try to delete a node on the tree. The
   *  delete button will only be shown if this is given.
   */
  @Input() deleteCallback: { (node: TrackedFile): void } | undefined;

  constructor() {}

  ngOnInit(): void {}
}
