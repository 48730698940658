import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { Bookmark, Session } from "@tdms/common";
import { cloneDeep } from "lodash-es";
import { SessionActions } from "./session.action";
import { selectAllSessions } from "./session.selector";
import { SessionState } from "./session.state";

/**
 * NGRX entity sessionAdapter
 */
export const sessionAdapter: EntityAdapter<Session> = createEntityAdapter<Session>({
  selectId: (session) => session.id,
});

/**
 * Reducer for session NGRX Store
 */
export const sessionReducer = createReducer(
  sessionAdapter.getInitialState({
    selectedSessionID: undefined,
  } as SessionState),
  on(SessionActions.select, (state, data) => {
    // Select session
    return { ...state, selectedSessionID: data.session?.id };
  }),
  on(SessionActions.addSession, (state, data) => {
    // Add session
    return sessionAdapter.addOne(data, state);
  }),
  on(SessionActions.addManySessions, (state, data) => {
    // Add Many sessions
    return sessionAdapter.addMany(data.sessions, state);
  }),
  on(SessionActions.updateSession, (state, data) => {
    // Update session
    return sessionAdapter.updateOne({ id: data.id, changes: data }, state);
  }),
  on(SessionActions.deleteSession, (state, data) =>
    // Delete session
    sessionAdapter.removeOne(data.id, state)
  ),
  on(SessionActions.emptySessions, (state, _data) =>
    // Delete all sessions
    sessionAdapter.removeAll(state)
  ),
  /// Role options
  on(SessionActions.updateRole, (state, data) => {
    // Update a Role
    const matchingSession = cloneDeep(selectAllSessions(state).find((x) => x.id === data.sessionId));
    if (matchingSession) {
      const matchingRoleIndex = matchingSession?.roles.findIndex((x) => x.id === data.id);
      matchingSession.roles[matchingRoleIndex] = data;
      return sessionAdapter.updateOne({ id: data.sessionId, changes: matchingSession }, state);
    } else {
      console.error("Failed to locate matching session");
      return state;
    }
  }),
  on(SessionActions.deleteRole, (state, data) => {
    // Delete a Role
    const matchingSession = cloneDeep(selectAllSessions(state).find((x) => x.id === data.sessionId));
    if (matchingSession) {
      const matchingRoleIndex = matchingSession?.roles.findIndex((x) => x.id === data.id);
      matchingSession.roles.splice(matchingRoleIndex, 1);
      return sessionAdapter.updateOne({ id: data.sessionId, changes: matchingSession }, state);
    } else {
      console.error("Failed to locate matching session");
      return state;
    }
  }),
  on(SessionActions.addBookmark, (state, data) => {
    const matchingSession = cloneDeep(selectAllSessions(state).find((x) => x.id === data.sessionId));
    if (matchingSession) {
      if (matchingSession.bookmarks == null) matchingSession.bookmarks = [];
      matchingSession.bookmarks.push(Bookmark.fromPlain(data.bookmark));
      return sessionAdapter.updateOne({ id: data.sessionId, changes: matchingSession }, state);
    }
    return state;
  }),
  on(SessionActions.deleteBookmark, (state, data) => {
    const matchingSession = cloneDeep(selectAllSessions(state).find((x) => x.id === data.sessionId));
    if (matchingSession) {
      const matchingBookmarkIndex = matchingSession.bookmarks?.findIndex((x) => x.id === data.bookmark.id);
      if (matchingBookmarkIndex != null && matchingBookmarkIndex !== -1)
        matchingSession.bookmarks?.splice(matchingBookmarkIndex, 1);
      return sessionAdapter.updateOne({ id: data.sessionId, changes: matchingSession }, state);
    }
    return state;
  }),
  on(SessionActions.updateBookmark, (state, data) => {
    const matchingSession = cloneDeep(selectAllSessions(state).find((x) => x.id === data.sessionId));
    if (matchingSession) {
      const matchingBookmarkIndex = matchingSession.bookmarks?.findIndex((x) => x.id === data.bookmark.id);
      if (matchingBookmarkIndex != null && matchingBookmarkIndex !== -1 && matchingSession.bookmarks)
        matchingSession.bookmarks[matchingBookmarkIndex] = data.bookmark;
      return sessionAdapter.updateOne({ id: data.sessionId, changes: matchingSession }, state);
    }
    return state;
  })
);

export const { selectAll, selectEntities, selectIds, selectTotal } = sessionAdapter.getSelectors();
