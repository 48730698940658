import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import {
  Session,
  SessionCreateMessage,
  SessionTopics,
  TDMSWebSocketMessage,
  User,
  WebSocketCommunication,
} from "@tdms/common";
import { WebSocketService } from "@tdms/frontend/modules/communication/services/websocket.service";
import { Route_URLs } from "@tdms/frontend/modules/routing/models/url";
import { RouterParamTypes, RouterService } from "@tdms/frontend/modules/routing/services/router.service";
import {
  SessionInfoComponent,
  SessionInfoDialogInput,
} from "@tdms/frontend/modules/session/components/info/info.component";
import { SessionActions } from "@tdms/frontend/modules/session/store/session.action";
import {
  selectAllSessionsFromState,
  selectCurrentSession,
  selectSessionFromId,
} from "@tdms/frontend/modules/session/store/session.selector";
import { SessionState } from "@tdms/frontend/modules/session/store/session.state";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogProperties,
  DialogWrapperComponent,
} from "@tdms/frontend/modules/shared/components";
import { Service } from "@tdms/frontend/modules/shared/services/base.service";
import { UserService } from "@tdms/frontend/modules/user/services/user.service";
import { firstValueFrom } from "rxjs";
/**
 * This service handles data calculation and correlation of the current service.
 */
@Injectable({
  providedIn: "root",
})
export class SessionService extends Service {
  /** The currently initialized session */
  currentSession: Session | undefined;

  constructor(
    private store: Store<SessionState>,
    private routerService: RouterService,
    private wsService: WebSocketService,
    private userService: UserService,
    private dialog: MatDialog
  ) {
    super();
  }

  /** Given a session id, finds it's matching session object in the store */
  protected async getSessionById(sessionId: number) {
    const sessions = await firstValueFrom(this.store.select(selectAllSessionsFromState));
    return sessions.find((x) => x.id === sessionId);
  }

  /**
   * Sets the current session to the session provided by the query param, if available. If not, does not process.
   */
  async setQuerySession() {
    const sessionId = this.routerService.getQueryParam(RouterParamTypes.sessionID);
    // Only call if we have an id in the query param
    if (sessionId) {
      const matchingSession = await this.getSessionById(sessionId);
      if (matchingSession) this.store.dispatch(SessionActions.select({ session: matchingSession }));
      return matchingSession;
    }
  }

  override async onSessionChangedPost(session?: Session) {
    // Tell the router service to redirect as all sessions are loaded and services have processed
    if (session) this.routerService.redirectConsideringParams(true, true, true);
  }

  override async onSessionChanged(session?: Session): Promise<void> {
    this.currentSession = session;
    // Keep track of session in query param
    if (session) this.routerService.setQueryParam(RouterParamTypes.sessionID, session.id);
  }

  override async onUserChanged(_: User): Promise<void> {
    const response = await this.wsService.sendAndReceive<Object[]>(
      new TDMSWebSocketMessage(SessionTopics.getAll, undefined)
    );
    const sessions = Session.fromPlainArray(response.payload);
    this.store.dispatch(SessionActions.emptySessions({}));
    this.store.dispatch(SessionActions.addManySessions({ sessions: sessions }));
    // Associate the current query session once the user is logged in. We know all sessions will be loaded because they are loaded right above.
    await this.setQuerySession();
  }

  override async onBackendDisconnected(): Promise<void> {
    // Handle disconnects
    // Redirect to login, remembering some important params
    const currentRoute = this.routerService.currentRouteBaseUrl;
    // Get query params so we can remember some details when the backend reconnects
    const queryParams = this.routerService.getAllQueryParams();
    // Create the original request route to remember, so long as it's not login.
    const originalRequestURL = currentRoute != null && currentRoute === Route_URLs.login ? undefined : currentRoute;
    queryParams[RouterParamTypes.originalRequestURL] = originalRequestURL;
    queryParams[RouterParamTypes.sessionID] = this.currentSession ? this.currentSession.id : null;
    this.routerService.redirectTo(Route_URLs.login, queryParams);
    // Wipe session data
    this.store.dispatch(SessionActions.emptySessions({}));
    this.store.dispatch(SessionActions.select({ session: undefined }));
    this.currentSession = undefined;
    // Reset authentication status on connect lost
    this.userService.setAuthenticationState(undefined);
    this.userService.isAwaitingLoginStatus = false;
    this.userService.previousLoginResponse = undefined;
    this.initialize();
  }

  override async onSessionUpdated(session: Session) {
    this.currentSession = session;
  }

  /**
   * Listens for new sessions created from the backend
   */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<Object[]>>(SessionTopics.new)
  async newSessionsReceived(data: TDMSWebSocketMessage<Object[]>) {
    this.store.dispatch(SessionActions.addManySessions({ sessions: Session.fromPlainArray(data.payload) }));
  }

  /**
   * Listens for session updates from the websocket
   */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<Session>>(SessionTopics.update)
  async sessionUpdateReceived(data: TDMSWebSocketMessage<Session>) {
    this.store.dispatch(SessionActions.updateSession(Session.fromPlain(data.payload)));
  }

  /**
   * Listens for session deletions from the websocket
   */
  @WebSocketCommunication.listen<void, TDMSWebSocketMessage<Session>>(SessionTopics.delete)
  async sessionDeleteReceieved(data: TDMSWebSocketMessage<Session>) {
    // Deselect this session
    if (this.currentSession?.id === data.payload.id) {
      this.routerService.redirectTo(Route_URLs.sessionSelection);
      SessionActions.select({ session: undefined });
    }
    // Remove it from the store
    this.store.dispatch(SessionActions.deleteSession(data.payload));
  }

  /** Returns a promise the waits for the sessions to be loaded */
  async waitForSessionLoad() {
    return await firstValueFrom(this.store.select(selectCurrentSession));
  }

  /**
   * Helper function that, given a session id, returns the loaded session instance matching it.
   * This function will also block to ensure that session data has been loaded from the backend.
   * @param id the session id
   * @returns The loaded session, or undefined if not found.
   */
  async lookupSessionById(id: number): Promise<Session | undefined> {
    await this.waitForSessionLoad();
    return firstValueFrom(this.store.select(selectSessionFromId(id)));
  }

  /** Given a session, informs the backend that we would like to create a new session with this information */
  async create(session: Session) {
    return await this.wsService.sendAndReceive<SessionCreateMessage>(
      new TDMSWebSocketMessage(
        SessionTopics.newFromScratch,
        undefined,
        SessionCreateMessage.fromPlain({ session: session })
      )
    );
  }

  /** Opens a dialog to edit the given session */
  editSession(session: Session | undefined, event: MouseEvent) {
    event.stopPropagation();
    if (session == null) return;
    // Open the session info modal
    this.dialog.open(SessionInfoComponent, {
      ...DialogWrapperComponent.getDefaultOptions(),
      data: { session: session, timeEditingEnabled: false, roleMappingEditingEnabled: true } as SessionInfoDialogInput,
    });
  }

  /** Given a session, informs the backend that we would like to edit it's information. */
  async edit(session: Session) {
    return await this.wsService.sendAndReceive<SessionCreateMessage>(
      new TDMSWebSocketMessage(
        SessionTopics.updateRequest,
        undefined,
        SessionCreateMessage.fromPlain({ session: session })
      )
    );
  }

  /** Confirms that we want to regenerate metrics based on the original file */
  regenerateMetricData() {
    const ref = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        header: "Metric Regeneration",
        description:
          "Are you sure you want to regenerate the metrics of this session based on the original file? This cannot be undone!",
        confirmButtonText: "Confirm",
        confirmButtonColor: "primary",
        confirmClickCallback: () => {
          this.dialog.closeAll();
          this.wsService.send(new TDMSWebSocketMessage(SessionTopics.regenerateMetrics));
        },
        cancelClickCallback: () => ref.close(),
      } as Partial<ConfirmationDialogProperties>,
      ...DialogWrapperComponent.getDefaultOptions(),
    });
  }
}
