import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DataStoreState } from "@tdms/frontend/modules/data-store/models/data.store.state";

/**
 * Base data store selector from state
 */
const selectDataStore = createFeatureSelector<DataStoreState>("dataStore");

/**
 * Get's the metric configuration from state
 */
export const selectDataStoreUploadOptions = createSelector(selectDataStore, (ds) => ds.supportedUploadTypes);

/** Selects and returns all files for the current session */
export const selectFilesForSession = createSelector(selectDataStore, (ds) => ds.files);

/** Selects and returns all files that should be in the recycle bin */
export const selectRecycleBinFiles = createSelector(selectDataStore, (ds) => ds.recycleBin);
