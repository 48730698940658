import { Component } from "@angular/core";
import { DataStoreAccessLog, Utility } from "@tdms/common";
import { AccessLogService } from "@tdms/frontend/modules/data-store/services/access-log.service";
import { DataColumn } from "@tdms/frontend/modules/shared/components";

/** Definition of internal filter reasons for access log related to internal TDMS code requests */
export const ACCESS_INTERNAL_FILTER = ["role-mapping", "waveform display", "speech-processing"];

/**
 * Access log tab displayed on the data store page.
 */
@Component({
  selector: "access-log",
  templateUrl: "./access-log.component.html",
  styleUrls: ["./access-log.component.scss"],
})
export class AccessLogComponent {
  accessLog!: DataStoreAccessLog[];

  /**
   * Bool to filter "waveform display" values form access log table
   */
  filterAccessLog: boolean = false;

  /**
   * The columns we wish to display for our table
   */
  displayedColumns = [
    new DataColumn<DataStoreAccessLog, number>("id", "ID"),
    new DataColumn<DataStoreAccessLog, string>("username", "Username"),
    new DataColumn<DataStoreAccessLog, string>(
      "fileName" as any,
      "File Name",
      (_, obj) => obj.dataStoreFile.uncompressedFileName
    ),
    new DataColumn<DataStoreAccessLog, string>(
      "plugin" as any,
      "Matching Plugin",
      (_, obj) => obj.dataStoreFile.matchingPlugin
    ),
    new DataColumn<DataStoreAccessLog, string>("reason", "Access Reason"),
    new DataColumn<DataStoreAccessLog, Date>("accessedOn", "Accessed On", Utility.getDateAsZulu.bind(this)),
  ];

  constructor(private accessLogService: AccessLogService) {
    this.accessLogClicked = this.accessLogClicked.bind(this);
  }

  ngOnInit() {
    this.loadAccessLog();
  }

  /**
   * Load our access log data from the backend.
   */
  async loadAccessLog() {
    this.filterAccessLog = !this.filterAccessLog;
    this.accessLog = await this.accessLogService.getAccessLog();
    if (this.filterAccessLog) {
      this.filterAccessLogValues();
    }
  }

  /**
   * Handle an access log being clicked.
   * TODO: Implement drill-down access log view.
   * @param entry The log that was clicked.
   */
  accessLogClicked(_entry: DataStoreAccessLog) {}

  /**
   * A function for converting a Date object to a better string format
   */
  formatTime(date: Date) {
    return Utility.getDateAsZulu(date);
  }

  /** Filters the access log to exclude internal TDMS requests */
  filterAccessLogValues() {
    this.accessLog = this.accessLog.filter((x) => !ACCESS_INTERNAL_FILTER.includes(x.reason.toLowerCase()));
  }
}
